import todaysDate from './todaysDate';
import determineTradingDayOfWeek from './determineTradingDayOfWeek';
import determineMarketOpenTimestamp from './determineMarketOpenTimestamp';
import determineMarketCloseTimestamp from './determineMarketCloseTimestamp';

const main = () => {
	let currentUtcDateString = todaysDate();
	let currentTime = (new Date()).getTime();
	let tradingDayOfWeek = determineTradingDayOfWeek(currentUtcDateString);
	let timeOfMarketOpen = determineMarketOpenTimestamp(currentUtcDateString);
	let timeOfMarketPreOpen = timeOfMarketOpen + (5 * 60 * 60 * 1000 * -1) + (30 * 60 * 1000 * -1);
	let timeOfMarketClosed = determineMarketCloseTimestamp(currentUtcDateString);
	let timeOfMarketPostClosed = timeOfMarketClosed + (4 * 60 * 60 * 1000);

	if (!tradingDayOfWeek || currentTime <= timeOfMarketPreOpen || currentTime >= timeOfMarketPostClosed) {
		// console.log('closed');
		return false;
	}

	// if (timeOfMarketPreOpen <= currentTime && timeOfMarketOpen >= currentTime) { setMarket('pre'); }
	// else if (timeOfMarketOpen <= currentTime && timeOfMarketClosed >= currentTime) { setMarket('open'); }
	// else if (timeOfMarketClosed <= currentTime && timeOfMarketPostClosed >= currentTime) { setMarket('post'); }


	return true;
};

export default main;