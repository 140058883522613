import React, { useEffect, useState } from 'react';
import config from '../config.json';
import LOADING from "../components/LOADING";
import { useSearchParams } from 'react-router-dom';
import dontRerender from "../bin/dontRerender";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import removeCentsFromStrike from "../bin/removeCentsFromStrike";
import daysBetween from "../bin/daysBetween";
import abbrInt from "../bin/abbrInt";
import isOiProbableUi from "../bin/isOiProbableUi";

const MAIN = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const urlId = searchParams.get("id");
	const urlToken = searchParams.get("token");
	const [trade, setTrade] = useState({});
	const [LoadingValue, setLoadingValue] = useState(true);
	const [Unauthorized, setUnauthorized] = useState(false);

	const start = async () => {
		if (!urlToken || !urlId) {
			setLoadingValue(false);
			setUnauthorized(true);
			return;
		}
		setLoadingValue(true);
		let headers = { 'Content-Type': 'application/json', 'Authorization': urlToken };
		let _trade = await fetch(`${config.api}/notabletrades?id=${urlId}`, { headers }).then(d => d.json()).then(d => d.message === 'ok' && d.result && d.result[0] ? d.result[0] : d);
		let upcomingEarnings = await fetch(`${config.api}/earnings?tickers=${_trade.UnderlyingTicker}&upcoming=true&date=${_trade.EntryDateString}`, { headers }).then(d => d.json()).then(d => d.message === 'ok' && d.result ? d.result : null).then(d => d && d[0] ? d[0] : null);
		if (upcomingEarnings) {
			let ExpirationDate = new Date(_trade.ExpirationDateString);
			ExpirationDate.setUTCHours(0, 0, 0, 0);
			let EarningsDate = new Date(upcomingEarnings.EarningsDateString);
			if (EarningsDate <= ExpirationDate) {
				_trade.EarningsDateString = `${EarningsDate.getUTCFullYear()}-${(EarningsDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${EarningsDate.getUTCDate().toString().padStart(2, '0')}`;
				_trade.EarningsTime = upcomingEarnings.EarningsTime;
				_trade.EarningsDaysTill= daysBetween(upcomingEarnings.EarningsDateString, _trade.EntryDateString);
			}
		}
		setTrade(_trade);
		setLoadingValue(false);
		setUnauthorized(false);
	};

	useEffect(() => { start(); }, []);

	return (
		<div className='w-[1000px] h-[300px]'>
			{Unauthorized === true && <div className='w-full'><h1 className="text-center my-10 text-white">UNAUTHORIZED</h1></div>}
			{LoadingValue === true && <div className='w-full'><LOADING color="text-green-700" /></div>}
			{LoadingValue === false ?
				<table className="divide-y divide-gray-400 w-full self-start text-xs whitespace-nowrap">
					<thead>
						<tr className="divide-x divide-gray-400 bg-gray-800 text-gray-200 bg-opacity-50 text-xl text-left">
							<th scope="col" className="font-medium tracking-wider w-[250px] p-2">Trade</th>
							<th scope="col" className="font-medium tracking-wider w-[90px] p-2">Low</th>
							<th scope="col" className="font-medium tracking-wider w-[90px] p-2">High</th>
							<th scope="col" className="font-medium tracking-wider w-[90px] p-2">Last</th>
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-800'>
						<tr className='divide-x divide-gray-800 bg-opacity-25 text-left bg-gray-800 text-xl text-gray-300'>
							<td className="w-full p-2 font-light align-top block text-left">
								<span className='font-bold'>{ trade.UnderlyingTicker }</span> - <span className={`${ trade.OptionType==='PUT' ? 'text-red-700' : '' }${ trade.OptionType==='CALL' ? 'text-green-700' : '' }`}>{ trade.OptionType }</span> - <span className='text-amber-600'>${ removeCentsFromStrike(Number((trade.OptionStrike).toFixed(2)).toFixed(2).toLocaleString()) }</span><br/>
								{ (trade.Size).toLocaleString() } @ ${ ( Number((trade.Price).toFixed(2)) ).toFixed(2).toLocaleString() } = <span className='font-light text-amber-300'>${ abbrInt(trade.Notional) }</span><br/>
								<span className='flex flex-row w-100 justify-start items-between'>
									<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Trade Date:</span><span className="ml-1 font-light w-100 text-center text-sky-700">{ trade.EntryDateString }</span>{isNaN(trade.DaysToExpiration) === false ? <span className='text-red-500 ml-1'>{trade.DaysToExpiration} DTE</span> : ''}
								</span>
								{trade.Expired ?
									<span className='block font-medium bg-rose-900 text-gray-300 w-100 text-center'>expired</span> :
									<span className='flex flex-row w-100 justify-start items-between'>
										<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Expires:</span><span className="ml-1 font-light w-100 text-center text-rose-600">{ trade.ExpirationDateString }</span>
									</span>
								}
								<span className='flex flex-row w-100 justify-between items-center'>
									{ trade.TradeSide==="BUY" ? <span className='text-green-500 mr-1'>BUY SIDE</span> : ''}
									{ trade.TradeSide==="SELL" ? <span className='text-red-500 mr-1'>SELL SIDE</span> : ''}
									<span className='text-gray-500'>{trade.EntryTimeString}</span>
								</span>
								{trade.OpenInterestIncreasedNetProjectionMinimumAfterTrade ? <><span className='block font-medium text-amber-500 w-100 text-center'>oi confirmed</span></> : isOiProbableUi(trade)}
								{!trade.OpenInterestIncreasedNetProjectionMinimumAfterTrade && trade.OpenInterestIncreasedExceedsTradeSizePotentiallyOpen ? <><br /><span className='block font-medium text-violet-700 w-100 text-center'>oi supports open position</span></> : <></>}
								{trade.UnderlyingPriceAtTrade ?
									<span className='flex flex-row w-100 justify-between items-center'>
										<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Underlying:</span>
										<span className={`ml-1 font-light w-100 text-center ${trade.Moneyness === 'OTM' ? `text-amber-200` : ``}${trade.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>${(Number((trade.UnderlyingPriceAtTrade).toFixed(2))).toFixed(2).toLocaleString()}</span>
										<span className={`mr-1 font-light w-100 text-right flex-grow ${trade.Moneyness === 'OTM' ? `text-amber-500` : ``}${trade.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>{trade.Moneyness}</span>
									</span>
								:<></>}
								{trade.BreakevenPrice && trade.BreakevenMove ? 
									<span className='flex flex-row w-100 justify-between items-center'>
										<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Breakeven:</span>
										<span className="ml-1 font-light w-100 text-center text-indigo-200">${(Number((trade.BreakevenPrice).toFixed(2))).toFixed(2).toLocaleString()}</span>
										<span className="mr-1 font-light w-100 text-right flex-grow text-indigo-400">{trade.BreakevenMove}%</span>
									</span>
								:<></>}
								{trade.EarningsDateString ?
									<span className='flex flex-row w-100 bg-amber-950 w-100 justify-between items-center'>
										<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Earnings:</span>
										<span className="ml-1 font-light w-100 text-center text-amber-300">{ trade.EarningsDateString }</span>
										{trade.EarningsTime && trade.EarningsTime !== 'Unknown' ? <span className="ml-1 font-light text-center text-amber-200 text-[8px]">{(trade.EarningsTime).toLowerCase()}</span> : <></>}
										<span className='text-yellow-500 mr-1 flex-grow text-right'>{trade.EarningsDaysTill}d</span>
									</span>
								:<></>}
							</td>
							<td className="w-[90px] p-2 font-light align-top">
								<div className='flex flex-col gap-2'>
									{trade.LowestPercent === 0 && trade.EndingLowestPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
									{trade.LowestPercent != 0 ?
										<span>
											<span className={`font-medium ${trade.LowestPercent < 0 ? `text-red-700` : `text-gray-500`}`}>{(trade.LowestPercent).toLocaleString()}%</span><span className='ml-1 font-light text-amber-700 text-[18px]'>same day</span><br />
											${(trade.Lowest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.Lowest * trade.Size * trade.SharesPerContract),false)}</span><br />
											<span className='text-sky-600'>{trade.TimeToLow}</span>
										</span>
									: <></>}
									{trade.EndingLowestPercent != 0 ?
										<span>
											<span className={`font-medium ${trade.EndingLowestPercent < 0 ? `text-red-700` : `text-gray-500`}`}>{(trade.EndingLowestPercent).toLocaleString()}%</span><br />
											${(trade.EndingLowest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EndingLowest * trade.Size * trade.SharesPerContract),false)}</span><br />
											<span className='text-sky-600'>{trade.TimeToEndingLowest}</span>
										</span>
									: <></>}
								</div>
							</td>
							<td className="w-[90px] p-2 font-light align-top">
								<div className='flex flex-col gap-2'>
									{trade.HighestPercent === 0 && trade.EndingHighestPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
									{trade.HighestPercent != 0 ?
										<span className='w-100'>
											<span className={`font-medium ${trade.HighestPercent > 0 ? `text-green-700` : `text-gray-500`}`}>{(trade.HighestPercent).toLocaleString()}%</span><span className='ml-1 font-light text-amber-700 text-[18px]'>same day</span><br />
											${(trade.Highest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.Highest * trade.Size * trade.SharesPerContract),false)}</span><br />
											<span className='text-sky-600'>{trade.TimeToHigh}</span>
										</span>
									: <></>}
									{trade.EndingHighestPercent != 0 ?
										<span className='w-100'>
											<span className={`font-medium ${trade.EndingHighestPercent > 0 ? `text-green-700` : `text-gray-500`}`}>{(trade.EndingHighestPercent).toLocaleString()}%</span><br />
											${(trade.EndingHighest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EndingHighest * trade.Size * trade.SharesPerContract),false)}</span><br />
											<span className='text-sky-600'>{trade.TimeToEndingHighest}</span>
										</span>
									: <></>}
								</div>
							</td>
							<td className="w-[90px] p-2 font-light align-top bg-gradient-to-b from-indigo-900 to-ingigo-800">
								<div className='flex flex-col gap-2'>
									{trade.EODPercent === 0 && trade.EndingLastPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
									{trade.EODPercent != 0 ?
										<span>
											<span className={`font-medium ${trade.EODPercent>0 ? `text-green-700` : ``} ${trade.EODPercent<0 ? `text-red-700` : ``} ${trade.EODPercent===0 ? `text-gray-500` : ``}`}>{ (trade.EODPercent).toLocaleString() }%</span><span className='ml-1 font-light text-amber-700 text-[18px]'>same day</span><br />
											${(trade.EOD).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EOD * trade.Size * trade.SharesPerContract),false,false)}</span><br />
											<span className='text-sky-600'>{trade.TimeToEOD}</span>
										</span>
									: <></>}
									{trade.EndingLastPercent != 0 ?
										<span>
											<span className={`font-medium ${trade.EndingLastPercent>0 ? `text-green-700` : ``} ${trade.EndingLastPercent<0 ? `text-red-700` : ``} ${trade.EndingLastPercent===0 ? `text-gray-500` : ``}`}>{(trade.EndingLastPercent).toLocaleString() }%</span><span className='ml-1 font-light text-amber-700 text-[18px]'>last</span><br />
											${(trade.EndingLast).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EndingLast * trade.Size * trade.SharesPerContract),false,false)}</span><br />
											<span className='text-sky-600'>{trade.TimeToEndingLast}</span>
										</span>
									: <></>}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			: <></>}
    </div>
  );
}

export default React.memo(MAIN, dontRerender);