import Chart from 'chart.js/auto';
import React, { useEffect, useState, useRef } from 'react';
import dontRerender from '../bin/dontRerender';
import truncateToTwoDigits from '../bin/truncateToTwoDigits';
import Headers from '../bin/Headers';
import { useSelector } from 'react-redux';
import config from '../config.json';
import LOADING from "./LOADING";

const MAIN = (props) => {
	let token = useSelector(state => state.token),
		ticker = props.ticker,
		entryprice = props.entryprice,
		entrysize = props.entrysize,
		entrydate = props.entrydate,
		starttime = props.starttime,
		charttiming = props.charttiming;
	const initialized = useRef(false),
		candles = useRef(null),
		chartRef = useRef(null),
		[LoadingValue, setLoadingValue] = useState(true),
  	[stats, setStats] = useState({}),
  	[format, setFormat] = useState(charttiming || 'minute');

	const fetchCandles = async (payload) => {
		starttime = entrydate && starttime && starttime.includes(':') ? (new Date(`${entrydate}T${starttime}`)).getTime() : starttime;
		candles.current = await fetch(`https://io.redstripedtie.com/ursa/candles_p3?ticker=${ticker}&format=${payload && payload.format ? payload.format : format}${starttime ? `&startTime=${starttime}` : ''}`, { headers: Headers(token) })
      .then((d) => (d.status === 200 ? d.json() : null))
      .then((d) => (d && d.result && d.result.length ? d.result : null));
  };
  
	const addDataset = (chart, newDataset) => {
		chart.data.datasets.push(newDataset);
		chart.update();
	};

	const generateChart = ()=>{
		chartRef.current = new Chart(document.getElementById('ChartSpace').getContext('2d'), {
			type: 'line',
			data: {
				labels:[],
				datasets: [
					{ name:'high', type:'line', data:[], borderColor:'rgba(7, 207, 0, 0)', radius:0, tension: 0.1, },
					{ name:'low', type:'line', data:[], borderColor:'rgba(207, 0, 0, 0.5)', radius:0, tension: 0.1, },
					{ name:'highesthigh', type:'bubble', pointStyle:'bubble', data:[], backgroundColor:'rgb(7, 207, 0)', },
					{ name:'lowestlow', type:'bubble', pointStyle:'bubble', data:[], backgroundColor:'rgb(207, 0, 0)', },
					{ name:'bluesignal', type:'bubble', pointStyle:'bubble', data:[], backgroundColor:'rgb(0, 213, 255)', },
					{ name:'newdayline', type:'line', data:[], borderColor:'rgba(75, 192, 192, 0.25)', fill:false, pointRadius:1, borderWidth:2 },
				],
			},
			options: {
				responsive: true,
				animation: false,
				scales: {
					y: {
						beginAtZero: true
					}
				},
				elements: {
					line: {
						tension: 0.1 // Disable bezier curves for a straight line
					}
				},
				plugins:{
					legend:{
						display: false,
					},
					tooltip: {
						enabled: true,
					},
				},
				scales: {
					x: {
						ticks: {
							callback: function(value, index, ticks) {
								let time = this.getLabelForValue(value);
								return `${time.DATE} ${time.TIME}`;
							}
						}
					},
				}
			},
		});
	};

	const addChartData = (chart, label, payload)=>{
    chart.data.labels.push(label);
    chart.data.datasets.forEach((dataset) => {
			if (payload.hasOwnProperty(dataset.name)){
				dataset.data.push( payload[dataset.name] );
			}else if (dataset.type==='line'){
				dataset.data.push( null );
			}
		});
    chart.update();
	};

	const loadCandles = ()=>{
		let _candles = [...candles.current];
		let hh = _candles.map( ({High}) => High ).sort( (a,b) => b-a );
		hh = hh && hh.length ? hh[0] : null;
		let ll = _candles.map( ({Low}) => Low ).sort( (a,b) => a-b );
		ll = ll && ll.length ? ll[0] : null;
		let currentdate = _candles.length > 0 ? _candles[0].DateString : null;
		for (let i = 0; i < _candles.length; ++i){
			let isANewDay = currentdate && currentdate !== _candles[i].DateString;
			let payload = {
				high: _candles[i].High,
				low: _candles[i].Low,
				newdayline: null,
				bluesignal: (entryprice && i===0 ? entryprice : null),
				highesthigh: (_candles[i].High===hh) ? _candles[i].High : null,
				lowestlow: (_candles[i].Low===ll) ? _candles[i].Low : null,
			};
			if (isANewDay) {
        currentdate = _candles[i].DateString;  // Update the current date to the new day
        // Add an additional chart data point right after the top one, but with the lowest low value.
        // let _DateString = (_candles[i].DateString).slice(-5);
        // let _TimeString = (_candles[i].TimeString).slice(0,5);
        // let label = { DATE: _DateString, TIME: _TimeString };
				// addChartData(chartRef.current, label, { ...payload, newdayline: ll });
				// payload.newdayline = ll;
			}
			
			let label = null;
			let _DateString = (_candles[i].DateString).slice(-5);
			let _TimeString = (_candles[i].TimeString).slice(0,5);
			label = { DATE: _DateString, TIME: _TimeString };
			addChartData(chartRef.current, label, payload);
		}
	};

	const loadStats = () => {
		let _candles = [...candles.current];
		let _stats = {
			entryTime: null,
			sameDayOpen: null,
			sameDayHigh: null,
			sameDayHighPercent: null,
			sameDayHighTime: null,
			sameDayLow: null,
			sameDayLowPercent: null,
			sameDayLowTime: null,
			nextDayOpenDate: null,
			nextDayOpen: null,
			nextDayOpenPercent: null,
			nextDayHigh: null,
			nextDayHighPercent: null,
			nextDayHighTime: null,
			nextDayLowBeforeHigh: null,
			nextDayLowPercentBeforeHigh: null,
			nextDayLowTimeBeforeHigh: null,
			potentialTrimTimes: [],
			potentialExitTime: null,
			perfectReturn: null,
			perfectReturnPercent: null,
		};
		for (let candle of _candles) {
			if (!_stats.entryTime) { _stats.entryTime = (candle.TimeString).slice(0,5); }
			if (candle.DateString !== entrydate && !_stats.potentialExitTime && candle.Volume>entrysize) {
				_stats.potentialExitHigh = candle.High;
				_stats.potentialExitLow = candle.Low;
				_stats.potentialExitTime = `${(candle.DateString).slice(-5)}@${(candle.TimeString).slice(0,5)}`;
			}
			if (candle.DateString !== entrydate && !_stats.nextDayOpenDate) {
				_stats.nextDayOpenDate = candle.DateString;
				_stats.nextDayOpen = candle.Open;
				_stats.nextDayOpenPercent = truncateToTwoDigits(((candle.Open / Number(entryprice || _stats.nextDayOpen))-1)*100);;
			}
			if (candle.DateString === _stats.nextDayOpenDate && (!_stats.nextDayHigh || candle.High>_stats.nextDayHigh)) {
				_stats.nextDayHigh = candle.High;
				_stats.nextDayHighPointId = `${candle.DateString}_${candle.TimeString}`;
				_stats.nextDayHighPercent = truncateToTwoDigits(((candle.High / Number(entryprice || _stats.nextDayOpen))-1)*100);
				_stats.nextDayHighTime = `${(candle.TimeString).slice(0,5)}`;
			}
			if (candle.DateString !== entrydate && candle.Volume >= (entrysize * 0.25)) {
				_stats.potentialTrimTimes.push(`${(candle.DateString).slice(-5)}@${(candle.TimeString).slice(0,5)}`);
			}
			if (candle.DateString === entrydate && !_stats.sameDayOpen && candle.Open) {
				_stats.sameDayOpen = candle.Open;
			}
			if (candle.DateString === entrydate && (!_stats.sameDayHigh || candle.High>_stats.sameDayHigh)) {
				_stats.sameDayHigh = candle.High;
				_stats.sameDayHighPercent = truncateToTwoDigits(((candle.High / Number(entryprice || _stats.sameDayOpen))-1)*100);
				_stats.sameDayHighTime = `${(candle.TimeString).slice(0,5)}`;
			}
			if (candle.DateString === entrydate && (!_stats.sameDayLow || candle.Low<_stats.sameDayLow)) {
				_stats.sameDayLow = candle.Low;
				_stats.sameDayLowPercent = truncateToTwoDigits(((candle.Low / Number(entryprice || _stats.sameDayOpen))-1)*100);
				_stats.sameDayLowTime = `${(candle.TimeString).slice(0,5)}`;
			}
		}
		for (let candle of _candles) {
			if (candle.DateString !== _stats.nextDayOpenDate) { continue; }
			let Id = `${candle.DateString}_${candle.TimeString}`;
			if (!_stats.nextDayLowBeforeHigh || candle.Low<_stats.nextDayLowBeforeHigh) {
				_stats.nextDayLowBeforeHigh = candle.Low;
				_stats.nextDayLowPercentBeforeHigh = truncateToTwoDigits(((candle.Low / Number(entryprice || _stats.nextDayOpen))-1)*100);
				_stats.nextDayLowTimeBeforeHigh = `${(candle.TimeString).slice(0,5)}`;
			}
			if (Id === _stats.nextDayHighPointId) { break;}
		}
		// if (!_stats.perfectReturn && _stats.nextDayHigh && _stats.nextDayLowBeforeHigh && _stats.sameDayLow) {
		if (!_stats.perfectReturn && _stats.nextDayHigh && _stats.nextDayLowBeforeHigh) {
			// let perfectLow = _stats.sameDayLow < _stats.nextDayLowBeforeHigh ? _stats.sameDayLow : _stats.nextDayLowBeforeHigh;
			let perfectLow = _stats.nextDayLowBeforeHigh;
			_stats.perfectReturn = truncateToTwoDigits(_stats.nextDayHigh + (perfectLow * -1));
			_stats.perfectReturnPercent = truncateToTwoDigits(((_stats.nextDayHigh / perfectLow)-1)*100);
		}
		setStats(_stats);
	};

	const updateCandles = async(payload)=>{
		if (payload.format){ setFormat(payload.format); }
		chartRef.current.destroy();
		generateChart();
		await fetchCandles(payload);
		loadCandles();
		setLoadingValue(false);
	};

	const start = async()=>{
		if (initialized.current!==false){ return; }
		initialized.current = true;
		generateChart();
		await fetchCandles();
		await loadCandles()
		await loadStats();
		setLoadingValue(false);
	};

  useEffect(() => { start(); }, [ticker]);

	return (
		<div>
			{ LoadingValue===true && 
				<div className='w-full'>
				<LOADING color="text-green-700" />
				</div>
			}
			<div className={`${LoadingValue===true ? `hidden` : `flex`} max-w-full sm:max-w-none max-h-fit flex-grow flex-col justify-start md:ml-4 gap-4`}>
				<div className='max-w-full sm:max-w-none max-h-fit flex-grow flex-col justify-start md:ml-4 gap-4 rounded border bg-gray-800'>
					<canvas id="ChartSpace" />
				</div>
					<div className="mt-2 w-full flex flex-col items-start justify-center px-2">
					{entryprice ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-full text-left text-sm'>Entry:</span>
							<span className="ml-1 font-light w-full flex flex-col items-center justify-end gap-1">
								{entryprice ? <span className="font-bold text-amber-500 text-md w-full text-right">${(entryprice).toLocaleString()}</span> : `-`}
								<span className='flex flex-row items-center justify-end gap-1 w-full'>
									{stats && stats.entryTime ? <span className="w-14 font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded flex items-center justify-center text-xs">{stats.entryTime}</span> : ``}
								</span>
							</span>
						</span>
						: ''
					}
					<h5 className="text-center my-0 w-full">Same Day</h5>
					{entrydate && stats.sameDayOpen ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-full text-left text-sm'>Open:</span>
							<span className="ml-1 font-light w-full flex flex-row items-center justify-end gap-1">
								{stats.sameDayOpen ? <span className="font-bold text-amber-500 text-md">${(stats.sameDayOpen).toLocaleString()}</span> : `-`}
							</span>
						</span>
						: ''
					}
					{entrydate && stats.sameDayHighPercent ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-full text-left text-sm'>High:</span>
							<span className="ml-1 font-light w-full flex flex-col items-center justify-end gap-1">
								{stats.sameDayHighPercent ? <span className="font-bold text-green-500 text-md w-full text-right">{(stats.sameDayHighPercent).toLocaleString()}%</span> : `-`}
								<span className='flex flex-row items-center justify-end gap-1 w-full'>
									{stats.sameDayHighPercent && stats.sameDayHigh ? <span className="font-light text-center text-green-700 px-2 py-0 bg-green-300 rounded inline-flex items-center justify-center text-xs">${(stats.sameDayHigh).toLocaleString()}</span> : ``}
									{stats.sameDayHighPercent && stats.sameDayHighTime ? <span className="w-14 font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded flex items-center justify-center text-xs">{stats.sameDayHighTime}</span> : ``}
								</span>
							</span>
						</span>
						: ''
					}
					{entrydate && stats.sameDayLowPercent ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-full text-left text-sm'>Low:</span>
							<span className="ml-1 font-light w-full flex flex-col items-center justify-end gap-1">
								{stats.sameDayLowPercent ? <span className="font-bold text-red-500 text-md w-full text-right">{(stats.sameDayLowPercent).toLocaleString()}%</span> : `-`}
								<span className='flex flex-row items-center justify-end gap-1 w-full'>
									{stats.sameDayLowPercent && stats.sameDayLow ? <span className="font-light text-center text-red-700 px-2 py-0 bg-red-300 rounded inline-flex items-center justify-center text-xs">${(stats.sameDayLow).toLocaleString()}</span> : ``}
									{stats.sameDayLowPercent && stats.sameDayLowTime ? <span className="w-14 font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded flex items-center justify-center text-xs">{stats.sameDayLowTime}</span> : ``}
								</span>
							</span>
						</span>
						: ''
					}
					{entrydate && stats.nextDayOpen ? <h5 className="text-center my-0 w-full mt-5">Next Day</h5> : ''}
					{entrydate && stats.nextDayOpenPercent ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-full text-left text-sm'>Open:</span>
							<span className="ml-1 font-light w-full flex flex-row items-center justify-end gap-1">
								{stats.nextDayOpen ? <span className="font-light text-center text-amber-700 px-2 py-0 bg-amber-300 rounded inline-flex items-center justify-center text-xs">${(stats.nextDayOpen).toLocaleString()}</span> : ``}
								{stats.nextDayOpenPercent ? <span className="font-bold text-amber-500 text-md">{(stats.nextDayOpenPercent).toLocaleString()}%</span> : `-`}
							</span>
						</span>
						: ''
					}
					{entrydate && stats.nextDayHighPercent ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
						<span className='font-light text-gray-400 w-full text-left text-sm'>High:</span>
							<span className="ml-1 font-light w-full flex flex-col items-center justify-end gap-1">
								{stats.nextDayHighPercent ? <span className="font-bold text-green-500 text-md w-full text-right">{(stats.nextDayHighPercent).toLocaleString()}%</span> : `-`}
								<span className='flex flex-row items-center justify-end gap-1 w-full'>
									{stats.nextDayHighPercent && stats.nextDayHigh ? <span className="font-light text-center text-green-700 px-2 py-0 bg-green-300 rounded inline-flex items-center justify-center text-xs">${(stats.nextDayHigh).toLocaleString()}</span> : ``}
									{stats.nextDayHighPercent && stats.nextDayHighTime ? <span className="w-14 font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded flex items-center justify-center text-xs">{stats.nextDayHighTime}</span> : ``}
								</span>
							</span>
						</span>
						: ''
					}
					{entrydate && stats.nextDayLowPercentBeforeHigh ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-full text-left text-sm'>Low Before High:</span>
							<span className="ml-1 font-light w-full flex flex-col items-center justify-end gap-1">
								{stats.nextDayLowPercentBeforeHigh ? <span className="font-bold text-red-500 text-md w-full text-right">{(stats.nextDayLowPercentBeforeHigh).toLocaleString()}%</span> : `-`}
								<span className='flex flex-row items-center justify-end gap-1 w-full'>
									{stats.nextDayLowPercentBeforeHigh && stats.nextDayLowBeforeHigh ? <span className="font-light text-center text-red-700 px-2 py-0 bg-red-300 rounded inline-flex items-center justify-center text-xs">${(stats.nextDayLowBeforeHigh).toLocaleString()}</span> : ``}
									{stats.nextDayLowPercentBeforeHigh && stats.nextDayLowTimeBeforeHigh ? <span className="w-14 font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded flex items-center justify-center text-xs">{stats.nextDayLowTimeBeforeHigh}</span> : ``}
								</span>
							</span>
						</span>
						: ''
					}
					{entrydate && stats.perfectReturnPercent ?
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-full text-left text-sm'>Optimal:</span>
							<span className="ml-1 font-light w-full flex flex-col items-center justify-end gap-1">
								{stats.perfectReturnPercent ? <span className="font-bold text-violet-500 text-md w-full text-right">{(stats.perfectReturnPercent).toLocaleString()}%</span> : `-`}
							</span>
						</span>
						: ''
					}
					{entrydate && entrysize && stats && stats.potentialExitTime ?
						<span className="flex flex-row w-full items-start justify-between gap-1 mt-5">
						<span className='font-light text-gray-400 w-full text-left text-sm'>Potential Exit:</span>
							<span className="ml-1 font-light w-full flex flex-col items-end justify-end gap-1">
								<span className='flex flex-row items-center justify-end gap-1 w-full'>
									{stats.potentialExitLow ? <span className="ml-1 font-light text-center text-red-700 px-2 py-0 bg-red-300 rounded inline-flex items-center justify-center text-xs">${(stats.potentialExitLow).toLocaleString()}</span> : ``}
									{stats.potentialExitHigh ? <span className="ml-1 font-light text-center text-green-700 px-2 py-0 bg-green-300 rounded inline-flex items-center justify-center text-xs">${(stats.potentialExitHigh).toLocaleString()}</span> : ``}
								</span>
								{stats.potentialExitTime ? <span className="font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded flex items-center justify-center text-xs">{stats.potentialExitTime}</span> : ``}
							</span>
						</span>
						: ''
					}
				</div>
			</div>
		</div>
  );
};

export default React.memo(MAIN, dontRerender);