import { Link } from 'react-router-dom';
import decodeJwt from '../bin/decodeJwt';
import classNames from '../bin/classNames';
import ICONS from '../bin/ICONS';

import { useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MENU_ITEM_ACCOUNT = (props) => {
  const token= decodeJwt(props.token),
        navigation= props.navigation,
        setSidebarOpen= props.setSidebarOpen,
        current= navigation.filter( ({name,current})=> current===true&&name==='Account').reduce( onAccountPage => true, false);
  const store= useStore(),
        navigate = useNavigate();
  // return (
  //   <div
  //     className={classNames(
  //       current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
  //       'flex-shrink-0 flex p-4')}
  //     >
  //     <Link
  //       to="/account"
  //       className="flex-shrink-0 w-full group block"
  //       onClick={() => setSidebarOpen(false)} >
  //       <div className="flex items-center">
  //         <div>
  //           { token.avatar ?
  //             <img
  //               className="inline-block h-9 w-9 rounded-full"
  //               src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
  //               alt="" />
  //             :
  //             <ICONS.UserIcon
  //               className={classNames(
  //                 current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
  //                 'mr-3 flex-shrink-0 h-6 w-6'
  //               )}
  //               aria-hidden="true" />
  //           }
  //         </div>
  //         <div className="ml-3">
  //           <p className="text-sm font-medium text-white">{ token.username }</p>
  //           <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">Account</p>
  //         </div>
  //       </div>
  //     </Link>
  //   </div>
  // )

  const navigateToAccount=()=>{
    if (setSidebarOpen){
      setSidebarOpen(false);
    }
    // navigate('/account', {replace:true});
    navigate('/logout', {replace:true});
  };
  return (
    <div
      className={classNames(
        current ? 'bg-red-900 text-white' : 'text-gray-300 hover:bg-red-700 hover:text-white'
        , 'flex-shrink-0 flex')}
      >
      <button
        className="group flex items-center px-2 py-2 text-sm font-medium w-full m-4"
        onClick={() => navigateToAccount()} >
        <ICONS.UserIcon
          className={classNames(
            current ? 'text-white' : 'text-gray-400 group-hover:text-white'
            , 'mr-3 flex-shrink-0 h-6 w-6')}
          aria-hidden="true" />
        {/* Account */}
        Logout
      </button>
    </div>
  )
}

export default MENU_ITEM_ACCOUNT
