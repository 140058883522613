import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from "../bin/classNames";
import ICONS from '../bin/ICONS';

const MENU_ITEM_LOGIN = (props) => {
  const navigation= props.navigation,
        setSidebarOpen= props.setSidebarOpen,
        [currentPageState, setCurrentPageState]= useState(false);
  
  const checkIfCurrenPageIsLogin= ()=>{
    let currentNavigation= navigation.filter( ({current})=>current===true ).reduce( (title,{name})=>title=name, '');
    let isPageLogin= (currentNavigation==='Login') ? true : false;
    setCurrentPageState( isPageLogin );
  };

  useEffect(checkIfCurrenPageIsLogin, [navigation]);

	const navigateToLogin=()=>{
    if (setSidebarOpen){
      setSidebarOpen(false);
    }
    // navigate('/account', {replace:true});
  };
  return (
    <div
      className={`${ currentPageState ? `bg-green-900 text-white` : `text-gray-300`} hover:bg-green-700 hover:text-white flex-shrink-0 flex`}>
      <Link
				to="/login"
				className="group flex items-center px-2 py-2 text-sm font-medium w-full m-4"
				onClick={() => navigateToLogin()}>
        <ICONS.UserIcon
          className={classNames(
            currentPageState ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
            'mr-3 flex-shrink-0 h-6 w-6'
          )}
          aria-hidden="true" />
        Login
      </Link>
    </div>
  )
}

export default MENU_ITEM_LOGIN
