import React, {useEffect, useState, useRef} from 'react';
import dontRerender from "../bin/dontRerender";
import config from '../config.json';
import { useStore, useSelector } from 'react-redux';
import headers from '../bin/Headers';
import _logout from "../bin/logout";
import _displayChangeInPercentJSX from "../bin/_displayChangeInPercentJSX";
import LOADING from "./LOADING";
import TickerEntryUi from "./TickerEntryUi";
import TICKER_INPUT_WITH_INFO_BUTTON from "./TICKER_INPUT_WITH_INFO_BUTTON";
import TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL from "./TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL";

const MAIN = (props) => {
	const store = useStore(),
		view = props.view,
		subview = props.subview,
		token= useSelector(state => state.token),
		[watchlist, setWatchlist]= useState([]),
		[loading, setLoading]= useState(true),
		[detailLoading, setDetailLoading]= useState(false),
		[error, setError]= useState(false),
		[focusedTicker, setFocusedTicker] = useState(null),
		[showTickerInfo, updateShowTickerInfo] = useState(false),
		[lastTrade, updateLastTrade]= useState(null),
		[tickerDetails, updateTickerDetails] = useState(null),
		[tickerStats, updateTickerStats]= useState(null),
		[market, setMarket]= useState('closed'),
		intervalRef = useRef(null),
		internalLoading = useRef(true),
		internalFocusedTicker = useRef(false),
		uiSorted= useRef([]),
		personalSort= useRef([]);

	const loadWatchlist = async () => {
		if (!token) { return; }
		setLoading(true);
		let _watchlist= await fetch(`${ config.api }/watchlist`, {headers:headers(token)}).then( d => d.status===200 ? d.json() : null ).then( d => d.result ? d.result : null);
		if (!_watchlist || !_watchlist.data || !_watchlist.list){
			setLoading(false);
			setError(true);
			return;
		}
		let sorted = [..._watchlist.list];
		sorted = sorted.map((ticker) => {
			if (!_watchlist.data[ticker]) { return null; }
			return _watchlist.data[ticker];
		});
		personalSort.current = sorted;
		resort();
		let mobileScreen = (window.innerWidth < 640) ? true : false;
		if (!mobileScreen && !focusedTicker && uiSorted.current && uiSorted.current.length > 0 && uiSorted.current[0] && uiSorted.current[0].ticker) {
			setDetailLoading(true);
			setFocusedTicker(uiSorted.current[0].ticker);
			internalFocusedTicker.current = uiSorted.current[0].ticker;
		}
		internalLoading.current = false;
		setLoading(false);
	};

	const reloadData = async () => {
		try {
			if (!token) { return; }
			if (internalLoading.current === true) { return; }
			// if (marketOpen.current === false || marketOpen.current === null) { return; }
			internalLoading.current = true;
			let [_watchlist, _tickerDetail] = await Promise.all([
				fetch(`${config.api}/watchlist`, { headers: headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null),
				loadTickerDetail(true),
			]);
			if (!_watchlist || !_watchlist.data || !_watchlist.list){ internalLoading.current = false; return; }
			let sorted = [..._watchlist.list];
			sorted = sorted.map((ticker) => {
				if (!_watchlist.data[ticker]) { return null; }
				return _watchlist.data[ticker];
			});
			personalSort.current = sorted;
			resort();
			internalLoading.current = false;
		} catch (e) {
			//dont do anything since we'll retry on the next interval
			internalLoading.current = false;
		}
	};

	const resort = () => {
		// if ((view === 'tracking' && !subview) || (view === 'tracking' && subview === 'mysort') || (view === 'tracking' && subview!=='mysort' && subview!=='positivechange' && subview!=='negativechange')) {
		if ((view === 'tracking' && !subview) || (view === 'tracking' && subview === 'tickers')) {
			uiSorted.current = [...personalSort.current];
			setWatchlist(uiSorted.current);
			return;
		}
		if (view === 'tracking' && subview === 'positivechange') {
			uiSorted.current = [...personalSort.current].sort((a, b) => { return b.change - a.change; });
			setWatchlist(uiSorted.current);
			return;
		}
		if (view === 'tracking' && subview === 'negativechange') {
			uiSorted.current = [...personalSort.current].sort((a,b) => { return a.change - b.change; });
			setWatchlist(uiSorted.current);
			return;
		}
	};

	const loadTickerDetail = async (noload=false) => {
		if (!focusedTicker && !internalFocusedTicker.current) { return; }
		let _focusedTicker = focusedTicker ? focusedTicker : internalFocusedTicker.current;
		if (!noload) { setDetailLoading(true); }
		let _tickerDetails = await fetch(`https://io.redstripedtie.com/ursa/tickerdata?ticker=${_focusedTicker}`, { headers: headers(token) }).then(x => x.json());
		if (_tickerDetails && _tickerDetails.error && _tickerDetails.error === 'invalid token') { return _logout(token); }
		if (!_tickerDetails || !_tickerDetails.result || !_tickerDetails.result) { return; }
		updateLastTrade(_tickerDetails.result.TradeLast);
		updateTickerDetails(_tickerDetails.result);
		if (!noload) { setDetailLoading(false); }
	};

	const loadTickerData = async (_ticker) => {
		// console.log("loadTickerData: ", _ticker);
	};

	// eslint-disable-next-line
	useEffect(() => {
		loadWatchlist();
		// intervalRef.current = setInterval(reloadData, 1000); //refresh every second
		intervalRef.current = setInterval(reloadData, 60000); //refresh every second
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);
	useEffect(() => { loadTickerDetail(); }, [focusedTicker]);
	useEffect(() => { resort(); }, [view, subview]);
	

	return (
		<div className="flex flex-col justify-start items-center w-full">
			{ loading ? <div className='h-24 flex items-center justify-center'><LOADING /></div> : <></>}
			{ !loading && (!watchlist || watchlist && watchlist.length===0) ? <div className="w-full sm:w-[350px] space-y-5"><h6 className='my-5 text-gray-400 text-center'>Not tracking any tickers</h6></div> : <></>}
			{ !loading && error ? <div className='h-24 flex items-center justify-center'><h1 className='text-red-500'>Error loading watchlist</h1></div> : <></>}
			{!loading && watchlist && watchlist.length > 0 ?
				<div className="flex md:flex-row gap-2 md:gap-5 justify-center items-start w-full">
					<div className="w-full sm:w-[320px] space-y-5">
						{watchlist.map((entry, index) => <TickerEntryUi key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={(newValue) => { setFocusedTicker(newValue); internalFocusedTicker.current = newValue; }} loadWatchlist={() => loadWatchlist()} />)}
					</div>
					<div className="hidden sm:flex flex-grow w-full items-start justify-center">
						{detailLoading ? <div className='h-24 flex items-center justify-center'><LOADING /></div> : <></>}
						{!detailLoading && focusedTicker ? 
							<div className='flex flex-col items-start justify-center'>
								<TICKER_INPUT_WITH_INFO_BUTTON ticker={focusedTicker} loadTickerData={loadTickerData} token={token} showTickerInfo={showTickerInfo} updateShowTickerInfo={updateShowTickerInfo} />
								<TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL tickerDetails={tickerDetails} tickerStats={null} lastTrade={lastTrade} showTickerInfo={showTickerInfo} optionOpenInterestValue={null} market={market} />
							</div>
							: <></>
						}
					</div>
				</div>
			: <></>}
	</div>
  );
};

export default React.memo(MAIN, dontRerender);