import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import todaysDate from "../bin/todaysDate";
import decodeJwt from "../bin/decodeJwt";
import dontRerender from "../bin/dontRerender";
import abbrInt from "../bin/abbrInt";
import Headers from "../bin/Headers";
import config from "../config.json";
import LOADING from "../components/LOADING";
import SPARKLINECHART from "../components/SPARKLINECHART";
import NOTABLE_TRADE_MODAL from "../components/NOTABLE_TRADE_MODAL";
import { BanknotesIcon, BarsArrowDownIcon, ClockIcon, BeakerIcon } from "@heroicons/react/24/outline";

const MAIN= (props)=>{
	let token = useSelector(state => state.token),
		ticker = props.ticker,

		largesttype = props.largesttype,
		passedtrades = props.trades,
		loading = props.loading,
		_trades = useRef([]),
		_sort = useRef('time'),
		[sort, setSort] = useState('time'),
		[trades, setTrades] = useState([]),
		[candles, updateCandles] = useState([]),
		
		[delayedData, setDelayedData] = useState(false),
		[showTradeInfo, updateShowTradeInfo]= useState(false),
		[tradeDetail, updateTradeDetail]= useState(null);

	const tickerEntryUi = (item, index) => {
		if (!item || !item.UnderlyingTicker || !item.Type || !item.Strike || !item.Size) { return; }
		let bgColor = '';
		let liquidity = item.Audited && (!item.BreakevenMove || (item.BreakevenMove && isNaN(item.BreakevenMove)===false && Math.abs(item.BreakevenMove)<0.5))? true : false;
		if (item.TradeSide) {
			if ((item.TradeSide === 'BUY' && item.Type==='CALL') || (item.TradeSide === 'SELL' && item.Type==='PUT')) { bgColor = 'bg-emerald-950/50'; }
			else if ((item.TradeSide === 'SELL' && item.Type==='CALL') || (item.TradeSide === 'BUY' && item.Type==='PUT')) { bgColor = 'bg-rose-950/50'; }
			// if (item.TradeSide === 'BUY' && item.TradeSidePrecise) { bgColor = 'bg-emerald-800'; }
			// else if (item.TradeSide === 'SELL' && item.TradeSidePrecise) { bgColor = 'bg-rose-800'; }
			// else if (item.TradeSide === 'BUY' && !item.TradeSidePrecise) { bgColor = 'bg-emerald-950/50'; }
			// else if (item.TradeSide === 'SELL' && !item.TradeSidePrecise) { bgColor = 'bg-rose-950/50'; }
		}
		// if (liquidity) { bgColor = 'bg-sky-950/50'; }
		return (
			<button key={index} className={`flex flex-row w-full text-xxs sm:text-xs text-gray-200 ${bgColor}`} onClick={() => { updateTradeDetail(item); updateShowTradeInfo(true); } }>
				<span className="p-1 flex items-center justify-center font-light w-12">#{item.Rank}</span>
				<span className={`hidden p-1 items-center justify-center w-20 ${!ticker ? 'sm:flex' : ''}`}>{item.UnderlyingTicker}</span>
				{/* <span className={`hidden sm:flex p-1 items-center justify-center w-24 ${item.Type==='CALL' ? 'bg-emerald-800' : ''}${item.Type==='PUT' ? 'bg-rose-800' : ''}`}>{item.Type}</span> */}
				<span className={`hidden sm:flex p-1 items-center justify-center w-24`}>{item.Type}</span>
				<span className="hidden sm:flex p-1 items-center justify-center w-20">${item.Strike && isNaN(item.Strike)===false ? (item.Strike).toLocaleString() : item.Strike}</span>
				<span className={`hidden p-1 items-center justify-center w-32 sm:flex`}>{item.ExpirationDateString}</span>
				<span className="p-1 flex items-center justify-start sm:justify-center flex-grow">
					<span className='hidden sm:block'>{item.Size && isNaN(item.Size)===false ? (item.Size).toLocaleString() : item.Size} @ ${item.Price && isNaN(item.Price)===false ? (item.Price).toLocaleString() : item.Price}{ item.TradeSidePrecise ? <span className='text-xxxs mx-1 px-1 bg-amber-500 text-amber-950 rounded'>precise</span> : `` }</span>
					{liquidity ? <span className='hidden sm:block'><span className='text-xxxs mx-1 px-1 bg-sky-400 text-sky-950 rounded'>liquidity</span></span> : <></>}
					<span className='flex flex-col w-full items-start justify-start sm:hidden'>

						<span className='flex flex-row w-full items-start justify-start gap-1'>
							<span className={`text-left font-bold ${!ticker ? 'inline' : 'hidden'} w-14`}>{item.UnderlyingTicker}</span>
							{/* <span className={`${item.Type === 'CALL' ? 'bg-emerald-800' : ''}${item.Type === 'PUT' ? 'bg-rose-800' : ''} w-10`}>{item.Type}</span> */}
							<span className={`w-10`}>{item.Type}</span>
							<span className='text-amber-600 text-center flex-grow'>${item.Strike && isNaN(item.Strike)===false ? Number((item.Strike).toFixed(2)).toFixed(2).toLocaleString() : item.Strike}</span>
							<span className='text-green-600 text-right pl-1 text-xxs'>{item.Binary ? 'binary' : ''}</span>
						</span>
						<span className='flex flex-row w-full items-start justify-between gap-1'>
							<span className="inline-block text-left w-full">
								{item.Size && isNaN(item.Size) === false ? (item.Size).toLocaleString() : item.Size} @ ${item.Price && isNaN(item.Price) === false ? (Number((item.Price).toFixed(2))).toFixed(2).toLocaleString() : item.Price}{item.TradeSidePrecise ? <span className='text-xxxs mx-1 px-1 bg-amber-500 text-amber-950 rounded'>precise</span> : ``}
								{liquidity ? <span className='text-xxxs mx-1 px-1 bg-sky-400 text-sky-950 rounded'>liquidity</span> : <></>}
							</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Premium:</span>
								<span className="ml-1 font-light w-100 text-center text-amber-300">${abbrInt(item.Notional)}</span>
							</span>
						</span>
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className="inline-block text-left w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Expires:</span>
								<span className="ml-1 font-light w-100 text-center text-rose-400">{item.ExpirationDateString}</span>
							</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Time:</span>
								<span className="ml-1 font-light w-100 text-center text-gray-400">{item.EntryTimeString}</span>
							</span>
						</span>
					</span>
				</span>
				<span className="hidden sm:flex p-1 items-center justify-center w-32">${abbrInt(item.Notional, false)}</span>
				{/* <span className="hidden sm:flex p-1 items-center justify-center w-20">{item.Binary ? <CheckIcon className='w-5 h-5 text-green-500' /> : ''}</span> */}
				<span className="hidden sm:flex p-1 items-center justify-end text-xxs w-24">{item.EntryTimeString}</span>
			</button>
		);
	};

	const sortTrades = () => {
		if (!_trades.current || !_trades.current.length) { return; }
		let _tradesArray = [..._trades.current];
		let __sort = _sort.current;
		_tradesArray.sort((a, b) => b.Notional - a.Notional);
		// _tradesArray = _tradesArray.filter((entry) => typeof entry==='object').map((entry, index) => { entry.Rank = (index + 1); return entry; });
		if (__sort === 'time') {
			_tradesArray.sort((a, b) => {
				const timeA = Number(a.EntryTimestamp);
        const timeB = Number(b.EntryTimestamp);

        // Check if both timestamps are valid numbers
        if (!isNaN(timeA) && !isNaN(timeB)) {
					return timeB - timeA;
        } else {
					// Handle invalid timestamps here (e.g., return 0 or some default value)
					return 0;
        }
			});
		}
		if (__sort === 'value' && largesttype==='value') {
			_tradesArray.sort((a, b) => b.Notional - a.Notional);
		} else if (__sort === 'value' && largesttype === 'size') {
			_tradesArray.sort((a, b) => b.Size - a.Size);
		}
		_trades.current = _tradesArray;
		setTrades(_trades.current);
	};

	const updateSorting = (sorting) => {
		setSort(sorting);
		_sort.current = sorting;
		sortTrades();
	};

	const readCandlesP3 = async (latestTradeDate) => {
		// const response = await fetch(`${config.api}/candles_p3?ticker=${ticker}&format=minute&startDate=${latestTradeDate ? latestTradeDate : todaysDate()}&endDate=${latestTradeDate ? latestTradeDate : todaysDate()}`, { headers: Headers(token) }).then(d => d.json());
		const response = await fetch(`https://io.redstripedtie.com/ursa/candles_p3?ticker=${ticker}&format=minute&startDate=${latestTradeDate ? latestTradeDate : todaysDate()}&endDate=${latestTradeDate ? latestTradeDate : todaysDate()}`, { headers: Headers(token) }).then(d => d.json());
		if (!response || response.message !== 'ok' || !response.result) { return; }
		updateCandles(response.result);
	};

	const checkIfDelayedData = () => {
		let tradeDate = trades && trades.length ? trades[0].EntryDateString : null;
		if (tradeDate && tradeDate !== todaysDate()) {
			setDelayedData(tradeDate);
		}
	};

	useEffect(()=>{
		checkIfDelayedData();
		
		if (largesttype === 'value') {
			passedtrades.sort((a, b) => b.Notional - a.Notional);
			passedtrades = passedtrades.filter((entry) => typeof entry==='object').map((entry, index) => { entry.Rank = (index + 1); return entry; });
		}
		if (largesttype === 'size') {
			passedtrades.sort((a, b) => b.Size - a.Size);
			passedtrades = passedtrades.filter((entry) => typeof entry === 'object').map((entry, index) => { entry.Rank = (index + 1); return entry; });
		}
		_trades.current = passedtrades;
		let latestTradeDate = trades && trades.length ? trades[0].EntryDateString : null;
		if (!candles.length && latestTradeDate) {
			readCandlesP3(latestTradeDate);
		}
		sortTrades();
	}, [passedtrades, sort]);
	
  return (
		<div className={`w-full h-full flex flex-col rounded-tr-md rounded-md border bg-gray-200 text-black`}>
			
			<div className="border-gray-300 rounded-t-lg w-full text-center text-semibold flex flex-col justify-center items-start px-1 py-0.5">
				<span className='flex flex-row items-center justify-end w-full'>
					{delayedData ? <Link to="/pricing" className='flex flex-row items-start justify-start'><span className='text-xs text-rose-600'>Trades from {delayedData}</span></Link> : <></>}
					{!loading ?
						<span className="flex-col inline-flex items-end justify-end flex-grow sm:items-center gap-1">
							<span className='inline-flex w-full justify-end items-center gap-1'>
								<button
									type="button"
									onClick={() => { updateSorting('graph'); }}
									className={`${!token ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'graph' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<BeakerIcon className='w-5 h-4' />
								</button>
								<button
									type="button"
									onClick={() => { updateSorting(sort === 'value' ? 'time' : 'value'); }}
									className={`rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'value' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									{largesttype === 'size' ? <BarsArrowDownIcon className='w-5 h-4' /> : <BanknotesIcon className='w-5 h-4' />}
								</button>
								<button
									type="button"
									onClick={() => { updateSorting(sort === 'time' ? 'value' : 'time'); }}
									className={`rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'time' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<ClockIcon className='w-5 h-4' />
								</button>
							</span>
						</span>
						:
						<></>
					}
				</span>
			</div>
			
			<div className="col-span-2 flex flex-grow flex-col rounded-br-md rounded-bl-md border bg-gray-700">
				{loading ? <div className='h-48 flex items-center justify-center'><LOADING color="text-green-500" /></div> : <></>}
				{!loading && (!trades || !trades.length) ? <h6 className='my-5 text-gray-400 text-center'>No Stock Trades</h6> : <></>}
				{!loading && sort!=='graph' && trades && trades.length ?
					<div className="h-full w-full">
						<div className='flex flex-row w-full text-xxs sm:text-xs text-gray-200'>
							<span className="p-1 flex items-center justify-center border uppercase font-medium tracking-wider w-12">Rank</span>
							<span className={`hidden p-1 items-center justify-center border font-medium uppercase tracking-wider w-20 ${!ticker ? 'sm:flex' : ''}`}>Ticker</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-24">Type</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-20">Strike</span>
							<span className={`hidden p-1 items-center justify-center border font-medium uppercase tracking-wider w-32 sm:flex`}>Expiration</span>
							<span className="p-1 flex items-center justify-center border font-medium uppercase tracking-wider flex-grow">Trade</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-32">Value</span>
							<span className="hidden sm:flex p-1 items-center justify-end border font-medium uppercase tracking-wider w-24">Time</span>
						</div>
						
						{trades.map((item, index) => tickerEntryUi(item, index))}
					</div>
					: <></>
				}

				{!loading && sort === 'graph' && trades && trades.length && candles && candles.length ?
					<div className="h-full w-full flex items-center justify-center">
						<SPARKLINECHART data={candles} trades={trades} />
					</div>
					: <></>
				}
			</div>

			<NOTABLE_TRADE_MODAL
				open={ showTradeInfo }
				setOpen={() => { updateShowTradeInfo(false); updateTradeDetail(null); }}
				audit={true}
				data={ tradeDetail } />

		</div>
  );
};

// export default MAIN;
export default React.memo(MAIN, dontRerender);