const main = (str) => {
  // Split the string into an array of words
  let words = str.toLowerCase().split(" ");
  
  // Capitalize the first letter of each word except the last one
  for (let i = 0; i < words.length - 1; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  
  // Convert the state abbreviation to uppercase and append it to the formatted string
  let state = words[words.length - 1].toUpperCase();
  return words.slice(0, -1).join(" ") + " " + state;
};
export default main;