import React, { useState, useEffect, useRef } from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const MAIN = (props) => {
	const { data, trades } = props;
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 500, height: 200 });
	

	function calculatePosition(data, index, type = '', dimensions) {
		if (!data || !data.length || !index) { return { x: 0, y: 0, type }; }
		//zero the seconds and miliseconds value in the index
		index = index.split(':');
		index = index[0] + ':' + index[1] + ':00.000';
		//find the index point in the data array TimeString field
		index = data.findIndex(d => d.TimeString === index);
		const x = (index / data.length) * dimensions.width;
		const y = ((data[index] - Math.min(...data)) / (Math.max(...data) - Math.min(...data))) * dimensions.height;
		return { x, y, type };
	}

	useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    };

    // Set initial size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount and unmount
	
	return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }} ref={containerRef}>
      <Sparklines data={data.map(d => d.Close)} width={dimensions.width} height={dimensions.height}>
        <SparklinesLine color="white" />
      </Sparklines>
			{trades.map((trade, i) => {
				if (!trade.EntryTimeString || !trade.TradeSide) { return null; }
				const tradeOccurrence = calculatePosition(data, trade.EntryTimeString, trade.TradeSide, dimensions);
				return (
					<div key={i}
						style={{
							position: 'absolute',
							left: `${tradeOccurrence.x}px`,
							top: 0,
							bottom: 0,
							width: '1px',
							backgroundColor: trade.TradeSide === 'SELL' ? 'red' : (trade.TradeSide === 'BUY' ? 'green' : ''),
						}}
					/>
				);
			})}
    </div>
  );
};

export default MAIN;