import React from 'react';
import dontRerender from "../bin/dontRerender";

const MAIN = () => {
	return (
		<div className='flex flex-col items-center justify-center text-white max-w-[700px]'>
				<h1 className="text-2xl font-bold tracking-tight text-gray-300 my-10">Terms, Conditions, and Disclaimer Policy for RedStripedTie.com</h1>

				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">1. Acceptance of Terms</h2>
          <p className="mt-6 text-justify">
						By accessing and using RedStripedTie.com, you accept and agree to be bound by the terms and provision of this agreement.
						In addition, when using RedStripedTie.com's particular services, you shall be subject to any posted guidelines or rules applicable to such services.
						Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this site and/or service.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">2. Advice Disclaimer</h2>
          <p className="mt-6 text-justify">
						RedStripedTie.com provides a platform for sharing and viewing trading strategies, opinions on stocks, and notable trades occurring in the market.
						It is important to understand that nothing on RedStripedTie.com constitutes financial advice or a financial recommendation.
						The content provided by RedStripedTie.com and its users is for informational and entertainment purposes only and should not be interpreted as financial advice,
						nor should it be used as a basis for making financial decisions. Before making any financial decision, you should perform your due diligence and consult with a
						qualified financial advisor. RedStripedTie.com and its owners, managers, officers, employees, and agents will not be liable for any loss or damage arising from
						your reliance on information or other content posted on the site or transmitted to or by any users of the site.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">3. Content and Liability Disclaimer</h2>
          <p className="mt-6 text-justify">
						The content shared on RedStripedTie.com reflects the personal opinions and trades of individuals and is not reviewed, endorsed, or verified by RedStripedTie.com.
						As such, the accuracy, completeness, or reliability of any content is not guaranteed. RedStripedTie.com and its affiliates will not be responsible for any errors,
						inaccuracies, or omissions in the content provided nor for any losses or damages incurred as a result of the use or reliance on any content made available through the site and services.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">4. Third-Party Websites</h2>
          <p className="mt-6 text-justify">
						RedStripedTie.com may include links to other websites or content belonging to or originating from third parties. RedStripedTie.com does not investigate, monitor,
						or check these external websites for accuracy, appropriateness, legality, or completeness. Inclusion of any linked website on RedStripedTie.com does not imply
						approval or endorsement of the linked website by RedStripedTie.com.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">5. Limitation of Liability</h2>
          <p className="mt-6 text-justify">
						In no event shall RedStripedTie.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special,
						consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your
						access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from
						the service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence),
						or any other legal theory, whether or not we have been informed of the possibility of such damage.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">7. User Generated Content</h2>
          <p className="mt-6 text-justify">
						<b>a. Responsibility for Content:</b> Users of RedStripedTie.com are solely responsible for the content they post on the site, including but not limited to text,
						photos, links, and other materials. RedStripedTie.com does not control or endorse the content that users submit to the site and, as such, does not guarantee the
						accuracy, integrity, or quality of such content. Under no circumstances will RedStripedTie.com be liable in any way for any content posted by users or third
						parties, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of any content
						posted, emailed, transmitted, or otherwise made available via RedStripedTie.com.
					</p>
					<p className="mt-6 text-justify">
						<b>b. Right to Remove or Edit Content:</b> RedStripedTie.com has the right, but not the obligation, to monitor and edit or remove any activity or content.
						RedStripedTie.com reserves the right to take any action it deems necessary to ensure the safety of its users and the integrity of the platform, which may include
						removing content that violates the Terms and Conditions or is otherwise objectionable.
					</p>
					<p className="mt-6 text-justify">
						<b>c. License Grant:</b> By submitting, posting, or displaying content on or through RedStripedTie.com, you grant RedStripedTie.com a worldwide, non-exclusive,
						royalty-free license to use, reproduce, adapt, publish, translate, create derivative works from, distribute, and display such content in any media. This license
						includes the right for RedStripedTie.com to make your content available to other companies, organizations, or individuals who partner with RedStripedTie.com for
						the syndication, broadcast, distribution, or publication of such content on other media and services.
					</p>
					<p className="mt-6 text-justify">
						<b>d. User Representations and Warranties:</b> You represent and warrant that (i) you own or have the necessary licenses, rights, consents, and permissions to
						publish content you submit; (ii) your content does not and will not violate, plagiarize, or infringe on the rights of any third party, including copyright, trademark,
						privacy, publicity, or other personal or proprietary rights; and (iii) you have the legal right and capacity to enter into these Terms in your jurisdiction.
					</p>
					<p className="mt-6 text-justify">
						<b>e. Indemnification:</b> You agree to defend, indemnify, and hold harmless RedStripedTie.com, its parent corporation, officers, directors, employees, and agents,
						from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees) arising
						from: (i) your use of and access to RedStripedTie.com; (ii) your violation of any term of these Terms; (iii) your violation of any third-party right, including
						without limitation any copyright, trademark, privacy, or other property right; or (iv) any claim that your content caused damage to a third party.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">8. Prohibited Use of the Site</h2>
          <p className="mt-6 text-justify">
						<b>a. Redistribution and Unauthorized Use:</b> You agree not to redistribute, reproduce, duplicate, copy, sell, resell, or exploit for any commercial purposes, any
						portion of RedStripedTie.com, use of RedStripedTie.com, or access to RedStripedTie.com without the express written consent of RedStripedTie.com. Unauthorized use of
						RedStripedTie.com or the materials contained on or accessible through the site may violate copyright laws, trademark laws, the laws of privacy and publicity, and
						communications regulations and statutes.
					</p>
					<p className="mt-6 text-justify">
						<b>b. Scraping and Data Harvesting:</b> The scraping, data mining, data harvesting, data extracting, or any other form of similar activity in regard to RedStripedTie.com
						is strictly prohibited unless expressly permitted by RedStripedTie.com. You may not engage in any activities that will impose an unreasonable or disproportionately
						large load on the infrastructure of RedStripedTie.com or its systems or networks, or any systems or networks connected to RedStripedTie.com.
					</p>
					<p className="mt-6 text-justify">
						<b>c. Unauthorized Cataloging and Indexing:</b> The use of RedStripedTie.com content for any purposes related to marketing without RedStripedTie.com's express written
						consent is prohibited. This includes cataloging, indexing, or aggregating content for the purpose of creating or compiling, directly or indirectly, a collection,
						compilation, database, or directory without written permission from RedStripedTie.com.
					</p>
					<p className="mt-6 text-justify">
						<b>d. Restrictions on Content Use:</b> All content on RedStripedTie.com, including but not limited to articles, photos, images, graphics, and data, is protected by
						copyrights, trademarks, and other intellectual property rights. You may not modify, publish, transmit, participate in the transfer or sale, create derivative works,
						or in any way exploit any of the content, in whole or in part, found on RedStripedTie.com.
					</p>
					<p className="mt-6 text-justify">
						<b>e. Unauthorized Access:</b> You may not attempt to gain unauthorized access to any portion or feature of RedStripedTie.com, or any other systems or networks connected.
						You may not probe, scan, or test the vulnerability of RedStripedTie.com or any network connected to RedStripedTie.com, nor breach the security or authentication measures
						on RedStripedTie.com or any network connected to RedStripedTie.com.
					</p>
					<p className="mt-6 text-justify">
						<b>f. Interference with Service:</b> You may not interfere with or disrupt the service or servers or networks connected to the service, or disobey any requirements,
						procedures, policies, or regulations of networks connected to the service.
					</p>
					<p className="mt-6 text-justify">
						<b>g. Violation of Security Measures:</b> You may not violate the security of RedStripedTie.com or attempt to gain unauthorized access to RedStripedTie.com, data, materials,
						information, computer systems, or networks connected to any server associated with RedStripedTie.com, through hacking, password mining, or any other means.
					</p>
					<p className="mt-6 text-justify">
						<b>h. Unauthorized Use of User Accounts:</b> You may not use another user's account without permission. You may not attempt to gain unauthorized access to another user's
						account or personal information.
					</p>
					<p className="mt-6 text-justify">
						<b>i. Impersonation:</b> You may not impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.
					</p>
					<p className="mt-6 text-justify">
						<b>j. Violation of Laws:</b> You may not use RedStripedTie.com for any purpose that is illegal or prohibited by these terms, or to solicit the performance of any illegal
						activity or other activity that infringes the rights of RedStripedTie.com or others.
					</p>
					<p className="mt-6 text-justify">
						<b>k. Enforcement and Remedies:</b> RedStripedTie.com reserves the right to enforce these terms, and failure to comply with this section may result in immediate termination
						of your access to RedStripedTie.com, legal action for injunction, and recovery of all legal fees incurred in enforcing these rights.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">9. Data Sources and Reliability</h2>
					<p className="mt-6 text-justify">
						<b>a. Sources of Data:</b> RedStripedTie.com aggregates data from a variety of sources to provide users with comprehensive insights into market trends and trades. Our data
						sources include, but are not limited to, direct feeds from stock exchanges (e.g., SIP Feed, CTA), public data sources, regulatory filings from FINRA and the SEC, and other
						reputable financial information providers.
					</p>
					<p className="mt-6 text-justify">
						<b>b. Accuracy and Timeliness:</b> While RedStripedTie.com strives to provide accurate and up-to-date information by sourcing data from reliable providers, we cannot
						guarantee the completeness, accuracy, or timeliness of the data presented on our site. Financial markets are volatile and the data is subject to change rapidly. As such,
						RedStripedTie.com should not be held responsible for any inaccuracies, errors, or omissions in the data, or for any trading or investment decisions made based on such data.
					</p>
					<p className="mt-6 text-justify">
						<b>c. No Endorsement:</b> Mention of these data sources is not an endorsement of their services or an indication of a direct partnership unless explicitly stated. Users are
						encouraged to verify the data through these or other sources before making any financial decisions.
					</p>
					<p className="mt-6 text-justify">
						<b>d. Updates and Revisions:</b> The data provided on RedStripedTie.com is periodically updated to reflect new information and market changes. However, RedStripedTie.com reserves
						the right to amend or update the information provided at any time without prior notice.
					</p>
					<p className="mt-6 text-justify">
						<b>e. Use of Data:</b> The data available on RedStripedTie.com is intended for personal, non-commercial use by our users. Redistribution of data or use of the data for commercial
						purposes without express written permission from RedStripedTie.com and, if applicable, any of the data providers, is strictly prohibited.
          </p>
				</div>

				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">10. Refund Policy</h2>
					<p className="mt-6 text-justify">
						<b>a. Free vs. Paid Services:</b> RedStripedTie.com offers both a free, ad-supported version of our service, providing delayed data, and a premium, paid subscription that offers
						additional features. These premium features include ad removal, access to real-time data, the ability to charge other users for access to exclusive trade content, and real-time
						push notification alerts.
					</p>
					<p className="mt-6 text-justify">
						<b>b. No Refunds:</b> All purchases made on RedStripedTie.com are final. RedStripedTie.com does not offer refunds for any payments made for any of its services, including but not
						limited to monthly subscription fees. By subscribing to the premium service, you acknowledge and agree that no refund will be issued nor will you be enttitled to any refund or	
						credit for any unused portion of your subscription.
					</p>
					<p className="mt-6 text-justify">
						<b>c. Subscription Cancellations:</b> You may cancel your premium subscription at any time. Upon cancellation, you will continue to have access to the premium features until the end
						of your current billing cycle. No partial refunds are provided for cancellation partway through a billing period.
					</p>
					<p className="mt-6 text-justify">
						<b>d. Exceptional Circumstances:</b> While our policy is final, RedStripedTie.com may consider requests for refunds on a case-by-case basis in exceptional circumstances. Granting of
						refunds in such instances is at the sole discretion of RedStripedTie.com.
					</p>
					<p className="mt-6 text-justify">
						<b>e. Contact Information:</b> If you have any questions or concerns regarding your subscription, the use of 'gold upvotes,' or our refund policy, please contact us at
						support@redstripedtie.com.
          </p>
				</div>

				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">11. Username Policy</h2>
          <p className="mt-6 text-justify">
						<b>a. Fixed Username:</b> Once set, your username cannot be changed. Choose wisely to ensure it reflects your desired online identity.
          </p>
          <p className="mt-6 text-justify">
						<b>b. Ownership and Intellectual Property:</b> If your chosen username includes company names, locations, personal names, or any identifiable trademarks that you do not own, be
						aware that your username may be subject to automatic modification during periodic audits to ensure compliance with intellectual property rights and proper reputation identity.
					</p>
					<p className="mt-6 text-justify">
						<b>c. Prohibited Content:</b> Usernames that are found to be vulgar, obscene, or otherwise inappropriate will be subject to change without notice. This site is committed to maintaining
						a respectful and safe community.
					</p>
					<p className="mt-6 text-justify">
						<b>d. No Name Squatting:</b> Registering usernames for the purpose of selling them or preventing others from using them (name squatting) is prohibited. Such actions may lead to account
						suspension or termination.
					</p>
					<p className="mt-6 text-justify">
						<b>e. Inactive Accounts:</b> Please note that accounts showing no activity for an extended period may be considered inactive and subject to termination. This measure helps ensure the
						availability of desirable usernames for new community members.
					</p>
				</div>

				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">12. Amendments to Terms</h2>
          <p className="mt-6 text-justify">
						RedStripedTie.com reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30
						days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </p>
				</div>
				
				<div className='max-w-2xl mt-14'>
          <h2 className="text-2xl font-bold tracking-tight text-gray-300">13. Contact Us</h2>
          <p className="mt-6 text-justify">
						If you have any questions about these Terms, please contact us at support@redstripedtie.com.
          </p>
				</div>

    </div>
  );
}

export default React.memo(MAIN, dontRerender);