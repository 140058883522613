const main = (name) => {
  let formatted = [];
	for (let word of name.split(' ')){
		word = word.trim();
		if ( word.match(/^Inc$/gi)!==null ){ continue; }
		if ( word.match(/^Inc.$/gi)!==null ){ continue; }
		if ( word.match(/^Corp$/gi)!==null ){ continue; }
		if ( word.match(/^Corporation$/gi)!==null ){ continue; }
		if ( word.match(/^ETF$/gi)!==null ){ continue; }
		if ( word.match(/^ADR$/gi)!==null ){ continue; }
		if ( word.match(/^Shares$/gi)!==null ){ continue; }
		formatted.push(word);
	}
	formatted = formatted.join(' ');
	formatted = formatted.replace(/,/gi, '');
	formatted = formatted.replace(/Common Stock/gi, '');
	formatted = formatted.replace(/Class A/gi, '');
	formatted = formatted.replace(/Class B/gi, '');
	formatted = formatted.replace(/Class C/gi, '');
	formatted = formatted.replace(/SPDR/gi, '');
	formatted = formatted.replace(/Series 1/gi, '');
	formatted = formatted.replace(/CL A New/gi, '');
	formatted = formatted.replace(/Capital Stock/gi, '');
	if ( (formatted.toUpperCase()).indexOf('AMERICAN DEPOSITARY')>-1 ){
		formatted = formatted.substr(0, ((formatted.toUpperCase()).indexOf('AMERICAN DEPOSITARY')-1) );
	}
	if ( formatted.indexOf('(')>-1 ){
		formatted = formatted.substr(0, formatted.indexOf('(')-1 );
	}
	formatted = formatted.trim();
	return formatted;
};
export default main;