const main = (timestamp) => {
	if (!timestamp) { return ''; }
	let currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);
	currentDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
  
	let date = new Date(timestamp);
	let datestring = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

	if (currentDate === datestring) {
		return <span className="flex flex-col items-end text-xxs">
		<span>{date.getHours().toString().padStart(2, '0')}:{date.getMinutes().toString().padStart(2, '0')}:{date.getSeconds().toString().padStart(2, '0')}</span>
	</span>;
	}

	return <span className="flex flex-col items-end text-xxs">
		<span>{date.getFullYear()}-{(date.getMonth() + 1).toString().padStart(2, '0')}-{date.getDate().toString().padStart(2, '0')}</span>
		<span>{date.getHours().toString().padStart(2, '0')}:{date.getMinutes().toString().padStart(2, '0')}:{date.getSeconds().toString().padStart(2, '0')}</span>
	</span>;
};
export default main;