import React, { useState, useEffect } from 'react';
import config from '../config.json';
import { Link } from 'react-router-dom';
import TICKER_SEARCH from './TICKER_SEARCH';
import { useSelector } from 'react-redux';
import { StarIcon, FireIcon, UserIcon, BellIcon, BellAlertIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';

const HEADER_MOBILE = (props) => {
	const { noChrome, setSidebarOpen, token } = props,
		unseenalerts = useSelector(state => state.unseenalerts),
		tielevel = useSelector(state => state.tielevel),
		[isScrolled, setIsScrolled] = useState(false),
		[searchable, setSearchable] = useState(false);

	// CSS for fade-away effect
  const fadeAwayStyle = {
    transition: 'all 0.5s ease-out',
    opacity: isScrolled ? 0 : 1,
    fontSize: isScrolled ? '0px' : '12px',
	};
  
	// CSS for opacity effect
	const seeThruStyle = {
		transition: 'all 0.5s ease-out',
		backgroundColor: isScrolled ? 'rgba(0, 0, 0, 0.5)' : '#272727',
		opacity: isScrolled ? 0.5 : 1,
		boxShadow: isScrolled ? '0 10px 10px #4c0519' : '0 0 0 0',
	};

	
	
	useEffect(() => {
		const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
		};

    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll, { passive: true });

    // Remove event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll, { passive: true });
  }, []);
	
	return (
		// <div className={`${noChrome ? '' : 'lg:hidden'} p-2 flex items-center justify-center fixed w-full top-0 z-30 shadow-lg shadow-rose-950`} style={seeThruStyle}>
		<div className={`${noChrome ? '' : 'lg:hidden'} p-2 flex items-center justify-center fixed w-full top-0 z-30`} style={seeThruStyle}>
			<div className='max-w-4xl flex flex-col items-start justify-center w-full'>
				<div className={`flex flex-row justify-between w-full`}>
					<div className={`${searchable ? 'hidden sm:flex' : 'flex flex-1'} text-center flex items-center justify-center`}>
						{(token) ?
							<div className={`${searchable ? 'hidden' : 'flex'} flex-col items-center justify-center`}>
								<button onClick={() => setSearchable(true)} className={`inline-flex flex-shrink-0 sm:mx-10 h-12 w-12 items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}>
									<MagnifyingGlassIcon className={`w-6 h-6 text-gray-300`} />
								</button>
								<span className={`${searchable ? 'sm:hidden' : 'sm:flex'} hidden w-full items-center justify-center text-white`} style={fadeAwayStyle}>Search</span>
							</div>
							:
							<div className={`flex flex-col items-center justify-center`}>
								{/* <Link to="/rankings" className="flex-shrink-0 sm:mx-10 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"> */}
								<Link to="/rankings-largest-stock-trades" className="flex-shrink-0 sm:mx-10 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<FireIcon className={`w-6 h-6 text-gray-300`} />
								</Link>
								<span className={`${searchable ? 'sm:hidden' : 'sm:flex'} hidden w-full items-center justify-center text-white`} style={fadeAwayStyle}>Rankings</span>
							</div>
						}
						<div className={`hidden ${searchable ? 'sm:flex' : ''} flex-row gap-2`}>
							<Link to="/" className="inline-flex flex-shrink-0 pr-2 h-12 w-12 items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
								<img src="/bin/Icon.png" alt="Red Striped Tie Logo" className="w-[30px]" />
							</Link>
						</div>
					</div>
					<div className={`${searchable ? 'hidden' : 'flex'} flex-1 text-center items-center justify-center`}>
						{/* {(token) ?
							<div className={`${searchable ? 'hidden' : 'flex'} flex flex-row items-center justify-center`}>
								<Link to="/watchlist" className="flex-shrink-0 sm:mx-10 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									{/* <StarIcon className={`w-6 h-6 text-sky-500`} /> * /}
									<StarIcon className={`w-6 h-6 text-gray-300`} />
								</Link>
							</div>
							:
							<></>
						} */}
					</div>
					<div className="min-w-[130px] flex-1 text-center flex flex-col items-center justify-center">
						<div className={`${searchable ? 'hidden' : 'flex'} flex-row h-12 text-white justify-center items-center`}>
							<Link to={token ? `/main` : `/`} className="flex-shrink-0 flex items-center text-white">
								{tielevel === 'red' ?
									<img src="/bin/RedTie.png" alt="Red Striped Tie Logo" className="w-[30px] border bg-gradient-to-b from-white to-gray-400 border-gray-700 p-1 rounded-full" />
									: (tielevel === 'black' ? <img src="/bin/BlackTie.png" alt="Red Striped Tie Logo" className="w-[30px] border bg-gradient-to-b from-white to-gray-400 border-gray-700 p-1 rounded-full" />
										: <img src="/bin/Icon.png" alt="Red Striped Tie Logo" className="w-[30px]" />)}
							</Link>
						</div>
						<div className={`${searchable ? 'flex' : 'hidden'} flex-grow text-white justify-center items-center flex-col`}>
							<TICKER_SEARCH token={token} setSearchable={setSearchable} />
						</div>
						<span className={`${searchable ? 'hidden' : 'flex'} w-full items-center justify-center text-white`} style={fadeAwayStyle}>Red Striped Tie</span>
					</div>
					<div className={`${searchable ? 'hidden' : 'flex'} flex-1 text-center items-center justify-center`}>
						{/* {(token) ?
							<div className={`${searchable ? 'hidden' : 'flex'} flex flex-row items-center justify-center`}>
								<Link to="/notabletrades" className="flex-shrink-0 sm:mx-10 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									{/* <FireIcon className={`w-6 h-6 text-rose-500`} /> * /}
									<FireIcon className={`w-6 h-6 text-gray-300`} />
								</Link>
							</div>
							:
							<></>
						} */}
					</div>
					<div className={`${searchable ? '' : 'flex-1'} text-center flex items-center justify-center`}>
						{(token) ?
							<div className={`${searchable ? 'hidden' : 'flex'} flex-col items-center justify-center`}>
								<Link to="/alerts" className="flex-shrink-0 sm:mx-10 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									{unseenalerts ?
										<BellAlertIcon className={`w-6 h-6 text-amber-500`} />
										:
										<BellIcon className={`w-6 h-6 text-gray-300`} />
									}
								</Link>
								<span className={`${searchable ? 'sm:hidden' : 'sm:flex'} hidden w-full items-center justify-center text-white`} style={fadeAwayStyle}>Alerts</span>
							</div>
							:
							<div className={`flex flex-col items-center justify-center`}>
								<Link to="/login" className="flex-shrink-0 sm:mx-10 h-12 w-12 inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<UserIcon className={`w-6 h-6 text-gray-300`} />
								</Link>
								<span className={`${searchable ? 'sm:hidden' : 'sm:flex'} hidden w-full items-center justify-center text-white`} style={fadeAwayStyle}>Login</span>
							</div>
						}
						<div className={`${searchable ? 'flex' : 'hidden'} flex-row items-center justify-center`}>
							<button onClick={() => setSearchable(false)} className={`inline-flex flex-shrink-0 h-12 w-12 items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}>
								<XMarkIcon className={`w-6 h-6 text-rose-300`} />
							</button>
						</div>
					</div>
				</div>
				{/* <span className={`${searchable ? 'hidden' : 'flex'} w-full items-center justify-center text-white`} style={fadeAwayStyle}>Red Striped Tie</span> */}
			</div>
    </div>
  );
}

export default HEADER_MOBILE;
