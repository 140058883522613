import React, {useEffect, useState} from 'react';
import config from "../config.json";
import { useSelector } from 'react-redux';
import Headers from "../bin/Headers";
import abbrInt from "../bin/abbrInt";
import LOADING from "../components/LOADING";
import FAILURE_TO_DELIVER_STATISTICS from "../components/FAILURE_TO_DELIVER_STATISTICS";
import dontRerender from "../bin/dontRerender";
import ICONS from '../bin/ICONS';

const FailToDelivers = ()=>{
  const token= useSelector(state => state.token);
  let dateObject = new Date();
  let dateString = `${ dateObject.getFullYear() }-${ (dateObject.getMonth()+1).toString().padStart(2,'0') }-${ dateObject.getDate().toString().padStart(2,'0') }`;
  let [list, setList] = useState([]),
      [matches, setMatches] = useState(null),
      [ticker, setTicker] = useState(''),
      [shareMinimum, setShareMinimum] = useState(0),
      [valueMinimum, setValueMinimum] = useState(0),
      [sort, setSort] = useState('Value'),
      [t38date, setT38date] = useState(dateString),
      [settlementdate, setSettlementdate] = useState(null),
      [LoadingValue, setLoadingValue] = useState(true);

  const stats = [
    { name:`Today`, detail:`08-12`, stat:`71,897`, previousStat:`70,946`, change:`12%`, changeType:`increase` },
    { name:`This Month`, detail:`August`, stat:`58.16%`, previousStat:`56.14%`, change:`2.02%`, changeType:`increase` },
    { name:`This Year`, detail:`2022`, stat:`$12.3B`, previousStat:`$1.2B`, change:`12.05%`, changeType:`increase` },
  ];

  const readFtds = async (overwrite) => {
    setLoadingValue(true);
    let headers = Headers(token);
    let uri = `${config.api}/failuretodelivers`;
    if ( (ticker && ticker.trim()!=='') || (overwrite && overwrite.ticker)){
      if (overwrite && overwrite.ticker){
        setTicker(overwrite.ticker);
        uri += `?ticker=${overwrite.ticker}`;
      }else{
        uri += `?ticker=${ticker}`;
      }
    }else if ( (t38date && t38date.trim()!=='') || (overwrite && overwrite.t38date)){
      if (overwrite && overwrite.t38date){
        setT38date(overwrite.t38date);
        uri += `?t38date=${overwrite.t38date}`;
      }else{
        uri += `?t38date=${t38date}`;
      }
    }else if (settlementdate || (overwrite && overwrite.settlementdate)){
      if (overwrite && overwrite.settlementdate){
        uri += `?settlementdate=${overwrite.settlementdate}`;
      }else{
        uri += `?settlementdate=${settlementdate}`;
      }
    }
    if (overwrite && overwrite.sort){
      setSort(overwrite.sort);
      uri += `&sort=${overwrite.sort}:-1`;
    }else{
      uri += `&sort=${sort}:-1`;
    }
    let ftds = await fetch(uri, {headers}).then(d => d.json());
    if (!ftds || !ftds.message || ftds.message!=='ok' || !ftds.result){ setLoadingValue(false); return; }
    let {matches, result} = ftds;
    if (matches && isNaN(matches)===false){
      setMatches(Number(matches));
    }
    setList(result);
    setLoadingValue(false);
  };

  const formatDatestring = (dateObject) => {
    dateObject = new Date(dateObject);
    let dateString = `${ dateObject.getUTCFullYear() }-${ (dateObject.getUTCMonth()+1).toString().padStart(2,'0') }-${ dateObject.getUTCDate().toString().padStart(2,'0') }`;
    return dateString;
  };
  
  const filterMatch = (ticker, entry, shareMinimum, valueMinimum) => {
    if (!ticker || ticker===''){ return true; }
    if (!entry.ClosePercent){ return false; }
    if (!entry.Quantity || Number(entry.Quantity)<shareMinimum){ return false; }
    if (!entry.Value || !entry.Value.$numberDecimal || Number(entry.Value.$numberDecimal)<valueMinimum){ return false; }
    return true;
  };

	const start = async()=>{
		setTicker('');
    // await readFtds({ticker:"GME", sort:'T38Date'});
    await readFtds();
    return;
	};

  useEffect(() => { start(); }, []);

  return (
    <main className="py-4 sm:py-6 lg:py-8">
      <div className="flex flex-col sm:justify-between sm:flex-row gap-4 w-full">
        
        <div className='w-full md:max-w-sm sm:w-1/2'>
          <div className="max-w-full flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              Ticker
            </span>
            <input
              disabled={ LoadingValue }
              type="text"
              value={ticker}
              onChange={ evt => setTicker(evt.target.value) }
              onKeyDown={ evt => { if (evt.keyCode!==13){ return; } setT38date(''); readFtds({ticker:evt.target.value, sort:'T38Date'}); }}
              className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            />
          </div>
          
          <div className="mt-2 max-w-full flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              T38 Date
            </span>
            <input
              disabled={ LoadingValue }
              type="date"
              value={t38date}
              onChange={ evt => { setTicker(''); readFtds({t38date:evt.target.value}); }}
              className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            />
          </div>

        { LoadingValue===false && ticker!=='' &&
          <div className="mt-2 max-w-full flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              Minimum Shares
            </span>
            <input
              disabled={ LoadingValue }
              type="text"
              value={shareMinimum}
              onChange={ evt => setShareMinimum(evt.target.value) }
              className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            />
          </div>
        }
        
        { LoadingValue===false && ticker!=='' &&
          <div className="mt-2 max-w-full flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              Minimum Value
            </span>
            <input
              disabled={ LoadingValue }
              type="text"
              value={valueMinimum}
              onChange={ evt => setValueMinimum(evt.target.value) }
              className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            />
          </div>
        }

          { LoadingValue===false && 
            <div className='mt-2'>
              <h3 className='text-white text-center'>Matches: { matches && isNaN(matches)===false ? matches.toLocaleString() : matches }</h3>
            </div>
          }
        </div>

        
        { LoadingValue===false && ticker!=='' && list && list.length &&
          <FAILURE_TO_DELIVER_STATISTICS
            matches={list}
            shareMinimum={shareMinimum} 
            valueMinimum={valueMinimum} />
        }
        
        { LoadingValue===true && 
          <div className='w-full'>
            <LOADING />
          </div>
        }
        

        {/* <div>
          <h3 className="text-lg leading-6 font-medium text-amber-600">Value of FTDs</h3>
          <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
            {stats.map((item) => (
              <div key={item.name} className="px-4 py-5 sm:p-6">
                <dt className="flex justify-between">
                  <span className='text-base font-normal text-gray-900'>{item.name}</span>
                  <span className='ml-3 text-xxs px-2.5 py-0.5 rounded-lg flex items-center bg-amber-100 text-amber-900'>{item.detail}</span>
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {item.stat}
                    <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
                  </div>

                  <div
                    className={`inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 ${item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`} >
                    {item.changeType === 'increase' ? (
                      <ICONS.ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ICONS.ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}

                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                    {item.change}
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div> */}
        

      </div>
      

      { LoadingValue===false && 
        <table className="mt-10 divide-y divide-gray-200 w-full self-start text-xs overflow-x-auto">
          
          <thead>
            <tr className="bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-left">
              <th scope="col" className="p-1 font-medium uppercase tracking-wider">Ticker</th>
              <th scope="col" className="p-1 font-medium uppercase tracking-wider">
                { ticker && ticker!=='' ? 'Fail Date' : 'Name' }
              </th>
              <th scope="col" className="p-1 font-medium uppercase tracking-wider" onClick={ () => { if (sort==='Quantity'){ return; } readFtds({sort:'Quantity'}); }}>
                Shares
                {sort==='Quantity' ? <ICONS.ChevronDownIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>}
              </th>
              <th scope="col" className="p-1 font-medium uppercase tracking-wider">Price</th>
              <th scope="col" className="p-1 font-medium uppercase tracking-wider cursor-pointer hover:bg-indigo-700" onClick={ () => { if (sort==='Value'){ return; } readFtds({sort:'Value'}); }}>
                Value
                {sort==='Value' ? <ICONS.ChevronDownIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>}
              </th>
              <th scope="col" className="hidden p-1 font-medium uppercase tracking-wider lg:table-cell cursor-pointer hover:bg-indigo-700" onClick={ () => { if (sort==='T38Date'){ return; } readFtds({sort:'T38Date'}); }}>
                T38 Date
                {sort==='T38Date' ? <ICONS.ChevronDownIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>}
              </th>
              <th scope="col" className={`hidden p-1 font-medium uppercase tracking-wider ${ ticker && ticker!=='' ? 'lg:table-cell' : ''}`}>T38 Volume</th>
              <th scope="col" className="hidden p-1 font-medium uppercase tracking-wider lg:table-cell">High %</th>
              <th scope="col" className="hidden p-1 font-medium uppercase tracking-wider lg:table-cell">Low %</th>
              <th scope="col" className="hidden p-1 font-medium uppercase tracking-wider lg:table-cell">Close %</th>
            </tr>
          </thead>
          
          <tbody>
            { (!list || !list.length) &&
              <tr><th scope="col" colSpan="10" className="py-8 font-light text-center text-gray-300 text-xs">no ftds recorded</th></tr>
            }
            { list && list.length>0 &&
              (list).map( (entry,idx) =>
                <tr key={idx} className={`bg-opacity-25 text-left ${idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'} text-xxs ${ filterMatch(ticker, entry, shareMinimum, valueMinimum) ? 'text-gray-300' : 'text-gray-500'}`}>
                  <td className="p-1 font-light">
                    <span className='cursor-pointer hover:bg-indigo-800 w-full block lg:hidden'>{ entry.Ticker }</span>
                    <span className='hidden lg:block'>{ entry.Ticker }</span>
                  </td>
                  <td className="p-1 font-light">
                    <span className='cursor-pointer hover:bg-indigo-800 w-full block lg:hidden'>{ ticker && ticker!=='' ? formatDatestring(entry.SettlementDate) : entry.Description}</span>
                    <span className='hidden lg:block'>{ ticker && ticker!=='' ? formatDatestring(entry.SettlementDate) : entry.Description}</span>
                  </td>
                  <td className="p-1 font-light">{ entry.Quantity && entry.Quantity ? (entry.Quantity).toLocaleString() : '-' }</td>
                  <td className="p-1 font-light">{ entry.Price && entry.Price.$numberDecimal && Number(entry.Price.$numberDecimal)>0 ? `$${ (Number(entry.Price.$numberDecimal)).toLocaleString() }` : '-' }</td>
                  <td className="p-1 font-light">{ entry.Value && entry.Value.$numberDecimal && Number(entry.Value.$numberDecimal)>0 ? `$${ abbrInt(entry.Value.$numberDecimal) }` : '-' }</td>
                  <td className="hidden p-1 font-light lg:table-cell">{ entry.T38Date ? formatDatestring(entry.T38Date) : '-' }</td>
                  <td className={`hidden p-1 font-light ${ ticker && ticker!=='' ? 'lg:table-cell' : '' }`}>
                    { entry.Volume && isNaN(entry.Volume)===false ? (entry.Volume).toLocaleString() : '-'}
                  </td>
                  <td className="hidden p-1 font-light lg:table-cell">{ entry.PreviousCloseToHighPercent && entry.PreviousCloseToHighPercent.$numberDecimal ? `${ entry.PreviousCloseToHighPercent.$numberDecimal }%` : '-' }</td>
                  <td className="hidden p-1 font-light lg:table-cell">{ entry.PreviousCloseToLowPercent && entry.PreviousCloseToLowPercent.$numberDecimal ? `${ entry.PreviousCloseToLowPercent.$numberDecimal }%` : '-' }</td>
                  <td className="hidden p-1 font-light lg:table-cell">
                    { entry.ClosePercent && entry.ClosePercent.$numberDecimal && Number(entry.ClosePercent.$numberDecimal)>0 ? <ICONS.ChevronUpIcon className='ml-1 text-green-500 h-4 w-4 inline' /> : <></>}
                    { entry.ClosePercent && entry.ClosePercent.$numberDecimal && Number(entry.ClosePercent.$numberDecimal)<0 ? <ICONS.ChevronDownIcon className='ml-1 text-red-500 h-4 w-4 inline' /> : <></>}
                    { entry.ClosePercent && entry.ClosePercent.$numberDecimal ? `${ entry.ClosePercent.$numberDecimal }%` : '-' }
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      }

    </main>
  );
};

export default React.memo(FailToDelivers, dontRerender);