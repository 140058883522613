
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import VisibilitySensor from 'react-visibility-sensor';
import signedCurrency from '../bin/signedCurrency';
import abbrInt from '../bin/abbrInt';

const MAIN = ({trade}) => {
	const [chartData, setChartData] = useState([]);

  // This function is triggered when visibility changes
	const onVisibilityChange = (visible) => {
		if (!visible || chartData.length>0 || !trade || !trade.SparklineData || !trade.SparklineData.length) { return; }
		//add the data to chartData with a slight delay of 100ms to give an animation effect
		for (let i = 0; i < trade.SparklineData.length; i++) {
			setTimeout(() => {
				setChartData(chartData => [...chartData, trade.SparklineData[i]]);
			}, i * 10);
		}
  };
	
  return (
    <VisibilitySensor onChange={onVisibilityChange}>
			<Link to={`/notabletrades?id=${trade._id}`} className="relative rounded-xl bg-black text-white w-[300px] h-[120px]">
				<div className="absolute top-2 left-2 text-xs bg-sky-700/50 rounded px-2 py-1">
					{trade && trade.UnderlyingTicker && trade.OptionType && trade.OptionStrike && trade.hasOwnProperty('DaysToExpiration') ?
						<span>{trade.UnderlyingTicker} {trade.OptionType} ${trade.OptionStrike} <span className='text-rose-400'>{trade.DaysToExpiration}dte</span></span> : '' }
				</div>
				<div className="absolute top-2 right-2 text-xs bg-emerald-700/50 rounded px-2 py-1">
					{trade && trade.EndingHighestPercent ? <div>{(trade.EndingHighestPercent).toLocaleString()}%</div> : ''}
				</div>
				<Sparklines data={chartData} height={90}>
					<SparklinesLine color={trade && trade.SparklineColor ? trade.SparklineColor : 'gray'} />
					<SparklinesSpots spotColors={trade && trade.SparklineColor && trade.SparklineColor === 'green' ? { '1': 'green' } : ( trade && trade.SparklineColor && trade.SparklineColor==='red' ? {'-1':'red'} : {'0':'black'})} />
				</Sparklines>
				<div className="absolute bottom-2 left-2">
					
				</div>
				<div className="absolute bottom-2 left-2 text-xs bg-purple-700/50 rounded px-2 py-1">
					{trade && trade.Price && trade.Size && trade.EndingHighest && trade.SharesPerContract ?
						`$${abbrInt(Math.trunc(trade.Price * trade.Size * trade.SharesPerContract))} -> $${abbrInt(Math.trunc(trade.EndingHighest * trade.Size * trade.SharesPerContract))}` : ''}
				</div>
				<div className="absolute bottom-2 right-2 text-xs bg-emerald-700/50 rounded px-2 py-1">
					{trade && trade.Price && trade.Size && trade.EndingHighest && trade.SharesPerContract ? signedCurrency(abbrInt(Math.trunc(((trade.Price * -1) + trade.EndingHighest) * trade.Size * trade.SharesPerContract)), '$') : ''}
				</div>
			</Link>
		</VisibilitySensor>
  )
}

export default MAIN;