import isLoadedFromPWA from '../bin/isLoadedFromPWA';

const Headers = (token) => {
	let pwaRequest = isLoadedFromPWA() === true ? true : false;
  const payload={ 'Content-Type': 'application/json', };
  if ( token ){  payload.Authorization= token; }
  
	if ( pwaRequest ) { payload.Accept = 'PWA'; }
  return payload;
};

export default Headers;