import React from 'react';
import MENU_ITEM_LOGIN from './MENU_ITEM_LOGIN';
import MENU_ITEM_LOGOUT from './MENU_ITEM_LOGOUT';
import MENU_ITEM_ACCOUNT from './MENU_ITEM_ACCOUNT';
import MENU_ITEM_TICKER_SEARCH from './MENU_ITEM_TICKER_SEARCH';
import { Link } from 'react-router-dom';
import classNames from '../bin/classNames';
import ICONS from '../bin/ICONS';

const SIDE_MENU = (props) => {
	const noChrome = props.noChrome,
		navigation = props.navigation,
		token= props.token,
		level= props.level;
  let ui_navigation= navigation.filter( ({menu})=>menu!==false ).map((item) => {
		if (!level && item.menulevel) { return; }
		else if (level && item.menulevel) {
			if (item.menulevel === 'black' && level !== 'black' && level !== 'diamond' && level !== '1337') { return; }
			if (item.menulevel === 'diamond' && level !== 'diamond' && level !== '1337') { return; }
			if (item.menulevel === '1337' && level !== '1337') { return; }
		}
    const buildIcon=(itemData)=>{
      let classData = classNames( itemData.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', `mr-3 flex-shrink-0 h-6 w-6` );
      let builtIcon = React.createElement(ICONS[itemData.icon], {"aria-hidden":"true", className:classData});
      return builtIcon;
    };
    return <Link key={item.name} to={item.href} className={ classNames(item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md')}>
      {item.icon && ICONS[item.icon] ? buildIcon(item) : <></>}
      {item.name}
    </Link>;
  });
  return (
		<div className={`${noChrome ? 'hidden' : 'hidden lg:flex lg:flex-shrink-0'}`}>
      <div className="flex flex-col w-64">

        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">

            <Link to="/" className="flex items-center flex-shrink-0 px-4 text-white">
              <img src="/bin/Icon.png" alt="Red Striped Tie Logo" className="w-50px mr-2" />
							Red Striped Tie
            </Link>
						
						{(token) ?
							<nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
								<MENU_ITEM_TICKER_SEARCH setSidebarOpen={null} token={token} />
								{ui_navigation}
							</nav>
							:
							<></>
						}
          </div>

          { (!token) ? 
            <MENU_ITEM_LOGIN
              navigation={navigation}
							setSidebarOpen={null} />
						:
            <MENU_ITEM_ACCOUNT
              navigation={navigation}
              setSidebarOpen={null}
              token={token} />
          }

        </div>
      </div>
    </div>
  );
};
export default SIDE_MENU;