import abbrInt from "../bin/abbrInt";
import signedCurrency from "../bin/signedCurrency";
import formatStockName from "../bin/formatStockName";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";

const MAIN = (tickersBySicData, highlightTicker, outstandingShares) => {
  return `<div class="w-full">
		<div class="pt-2">
			<dl class="divide-y divide-gray-900">
				${ tickersBySicData ?
				(tickersBySicData).map((entry,index) => 
						`<div class="py-0 grid grid-cols-3 gap-4 px-0 ${entry.Ticker===highlightTicker ? 'bg-gray-200' : ''}" key=${index}>
							<dt class="text-xs font-medium flex flex-col sm:flex-row items-start justify-start gap-1">
								<span class="text-sky-800 font-bold">${ entry.Ticker }</span>
								<span class="text-sky-400 font-light tracking-tighter w-full truncate">${ formatStockName(entry.Name) }</span>
							</dt>
							<dd class="text-xs text-black mt-0 col-span-2 flex flex-col items-end justify-start">
								<span class="text-gray-600"><span class="sm:hidden inline-block">M.Cap:</span><span class="hidden sm:inline-block">Market Cap:</span> $${ abbrInt(entry.MarketCap, false) } <span class="rounded px-1 ${entry.MarketCapChange>0 ? 'bg-emerald-500' : '' }${entry.MarketCapChange<0 ? 'bg-rose-500' : '' }${!entry.MarketCapChange ? 'bg-gray-500' : '' } text-white">${ signedCurrency(abbrInt(entry.MarketCapChange, false), '$') }</span></span>
								<span class="text-gray-600">Last: $${ entry.Last } <span class="rounded px-1 ${entry.PercentLast>0 ? 'bg-emerald-500' : '' }${entry.PercentLast<0 ? 'bg-rose-500' : '' }${!entry.PercentLast ? 'bg-gray-500' : '' } text-white">${ entry.PercentLast }%</span></span>
								${outstandingShares && outstandingShares!==entry.OutstandingShares ? `<span class="text-purple-200 bg-purple-800 w-full text-right"><span class="sm:hidden inline-block">M.Cap Comp:</span><span class="hidden sm:inline-block">Market Cap Comparable:</span> $${ (truncateToTwoDigits(entry.MarketCap / outstandingShares)).toLocaleString() }</span>` : ''}
							</dd>
						</div>`
					).join('')
					: ''
				}
			</dl>
		</div>
	</div>`;
};
export default MAIN;