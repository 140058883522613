const main = (event, setStateFunction) => {
	if (!setStateFunction) { return; }
		event.preventDefault();
		//simulate a file input click
		let input = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/*';
		input.onchange = (e) => {
			let file = e.target.files[0];
			if (!file) { return; }
			let reader = new FileReader();
			reader.onload = (e) => {
				//resize the image to a max of 150x150
				let img = new Image();
				img.src = e.target.result;
				img.onload = () => {
					let canvas = document.createElement('canvas');
					let ctx = canvas.getContext('2d');
					let max_size = 150;
					let width = img.width;
					let height = img.height;
					if (width > height) {
						if (width > max_size) {
							height *= max_size / width;
							width = max_size;
						}
					} else {
						if (height > max_size) {
							width *= max_size / height;
							height = max_size;
						}
					}
					canvas.width = width;
					canvas.height = height;
					ctx.drawImage(img, 0, 0, width, height);
					// setAvatar(canvas.toDataURL('image/jpeg', 0.7));
					setStateFunction(canvas.toDataURL('image/jpeg', 0.7));
				}
			};
			reader.readAsDataURL(file);
		};
    document.body.appendChild(input);
		input.click();
		input.remove();
}

export default main;