import Chart from 'chart.js/auto';
import React, { useEffect, useState, useRef } from 'react';
import dontRerender from '../bin/dontRerender';
import Headers from '../bin/Headers';
import classNames from '../bin/classNames';
import config from '../config.json';
import * as d3 from 'd3';

const MAIN = (props) => {
  let ticker = props.ticker;
  let token = props.token;
  const initialized = useRef(false);
  const candles = useRef(null);
  const highestHigh = useRef(null);
  const lowestLow = useRef(null);
  const priceLevels = useRef(null);
  const chartRef = useRef(null);
  const [format, setFormat] = useState('minute');
  const timespans = [
		{name:"day", value:"day"},
		{name:"minute", value:"minute"},
		{name:"hour", value:"hour"},
		{name:"month", value:"month"},
	];

  const fetchCandles = async (payload) => {
    return await fetch(`${ config.api }/candles_p3?ticker=${ ticker }&format=${ payload && payload.format ? payload.format : format }`, { headers: Headers(token) })
      .then((d) => (d.status === 200 ? d.json() : null))
      .then((d) => (d && d.result && d.result.length ? d.result : null));
  };
  
	const addDataset = (chart, newDataset) => {
		chart.data.datasets.push(newDataset);
		chart.update();
	};

	const generateChart = ()=>{
		chartRef.current = new Chart(document.getElementById('ChartSpace').getContext('2d'), {
			type: 'line',
			data: {
				labels:[],
				datasets: [
					{ name:'high', type:'line', data:[], borderColor:'rgba(7, 207, 0, 0.5)', radius:0, },
					{ name:'low', type:'line', data:[], borderColor:'rgba(207, 0, 0, 0.5)', radius:0, },
					{ name:'highesthigh', type:'bubble', pointStyle:'bubble', data:[], backgroundColor:'rgb(7, 207, 0)', },
					{ name:'lowestlow', type:'bubble', pointStyle:'bubble', data:[], backgroundColor:'rgb(207, 0, 0)', },
					{ name:'bluesignal', type:'bubble', pointStyle:'bubble', data:[], backgroundColor:'rgb(0, 213, 255)', },
					// { name:'pricelevel33', type:'line', data:[], borderColor:'rgba(0, 157, 255, 0.33)', radius:0, },
					// { name:'pricelevel67', type:'line', data:[], borderColor:'rgba(0, 157, 255, 0.67)', radius:0, },
					// { name:'pricelevel100', type:'line', data:[], borderColor:'rgba(0, 157, 255, 1)', radius:0, },
				],
			},
			options: {
				responsive: true,
				animation: false,
				scales: {
					y: {
						beginAtZero: true
					}
				},
				// onHover: (e, element) => {
					// console.log(element);
				// },
				elements: {
					line: {
						tension: 0 // Disable bezier curves for a straight line
					}
				},
				plugins:{
					legend:{
						display: false,
					},
					tooltip: {
						enabled: true,
					},
				},
				scales: {
					x: {
						ticks: {
							callback: function(value, index, ticks) {
								let time = this.getLabelForValue(value);
								// if (time.indexOf(':00')>-1 || time.indexOf(':15')>-1 || time.indexOf(':30')>-1 || time.indexOf(':45')>-1){
									return time;
								// }
								// return null;
							}
						}
					},
				}
			},
		});
	};

	const addChartData = (chart, label, payload)=>{
    chart.data.labels.push(label);
    chart.data.datasets.forEach((dataset) => {
			if (payload.hasOwnProperty(dataset.name)){
				dataset.data.push( payload[dataset.name] );
			}else if (dataset.type==='line'){
				dataset.data.push( null );
			}
		});
    chart.update();
	};

	const loadCandles = ()=>{
		let _candles = [...candles.current];
		let hh = _candles.map( ({High}) => High ).sort( (a,b) => b-a );
		hh = hh && hh.length ? hh[0] : null;
		let ll = _candles.map( ({Low}) => Low ).sort( (a,b) => a-b );
		ll = ll && ll.length ? ll[0] : null;
		for (let i=0; i<_candles.length; ++i){
			let payload = {
				high: _candles[i].High,
				low: _candles[i].Low,
				highesthigh: (_candles[i].High===hh) ? _candles[i].High : null,
				lowestlow: (_candles[i].Low===ll) ? _candles[i].Low : null,
			};
			
			let label = null;
			let _DateString = (_candles[i].DateString).slice(-5);
			label = _DateString;
			addChartData(chartRef.current, label, payload);
		}
	};

	const updateCandles = async(payload)=>{
		if (payload.format){ setFormat(payload.format); }
		chartRef.current.destroy();
		generateChart();
		let _candles = await fetchCandles(payload);
		candles.current = _candles;
		await loadCandles();
	};

	const start = async()=>{
		if (initialized.current!==false){ return; }
		initialized.current = true;
		generateChart();
		let _candles = await fetchCandles();
		let _highestHigh = ((_candles) => _candles.reduce((max, item) => item.High > max ? item.High : max, -Infinity))(_candles);
		let _lowestLow = ((_candles) => _candles.reduce((min, item) => item.Low < min ? item.Low : min, Infinity))(_candles);
		candles.current = _candles;
		highestHigh.current = _highestHigh;
		lowestLow.current = _lowestLow;
		await loadCandles();
	};

  useEffect(() => { start(); }, [ticker]);

  return (
    <div className="max-w-full sm:max-w-none max-h-fit flex-grow flex flex-col justify-start md:ml-4 gap-4 rounded border bg-gray-800">
      <canvas id="ChartSpace" />
    </div>
  );
};

export default React.memo(MAIN, dontRerender);