const readUrlQuery = () => {
  // const params = new Proxy(new URLSearchParams(window.location.search), {
  //   get: (searchParams, prop) => searchParams.get(prop),
  // });
  // return params;
	const searchParams = new URLSearchParams(window.location.search);
  let params = {};
  for(let pair of searchParams.entries()) {
    params[pair[0]] = pair[1];
  }
  return params;
};

export default readUrlQuery;