import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import abbrInt from "../bin/abbrInt";
import formatExchangeVolumePercentWithSign from "../bin/formatExchangeVolumePercentWithSign";
import { ChartPieIcon, XMarkIcon } from '@heroicons/react/24/solid';

const MODAL= (props)=>{
	const { open, setOpen, ticker, totalVolume, exchangeVolume } = props,
		[chartReady, setChartReady] = useState(false),
		[modalWidth, setModalWidth] = useState(320),
		cancelButtonRef = useRef(null),
		data = exchangeVolume && Object.keys(exchangeVolume) ? Object.keys(exchangeVolume).map(
			(Exchange) => {
				const ExchangeData = exchangeVolume[Exchange];
				return {
					value: ExchangeData && ExchangeData.Notional ? Math.trunc(ExchangeData.Notional) : 0,
					capital: ExchangeData && ExchangeData.Notional ? `$${abbrInt(ExchangeData.Notional, false)}` : 0,
					shares: ExchangeData && ExchangeData.Shares ? abbrInt(ExchangeData.Shares, false, false) : 0,
					trades: ExchangeData && ExchangeData.Trades ? abbrInt(ExchangeData.Trades, false, false) : 0,
					name: Exchange
				}
			}
		) : [],
		[option, setOption] = useState({
			tooltip: {
				trigger: 'item',
				formatter: function (params) {
					return `${params.seriesName} <br/>${params.name}: (${params.percent}%)<br/>Capital: ${params.data.capital}<br/>Trades: ${params.data.trades}<br/>Shares: ${params.data.shares}`;
				}
			},
			legend: {
				show: false,
				orient: 'vertical',
				left: 10,
				data: (exchangeVolume && Object.keys(exchangeVolume) ? Object.keys(exchangeVolume) : [])
			},
			series: [
				{
					name: 'Exchange Volume',
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: 'center'
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '20',
							fontWeight: 'bold'
						}
					},
					labelLine: {
						show: false
					},
					data,
				}
			]
		});
	
	// const data = exchangeVolume && Object.keys(exchangeVolume).map(
	// 	(Exchange) => {
	// 		const ExchangeData = exchangeVolume[Exchange];
	// 		return {
	// 			value: ExchangeData && ExchangeData.Notional ? Math.trunc(ExchangeData.Notional) : 0,
	// 			capital: ExchangeData && ExchangeData.Notional ? `$${abbrInt(ExchangeData.Notional, false)}` : 0,
	// 			shares: ExchangeData && ExchangeData.Shares ? abbrInt(ExchangeData.Shares, false, false) : 0,
	// 			trades: ExchangeData && ExchangeData.Trades ? abbrInt(ExchangeData.Trades, false, false) : 0,
	// 			name: Exchange
	// 		}
	// 	}
	// );

	// const getOption = () => ({
  //   tooltip: {
  //     trigger: 'item',
  //     formatter: function (params) {
	// 			return `${params.seriesName} <br/>${params.name}: (${params.percent}%)<br/>Capital: ${params.data.capital}<br/>Trades: ${params.data.trades}<br/>Shares: ${params.data.shares}`;
	// 		}
  //   },
	// 	legend: {
	// 		show: false,
  //     orient: 'vertical',
  //     left: 10,
  //     data: (exchangeVolume && Object.keys(exchangeVolume) ? Object.keys(exchangeVolume) : [])
  //   },
  //   series: [
  //     {
  //       name: 'Exchange Volume',
  //       type: 'pie',
  //       radius: ['50%', '70%'],
  //       avoidLabelOverlap: false,
  //       label: {
  //         show: false,
  //         position: 'center'
  //       },
  //       emphasis: {
  //         label: {
  //           show: true,
  //           fontSize: '20',
  //           fontWeight: 'bold'
  //         }
  //       },
  //       labelLine: {
  //         show: false
  //       },
	// 			data,
  //     }
  //   ]
	// });
	
	useEffect(() => {
    if (open) {
      // Delay the chart initialization
			const timer = setTimeout(() => setChartReady(true), 300); // Adjust based on your modal's animation time
			//watch the screen size to determine to show the chart legent or not
			const handleResize = () => {
				const width = window.innerWidth;
				if (width < 640) {
					setOption({
						legend: {
							show: false
						}
					});
					setModalWidth(320);
				} else {
					setOption({
						legend: {
							show: true
						}
					});
					setModalWidth(600);
				}
			}
			window.addEventListener('resize', handleResize);
      return () => {clearTimeout(timer); window.removeEventListener('resize', handleResize);}
    } else {
      setChartReady(false); // Reset on close
    }
  }, [open]);
	
  return (
    <Transition.Root show={open} as="div">
      <Dialog as="div" className="fixed inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex justify-center items-center min-h-screen text-center pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6 min-w-[600px]"> */}
            <div className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6 min-w-[${modalWidth}px]`}>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500" onClick={() => setOpen(false)} >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 flex items-center justify-center">
										<ChartPieIcon className="h-6 w-6 inline-block text-amber-500" aria-hidden="true" />
										<span className='text-sm bg-amber-300 text-amber-700 px-2 py-0.5 rounded mx-1'>{ticker}</span> Exchange Volume
									</Dialog.Title>
									<div className="mt-2">

                    {chartReady && (
                      <ReactECharts option={option}  />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
												
  )
};
export default MODAL;