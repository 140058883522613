const main = (ticker, returnMap=false) => {
  ticker = ticker.replace('O:','');

  let characters = ticker.split('');
  let brokerTicker = '';
  // let expirationDate = null;
  let underlyingTicker = '';
  let yy = '';
  let mm = '';
  let dd = '';
  let type = '';
  let strike = '';
  let sequence = ['underlyingTicker', 'yy', 'mm', 'dd', 'type', 'strike'];

  for (let c of characters){
    //underlyingTicker
    if (sequence[0]==='underlyingTicker'){
      if (isNaN(c)===false){
        sequence.shift();
        yy += c.toString();
        continue;
      }else{
        underlyingTicker += c.toString();
        continue;
      }
    }
    //yy
    if (sequence[0]==='yy'){
      if (yy.length===2){
        sequence.shift();
        mm += c.toString();
        continue;
      }else{
        yy += c.toString();
        continue;
      }
    }
    //mm
    if (sequence[0]==='mm'){
      if (mm.length===2){
        sequence.shift();
        dd += c.toString();
        continue;
      }else{
        mm += c.toString();
        continue;
      }
    }
    //dd
    if (sequence[0]==='dd'){
      if (dd.length===2){
        sequence.shift();
        type += c.toString();
        continue;
      }else{
        dd += c.toString();
        continue;
      }
    }
    //type
    if (sequence[0]==='type'){
      if (type.length===1){
        sequence.shift();
        strike += c.toString();
        continue;
      }
    }
    //strike
    if (sequence[0]==='strike'){
      if (strike.length===5){ strike += '.'; }
      strike += c.toString();
      continue;
    }
  }

  underlyingTicker = underlyingTicker.toUpperCase();
  // yy = Number(yy) < 30 ? '20'+yy : '19'+yy;
  // type = type==='C' ? 'CALL' : ( type==='P' ? 'PUT' : null );
  strike = Number(Number(strike).toFixed(2));
  // expirationDate = new Date(`20${yy}-${mm}-${dd}T07:00:00.000Z`);
  brokerTicker = `${underlyingTicker} ${yy}${mm}${dd}${type}${strike}`;

  // brokerTicker += `${ (expiryDate.getFullYear().toString()).slice(-2) }${ (expiryDate.getMonth()+1).toString().padStart(2, "0") }${ expiryDate.getDate().toString().padStart(2, "0") }`;
  // brokerTicker += `${ type.toUpperCase().slice(0,1) }`;

	if (returnMap){
		return {
			UnderlyingTicker: underlyingTicker,
			BrokerTicker: brokerTicker,
			Strike: strike,
			Type: (type==='C' ? 'CALL' : (type==='P' ? 'PUT' : null)),
			Year: Number(`20${yy}`),
			Month: Number(mm),
			Day: Number(dd),
			ExpirationDateString: `20${yy}-${mm}-${dd}`,
		};
	}
	
  return brokerTicker;

};
export default main;