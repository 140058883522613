const hideKeyboardOnEnter = (element)=>{
  let userAgent= navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)===false && /android/i.test(userAgent)===false && (/iPad|iPhone|iPod/.test(userAgent)===false && window.MSStream)){ return; }
  try{
    element.setAttribute('readonly', 'readonly');
    element.setAttribute('disabled', 'true');
    setTimeout( () => {
      element.blur();
      element.removeAttribute('readonly');
      element.removeAttribute('disabled');
    }, 100);
  }catch(e){
    //don't care
    // console.error(e);
  }
};
export default hideKeyboardOnEnter;