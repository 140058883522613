import React from 'react';
import STOCKS_LARGEST_TRADES from "../components/STOCKS_LARGEST_TRADES";
import OPTIONS_LARGEST_TRADES from "../components/OPTIONS_LARGEST_TRADES";
import OPTIONS_NOTABLE_TRADES from "../components/OPTIONS_NOTABLE_TRADES";

const MAIN = (props) => {
	let ticker = props.ticker,
		topTrades = props.topTrades,
		selectedLargestTradeType = props.selectedLargestTradeType,
		topStockTradesByValue = props.topStockTradesByValue;
	
  return (
		<div className='w-full h-full p-1'>
			{selectedLargestTradeType === 'stock trades' && topStockTradesByValue ? <STOCKS_LARGEST_TRADES ticker={ticker} trades={topStockTradesByValue} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
			{selectedLargestTradeType === 'binary options' && topTrades ? <OPTIONS_LARGEST_TRADES largesttype='value' ticker={ticker} trades={topTrades.TopOptionBinaryTradesByValue} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
			{selectedLargestTradeType === 'valuable options' && topTrades ? <OPTIONS_LARGEST_TRADES largesttype='value' ticker={ticker} trades={topTrades.TopOptionTradesByValue} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
			{selectedLargestTradeType === 'sizable options' && topTrades ? <OPTIONS_LARGEST_TRADES largesttype='size' ticker={ticker} trades={topTrades.TopOptionTradesBySize} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
			{selectedLargestTradeType === 'notable options' && topTrades ? <OPTIONS_NOTABLE_TRADES largesttype='value' ticker={ticker} trades={topTrades.NotableOptionTrades} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
		</div>
  );
}
export default MAIN;