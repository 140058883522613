import React, { createContext, useContext, useRef, useState } from 'react';
import config from '../config.json';
import { useNavigate } from "react-router-dom";
import { useSelector, useStore, useDispatch } from 'react-redux';
import actions from '../state/actions';


const WebSocketContext = createContext({
  websocket: null,
	updateWebSocket: () => { },
	closeWebSocket: () => { },
});

export const WebSocketProvider = ({ children }) => {
	const dispatch = useDispatch(),
		navigate = useNavigate(),
		heartbeat = useRef(null),
		attemptingReconnection = useRef(false),
		[websocket, setWebsocket] = useState(null);

	const updateWebSocket = (token) => {
		// if (token === null || token === undefined) { return; }
		if (websocket && websocket.readyState && websocket.readyState===WebSocket.OPEN) { return; }
		const ws = new WebSocket(`${config.wsapi}/${token ? `?token=${token}` : ''}`);
		ws.onopen = () => {
			setWebsocket(ws);
			heartbeat.current = setInterval(() => { ws.send(JSON.stringify({ request: 'heartbeat' })); }, 5000);
			attemptingReconnection.current = false;
		};
		ws.onclose = () => {
			setWebsocket(null);
			clearInterval(heartbeat.current);
			attemptingReconnection.current = true;
		};
		ws.onerror = (e) => {
			console.log('websocket error', e);
			setWebsocket(null);
			clearInterval(heartbeat.current);
			//attempt to reconnect as long as it's not already trying to reconnect
			if (!attemptingReconnection.current) {
				attemptingReconnection.current = true;
				return updateWebSocket(token);
			}
			// dispatch(actions.removeToken(token)); //remove the token from the store
			// navigate('/login', { replace: true });
		};
	};

	const closeWebSocket = () => {
		if (websocket) { websocket.close(); }
	};

  return (
    <WebSocketContext.Provider value={{ websocket, updateWebSocket, closeWebSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);