import { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { MinusIcon } from '@heroicons/react/20/solid';
import Headers from "../bin/Headers";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import decodeJwt from "../bin/decodeJwt";
import config from '../config.json';

const stripePromise = loadStripe(config.stripe[config.stripe.env]);

const Main = () => {
	const token = useSelector(state => state.token),
		decodedToken = decodeJwt(token),
		[currentLevel, setCurrentLevel] = useState(null),
		[selectedLevel, setSelectedLevel] = useState(null),
		[user, setUser] = useState({}),
		[showDowngrade, setShowDowngrade] = useState(false),
		[showCancelAutoRenewal, setShowCancelAutoRenewal] = useState(false),
		[showExclusiveMemberships, setShowExclusiveMemberships] = useState(false),
		navigate = useNavigate(),
		tiers = [
			{
				name: 'Red',
				image: '/bin/RedTie.png',
				monthlyPrice: null,
				features: [
					// 'Ad supported',
					'Limited/Delayed data',
				],
			},
			{
				name: 'Black',
				image: '/bin/BlackTie.png',
				monthlyPrice: 40,
				discount: 0, //50,
				features: [
					// 'No ads',
					'Live data',
					'Real-time alerts',
					// 'Trade log & reports',
					// 'Historic trade/quote data',
					// 'Order book depth & tracking',
					// 'Public social media sharing',
					// 'Buy/Sell trade flow analysis',
				],
				comingFeatures: [
					'Risk assessment',
					'Strategy backtesting',
					'Correlation analysis',
					// 'Trade tracing',
					// 'Profile trade shadowing',
					// 'Option/Stock trade management',
					// 'Automated event trading',
				],
			},

			// {
			// 	name: 'White',
			// 	image: '/bin/PurpleTie.png',
			// 	monthlyPrice: 1000,
			// 	discount: 0,
			// 	features: [
			// 		'Exclusive Invite Only',
			// 		// 'Buy/Sell trade flow analysis',
			// 	],
			// },
		];
	
	const upgradeLevel = async () => {
		const stripe = await stripePromise;
		const session = await fetch(`${config.api}/subscription`, { headers: Headers(token), method: 'POST', body: JSON.stringify({ Tie: selectedLevel }) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
		if (!session || !session.id) { return; }
		const { error } = await stripe.redirectToCheckout({ sessionId: session.id });
		if (error) {
			window.alert(`An error occurred: ${error.message}`);
		}
	};

	const downgradeLevel = async () => {
		if (showCancelAutoRenewal) {
			let expireDateString = new Date();
			expireDateString.setTime(user.stripeSubscriptionEndTime * 1000);
			expireDateString = expireDateString.toLocaleDateString();
			let confirmed = window.confirm(`Are you sure you'd like to downgrade to Red Tie.\nThis will cancel your subscription's auto-renewal and your access to Black Tie features will expire on ${expireDateString}.`);
			if (!confirmed) { return; }
			let response = await fetch(`${config.api}/autorenew`, { method: 'PUT', headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
			return navigate('/account', { replace: true });		
		} else if (showDowngrade) {
			let confirmed = window.confirm(`Are you sure you want to downgrade to the Red Tie?\nThis will remove your access to Black Tie features and the change will be immediate.`);
			if (!confirmed) { return; }
			const response = await fetch(`${config.api}/account`, { headers: Headers(token), method: 'PUT', body: JSON.stringify({ downgrade: true }) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
			return navigate('/', { replace: true });
		}
	};

	const readLevelCheck = async () => {
		if (!token) { return; }
		const response = await fetch(`${config.api}/levelcheck`, { headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
		if (!response) { return; }
		if (response.level === 'black' && response.stripeCustomerId && response.stripeSubscriptionRecurring && response.stripeSubscriptionRecurringCost) {
			setShowCancelAutoRenewal(true);
		}
		if (response.level === 'black' && !response.stripeCustomerId && !response.stripeSubscriptionRecurring && !response.stripeSubscriptionRecurringCost) {
			setShowDowngrade(true);
		}
		setUser(response);
	};

	const canShowExclusiveMemberships = async (decodedToken) => {
		if (!decodedToken || !decodedToken.level || decodedToken.level!=='black') { return; }
		// const response = await fetch(`${config.api}/exclusive`, { headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
		const response = true;
		if (!response) { return; }
		setShowExclusiveMemberships(true);
	};
	
	useEffect(() => {
		if (!decodedToken || !decodedToken.level) { return; }
		setShowCancelAutoRenewal(false);
		setShowDowngrade(false);
		setCurrentLevel( (decodedToken.level).toLowerCase() );
		setSelectedLevel((decodedToken.level).toLowerCase());
		readLevelCheck();
		// canShowExclusiveMemberships(decodedToken);
	}, []);

  return (
    <div className="bg-gray-900 py-8 sm:py-12">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
					<h1 className="mt-2 text-4xl font-bold tracking-normal text-white sm:text-5xl">Pick a Tie</h1>
					<p className="mt-4 text-lg leading-6 text-gray-300">Simple metrics. Simple pricing. Fast actionable data.</p>
        </div>
				<div className="isolate mx-auto mt-10 grid max-w-lg grid-cols-2 gap-4 sm:gap-8">
					
					
          {tiers.map((tier) => (
						<button key={tier.name} className={`${selectedLevel === (tier.name).toLowerCase() ? 'bg-white/5 ring-2 ring-indigo-500' : ''} ring-1 ring-white/10 rounded-3xl relative`} onClick={() => { if (!token) { return; } setSelectedLevel((tier.name).toLowerCase())}}>
								{selectedLevel === (tier.name).toLowerCase() ? <div className="absolute inset-0 rounded-3xl bg-indigo-500 blur-md filter drop-shadow-lg"></div> : <></>}
								<div className="relative rounded-3xl bg-gray-900 ring-1 ring-white/10">

								{(tier.name).toLowerCase() === currentLevel ? <span className='w-full text-sky-200 text-center block font-light bg-indigo-500 rounded-t-3xl h-6'>curent level</span> : <></>}
								{(tier.name).toLowerCase() !== currentLevel ? <span className='w-full block rounded-t-3xl h-6'></span> : <></>}
								<div className='px-2 py-4 sm:p-8 xl:p-10 h-[300px]'>
									<div className="flex flex-col items-center justify-center gap-x-4">
										<h2 className="text-2xl font-semibold leading-8 text-white">
											{tier.name}
										</h2>
										<div className="mt-4 h-14 flex items-center justify-center">
											<img className="border bg-gradient-to-b from-white to-gray-400 border-gray-700 p-1 rounded-full h-14 w-14" src={tier.image} alt={tier.name} />
										</div>
									</div>
									<ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
										{tier.features.map((feature) => (
											<li key={feature} className="flex gap-x-3 text-left">
												<MinusIcon className="h-6 w-5 flex-none text-green-500" aria-hidden="true" />
												{feature}
											</li>
										))}
									</ul>
								</div>

								<div className='w-full flex flex-col items-center justify-center bg-indigo-500 rounded-b-3xl h-14'>
									{!tier.monthlyPrice ? <span className='text-white text-xl font-bold tracking-wider'>FREE</span> : ``}
									{tier.monthlyPrice && tier.discount ? <span className='text-gray-400 text-sm font-bold tracking-wider line-through'>${ tier.monthlyPrice }/month</span> : ``}
									{tier.monthlyPrice && tier.discount ? <span className='text-white text-xl font-bold tracking-wider'>${ (tier.monthlyPrice * (tier.discount * 0.01)) }/month</span> : ``}
									{tier.monthlyPrice && !tier.discount ? <span className='text-white text-xl font-bold tracking-wider'>${ (tier.monthlyPrice ) }/month</span> : ``}
								</div>

							</div>
            </button>
          ))}
				</div>
				
				<div className="mt-10 flex items-center justify-center">
					{!currentLevel && !token ? <Link to={`/signup`} className="bg-amber-600 hover:bg-amber-500 text-white py-3 px-6 rounded-lg">Join</Link> : <></>}
					{!currentLevel && token ? <button className="bg-amber-600 hover:bg-amber-500 text-white py-3 px-6 rounded-lg">Join</button> : <></>}
					{/* {currentLevel === 'black' && selectedLevel === 'red' ? <button className="bg-rose-800 hover:bg-rose-700 text-white py-3 px-6 rounded-lg" onClick={()=>{downgradeLevel()}}>Downgrade</button> : <></>} */}
					{showDowngrade && selectedLevel === 'red' ? <button className="bg-rose-800 hover:bg-rose-700 text-white py-3 px-6 rounded-lg" onClick={()=>{downgradeLevel()}}>Downgrade</button> : <></>}
					{showCancelAutoRenewal && selectedLevel === 'red' ? <button className="bg-rose-800 hover:bg-rose-700 text-white py-3 px-6 rounded-lg" onClick={()=>{downgradeLevel()}}>Switch to Red</button> : <></>}
					{currentLevel === 'red' && selectedLevel === 'black' ? <button className="bg-sky-600 hover:bg-sky-500 text-white py-3 px-6 rounded-lg" onClick={()=>{upgradeLevel()}}>Upgrade</button> : <></>}
					{/* {currentLevel === 'black' && (selectedLevel === 'purple' || selectedLevel === 'white') && showExclusiveMemberships ? <button className="bg-sky-600 hover:bg-sky-500 text-white py-3 px-6 rounded-lg" onClick={()=>{upgradeLevel()}}>Upgrade</button> : <></>} */}
				</div>
      </div>
    </div>
  );
}
export default Main;