import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useStore } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import config from '../config.json';
import Headers from "../bin/Headers";
import dontRerender from "../bin/dontRerender";
import actions from '../state/actions';
import _logout from "../bin/logout";
import NOTABLE_TRADE_RENDER from "./NOTABLE_TRADE_RENDER";
import NOTABLE_TRADE_ALERT_MODAL from "./NOTABLE_TRADE_ALERT_MODAL";
import NOTABLE_TRADE_MODAL from "./NOTABLE_TRADE_MODAL";

import LOADING from './LOADING';
import { Cog8ToothIcon, XMarkIcon, StarIcon, ChevronUpIcon, ChevronDownIcon, BellIcon } from '@heroicons/react/24/solid';

const MAIN = (props) => {
	const token = useSelector(state => state.token),
		navigate = useNavigate(),
		store = useStore(),
		[loading, setLoading] = useState(false),
		[searchParams, setSearchParams] = useSearchParams(),
		[list, setList] = useState([]),
		[tradeWatchList, setTradeWatchList] = useState([]),
		[matches, setMatches] = useState(0),
		[delayedData, setDelayedData]= useState(false),
		[detailLoading, setDetailLoading]= useState(false),
		[displaySettings, setDisplaySettings] = useState(false),
		[showAlertModal, updateShowAlertModal]= useState(false),
		[showTradeInfo, updateShowTradeInfo]= useState(false),
		[tradeDetail, updateTradeDetail]= useState(null),
		trackingSettings = useRef(null),
		// urlBuySideOnly = searchParams.get("buysideonly") && searchParams.get("buysideonly") === 'true' ? true : (searchParams.get("buysideonly") && searchParams.get("buysideonly") === 'false' ? false : true),
		// urlBinaryOnly = searchParams.get("binaryonly") && searchParams.get("binaryonly") === 'true' ? true : (searchParams.get("binaryonly") && searchParams.get("binaryonly") === 'false' ? false : true),
		// urlBreakevenMin = searchParams.get("breakevenmin") && isNaN(searchParams.get("breakevenmin")) === false ? Number(searchParams.get("breakevenmin")) : 3,
		// urlDteMin = searchParams.get("dtemin") && isNaN(searchParams.get("dtemin")) === false ? Number(searchParams.get("dtemin")) : 0,
		// urlDteMax = searchParams.get("dtemax") && isNaN(searchParams.get("dtemax")) === false ? Number(searchParams.get("dtemax")) : 180,
		// urlNotionalMin = searchParams.get("notionalmin") && isNaN(searchParams.get("notionalmin")) === false ? Number(searchParams.get("notionalmin")) : 75000,
		urlBuySideOnly = searchParams.get("buysideonly") && searchParams.get("buysideonly") === 'true' ? true : (searchParams.get("buysideonly") && searchParams.get("buysideonly") === 'false' ? false : defaultParameters('buysideonly', true)),
		urlBinaryOnly = searchParams.get("binaryonly") && searchParams.get("binaryonly") === 'true' ? true : (searchParams.get("binaryonly") && searchParams.get("binaryonly") === 'false' ? false : defaultParameters('binaryonly', true)),
		urlBreakevenMin = searchParams.get("breakevenmin") && isNaN(searchParams.get("breakevenmin")) === false ? Number(searchParams.get("breakevenmin")) : defaultParameters('breakevenmin', 3),
		urlDteMin = searchParams.get("dtemin") && isNaN(searchParams.get("dtemin")) === false ? Number(searchParams.get("dtemin")) : defaultParameters('dtemin', 0),
		urlDteMax = searchParams.get("dtemax") && isNaN(searchParams.get("dtemax")) === false ? Number(searchParams.get("dtemax")) : defaultParameters('dtemax', 180),
		urlNotionalMin = searchParams.get("notionalmin") && isNaN(searchParams.get("notionalmin")) === false ? Number(searchParams.get("notionalmin")) : defaultParameters('notionalmin', 75000),
		urlPrecise = searchParams.get("precise") && searchParams.get("precise") === 'true' ? true : (searchParams.get("precise") && searchParams.get("precise") === 'false' ? false : defaultParameters('precise', false)),
		urlSkip = searchParams.get("skip") && isNaN(searchParams.get("skip")) === false ? Number(searchParams.get("skip")) : 0,
		urlSort = searchParams.get("sort") ? searchParams.get("sort") : 'EntryTimestamp:-1',
		urlLimit = searchParams.get("limit") && isNaN(searchParams.get("limit")) === false ? Number(searchParams.get("limit")) : 250,
		urlAlgo = searchParams.get("algo") ? searchParams.get("algo") : 'notable';
		

	function defaultParameters(key, defaultValue) {
		if (key === 'buysideonly') {
			if (trackingSettings.current && trackingSettings.current.TradeSide && trackingSettings.current.TradeSide === 'BUY') { return true; }
			if (trackingSettings.current && trackingSettings.current.TradeSide && trackingSettings.current.TradeSide === 'SELL') { return false; }
			if (trackingSettings.current && trackingSettings.current.TradeSide === null) { return false; }
			return defaultValue;
		}
		if (key === 'binaryonly') {
			if (trackingSettings.current && trackingSettings.current.Binary===true) { return true; }
			if (trackingSettings.current && (trackingSettings.current.Binary===false || trackingSettings.current.Binary===null)) { return false; }
			return defaultValue;
		}
		if (key === 'breakevenmin') {
			if (trackingSettings.current && trackingSettings.current.BreakevenMove && isNaN(trackingSettings.current.BreakevenMove)===false) { return Number(trackingSettings.current.BreakevenMove); }
			return defaultValue;
		}
		if (key === 'dtemin') {
			if (trackingSettings.current && trackingSettings.current.MinimumDaysToExpiration && isNaN(trackingSettings.current.MinimumDaysToExpiration)===false) { return Number(trackingSettings.current.MinimumDaysToExpiration); }
			return defaultValue;
		}
		if (key === 'dtemax') {
			if (trackingSettings.current && trackingSettings.current.MaximumDaysToExpiration && isNaN(trackingSettings.current.MaximumDaysToExpiration)===false) { return Number(trackingSettings.current.MaximumDaysToExpiration); }
			return defaultValue;
		}
		if (key === 'notionalmin') {
			if (trackingSettings.current && trackingSettings.current.Notional && isNaN(trackingSettings.current.Notional)===false) { return Number(trackingSettings.current.Notional); }
			return defaultValue;
		}
		if (key === 'precise') {
			if (trackingSettings.current && trackingSettings.current.TradeSidePrecise===true) { return true; }
			if (trackingSettings.current && trackingSettings.current.TradeSidePrecise===false) { return false; }
			return defaultValue;
		}
		return defaultValue;
	}
	
	const start = async () => {
		if (!token) { navigate('/login', { replace: true }); return; }
		let _trackingSettings = await fetch(`${config.api}/alerts`, { headers: Headers(token) }).then(d => d.json()).then(d => d.result ? d.result : null);
		_trackingSettings = _trackingSettings && _trackingSettings.length ? _trackingSettings.filter(({ TrackWhat }) => TrackWhat === 'NotableOptionTrade') : null;
		_trackingSettings = _trackingSettings && _trackingSettings.length === 1 ? _trackingSettings[0] : null;
		// console.log(_trackingSettings);
		trackingSettings.current = _trackingSettings;

		setLoading(true);
		let uriParameters = [];
    uriParameters.push(`buysideonly=${ urlBuySideOnly }`);
   	uriParameters.push(`binaryonly=${ urlBinaryOnly }`);
		uriParameters.push(`breakevenmin=${urlBreakevenMin}`);
		uriParameters.push(`dtemin=${urlDteMin}`);
		uriParameters.push(`dtemax=${urlDteMax}`);
		uriParameters.push(`notionalmin=${urlNotionalMin}`);
		uriParameters.push(`precisionsidematchonly=${urlPrecise}`);
		uriParameters.push(`skip=${urlSkip}`);
		uriParameters.push(`algo=${urlAlgo}`);
		//EarningsDateString,EarningsTime,EarningsDaysTill
		uriParameters.push(`fields=TradeId,Ticker,UnderlyingTicker,SharesPerContract,OptionStrike,OptionType,Notional,Price,Size,DaysToExpiration,EntryDateString,Expired,TradeSide,EntryTimeString,OpenInterestIncreasedNetProjectionMinimumAfterTrade,OpenInterestBeforeTrade,OpenInterestAfterTrade,OpenInterestIncreasedExceedsTradeSizePotentiallyOpen,UnderlyingPriceAtTrade,Moneyness,BreakevenPrice,BreakevenMove,EarningsDateString,EarningsTime,EarningsDaysTill,LowestPercent,EndingLowestPercent,Lowest,TimeToLow,EndingLowest,TimeToEndingLowest,HighestPercent,EndingHighestPercent,Highest,TimeToHigh,EndingHighest,TimeToEndingHighest,EODPercent,EndingLastPercent,EOD,TimeToEOD,EndingLast,TimeToEndingLast,ExpirationDateString`);
		uriParameters.push(`limit=${urlLimit}`);
		uriParameters.push(`sort=${urlSort}`);
		uriParameters = `?${uriParameters.join('&')}`;
    
		let [_list, _tradeWatchList] = await Promise.all([
			fetch(`${config.api}/notabletrades${uriParameters}`, { headers: Headers(token) }).then(res => res.json()).catch(err => { return null; }),
			fetch(`${config.api}/tradewatchlist?listonly=true`, { headers: Headers(token) }).then(res => res.json()).catch(err => { return null; }),
		]);
		if (_tradeWatchList && _tradeWatchList.message && _tradeWatchList.message === 'ok' && _tradeWatchList.result && _tradeWatchList.result.list) {
			setTradeWatchList(_tradeWatchList.result.list);
		}
		if (!_list) {
			setLoading(false);
			setMatches(0);
			setList([]);
			return;
		}
		if (_list && _list.message && _list.message === 'invalid token') {
			store.dispatch(actions.removeToken(token));
			navigate('/login', {replace:true});
			return;
		}
		if (!_list || !_list.message || _list.message !== 'ok' || !_list.result) {
			setLoading(false);
			setMatches(0);
			setList([]);
			return;
		}
		let { matches, result, delayed } = _list;
		if (delayed) { setDelayedData(true); }else{ setDelayedData(false); }
		setList(_list.result);
		setMatches(matches);
		setLoading(false);
	};

	const navigateToHashed = (params) => {
		if (!token) { navigate('/login', {replace:true}); return; }
		setLoading(true);

		let uriParameters = [];
    uriParameters.push(`buysideonly=${ urlBuySideOnly }`);
   	uriParameters.push(`binaryonly=${ urlBinaryOnly }`);
		uriParameters.push(`breakevenmin=${urlBreakevenMin}`);
		uriParameters.push(`dtemin=${urlDteMin}`);
		uriParameters.push(`dtemax=${urlDteMax}`);
		uriParameters.push(`notionalmin=${urlNotionalMin}`);
		uriParameters.push(`skip=${urlSkip}`);
		uriParameters.push(`algo=${urlAlgo}`);
		//EarningsDateString,EarningsTime,EarningsDaysTill
		uriParameters.push(`fields=TradeId,Ticker,UnderlyingTicker,SharesPerContract,OptionStrike,OptionType,Notional,Price,Size,DaysToExpiration,EntryDateString,Expired,TradeSide,EntryTimeString,OpenInterestIncreasedNetProjectionMinimumAfterTrade,OpenInterestBeforeTrade,OpenInterestAfterTrade,OpenInterestIncreasedExceedsTradeSizePotentiallyOpen,UnderlyingPriceAtTrade,Moneyness,BreakevenPrice,BreakevenMove,EarningsDateString,EarningsTime,EarningsDaysTill,LowestPercent,EndingLowestPercent,Lowest,TimeToLow,EndingLowest,TimeToEndingLowest,HighestPercent,EndingHighestPercent,Highest,TimeToHigh,EndingHighest,TimeToEndingHighest,EODPercent,EndingLastPercent,EOD,TimeToEOD,EndingLast,TimeToEndingLast,ExpirationDateString`);
		uriParameters.push(`limit=${urlLimit}`);
		if (params && params.sort) { uriParameters.push(`sort=${params.sort}`); } else { uriParameters.push(`sort=${urlSort}`); }
		// console.log('uriParameters: ', uriParameters);
		setLoading(false);
		return navigate(`/main?view=notables&${uriParameters.join('&')}`);
	};

	useEffect(() => { start(); }, [searchParams, token]);
	
	return (
		<div className="flex flex-col justify-start items-center w-full">
			{ loading ? <div className='h-24 flex items-center justify-center'><LOADING /></div> : <></>}
			{ !loading && !matches ? <div className="w-full sm:w-[350px] space-y-5"><h6 className='my-5 text-gray-400 text-center'>no notables</h6></div> : <></>}
			{!loading && list && list.length > 0 ?
				<div className="flex md:flex-row gap-2 md:gap-5 justify-center items-start w-full">
					<div className="max-w-[600px] w-full space-y-5 overflow-y-auto">
						{!loading && matches ? <h6 className='sm:px-5 mb-2 w-full flex items-start justify-between'>
							<p className="flex-grow text-sm text-gray-400">
								<span className="font-medium">{matches && isNaN(matches) === false ? matches.toLocaleString() : 0}</span> {matches && isNaN(matches) === false && Number(matches) === 1 ? 'Notable' : 'Notables'}
								{delayedData ? <span className='text-xs text-rose-200 bg-rose-600 rounded px-2 ml-2'>delayed</span> : <></>}
							</p>
							{/* {!delayedData ? <button onClick={()=>updateShowAlertModal(true)} className='flex flex-row text-violet-300 bg-violet-700 text-xs rounded px-2 py-1'><BellIcon className='w-4 h-4' /> settings</button> : <></>} */}
							{/* <button onClick={()=>updateShowAlertModal(true)}><Cog8ToothIcon className='w-6 h-6' /></button> */}
							{/* <button className={`${displaySettings ? 'hidden' : ''}`} onClick={()=>setDisplaySettings(true)}><Cog8ToothIcon className='w-6 h-6' /></button> */}
							{/* <button className={`${displaySettings ? '' : 'hidden'}`} onClick={()=>setDisplaySettings(false)}><XMarkIcon className='w-6 h-6 text-rose-500' /></button> */}
						</h6> : <></>}
						<table className="divide-y divide-gray-400 w-full self-start text-xs overflow-x-auto whitespace-nowrap">
							<thead>
								<tr className="divide-x divide-gray-400 bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-left">
									<th scope="col" className="p-1 font-medium tracking-wider text-center w-5">
										<span className='text-amber-500'><StarIcon className='w-5' /></span>
									</th>
									<th scope="col" className="font-medium tracking-wider w-36">
										<button
											onClick={ () => { navigateToHashed({sort:( urlSort==='EntryTimestamp:1' ? 'EntryTimestamp:-1' : ( urlSort==='EntryTimestamp:-1' ? 'EntryTimestamp:1' : 'EntryTimestamp:-1' ) )}) }}
											className='p-1 flex items-center hover:cursor-pointer'>
											Trade
											{urlSort==='EntryTimestamp:-1' && <span className='text-amber-500 pl-0.5'><ChevronDownIcon className='w-3' /></span>}
											{urlSort==='EntryTimestamp:1' && <span className='text-amber-500 pl-0.5'><ChevronUpIcon className='w-3' /></span>}
										</button>
									</th>
									<th scope="col" className="p-1 font-medium tracking-wider w-24">
										<button
											onClick={ () => { navigateToHashed({sort:'LowestPercent:1'}) }}
											className='p-1 flex items-center hover:cursor-pointer'>
											Low
											{urlSort==='LowestPercent:1' && <span className='text-amber-500 pl-0.5'><ChevronUpIcon className='w-3' /></span>}
										</button>
									</th>
									<th scope="col" className="font-medium tracking-wider w-24">
										<button
											onClick={ () => { navigateToHashed({sort:'HighestPercent:-1'}) }}
											className='p-1 flex items-center hover:cursor-pointer'>
											High
											{urlSort==='HighestPercent:-1' && <span className='text-amber-500 pl-0.5'><ChevronDownIcon className='w-3' /></span>}
										</button>
									</th>
									<th scope="col" className="p-1 font-medium tracking-wider w-24">Last</th>
								</tr>
							</thead>
						
							<tbody className='divide-y divide-gray-800'>
								{(list).map((trade, index) => <NOTABLE_TRADE_RENDER key={index} trade={trade} index={index} tradeWatchList={tradeWatchList} setTradeWatchList={setTradeWatchList} updateTradeDetail={(trade) => { updateTradeDetail(trade); updateShowTradeInfo(true); }} />) }
							</tbody>
						</table>
					</div>
					{/* <div className="hidden md:flex flex-grow w-full items-start justify-center">
						{detailLoading ? <div className='h-24 flex items-center justify-center'><LOADING /></div> : <></>}
					</div> */}

					<NOTABLE_TRADE_ALERT_MODAL
						open={ showAlertModal }
						setOpen={() => { updateShowAlertModal(false); }} />
					<NOTABLE_TRADE_MODAL
						open={ showTradeInfo }
						audit={ true }
						setOpen={() => { updateShowTradeInfo(false); updateTradeDetail(null); }}
						data={ tradeDetail } />
				</div>
			: <></>}
		</div>
  );
};

export default React.memo(MAIN, dontRerender);