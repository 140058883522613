import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import config from "../config.json";
import Headers from "../bin/Headers";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import signedCurrency from "../bin/signedCurrency";
import LOADING from "../components/LOADING";
import { BackspaceIcon, CheckBadgeIcon } from "@heroicons/react/24/solid";
import abbrInt from '../bin/abbrInt';

const MAIN = (props) => {
	let token = props.token,
		ticker = props.ticker,
		// date = props.date,
		// expiration = props.expiration,
		lastTrade = props.lastTrade,
		high = props.high,
		low = props.low,
		priceLevelTable = useRef(null),
		overHighRange = useRef(null),
		overLowRange = useRef(null),
		[currentRange, setCurrentRange]= useState(null),
		[hideCallPutTriggers, setHideCallPutTriggers]= useState('false'),
		// [variance, setVariance]= useState(3),
		[variance, setVariance]= useState(99),
		[averageRanges, setAverageRanges]= useState(null),
		[data, setData]= useState({}),
		[priceLevels, setPriceLevels]= useState([]),
		[LoadingValue, setLoadingValue] = useState(true);

	const start = async () => {
		setLoadingValue(true);
		// if (!date) {
		// 	setLoadingValue(false);
		// 	return;
		// }
		if (high && low) {
			setCurrentRange(truncateToTwoDigits(high - low));
		}

		// let result = await fetch(`${config.api}/pricelevels?ticker=${ticker}${date ? `&date=${date}` : ``}${expiration ? `&expiration=${expiration}` : ``}${variance ? `&variance=${variance}` : ``}`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result ? d.result : null);
		let result = await fetch(`${config.api}/tickerstats?ticker=${ticker}`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.result ? d.result : null);
		// console.log(result);
		if (result && result.PriceLevels) {
			let PriceLevels = [...result.PriceLevels].sort((a, b) => b.mid - a.mid);
			setPriceLevels(PriceLevels);
			setData(PriceLevels);
		}
		if (result && result.Ranges) {
			setAverageRanges(result.Ranges);
			if (result.Ranges.average) {
				overLowRange.current = high ? truncateToTwoDigits(high + (result.Ranges.average * -1)) : null;
				overHighRange.current = low ? truncateToTwoDigits(low + result.Ranges.average) : null;
			}
		}

		updateHighlightedStrike();
		setLoadingValue(false);
	};

	const updateHighlightedStrike = () => {
		if (!lastTrade) { return; }
		if (priceLevelTable.current) {
			const highlightElement = priceLevelTable.current.querySelector("[data-highlight='true']");
			if (highlightElement) {
				const rect = highlightElement.getBoundingClientRect();
				const containerRect = getElementPositionInContainer(highlightElement, priceLevelTable.current);
				// Calculate the position of the element relative to the container
				const relativeTop = containerRect.top;
				// Calculate the vertical center position
				const centeredTop = relativeTop - (priceLevelTable.current.clientHeight / 2) + (rect.height / 2);
				// Scroll to the element
				priceLevelTable.current.scrollTop = centeredTop;
			}
		}
		else { setTimeout(() => updateHighlightedStrike(), 1000); }
		// loadOptionChainUI(optionChains, selectedExpirationDate);
	};
	
	function getElementPositionInContainer(element, container) {
		const elemRect = element.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();
		const topPosition = elemRect.top - containerRect.top + container.scrollTop;
		const leftPosition = elemRect.left - containerRect.left + container.scrollLeft;
		return { top: topPosition, left: leftPosition };
	}

	const findClosestNumber = (target, arr) => {
		const numArray = arr.map(({ price }) => Number(price) );
		let closestNum = numArray[0];
		for (let i = 1; i < numArray.length; i++) {
			if (Math.abs(target - closestNum) > Math.abs(target - numArray[i])) {
				closestNum = numArray[i];
			}
		}
		return closestNum;
	};

	const loadPriceLevelsUI = (_priceLevels) => {
		if (!_priceLevels || !_priceLevels.length) { return; }
		
		let closestPrice = null;
		// if (lastTrade && lastTrade.Price) {
		if (lastTrade) {
			// closestPrice = findClosestNumber(lastTrade.Price, _priceLevels);
			closestPrice = findClosestNumber(lastTrade, _priceLevels);
			closestPrice = closestPrice ? closestPrice.toString() : null;
		}
		for (let i = 0; i < _priceLevels.length; i++){
			_priceLevels[i].highlight = closestPrice && (closestPrice).toString() === (_priceLevels[i].price).toString() ? true : false;
		}
		
		return (_priceLevels).map((entry, idx) => {
			// let awayPercent = signedCurrency(truncateToTwoDigits(((entry.mid / lastTrade) - 1) * 100), '');
			let awayPercent = truncateToTwoDigits(((entry.mid / lastTrade) - 1) * 100);
			let _awayPercent = awayPercent;
			awayPercent = awayPercent > 99 ? signedCurrency(abbrInt(awayPercent, false, false), '') : awayPercent;
			let _awayAmount = truncateToTwoDigits(entry.mid - lastTrade);
			let awayAmount = signedCurrency(_awayAmount, '$');
			let OutsideAverageRange = false;
			let FirmPriceLevel = false;
			// console.log(`pl: ${entry.mid} | overLow: ${entry.mid<overLowRange.current}[${overLowRange.current}] | overHigh: ${entry.mid>overHighRange.current}[${overHighRange.current}]`);
			if (overLowRange.current && entry.mid < overLowRange.current) { OutsideAverageRange = true; }
			if (overHighRange.current && entry.mid > overHighRange.current) { OutsideAverageRange = true; }
			if (entry.high && entry.low && entry.high===entry.low) { FirmPriceLevel = true; }

			let logic = 'equal'; //equal, greaterThanEqual, lessThanEqual
			if (lastTrade && lastTrade >= entry.mid) { logic = 'lessThanEqual'; }
			else if (lastTrade && lastTrade <= entry.mid) { logic = 'greaterThanEqual'; }
			return <div key={idx} data-highlight={`${entry.highlight ? true : false}`} className={`flex flex-row w-full divide-x divide-gray-800 text-[8px] sm:text-xxs text-gray-300 ${entry.highlight ? 'bg-slate-950' : (idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800')}`}>
				<span className={`p-1 items-center justify-center font-light tracking-wider w-20 flex-col ${hideCallPutTriggers === 'true' ? 'hidden' : 'flex'}`}>
					{awayPercent ? <span className={`rounded mx-1 px-0.5 ${_awayPercent > 0 ? 'text-green-200 bg-green-700' : ''}${_awayPercent < 0 ? 'text-red-200 bg-red-700' : ''}`}>{awayPercent}%</span> : <></>}
				</span>
				<span className={`p-1 items-center justify-center font-light tracking-wider w-20 flex-col ${hideCallPutTriggers === 'true' ? 'hidden' : 'flex'}`}>
					{awayAmount ? <span className={`${_awayAmount > 0 ? 'text-green-300' : ''}${_awayAmount < 0 ? 'text-red-300' : ''}`}>{awayAmount}</span> : <></>}
				</span>
				<span className={`p-1 flex items-center justify-center font-light tracking-wider flex-grow bg-sky-950/50 ${FirmPriceLevel ? 'text-amber-500' : ''}`}>
					{/* <a href={`/alerts?ticker=${ticker}&type=stock&value=${entry.mid}&what=last&logic=${logic}`} className='p-1 w-full flex items-center justify-center'> */}
					<span className='p-1 w-full flex items-center justify-center'>
						{entry.mid ? (entry.mid).toLocaleString() : ''}
					</span>
					{/* </a> */}
				</span>
				<span className={`p-1 items-center justify-center font-light tracking-wider w-20 flex-col ${hideCallPutTriggers==='true' ? 'hidden' : 'flex'}`}>
					<span className='text-green-300'>{entry.high ? (entry.high).toLocaleString() : ''}</span>
					{/* <span className='text-green-300'>{entry.callEntryPrice ? (entry.callEntryPrice).toLocaleString() : ''}</span> */}
					{/* <span className='text-gray-300'>{entry.callReturn ? (entry.callReturn).toLocaleString() : ''}</span> */}
				</span>
				<span className={`p-1 items-center justify-center font-light tracking-wider w-20 flex-col ${hideCallPutTriggers==='true' ? 'hidden' : 'flex'}`}>
					<span className='text-red-300'>{entry.low ? (entry.low).toLocaleString() : ''}</span>
					{/* <span className='text-red-300'>{entry.putEntryPrice ? (entry.putEntryPrice).toLocaleString() : ''}</span> */}
					{/* <span className='text-gray-300'>{entry.putReturn ? (entry.putReturn).toLocaleString() : ''}</span> */}
				</span>
				{/* <span className={`p-1 flex items-center justify-center font-light tracking-wider w-16`}>{entry.percentToLevel ? `${(entry.percentToLevel).toLocaleString()}%` : ''}</span> */}
				<span className={`p-1 flex items-center justify-center font-light tracking-wider w-16`}>{entry.dates && entry.dates.length ? (entry.dates.length).toLocaleString() : '0'}</span>
				<span className={`p-1 flex items-center justify-center font-light tracking-wider w-5 sm:w-8 ${OutsideAverageRange ? 'bg-orange-950/50' : ''}`}>
					{entry.crossed ? <span className='text-rose-500'><BackspaceIcon className='w-3 sm:w-5' /></span> : <></>}
				</span>
			</div>
		});
	};
	
	useEffect(() => { start(); }, [high, low]);
	useLayoutEffect(()=>{ updateHighlightedStrike(); },[lastTrade]);
	
  return (
		<div className="w-full h-full">
			{LoadingValue === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300"><LOADING color="text-green-500" /></div>}
			{(!LoadingValue && (!priceLevels || !priceLevels.length)) &&
				<div className="flex items-center justify-center py-8 text-center text-gray-300">no price levels</div>
			}
			{!LoadingValue && priceLevels && priceLevels.length > 0 ?
				<div className="w-full">
					<div className='flex flex-row w-full text-xxs sm:text-xs'>
						<span className={`p-1 items-center justify-center border border-t-0 font-light sm:font-medium uppercase tracking-wider w-20 ${hideCallPutTriggers === 'true' ? 'hidden' : 'flex'}`}>
							<span className='hidden sm:block'>Percent</span>
							<span className='block sm:hidden'>%</span>
						</span>
						<span className={`p-1 items-center justify-center border border-t-0 font-light sm:font-medium uppercase tracking-wider w-20 ${hideCallPutTriggers === 'true' ? 'hidden' : 'flex'}`}>
							<span className='hidden sm:block'>Amount</span>
							<span className='block sm:hidden'>$</span>
						</span>
						<span className="p-1 flex items-center justify-center border border-t-0 font-light sm:font-medium uppercase tracking-wider flex-grow">
							{/* <span className='hidden sm:block'>Level</span> */}
							{/* <span className='block sm:hidden'>Level</span> */}
							Level
						</span>
						<span className={`p-1 items-center justify-center border border-t-0 font-light sm:font-medium uppercase tracking-wider w-20 ${hideCallPutTriggers==='true' ? 'hidden' : 'flex'}`}>High</span>
						<span className={`p-1 items-center justify-center border border-t-0 font-light sm:font-medium uppercase tracking-wider w-20 ${hideCallPutTriggers==='true' ? 'hidden' : 'flex'}`}>Low</span>
						<span className="p-1 flex items-center justify-center border border-t-0 font-light sm:font-medium uppercase tracking-wider w-16">Tested</span>
						<span className="p-1 flex items-center justify-center border border-t-0 border-r-0 font-medium tracking-wider w-5 sm:w-8">
							<span className='text-rose-500'><BackspaceIcon className='w-3 sm:w-5' /></span>
						</span>
					</div>
					<div ref={priceLevelTable} className="overflow-x-auto h-full max-h-[400px] xl:max-h-[700px] w-full">
						{loadPriceLevelsUI(priceLevels)}
					</div>
				</div>
				:
				<></>
			}
			{/* {averageRanges ? <div className="w-full flex flex-row flex-wrap items-center justify-evenly gap-2 p-2 bg-gradient-to-r from-sky-950 to-blue-800">
				{currentRange? <span className='w-28 rounded flex flex-col items-center justify-center bg-slate-900 text-slate-200 px-2 py-0.5'>
					<span className='text-xxs sm:text-xs font-medium'>Current</span>
					<span className='text-xxs sm:text-xs'>{ currentRange }</span>
				</span> : <></>}
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && averageRanges.average <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Average</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && averageRanges.average <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {averageRanges.average}</span>
				</span>
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && averageRanges.probable70 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Probable 70%</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && averageRanges.probable70 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {averageRanges.probable70}</span>
				</span>
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && averageRanges.probable80 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Probable 80%</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && averageRanges.probable80 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {averageRanges.probable80}</span>
				</span>
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && averageRanges.probable90 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Probable 90%</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && averageRanges.probable90 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {averageRanges.probable90}</span>
				</span>
			</div> : <></>} */}
		</div>
  );
}
export default MAIN;