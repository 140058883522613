const UNDER_MAINTENANCE = () => {
  return (
    <div className="py-4 text-white">
      <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 flex justify-center items-center">
        <h1 className="text-white p-5">The requested resource is under maintenace</h1>
        {/* <img src="/bin/maintenance-meme-ItAintMuch.jpg" alt="Maitenance Working" className="max-h-48 h-auto border rounded-lg" /> */}
      </div>
    </div>  
  );
}
export default UNDER_MAINTENANCE;
