const main={
  updateNavigation: (message)=>{ return { name:'navigation', type:'UPDATE_NAVIGATION', payload:message }; },
	updateToken: (message) => { return { name: 'token', type: 'UPDATE_TOKEN', payload: message }; },
	removeToken: ()=>{ return { name:'token', type:'REMOVE_TOKEN' }; },
	updateUnseenAlerts: (value) => { return { name: 'unseenalerts', type: 'UPDATE_UNSEEN_ALERTS', payload: value }; },
	removeUnseenAlerts: () => { return { name: 'unseenalerts', type: 'REMOVE_UNSEEN_ALERTS' }; },
	updateTieLevel: (value) => { return { name: 'tielevel', type: 'UPDATE_TIE_LEVEL', payload: value }; },
	removeTieLevel: () => { return { name: 'tielevel', type: 'REMOVE_TIE_LEVEL' }; },
	updateAvatar: (message) => { return { name: 'avatar', type: 'UPDATE_AVATAR', payload: message }; },
	removeAvatar: ()=>{ return { name:'avatar', type:'REMOVE_AVATAR' }; },
};
export default main;