import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const persistConfig={
  key: "root",
  blacklist: ["version","navigation"],
  storage,
};

const persistedReducer= persistReducer(
  persistConfig,
  rootReducer,
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const store= () => {
  let store = createStore(persistedReducer);
  let persistor= persistStore(store);
  return { store, persistor };
};

export default store;