import { useState, useEffect } from 'react';
import ICONS from '../bin/ICONS';

const SECTION_TITLE = (props) => {
  const navigation= props.navigation,
        setModalOpen= props.setModalOpen;
  let [pageName, setPageName]= useState(null),
      [infoModal, setInfoModal]= useState(false),
      [isVisible, setVisible]= useState(false);
  const checkVisibility=()=>{
    let {name, title, modal}= navigation.filter( ({current})=>current===true ).reduce( (selectedPage,pageData)=> selectedPage=pageData, {}),
        makeVisible= title===false ? false : true;
    if (makeVisible!==isVisible){
      setVisible(makeVisible);
    }
    if (name!==pageName){
      setPageName(name);
    }
    setInfoModal(modal ? true : false);
  };
  useEffect(checkVisibility, [navigation, isVisible, pageName]);
  return (
    isVisible===true ? 
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex">
        <h1 className="text-2xl font-semibold text-gray-300 flex-grow">{ pageName }</h1>
        { (infoModal) ? 
          <span>
            <button
              type="button"
              className="mx-auto flex-shrink-0 flex items-center justify-center rounded bg-gradient-to-r from-blue-600 to-blue-800 sm:mx-0 h-8 w-8 md:h-10 md:w-10"
              onClick={() => setModalOpen(true)} >
              <ICONS.InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </span>
          :
          <></>
        }
      </div>
    :
      <></>    
  )
}

export default SECTION_TITLE