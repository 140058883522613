import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import config from "../config.json";
import Headers from "../bin/Headers";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import signedCurrency from "../bin/signedCurrency";
import LOADING from "../components/LOADING";
import { ClockIcon, CurrencyDollarIcon } from "@heroicons/react/24/solid";
import abbrInt from '../bin/abbrInt';

const MAIN = (props) => {
	let optionTrades = props.optionTrades,
		stockTrades = props.stockTrades,
		bid = props.bid,
		ask = props.ask,
		last = props.last,
		[data, setData]= useState({}),
		[loading, setLoading] = useState(true);

	const start = async () => {
		setLoading(true);
		let trades = [...stockTrades, ...optionTrades].sort((a,b) => b.EntryTimestamp - a.EntryTimestamp);
		setData(trades);
		setLoading(false);
	};

	
	useEffect(() => { start(); }, [optionTrades, stockTrades]);
	
  return (
		<div className="w-full h-full">
			{loading === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300"><LOADING color="text-green-500" /></div>}
			{(!loading && (!data || !data.length)) && <div className="flex items-center justify-center py-8 text-center text-gray-300">no trades</div> }
			{!loading && data && data.length > 0 ?
				<div className="w-full">
					<div className='flex flex-row w-full text-xxs sm:text-xs'>
						<span className={`p-1 flex items-center justify-center border border-t-0 font-light text-xxs w-16 text-amber-300`}>
							<ClockIcon className='w-5 h-5' />
						</span>
						<span className="p-1 flex items-center justify-center border border-t-0 font-light flex-grow">
							Trade
						</span>
						<span className={`p-1 flex items-center justify-center border border-t-0 font-light w-16 text-sky-400`}>
							<CurrencyDollarIcon className='w-5 h-5' />
						</span>
					</div>
					<div className="overflow-x-auto h-full max-h-[400px] xl:max-h-[700px] w-full flex flex-col text-xxs sm:text-xs">
						{data.map((entry, index) => (
							<span key={index} className={`w-full flex ${index%2===0 ? 'bg-gray-800' : ''}`}>
								<span className={`p-1 flex items-center justify-center font-light text-[8px] w-16 text-amber-300`}>
									{entry.EntryTimeString}
								</span>
								<span className={`p-1 flex items-start justify-start font-light flex-grow ${entry.Type==='PUT' ? 'text-rose-200' : ''}${entry.Type==='CALL' ? 'text-emerald-200' : ''}`}>
									{!entry.BrokerTicker ? `${(entry.Size).toLocaleString()} for ${(entry.Price).toLocaleString()}` : ``}
									{entry.BrokerTicker ? `${entry.BrokerTicker}: ${(entry.Size).toLocaleString()} for ${(entry.Price).toLocaleString()}` : ``}
									{entry.Binary ? <span className={`mx-1 rounded px-1 text-xxxs ${entry.Type==='PUT' ? 'bg-rose-700 text-rose-200' : ''}${entry.Type==='CALL' ? 'bg-emerald-700 text-emerald-200' : ''}`}>binary</span> : ''}
								</span>
								<span className={`p-1 flex items-center justify-center font-light w-16 text-sky-400 ${entry.Notional>1000 ? 'bg-sky-950/60' : ''}`}>
									${ abbrInt(entry.Notional) }
								</span>
							</span>
						))}
					</div>
				</div>
				:
				<></>
			}
		</div>
  );
}
export default MAIN;