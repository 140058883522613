import React, { useState, useEffect, forwardRef } from 'react';
import config from '../config.json';
import Headers from "../bin/Headers";
import scrollToTop from "../bin/scrollToTop";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import LOADING from "../components/LOADING";
import { Link, useNavigate } from "react-router-dom";
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import abbrInt from '../bin/abbrInt';

const Main = forwardRef(({ item, index, token, focusedTicker, setFocusedTicker }, ref) => {
	const navigate = useNavigate();

	const focusOnTicker = (_ticker) => {
		return navigate(`/_/${_ticker}`);
		
		//on mobile, redirect to the ticker page
		let mobileScreen = (window.innerWidth < 640) ? true : false;
		if (mobileScreen || focusedTicker===_ticker){
			//redirect to the ticker page
			return navigate(`/_/${_ticker}`);
		}
		setFocusedTicker(_ticker);
		scrollToTop();
	};

	return (
		<div key={index} className={`w-full h-12 px-2 py-2 ${focusedTicker===item.Ticker ? 'bg-gray-950' : ''}`}>
			<button onClick={()=>focusOnTicker(item.Ticker)} className={`flex flex-row items-start w-full relative`}>
				<span className='text-amber-500 text-[8px] absolute -top-2 -left-2 -rotate-12'>#{index+1}</span>
				<span className={`text-sm font-bold w-[60px] text-gray-100 flex gap-1 justify-start items-center`}>
					<span>{item.Ticker ? item.Ticker : '-'}</span>
				</span>
				
				<div className="flex flex-grow flex-col gap-1 justify-center items-center w-[170px]">
					{item.Size ? <span className={`text-xxs leading-tight font-light text-gray-400`}>{abbrInt(item.Size, false)}<span className='ml-1 text-sky-400'>shares</span></span> : <></>}
					{item.Price ? <span className={`text-xxs leading-tight font-light text-gray-400`}>${truncateToTwoDigits(item.Price)}{item.OpeningTrade ? <span className={`text-xxs leading-tight font-light text-amber-400 ml-1`}>open</span> : (item.ClosingTrade ? <span className={`text-xxs leading-tight font-light text-amber-400 ml-1`}>close</span> : <></>)}</span> : <></>}
				</div>
					
				<div className='w-[100px] flex flex-col gap-1 items-end justify-end'>
					{item.Notional ? <span className={`w-[60px] h-[20px] inline-flex justify-center items-center rounded-sm px-1.5 py-0 text-xxs leading-tight font-medium ${item.TradeSide==='BUY' ? 'text-green-800 bg-green-300' : ''}${item.TradeSide==='SELL' ? 'text-red-800 bg-red-300' : ''}${!item.TradeSide || (item.TradeSide!=='BUY' && item.TradeSide!=='SELL') ? 'text-gray-800 bg-gray-300' : ''}`}>${abbrInt(item.Notional, false)}</span> : <></>}		
					{item.TradeSide==='BUY' ? <span className={`text-xxs leading-tight font-light text-emerald-400 mr-1`}>buy side</span> : (item.TradeSide==='SELL' ? <span className={`text-xxs leading-tight font-light text-rose-400 mr-1`}>sell side</span> : <></>)}
				</div>
			</button>
		</div>
  );
});

export default Main;