const main = (startDateStr, endDateStr) => {
  let weekdays = 0;
  
  // Create Date objects from the input date strings
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  
  // Initialize the current date to the start date
  let currentDate = new Date(startDate);
  
  // Loop through each day from the start date to the end date
  while (currentDate <= endDate) {
    // Check if the current date is a weekday (not Saturday or Sunday)
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      weekdays++;
    }
    
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }
  
  return weekdays;
}

export default main;