import epochConverterToEST from "../bin/epochConverterToEST";
import conditions from "../bin/stockTradeConditions";
import exchanges from "../bin/exchanges";
import abbrInt from "../bin/abbrInt";

const compileTradeDetailForModal = (trade, optionContractMap) => {
  let tradeDetail = [];
  let d = epochConverterToEST(trade.time);
  const commaSeparatedFlags = (flags) => {
    let _flags = flags.map( flag => {
      if (flag=='57' || flag=='58' || flag=='59' || flag=='60' || flag=='62' || flag=='63' || flag=='64' || flag=='65' || flag=='66' || flag=='67' || flag=='68' || flag=='69' || flag=='70'){
        return `<span class="bg-red-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>`;
      }
      if (flag=='35'){
        return `<span class="bg-blue-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>`;
      }
      if (conditions[flag]){
        return `<span class="bg-slate-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>`;
      }
      return `<span class="bg-gray-500 px-1 text-white font-thin text-xs">${ flag }</span>`;
    }).join(' ');
    // return flags.map( flag => `<span class="bg-slate-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>` ).join(' ');
    return _flags;
  };
  if (trade.ticker || trade.name){
    tradeDetail.push(`<span class="text-amber-600">Ticker: ${ (trade.ticker).toUpperCase() } ${ trade.name ? `<span class="ml-1 text-xs italic">${trade.name}</span>` : `` }</span>`);
  }
  if (trade.side){
    tradeDetail.push(`<span class="text-indigo-700">Side:</span> ${ trade.side ? (trade.side).toUpperCase() :'-' }`);
  }
  if (trade.type && trade.type==='option'){
    // tradeDetail.push(`<span class="text-indigo-700">Total Contracts:</span> ${ trade.qyt ? (trade.qyt).toLocaleString() :'-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Total Contracts:</span> ${ trade.qyt ? abbrInt(trade.qyt, false, false) :'-' }`);
  }else{
    // tradeDetail.push(`<span class="text-indigo-700">Total Shares:</span> ${ trade.qyt ? (trade.qyt).toLocaleString() :'-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Total Shares:</span> ${ trade.qyt ? abbrInt(trade.qyt, false, false) :'-' }`);
  }
  // tradeDetail.push(`<span class="text-indigo-700">Total Price:</span> ${ trade.price && trade.price!=='NaN' ? '$' + (trade.price).toLocaleString() : '-' }`);
  tradeDetail.push(`<span class="text-indigo-700">Total Price:</span> ${ trade.price && trade.price!=='NaN' ? '$' + abbrInt(trade.price, false) : '-' }`);
  // tradeDetail.push(`<span class="text-indigo-700">Trade Value:</span> ${ trade.value && trade.value!=='NaN' ? '$' + (Number(trade.value)).toLocaleString() : '-' }`);
  tradeDetail.push(`<span class="text-indigo-700">Trade Value:</span> ${ trade.value && trade.value!=='NaN' ? '$' + abbrInt(trade.value, false) : '-' }`);
  tradeDetail.push(``);
  if (trade.type && trade.type==='option' && trade.symbol && optionContractMap){
    tradeDetail.push(`<span class="text-indigo-700">Strike:</span> ${ trade.symbol && optionContractMap && optionContractMap[trade.symbol] && optionContractMap[trade.symbol].strike ? '$' + optionContractMap[trade.symbol].strike : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Expiry:</span> ${ trade.symbol && optionContractMap && optionContractMap[trade.symbol] && optionContractMap[trade.symbol].expiry ? optionContractMap[trade.symbol].expiry : '-' }`);
    tradeDetail.push(``);
  }
  if (trade.type && trade.type==='option'){
    tradeDetail.push(`<span class="text-indigo-700">Magnitude:</span> ${ trade.magnitude && trade.magnitude!=='NaN' ? (trade.magnitude).toLocaleString() + '%' : '-' }`);
    if (optionContractMap && optionContractMap[trade.symbol] && optionContractMap[trade.symbol].type){
      if (optionContractMap[trade.symbol].type==='CALL'){
        tradeDetail.push(`<span class="text-indigo-700">Break Even:</span> $${ (optionContractMap[trade.symbol].strike + trade.price).toLocaleString() }`);
      }else if (optionContractMap[trade.symbol].type==='PUT'){
        tradeDetail.push(`<span class="text-indigo-700">Break Even:</span> $${ (optionContractMap[trade.symbol].strike + (trade.price * -1 )).toLocaleString() }`);
      }
    }
    tradeDetail.push(`<span class="text-indigo-700">Last:</span> ${ trade.last && trade.last!=='NaN' ? '$' + (trade.last).toLocaleString() + ' [' + ( ((trade.last/trade.price-1)*100).toFixed() ) + '%]' : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Highest:</span> ${ trade.highest && trade.highest!=='NaN' ? '$' + (trade.highest).toLocaleString() + ' [' + ( ((trade.highest/trade.price-1)*100).toFixed() ) + '%]['+ trade.highesttime +']' : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Lowest:</span> ${ trade.lowest && trade.lowest!=='NaN' ? '$' + (trade.lowest).toLocaleString() + ' [' + ( ((trade.lowest/trade.price-1)*100).toFixed() ) + '%]['+ trade.lowesttime +']' : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Average Value:</span> ${ trade.avgValue && trade.avgValue!=='NaN' ? '$' + (trade.avgValue).toLocaleString() : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Average Contracts:</span> ${ trade.avgQyt && trade.avgQyt!=='NaN' ? (trade.avgQyt).toLocaleString() : '-' }`);
    tradeDetail.push(``);
  }
  tradeDetail.push(`<span class="text-indigo-700">Time:</span> ${d.Hour}:${d.Minute}:${d.Second}.<span class="text-xxs">${d.Millisecond}</span> <span class="text-xs">[est]</span>`);
  tradeDetail.push(`<span class="text-indigo-700">Date:</span> ${d.FullMonth} ${d.Date}, ${d.Year}`);
  tradeDetail.push(`<span class="text-indigo-700">Day:</span> ${d.Day}`);
  tradeDetail.push(``);
  if (trade.ex){
    tradeDetail.push(`<span class="text-indigo-700">Exchange:</span> ${ isNaN(trade.ex)===false ? exchanges[trade.ex] : trade.ex }`);
  }
  if (trade.flags){
    tradeDetail.push(`<span class="text-indigo-700">Trade Flags:</span> ${ trade.flags && trade.flags.length ? commaSeparatedFlags(trade.flags) : '-' }`);
  }
  tradeDetail = tradeDetail.join('<br/>');
  return tradeDetail;
 };

 export default compileTradeDetailForModal;