import { useSearchParams, } from "react-router-dom";

const Main = () => {
	let [searchParams, setSearchParams] = useSearchParams(),
		message = searchParams.get("message");


  return (
    <main className="flex flex-col items-center justify-center flex-grow overflow-auto py-48">
			<h1 className="text-white text-3xl">Unavailable</h1>
			{message && <p className="mt-5 text-white text-lg">{message}</p> }
    </main>
  );
};

export default Main;