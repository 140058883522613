import isDst from './isDst';

const main = (focalDate, format=null) => {
	let d = new Date(`${focalDate}T21:00:00.000Z`); //21:00utc === 16:00est
	let adjustForDst = isDst(d.getTime());
	if (adjustForDst){ d.setUTCHours( d.getUTCHours()-1 ); }
	if (format==='nanoseconds'){ return (d.getTime() * 1000000); }
	if (format==='microseconds'){ return (d.getTime() * 1000); }
	return d.getTime();
};

export default main;