import React from 'react';
import { Fragment, } from 'react';
import { Dialog, Transition, } from '@headlessui/react';
import { Link } from 'react-router-dom';
import MENU_ITEM_ACCOUNT from './MENU_ITEM_ACCOUNT';
import MENU_ITEM_LOGIN from './MENU_ITEM_LOGIN';
import MENU_ITEM_LOGOUT from './MENU_ITEM_LOGOUT';
import MENU_ITEM_TICKER_SEARCH from './MENU_ITEM_TICKER_SEARCH';
import classNames from '../bin/classNames';
import ICONS from '../bin/ICONS';

const SIDE_MENU_MOBILE = (props) => {
  const sidebarOpen= props.sidebarOpen,
		setSidebarOpen= props.setSidebarOpen,
		navigation= props.navigation,
		token = props.token,
		level= props.level;
	
	let ui_navigation = navigation.filter(({ menu }) => menu !== false).map((item) => {
		if (item.hasOwnProperty('mobilemenu') && item.mobilemenu === false) { return; }
		if (!level && item.menulevel) { return; }
		else if (level && item.menulevel) {
			if (item.menulevel === 'black' && level !== 'black' && level !== 'diamond' && level !== '1337') { return; }
			if (item.menulevel === 'diamond' && level !== 'diamond' && level !== '1337') { return; }
			if (item.menulevel === '1337' && level !== '1337') { return; }
		}
    const buildIcon=(itemData)=>{
      let classData = classNames( itemData.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', `mr-4 flex-shrink-0 h-6 w-6` );
      let builtIcon = React.createElement(ICONS[itemData.icon], {"aria-hidden":"true", className:classData});
      return builtIcon;
    };
    return <Link key={item.name} to={item.href} onClick={() => setSidebarOpen(false)} className={ classNames(item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md')}>
      {item.icon && ICONS[item.icon] ? buildIcon(item) : <></>}
      {item.name}
    </Link>;
  });

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0" >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-90" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full" >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0" >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)} >
                  <span className="sr-only">Close sidebar</span>
                  <ICONS.XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                
              <Link 
                to="/"
                className="flex-shrink-0 flex items-center px-4 text-white flex-row gap-2"
                onClick={() => setSidebarOpen(false)}>
                <img src="/bin/Icon.png" alt="Red Striped Tie Logo" className="w-[50px] mr-2" />
                <span>Red Striped Tie</span>
              </Link>

							{(token) ?
								<nav className="mt-5 px-2 space-y-1">
									<MENU_ITEM_TICKER_SEARCH setSidebarOpen={setSidebarOpen} token={token} />
									{ui_navigation}
								</nav>
								:
								<></>
							}

            </div>

            { (!token) ? 
              <MENU_ITEM_LOGIN
                navigation={navigation}
                setSidebarOpen={setSidebarOpen} /> :
              <MENU_ITEM_ACCOUNT
                navigation={navigation}
                setSidebarOpen={setSidebarOpen}
                token={token} />
            }
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
      </Dialog>
    </Transition.Root>
  );
}
export default SIDE_MENU_MOBILE;