import React from 'react';
import abbrInt from "../bin/abbrInt";
import dontRerender from "../bin/dontRerender";

const MAIN = (props)=>{
	let openInterestInfo = props.openInterestInfo;

	const barUI = (item) => {
		let sharesRepresentedByCallsPercent = Math.abs(Math.trunc((Number(item.SharesRepresentedByCalls) / Number(item.SharesRepresentedTotal)) * 100));
		let sharesRepresentedByPutsPercent = Math.abs(Math.trunc((Number(item.SharesRepresentedByPuts) / Number(item.SharesRepresentedTotal)) * 100));
		
		return <span className='flex flex-row justify-between w-full text-xxs h-1'>
			<span style={{ width: `${sharesRepresentedByCallsPercent}%` }} className={`bg-emerald-700 flex items-center justify-start`}></span>
			<span style={{ width: `${sharesRepresentedByPutsPercent}%` }} className={`bg-red-700 flex items-center justify-end`}></span>
		</span>;
	};

  return (
    <div className="h-full w-full">
			{/* <div className="col-span-6 md:col-span-4 h-full w-full text-white gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap"> */}
			<div className="col-span-6 md:col-span-4 w-full text-white gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
				<span className={`rounded text-xxs w-[80px] sm:w-[110px] flex flex-col items-center justify-center text-gray-400`}>
					<span>Call OI Shares</span>
					{openInterestInfo && openInterestInfo.SharesRepresentedByCalls ? <span className='text-green-300 text-center w-full flex flex-col'>
						{abbrInt(openInterestInfo.SharesRepresentedByCalls, false)}
						{openInterestInfo && openInterestInfo.SharesRepresentedByCallsChange ? <span className={`mx-2 inline-flex justify-center items-center rounded-full px-1.5 py-0 text-xxs leading-tight font-light ${openInterestInfo.SharesRepresentedByCallsChange > 0 ? 'bg-emerald-300 text-emerald-800' : ''}${openInterestInfo.SharesRepresentedByCallsChange < 0 ? 'bg-rose-300 text-rose-800' : ''}${!openInterestInfo.SharesRepresentedByCallsChange ? 'bg-gray-300 text-gray-800' : ''}`}>{openInterestInfo.SharesRepresentedByCallsChange > 0 ? '+' : ''}{abbrInt(openInterestInfo.SharesRepresentedByCallsChange, false)}</span> : <></>}
					</span> : <></>}
				</span>
				<span className={`text-xxs w-[80px] sm:w-[110px] flex flex-col items-center justify-center text-gray-400`}>
					<span>Total OI Shares</span>
					{openInterestInfo && openInterestInfo.SharesRepresentedTotal ? <span className='text-sky-300 text-center w-full flex flex-col'>
						{abbrInt(openInterestInfo.SharesRepresentedTotal, false)}
						{openInterestInfo && openInterestInfo.SharesRepresentedTotalChange ? <span className={`mx-2 inline-flex justify-center items-center rounded-full px-1.5 py-0 text-xxs leading-tight font-light ${openInterestInfo.SharesRepresentedTotalChange > 0 ? 'bg-emerald-300 text-emerald-800' : ''}${openInterestInfo.SharesRepresentedTotalChange < 0 ? 'bg-rose-300 text-rose-800' : ''}${!openInterestInfo.SharesRepresentedTotalChange ? 'bg-gray-300 text-gray-800' : ''}`}>{openInterestInfo.SharesRepresentedTotalChange > 0 ? '+' : ''}{abbrInt(openInterestInfo.SharesRepresentedTotalChange, false)}</span> : <></>}
					</span> : <></>}
				</span>
				<span className={`rounded text-xxs w-[80px] sm:w-[110px] flex flex-col items-center justify-center text-gray-400`}>
					<span>Put OI Shares</span>
					{openInterestInfo && openInterestInfo.SharesRepresentedByPuts ? <span className='text-red-300 text-center w-full flex flex-col'>
						{abbrInt(openInterestInfo.SharesRepresentedByPuts,false)}
						{openInterestInfo && openInterestInfo.SharesRepresentedByPutsChange ? <span className={`mx-2 inline-flex justify-center items-center rounded-full px-1.5 py-0 text-xxs leading-tight font-light ${openInterestInfo.SharesRepresentedByPutsChange > 0 ? 'bg-emerald-300 text-emerald-800' : ''}${openInterestInfo.SharesRepresentedByPutsChange < 0 ? 'bg-rose-300 text-rose-800' : ''}${!openInterestInfo.SharesRepresentedByPutsChange ? 'bg-gray-300 text-gray-800' : ''}`}>{openInterestInfo.SharesRepresentedByPutsChange > 0 ? '+' : ''}{abbrInt(openInterestInfo.SharesRepresentedByPutsChange, false)}</span> : <></>}
					</span> : <></>}
				</span>
			</div>
			{ openInterestInfo ? <div className='mt-1 mb-2'>{barUI(openInterestInfo)}</div> : <></> }
			<canvas id="OpenInterestShares"></canvas>
			{openInterestInfo && openInterestInfo.IndexDate ? <div className='text-xxs text-gray-400 text-center'>As Of {openInterestInfo.IndexDate}</div> : <></>}
		</div>
  );
};

export default React.memo(MAIN, dontRerender);