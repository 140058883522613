import React, { useState, useEffect } from 'react';
import config from '../config.json';
import LOADING from "../components/LOADING";
import Headers from '../bin/Headers';
import recaptchaReady from '../bin/recaptchaReady';
import wait from '../bin/wait';
import { useStore } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import actions from '../state/actions';

const Main = () => {
	const store = useStore(),
		[searchParams, setSearchParams] = useSearchParams(),
		urlType = searchParams.get("type"),
		urlCode = searchParams.get("code"),
		urlUsername = searchParams.get("username"),
		[requestNewCode, setRequestNewCode] = useState(false),
		[password, setPassword] = useState(''),
		[confirmPassword, setConfirmPassword] = useState(''),
		[loading, setLoading] = useState(false),
		[verificationLoading, setVerificationLoading] = useState(false),
		[headerText, setHeaderText] = useState('Validation'),
		[errorText, setErrorText] = useState(''),
		[showSignUpButton, setShowSignUpButton] = useState(false),
		[showPasswordForm, setshowPasswordForm] = useState(true),
		[responseText, setResponseText] = useState(''),
		[colorChange, setColorChange] = useState(null),
		navigate = useNavigate();
	
	const animateText = async (textSetter, text, delay) => {
		for (let i = 0; i <= text.length; i++) {
			setTimeout(() => {
				textSetter(text.slice(0, i));
			}, i * delay);
		}
	};

	const submitRequestNewCode = async () => {
		if (!urlCode || !urlCode.length) { alert(`Validation code is missing`); return; }
		if (!urlUsername || !urlUsername.length) { alert(`Username is missing`); return; }
		setLoading(true);
		let headers = Headers();
		let captcha = !headers.Accept ? await recaptchaReady('VALIDATION') : null;
		
		setColorChange('bg-amber-950');
		let requestOp = await fetch(`${config.api}/validation`, { headers, method: 'post', body: JSON.stringify({ username:urlUsername, type:'anotherverification', code:urlCode, captcha }) })
			.then(d => d.json())
			.catch(async (e) => {
				setColorChange('bg-rose-950');
				await animateText(setHeaderText, 'Request Failed', 50);
				console.log(e);
				if (e && e.error) { await animateText(setErrorText, e.error, 50); }
				setLoading(false);
				return;
			});
		if (!requestOp.message || requestOp.message !== 'ok' || requestOp.error) {
			setColorChange('bg-rose-950');
			await animateText(setHeaderText, 'Request Failed', 50);
			await animateText(setErrorText, requestOp.error, 50);
			setLoading(false);
			return;
		}
		setColorChange('bg-sky-950');
		setLoading(false);
		setshowPasswordForm(false);
			
		await animateText(setHeaderText, 'Recovery Email Sent', 50);
		setErrorText('');
		await wait(500);
			
		await animateText(setResponseText, 'now to wait for another verfication email', 50);
		setColorChange('bg-emerald-950');
		await wait(5000);
		return navigate('/login', {replace:true});
	};

  const handleSubmit = async (event)=>{
		event.preventDefault();
		if (password.length<4) { alert(`Password is too short`); return; }
		if (password.length > 50) { alert(`Password is too long`); return; }
		if (password !== confirmPassword) { alert(`Passwords do not match`); return; }
		if (!/^[a-zA-Z0-9!@#$%^&*<>?]+$/.test(password)) { alert(`Password is invalid. Only A-Z, 0-9, and the following symbols are allowed !,@,#,$,%,^,&,*,<,>,?`); return; }
		if (!urlCode || !urlCode.length) { alert(`Validation code is missing`); return; }
		if (!urlUsername || !urlUsername.length) { alert(`Username is missing`); return; }
		setLoading(true);
		let headers = Headers();
		let captcha = !headers.Accept ? await recaptchaReady('VALIDATION') : null;

		setColorChange('bg-amber-950');
		let requestOp = await fetch(`${config.api}/validation`, { headers, method: 'post', body: JSON.stringify({ password, username:urlUsername, type:urlType, code:urlCode, captcha }) })
			.then(d => d.json())
			.catch(async (e) => {
				setColorChange('bg-rose-950');
				await animateText(setHeaderText, 'Validation Failed', 50);
				console.log(e);
				if (e && e.error) { await animateText(setErrorText, e.error, 50); }
				setLoading(false);
				return;
			});
		if (!requestOp.message || requestOp.message !== 'ok' || requestOp.error) {
			setColorChange('bg-rose-950');
			await animateText(setHeaderText, 'Validation Failed', 50);
			await animateText(setErrorText, requestOp.error, 50);
			if (requestOp.error && requestOp.error === 'verification code is expired') { setRequestNewCode(true); }
			setLoading(false);
			return;
		}
		setColorChange('bg-sky-950');
		setLoading(false);
		setshowPasswordForm(false);
		
		await animateText(setHeaderText, 'Password Updated', 50);
		setErrorText('');
		await wait(500);
			
		await animateText(setResponseText, 'welcome back', 50);
		setColorChange('bg-emerald-950');
		await wait(1500);
		store.dispatch(actions.updateToken(requestOp.result.token));
		if (requestOp.result.avatar) { store.dispatch(actions.updateAvatar(requestOp.result.avatar)); }
		return navigate('/', {replace:true});
	};

	const checkVerications = async () => {
		if (!urlCode || !urlCode.length) { alert(`Validation code is missing`); return; }
		if (!urlUsername || !urlUsername.length) { alert(`Username is missing`); return; }
		setVerificationLoading(true);
		setLoading(false);
		setShowSignUpButton(false);
		setRequestNewCode(false);
		setshowPasswordForm(false);
		let headers = Headers();
		let captcha = !headers.Accept ? await recaptchaReady('VALIDATION') : null;
		
		setColorChange('bg-amber-950');
		let requestOp = await fetch(`${config.api}/validation`, { headers, method: 'post', body: JSON.stringify({ username:urlUsername, type:urlType, code:urlCode, captcha }) })
			.then(d => d.json())
			.catch(async (e) => {
				setColorChange('bg-rose-950');
				setHeaderText('Verification Failed');
				if (e && e.error) { await animateText(setErrorText, e.error, 50); }
				setLoading(false);
				return;
			});
		if (!requestOp.message || requestOp.message !== 'ok' || requestOp.error || !requestOp.result || !requestOp.result.token) {
			setColorChange('bg-rose-950');
			setHeaderText('Verification Failed');
			await animateText(setErrorText, requestOp.error, 50);
			if (requestOp.error && requestOp.error === 'verification code is expired') { setRequestNewCode(true); }
			if (requestOp.error && requestOp.error === 'verification ability expired. try signing up again.') { setShowSignUpButton(true); }
			setVerificationLoading(false);
			return;
		}
		setColorChange('bg-sky-950');
		setVerificationLoading(false);
		setHeaderText('Email Verified');
		setErrorText('');
		await wait(500);
			
		await animateText(setResponseText, 'welcome to RedStripedTie.com', 50);
		setColorChange('bg-emerald-950');
		await wait(1500);
		store.dispatch(actions.updateToken(requestOp.result.token));
		if (requestOp.result.avatar) { store.dispatch(actions.updateAvatar(requestOp.result.avatar)); }
		return navigate('/', {replace:true});
	};
	
	useEffect(() => {
		if (urlType === 'recovery') {
			setHeaderText('Create a new Password');
			setColorChange('bg-violet-950');
		}
		else if (urlType === 'verification') {
			setHeaderText('Verifying Email');
			setColorChange('bg-violet-950');
			setshowPasswordForm(false);
			setVerificationLoading(true);
			checkVerications();
		}
	}, [urlType]);

  return (
    <div className="flex items-start justify-center mt-4 text-white w-full flex-wrap sm:flex-nowrap">

			<div className={`flex flex-col ${colorChange ? colorChange : 'bg-gray-800'} animate-bg rounded-xl shadow-md sm:p-8 sm:m-4 w-full max-w-sm`}>
				<div className="flex min-h-full flex-1 items-center justify-center px-6 py-6">
					<div className="w-full max-w-sm space-y-10">
						<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">
							{headerText}
						</h2>
						{errorText ? <h6 className="text-center text-sm font-semibold leading-6 text-amber-300">{errorText}</h6> : ''}
						{requestNewCode ? <div className='flex justify-center'>
							<button onClick={()=>submitRequestNewCode()} className={`flex w-md justify-center rounded-md ${loading ? 'bg-gradient-to-r from-orange-800 to-amber-900' : 'bg-gradient-to-r from-orange-500 to-amber-500'} px-3 py-1.5 text-sm font-semibold leading-normal text-white hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
								{loading ? <span className='py-1'><LOADING color="text-white" text={false} nopadding={true} /></span> : 'Request a new code'}
							</button>
						</div> : <></>}
						{showSignUpButton ? <div className='flex justify-center'>
							<Link to={`/signup?username=${urlUsername}`} className={`flex w-md justify-center rounded-md bg-gradient-to-r from-orange-500 to-amber-500 px-3 py-1.5 text-sm font-semibold leading-normal text-white hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
								Sign Up Again
							</Link>
						</div> : <></>}
						{showPasswordForm && !requestNewCode && (<div className='w-full max-w-sm space-y-10'>
							<form className="space-y-6" onSubmit={handleSubmit}>
								<div className="relative -space-y-px rounded-md shadow-sm">
									<div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
									<div>
										<label htmlFor="Password" className="sr-only">
											New Password
										</label>
										<input
											name="password"
											type="password"
											required
											minLength="4"
											maxLength="50"
											className={`${password && password.length ? '' : 'rounded-b-md' } relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6`}
											placeholder="New Password"
											value={password}
											onChange={(evt)=>{setPassword(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
										/>
									</div>
									<div>
										<label htmlFor="ConfirmPassword" className="sr-only">
											Confirm Password
										</label>
										<input
											name="confirmPassword"
											type="password"
											required
											minLength="4"
											maxLength="50"
											className={`relative block w-full ${password && password.length ? 'block' : 'hidden' } rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6`}
											placeholder="Confirm Password"
											value={confirmPassword}
											onChange={(evt)=>{setConfirmPassword(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
										/>
									</div>
								</div>
								
								<div className={`${password && password.length && confirmPassword && confirmPassword.length && password===confirmPassword ? 'block' : 'hidden'}`}>
									<button type="submit" disabled={loading} className={`flex w-full justify-center rounded-md ${loading ? 'bg-gradient-to-r from-red-800 to-rose-900' : 'bg-gradient-to-r from-red-500 to-rose-500'} px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
										{loading ? <span className='py-1'><LOADING color="text-white" text={false} nopadding={true} /></span> : 'Update'}
									</button>
								</div>
									
							</form>
									
						</div> )}

						{verificationLoading ? <div><LOADING text={false} /></div> : <></>}
						{!showPasswordForm && <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">{responseText}</h2>}

					</div>
				</div>
      </div>

    </div>
  );
};
export default Main;