const main = (startTime, endTime, returnJsonObject = false) => {	
  //convert nanoseconds to milliseconds
	if (startTime >= Number(1000000000000000000)) {
		// startTime = Math.trunc(startTime / BigInt(1000000));
		startTime = startTime / Number(1000000);
	} else if (startTime >= Number(1000000000000000)) {
    // startTime= Math.trunc(startTime / BigInt(1000));
    startTime= startTime / Number(1000);
  }
  
	if (endTime >= Number(1000000000000000000)) {
    // endTime= Math.trunc(endTime / BigInt(1000000));
    endTime= endTime / Number(1000000);
	} else if (endTime >= Number(1000000000000000)) {
    // endTime= Math.trunc(endTime / BigInt(1000));
    endTime= endTime / Number(1000);
	}
	
  let timeRemaining = endTime - startTime;
  timeRemaining = Number(timeRemaining);
	
  let timeString = [];
	let seconds = Math.trunc(Number(timeRemaining / 1000));
  let timeInSeconds = seconds;
  //determine the days
  if (seconds>86400){
    let days = Math.trunc(seconds / 86400);
    seconds = seconds - (days * 86400);
    timeString.push(`${days}d`);
  }
  //determine the hours
  if (seconds>3600){
    let hours = Math.trunc(seconds / 3600);
    seconds = seconds - (hours * 3600);
    timeString.push(`${hours}${hours===1 ? 'hr': 'hrs'}`);
  }
  //determine the minutes
  if (seconds>60){
    let minutes = Math.trunc(seconds / 60);
    seconds = seconds - (minutes * 60);
    timeString.push(`${minutes}m`);
  }
  //determine the remaining seconds
  if (seconds){
    timeString.push(`${seconds}s`);
  }

	timeString = timeString.join(' ');
  if (returnJsonObject){
    return {
      timeString,
      timeInSeconds,
    };
  }
  return timeString;
};
export default main;