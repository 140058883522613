import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useStore } from 'react-redux';
import config from '../config.json';
import LOADING from "../components/LOADING";
import Headers from '../bin/Headers';
import recaptchaReady from '../bin/recaptchaReady';
import wait from '../bin/wait';
import actions from '../state/actions';

const Main = () => {
	const store = useStore(),
		[username, setUsername] = useState(''),
		[password, setPassword]= useState(''),
		[loading, setLoading] = useState(false),
		[headerText, setHeaderText] = useState('Login'),
		[showForm, setShowForm] = useState(true),
		[welcomeText, setWelcomeText] = useState(''),
		[errorText, setErrorText] = useState(''),
		[colorChange, setColorChange] = useState(null),
		[avatar, setAvatar] = useState(null),
		[loginTimeout, setLoginTimeout] = useState(null),
		navigate = useNavigate();
	
	const animateText = async (textSetter, text, delay) => {
		for (let i = 0; i <= text.length; i++) {
			setTimeout(() => {
				textSetter(text.slice(0, i));
			}, i * delay);
		}
	};

	const filterUsername = (value) => {
		// Use a regular expression to remove non-alphanumeric characters
		const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
		setUsername(filteredValue);
	};

  const handleSubmit = async (event)=>{
		event.preventDefault();
		// Clear any existing timeout to avoid duplicate timeouts
		if (loginTimeout) clearTimeout(loginTimeout);
		// Set a new timeout
		const newTimeout = setTimeout(() => {
			// This code runs after 9 seconds
			setColorChange('bg-rose-950');
			setHeaderText('Login Cancelled');
			setErrorText('Login process took too long and was cancelled.');
			setLoading(false);
			// Clear the timeout state
			setLoginTimeout(null);
		}, 9000); // 9 seconds
		setLoginTimeout(newTimeout);
		if (!username || !username.length) { alert(`Username is missing`); return; }
		if (!password || !password.length) { alert(`Password is missing`); return; }
		if (username.length<3) { alert(`Username is too short`); return; }
		if (username.length>25) { alert(`Username is too long`); return; }
		if (password.length<4) { alert(`Password is too short`); return; }
		if (password.length>50) { alert(`Password is too long`); return; }
		if (!/^[a-zA-Z0-9!@#$%^&*<>?]+$/.test(password)) { alert(`Password is invalid. Only A-Z, 0-9, and the following symbols are allowed !,@,#,$,%,^,&,*,<,>,?`); return; }
		setLoading(true);
		setColorChange('bg-amber-950');
		if (headerText!=='Login'){ setHeaderText('Retrying Login'); }
		if (errorText !== '') { setErrorText(''); }

		let headers = Headers();
		let captcha = !headers.Accept ? await recaptchaReady('LOGIN') : null;
		let loginOp = await fetch(`${config.api}/login`, { headers, method: 'post', body: JSON.stringify({ username, password, captcha }) })
			.then(d => d.json())
			.catch(async (e) => {
				clearTimeout(newTimeout);
				setLoginTimeout(null);
				setColorChange('bg-rose-950');
				setHeaderText('Login Failed');
				alert(e);
				setLoading(false);
				return;
			});				
		clearTimeout(newTimeout);
		setLoginTimeout(null);
		if (!loginOp.message || loginOp.message !== 'ok' || !loginOp.result || !loginOp.result.token) {
			setColorChange('bg-rose-950');
			setHeaderText('Login Failed');
			if (loginOp.error) { await animateText(setErrorText, loginOp.error, 50); }
			setLoading(false);
			return;
		}
		setColorChange('bg-sky-950');
		setLoading(false);
		setShowForm(false);
		
		store.dispatch(actions.updateToken(loginOp.result.token));
		if (loginOp.result.avatar) {
			setAvatar(loginOp.result.avatar);
			store.dispatch(actions.updateAvatar(loginOp.result.avatar));
		}
		if (loginOp.result.level) {
			store.dispatch(actions.updateTieLevel(loginOp.result.level));
		}
		
		// Animate typing "Welcome"
		setHeaderText('Welcome');
		await wait(500);
		
		// Type the username
		await animateText(setWelcomeText, username, 50);
		setColorChange('bg-emerald-950');
		await wait(1500);

		return navigate('/main', {replace:true});
	};

  return (
    <div className="flex items-start justify-center mt-4 text-white w-full flex-wrap sm:flex-nowrap">

			<div className={`flex flex-col ${colorChange ? colorChange : 'bg-gray-800'} animate-bg rounded-xl shadow-md sm:p-8 sm:m-4 w-full max-w-sm`}>
				<div className="flex min-h-full flex-1 items-center justify-center px-6 py-6">
					<div className="w-full max-w-sm space-y-10">
						<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">
							{headerText}
						</h2>
						{showForm && (<div className='w-full max-w-sm space-y-10'>
							<form className="space-y-6" onSubmit={handleSubmit}>
								<div className="relative -space-y-px rounded-md shadow-sm">
									<div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
									<div>
										<label htmlFor="Username" className="sr-only">
											Username
										</label>
										<input
											name="username"
											type="text"
											autoComplete="username"
											required
											minLength="3"
											maxLength="25"
											pattern="[a-zA-Z0-9]+"
											className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
											placeholder="Username"
											value={username}
											onChange={(evt)=>{filterUsername(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Entry') { return; } handleSubmit(evt); }}
										/>
									</div>
									<div>
										<label htmlFor="password" className="sr-only">
											Password
										</label>
										<input
											name="password"
											type="password"
											autoComplete="current-password"
											required
											minLength="4"
											maxLength="50"
											className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
											placeholder="Password"
											value={password}
											onChange={(evt)=>{setPassword(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Entry') { return; } handleSubmit(evt); }}
										/>
									</div>
								</div>

								<div>
									<button type="submit" disabled={loading} className={`flex w-full justify-center rounded-md ${loading ? 'bg-gradient-to-r from-red-800 to-rose-900' : 'bg-gradient-to-r from-red-500 to-rose-500'} px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
										{loading ? <span className='py-1'><LOADING color="text-white" text={false} nopadding={true} /></span> : 'Login'}
									</button>
								</div>

								{errorText ? <div className='w-full text-amber-500 py-2 px-1 w-full text-sm'>
									<b>Error: </b><span>{errorText}</span>
								</div> : <></>}
									
							</form>
									
							<hr className="border-gray-200" />
							<div className="mt-5 flex flex-row justify-between items-top">
								<div className="text-sm leading-6 mb-4 sm:mb-0">
										<a href="./recovery" className="font-semibold text-rose-600 hover:text-rose-500">Forgot Password</a>
								</div>
								<div className="text-sm leading-6">
										<a href="./signup" className="font-semibold text-rose-600 hover:text-rose-500">Sign Up</a>
								</div>
							</div>
						</div> )}

						{!showForm && avatar &&
							<div className="flex w-full items-center justify-center">
								<img className="inline-block border shadow h-16 w-16 rounded-b-2xl rounded-t-md" src={avatar} alt="" /> 
							</div>
						}

						{!showForm && <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">{welcomeText}</h2>}

					</div>
				</div>
      </div>

      <div className="mt-40 fixed inset-x-0 bottom-0 p-4 bg-rose-950/20 text-gray-400 sm:p-8">
        <p className="text-justify text-xs sm:text-sm">
					<b>Notice:</b> Live and historic data requires a paid subscription. No contracts or obligations and cancellation is simple. End of day data is free to access. Data duplication and dissemination may result in account termination and blocked access. Data limits apply.
					This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" rel="noreferrer" target='_blank' className='text-amber-600'>Privacy Policy</a> and <a href="https://policies.google.com/terms" rel="noreferrer" target='_blank' className='text-amber-600'>Terms of Service</a> apply.
        </p>
			</div>

    </div>
  );
};
export default Main;