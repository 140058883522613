import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import config from '../config.json';
import LOADING from "../components/LOADING";
import Headers from '../bin/Headers';
import recaptchaReady from '../bin/recaptchaReady';
import wait from '../bin/wait';
import isValidEmail from '../bin/isValidEmail';

const Main = () => {
	const [username, setUsername] = useState(''),
		[email, setEmail] = useState(''),
		[loading, setLoading] = useState(false),
		[headerText, setHeaderText] = useState('Account Recovery'),
		[errorText, setErrorText] = useState(''),
		[showForm, setShowForm] = useState(true),
		[responseText, setResponseText] = useState(''),
		[colorChange, setColorChange] = useState(null),
		navigate = useNavigate();
	
	const animateText = async (textSetter, text, delay) => {
		for (let i = 0; i <= text.length; i++) {
			setTimeout(() => {
				textSetter(text.slice(0, i));
			}, i * delay);
		}
	};

  const handleSubmit = async (event)=>{
		event.preventDefault();
		if (!username || !username.length) { alert(`Username is missing`); return; }
		if (!email || !email.length) { alert(`Email is missing`); return; }
		if (username.length<5) { alert(`Username is too short`); return; }
		if (username.length>25) { alert(`Username is too long`); return; }
		if (email.length<5) { alert(`Email is too short`); return; }
		if (email.length>254) { alert(`Email is too long`); return; }
		if (isValidEmail(email)===false) { alert(`Email is invalid`); return; }
		setLoading(true);
		let headers = Headers();
		let captcha = !headers.Accept ? await recaptchaReady('RECOVERY') : null;
		
		setColorChange('bg-amber-950');
		let requestOp = await fetch(`${config.api}/recovery`, { headers, method: 'post', body: JSON.stringify({ username, email, captcha }) })
			.then(d => d.json())
			.catch(async (e) => {
				setColorChange('bg-rose-950');
				setHeaderText('Recovery Failed');
				if (e && e.error) { await animateText(setErrorText, e.error, 50); }
				setLoading(false);
				return;
			});
		if (!requestOp.message || requestOp.message !== 'ok' || requestOp.error) {
			setColorChange('bg-rose-950');
			setHeaderText('Recovery Failed');
			await animateText(setErrorText, requestOp.error, 50);
			setLoading(false);
			return;
		}
		setColorChange('bg-sky-950');
		setLoading(false);
		setShowForm(false);
			
		// Animate typing "Welcome"
		setHeaderText('Recovery Email Sent');
		setErrorText('');
		await wait(500);
		
		// Type the username
		await animateText(setResponseText, 'Please allow up to 5 minutes before rage quitting and trying again.', 50);
		setColorChange('bg-emerald-950');
		await wait(5000);
		return navigate('/login', {replace:true});
	};

  return (
    <div className="flex items-start justify-center mt-4 text-white w-full flex-wrap sm:flex-nowrap">

			<div className={`flex flex-col ${colorChange ? colorChange : 'bg-gray-800'} animate-bg rounded-xl shadow-md sm:p-8 sm:m-4 w-full max-w-sm`}>
				<div className="flex min-h-full flex-1 items-center justify-center px-6 py-6">
					<div className="w-full max-w-sm space-y-10">
						<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">
							{headerText}
						</h2>
						{errorText ? <h6 className="text-center text-sm font-semibold leading-6 text-amber-300">{errorText}</h6> : ''}
						{showForm && (<div className='w-full max-w-sm space-y-10'>
							<form className="space-y-6" onSubmit={handleSubmit}>
								<div className="relative -space-y-px rounded-md shadow-sm">
									<div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
									<div>
										<label htmlFor="Username" className="sr-only">
											Username
										</label>
										<input
											name="username"
											type="text"
											autoComplete="username"
											required
											minLength="3"
											maxLength="25"
											className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
											placeholder="Username"
											value={username}
											onChange={(evt)=>{setUsername(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
										/>
									</div>
									<div>
										<label htmlFor="email" className="sr-only">
											Email
										</label>
										<input
											name="email"
											type="email"
											autoComplete="email"
											required
											minLength="4"
											maxLength="50"
											className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
											placeholder="Email Address"
											value={email}
											onChange={(evt)=>{setEmail(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
										/>
									</div>
								</div>

								<p className="mt-1 text-sm leading-6 text-gray-300">A recovery link will be sent to the email address you provide.</p>
								
								<div>
									<button type="submit" disabled={loading} className={`flex w-full justify-center rounded-md ${loading ? 'bg-gradient-to-r from-red-800 to-rose-900' : 'bg-gradient-to-r from-red-500 to-rose-500'} px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
										{loading ? <span className='py-1'><LOADING color="text-white" text={false} nopadding={true} /></span> : 'Help Me'}
									</button>
								</div>
									
							</form>
									
						</div> )}

						{!showForm && <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">{responseText}</h2>}

					</div>
				</div>
      </div>

    </div>
  );
};
export default Main;