import React, { useEffect, useState } from 'react';
import dontRerender from "../bin/dontRerender";
import abbrInt from "../bin/abbrInt";

const COMPONENT = (props)=>{
  let matches = props.matches,
      shareMinimum = props.shareMinimum,
      valueMinimum = props.valueMinimum,
      [totalFtdShareAverage, setTotalFtdShareAverage] = useState(0),
      [totalFtdValueAverage, setTotalFtdValueAverage] = useState(0),
      [totalFtdShares, setTotalFtdShares] = useState(0),
      [totalFtdsAnalyzed, setTotalFtdsAnalyzed] = useState(0),
      [totalFtdValue, setTotalFtdValue] = useState(0),
      [probablePositivePriceAction, setProbablePositivePriceAction] = useState(null),
      [probableNegativePriceAction, setProbableNegativePriceAction] = useState(null),
      [probablePositiveClose, setProbablePositiveClose] = useState(null),
      [averagePositivePriceAction, setAveragePositivePriceAction] = useState(null),
      [averageNegativePriceAction, setAverageNegativePriceAction] = useState(null),
      [averageClose, setAverageClose] = useState(null);
  
  useEffect(()=>{
    // console.log(matches);
    let _totalFtdShareAverage = 0;
    let _totalFtdValueAverage = 0;
    let _totalFtdShares = 0;
    let _totalFtdsAnalyzed = 0;
    let _totalFtdValue = 0;
    let closeToHighPercentSum = 0;
    let closeToLowPercentSum = 0;
    let closeToClosePercentSum = 0;
    let _probablePositivePriceAction = 0;
    let _probableNegativePriceAction = 0;
    let _probablePositiveClose = 0;
    for (let instance of matches){
      if ( !instance.ClosePercent ){ continue; }
      if ( Number(instance.Quantity) < shareMinimum ){ continue; }
      if ( Number(instance.Value.$numberDecimal) < valueMinimum ){ continue; }
      _totalFtdShares += instance.Quantity;
      _totalFtdValue += instance.Value && instance.Value.$numberDecimal ? Number(instance.Value.$numberDecimal) : 0;
      // _totalFtdShareAverage += _totalFtdShares;
      // _totalFtdValueAverage += _totalFtdValue;
      closeToHighPercentSum += instance.PreviousCloseToHighPercent && instance.PreviousCloseToHighPercent.$numberDecimal ? Number(instance.PreviousCloseToHighPercent.$numberDecimal) : 0;
      closeToLowPercentSum += instance.PreviousCloseToLowPercent && instance.PreviousCloseToLowPercent.$numberDecimal ? Number(instance.PreviousCloseToLowPercent.$numberDecimal) : 0;
      closeToClosePercentSum += instance.ClosePercent && instance.ClosePercent.$numberDecimal ? Number(instance.ClosePercent.$numberDecimal) : 0;
      _probablePositivePriceAction += instance.PreviousCloseToHighPercent && instance.PreviousCloseToHighPercent.$numberDecimal && Number(instance.PreviousCloseToHighPercent.$numberDecimal)>0 ? 1 : 0;
      _probableNegativePriceAction += instance.PreviousCloseToLowPercent && instance.PreviousCloseToLowPercent.$numberDecimal && Number(instance.PreviousCloseToLowPercent.$numberDecimal)<0 ? 1 : 0;
      _probablePositiveClose += instance.ClosePercent && instance.ClosePercent.$numberDecimal && Number(instance.ClosePercent.$numberDecimal)>0 ? 1 : 0;
      ++_totalFtdsAnalyzed;
    }
    _totalFtdShareAverage = _totalFtdShares && _totalFtdShares>0 ? Number((_totalFtdShares / _totalFtdsAnalyzed).toFixed()) : _totalFtdShares;
    _totalFtdValueAverage = _totalFtdValue && _totalFtdValue>0 ? Number((_totalFtdValue / _totalFtdsAnalyzed).toFixed()) : _totalFtdValue;
    setTotalFtdShareAverage(_totalFtdShareAverage);
    setTotalFtdValueAverage(_totalFtdValueAverage);
    setTotalFtdShares(_totalFtdShares);
    setTotalFtdsAnalyzed(_totalFtdsAnalyzed);
    _totalFtdValue = Number(_totalFtdValue.toFixed(2));
    setTotalFtdValue(_totalFtdValue);
    closeToHighPercentSum = Number(closeToHighPercentSum.toFixed(2));
    closeToHighPercentSum = Number((closeToHighPercentSum / _totalFtdsAnalyzed).toFixed(2));
    closeToHighPercentSum = `${ closeToHighPercentSum > 0 ? '+' : '' }${ closeToHighPercentSum }%`
    setAveragePositivePriceAction(closeToHighPercentSum);
    closeToLowPercentSum = Number(closeToLowPercentSum.toFixed(2));
    closeToLowPercentSum = Number((closeToLowPercentSum / _totalFtdsAnalyzed).toFixed(2));
    closeToLowPercentSum = `${ closeToLowPercentSum > 0 ? '+' : '' }${ closeToLowPercentSum }%`
    setAverageNegativePriceAction(closeToLowPercentSum);
    closeToClosePercentSum = Number(closeToClosePercentSum.toFixed(2));
    closeToClosePercentSum = Number((closeToClosePercentSum / _totalFtdsAnalyzed).toFixed(2));
    closeToClosePercentSum = `${ closeToClosePercentSum > 0 ? '+' : '' }${ closeToClosePercentSum }%`
    setAverageClose(closeToClosePercentSum);
    _probablePositivePriceAction = Number(((_probablePositivePriceAction / _totalFtdsAnalyzed) * 100).toFixed(2));
    _probablePositivePriceAction = `${_probablePositivePriceAction}%`
    setProbablePositivePriceAction(_probablePositivePriceAction);
    _probableNegativePriceAction = Number(((_probableNegativePriceAction / _totalFtdsAnalyzed) * 100).toFixed(2));
    _probableNegativePriceAction = `${_probableNegativePriceAction}%`
    setProbableNegativePriceAction(_probableNegativePriceAction);
    _probablePositiveClose = Number(((_probablePositiveClose / _totalFtdsAnalyzed) * 100).toFixed(2));
    _probablePositiveClose = `${_probablePositiveClose}%`
    setProbablePositiveClose(_probablePositiveClose);
  }, [props]);

  return (
    <div className='w-full md:max-w-sm sm:w-1/2'>
      <h3 className='text-white'>Statistics</h3>
      
      <div className="grid grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 pt-2">
        <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Positive Price Action</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-green-300 block'>
            <span className='text-xxs text-white inline align-top pr-1'>x̄</span>
            { averagePositivePriceAction ? averagePositivePriceAction : '-' }
          </span>
          <span className='text-xxs text-indigo-300 block'>
            <span className='text-xxxs text-white inline align-top pr-1 italic'>P</span>
            { probablePositivePriceAction ? probablePositivePriceAction : '-' }
          </span>
        </div>
      </div>
      
      <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-1">
      <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Negative Price Action</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-red-300 block'>
            <span className='text-xxs text-white inline align-top pr-1'>x̄</span>
            { averageNegativePriceAction ? averageNegativePriceAction : '-' }
          </span>
          <span className='text-xxs text-indigo-300 block'>
            <span className='text-xxxs text-white inline align-top pr-1 italic'>P</span>
            {probableNegativePriceAction ? probableNegativePriceAction : '-' }
          </span>
        </div>
      </div>
      
      <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-1">
      <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Positive Close</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-green-300 block'>
            <span className='text-xxs text-white inline align-top pr-1'>x̄</span>
            { averageClose ? averageClose: '-' }
          </span>
          <span className='text-xxs text-indigo-300 block'>
            <span className='text-xxxs text-white inline align-top pr-1 italic'>P</span>
            { probablePositiveClose ? probablePositiveClose : '-' }
          </span>
        </div>
      </div>
      
      {/* <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-0">
      <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Average Range</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-indigo-300 block'>
            9%
          </span>
        </div>
      </div> */}

      <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-1">
      <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Total FTDs Analyzed</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-indigo-300 block'>
            { totalFtdsAnalyzed && isNaN(totalFtdsAnalyzed)===false ? totalFtdsAnalyzed.toLocaleString() : totalFtdsAnalyzed }
          </span>
        </div>
      </div>

      <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-1">
        <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Total FTD Shares</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-indigo-300 block'>
            { abbrInt(totalFtdShares) }
          </span>
        </div>
      </div>
      
      <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-1">
        <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Total FTD Value</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-indigo-300 block'>
            ${ abbrInt(totalFtdValue) }
          </span>
        </div>
      </div>
      
      <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-1">
        <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Average FTD Shares</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-indigo-300 block'>
            { (totalFtdShareAverage).toLocaleString() }
          </span>
        </div>
      </div>

      <div className="grid grid-cols-3 sm:gap-4 sm:items-start mt-1">
        <label className="flex flex-row items-start text-sm font-medium text-gray-500 sm:mt-px col-span-2">
          <span className='block'>Average FTD Value</span>
        </label>
        <div className="mt-0 col-span-1 flex flex-col items-end justify-end">
          <span className='text-indigo-300 block'>
            ${ abbrInt(totalFtdValueAverage) }
          </span>
        </div>
      </div>

      
    </div>
  );

};

export default React.memo(COMPONENT, dontRerender);