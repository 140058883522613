import config from "../config.json";
import React, { useState, useEffect } from 'react';
import Headers from "../bin/Headers";
import { useNavigate } from 'react-router-dom';
import abbrInt from "../bin/abbrInt";
import dontRerender from "../bin/dontRerender";
import LOADING from "../components/LOADING";
import CANDLESTICKCHART from '../components/CANDLESTICKCHART';
import ECHARTNOTIONAL from "../components/ECHARTNOTIONAL";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";

const MAIN = (props) => {
	let token = props.token,
		ticker = props.ticker,
		[candles, setCandles] = useState(null),
		[LoadingValue, setLoadingValue] = useState(true);
	
	async function start() {
		if (!ticker || !token) { return; }
		setLoadingValue(true);
		let data = await fetch(`${config.api}/capitalflow?ticker=${ticker}`, { headers: Headers(token) }).then(d => d.json()).then(d => d ? d : null);
		if (!data || !data.length) { 
			setLoadingValue(false);
			return;
		}
		let chartData = data.reverse();
		setCandles(chartData);
		setLoadingValue(false);
	}

	useEffect(() => { start(); }, [ticker]);
	
	return (
    <div className="rounded-lg flex flex-col justify-start items-center bg-gray-500 border border-solid border-gray-500 w-full">
			<div className="bg-gradient-to-r from-sky-950 to-purple-950 w-full flex flex-row h-full min-h-24 items-center justify-center">
				{LoadingValue === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300"><LOADING color="text-green-500" /></div>}
				{LoadingValue === false && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300 w-full">
					<ECHARTNOTIONAL candles={candles} />
				</div>}
			</div>
		</div>
  );
};

export default React.memo(MAIN, dontRerender);