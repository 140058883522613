import React, {useState, useEffect} from 'react';
import { useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import config from '../config.json';
import Headers from "../bin/Headers";
import dontRerender from "../bin/dontRerender";
import _logout from "../bin/logout";
// import OPTION_SECTOR_PREMIUM from "../components/OPTION_SECTOR_PREMIUM";
// import TICKERS_HIGHEST_OPTIONSHARES from "../components/TICKERS_HIGHEST_OPTIONSHARES";
// import TICKERS_OVER_RANGE from "../components/TICKERS_OVER_RANGE";
import MARKET_STATS from "../components/MARKET_STATS";
import TickerEntryUi_HighestStockNotional from "./TickerEntryUi_HighestStockNotional";
import TickerEntryUi_HighestOptionPremium from "./TickerEntryUi_HighestOptionPremium";
import TickerEntryUi_HighestBinaryOptionPremium from "./TickerEntryUi_HighestBinaryOptionPremium";
import TickerEntryUi_HighestStockVolume from "./TickerEntryUi_HighestStockVolume";
import TickerEntryUi_HighestStockTrades from "./TickerEntryUi_HighestStockTrades";
import TickerEntryUi_HighestStockTradesNonAuction from "./TickerEntryUi_HighestStockTradesNonAuction";
import TickerEntryUi_HighestBinaryOptionTrades from "./TickerEntryUi_HighestBinaryOptionTrades";
import TickerEntryUi_HighestOptionVolume from "./TickerEntryUi_HighestOptionVolume";
import TickerEntryUi_HighestOddLot from "./TickerEntryUi_HighestOddLot";
import TickerEntryUi_HighestStockTurnover from "./TickerEntryUi_HighestStockTurnover";
import TickerEntryUi_HighestOptionTurnover from "./TickerEntryUi_HighestOptionTurnover";
import TickerEntryUi_HighestPercentGain from "./TickerEntryUi_HighestPercentGain";
import TickerEntryUi_HighestPercentLoss from "./TickerEntryUi_HighestPercentLoss";
import TickerEntryUi_HighestMarketCapGain from "./TickerEntryUi_HighestMarketCapGain";
import TickerEntryUi_HighestMarketCapLoss from "./TickerEntryUi_HighestMarketCapLoss";

import LOADING from './LOADING';
import todaysDate from '../bin/todaysDate';
import useInView from '../hooks/useInView';

const MAIN = (props) => {
	const token = useSelector(state => state.token),
		view = props.view,
		subview = props.subview,
		study = props.study,
		setDelayedDataDateString = props.setDelayedDataDateString,
		navigate = useNavigate(),
		[list, setList] = useState([]),
		[focusedTicker, setFocusedTicker] = useState(null),
		[loading, setLoading]= useState(false),
		[marketStats, setMarketStats] = useState(null);
	
	const [observe, inView] = useInView();
	
	const loadMetrics = async (initialLoad) => {
		// if (!token) { navigate('/login', { replace: true }); return; }
		// if (initialLoad) { setLoading(true); }
		setLoading(true);
		let _marketStats = await fetch(`${config.api}/marketstats`, { headers: Headers(token) }).then(d => d.json());
		if (_marketStats && _marketStats.error && _marketStats.error === 'invalid token') { return _logout(token); }
		if (!_marketStats || !_marketStats.result) { return; }
		
		let payload = { ..._marketStats.result };
		setMarketStats(payload);
		
		let uri = null;
		if ((view==='rankings' && subview==='capital' && !study) || (view==='rankings' && subview==='capital' && study==='stocknotional')) { uri = `${config.api}/higheststocknotional`; }
		if (view==='rankings' && subview==='capital' && study==='optionpremium') { uri = `${config.api}/highestoptionpremium`; }
		if (view === 'rankings' && subview === 'capital' && study === 'binaryoptionpremium') { uri = `${config.api}/highestoptionbinarypremium`; }
		
		if ((view === 'rankings' && subview === 'trade' && !study) || (view === 'rankings' && subview === 'trade' && study === 'stock')) { uri = `${config.api}/largeststocktrades`; }
		if ((view === 'rankings' && subview === 'trade' && !study) || (view === 'rankings' && subview === 'trade' && study === 'stocknonauction')) { uri = `${config.api}/largeststocktradesnonauction`; }
		if (view === 'rankings' && subview === 'trade' && study === 'binaryoption') { uri = `${config.api}/largestbinaryoptiontrades`; }
		
		if ((view === 'rankings' && subview === 'volume' && !study) || (view === 'rankings' && subview === 'volume' && study === 'stockvolume')) { uri = `${config.api}/higheststockvolume`; }
		if (view==='rankings' && view==='rankings' && subview==='volume' && study==='optionvolume') { uri = `${config.api}/highestoptionvolume`; }
		if (view==='rankings' && view==='rankings' && subview==='volume' && study==='oddlot') { uri = `${config.api}/higheststockoddlotvolume`; }
		if (view==='rankings' && view==='rankings' && subview==='volume' && study==='stockturnover') { uri = `${config.api}/higheststockturnover`; }
		if (view==='rankings' && view==='rankings' && subview==='volume' && study==='optionturnover') { uri = `${config.api}/highestoptionturnover`; }
		if ((view==='rankings' && subview==='change' && !study) || (view==='rankings' && subview==='change' && study==='percentgain')) { uri = `${config.api}/higheststockchange`; }
		if (view==='rankings' && subview==='change' && study==='percentloss') { uri = `${config.api}/higheststockloss`; }
		if (view==='rankings' && subview==='change' && study==='marketcapgain') { uri = `${config.api}/highestmarketcapgain`; }
		if (view==='rankings' && subview==='change' && study==='marketcaploss') { uri = `${config.api}/highestmarketcaploss`; }
		if (!uri) { return; }
		let _list = await fetch(uri, { headers: Headers(token) }).then(d => d.json());
		if (_list && _list.error && _list.error === 'invalid token') { return _logout(token); }
		if (!_list || !_list.result) { return; }

		let isCurrentDate = _list.result && _list.result[0] && _list.result[0].EntryDateString && _list.result[0].EntryDateString === todaysDate() ? true : false;
		if (!isCurrentDate && setDelayedDataDateString) { setDelayedDataDateString(_list.result[0].EntryDateString); }

		setList(_list.result);
		// if (initialLoad) { setLoading(false); }
		setLoading(false);
	};
	useEffect(() => { loadMetrics(true); }, [view, subview, study]);
	
	return (
		<div className={`py-4 text-white w-full`}>

			<div className="flex md:flex-row gap-2 md:gap-5 justify-center items-start w-full">
				<div className="w-full sm:w-[320px] space-y-5">
					{loading ? <LOADING /> : <></>}
					{!loading && ((view==='rankings' && subview==='capital' && !study) || (view==='rankings' && subview==='capital' && study==='stocknotional')) && list.map((entry, index) => <TickerEntryUi_HighestStockNotional key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='capital' && study==='optionpremium' && list.map((entry, index) => <TickerEntryUi_HighestOptionPremium key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='capital' && study==='binaryoptionpremium' && list.map((entry, index) => <TickerEntryUi_HighestBinaryOptionPremium key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					
					{!loading && ((view === 'rankings' && subview === 'trade' && !study) || (view === 'rankings' && subview === 'trade' && study === 'stock')) && list.map((entry, index) => <TickerEntryUi_HighestStockTrades key={index} ref={observe} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view === 'rankings' && subview === 'trade' && study === 'stocknonauction' && list.map((entry, index) => <TickerEntryUi_HighestStockTradesNonAuction key={index} ref={observe} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{/* {!loading && ((view === 'rankings' && subview === 'trade' && !study) || (view === 'rankings' && subview === 'trade' && study === 'stocknonauction')) && list.map((entry, index) => <TickerEntryUi_HighestStockTradesNonAuction key={index} ref={observe} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)} */}
					
					{!loading && view === 'rankings' && subview === 'trade' && study === 'binaryoption' && list.map((entry, index) => <TickerEntryUi_HighestBinaryOptionTrades key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					
					{!loading && ((view === 'rankings' && subview === 'volume' && !study) || (view === 'rankings' && subview === 'volume' && study === 'stockvolume')) && list.map((entry, index) => <TickerEntryUi_HighestStockVolume key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='volume' && study==='optionvolume' && list.map((entry, index) => <TickerEntryUi_HighestOptionVolume key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='volume' && study==='oddlot' && list.map((entry, index) => <TickerEntryUi_HighestOddLot key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='volume' && study==='stockturnover' && list.map((entry, index) => <TickerEntryUi_HighestStockTurnover key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='volume' && study==='optionturnover' && list.map((entry, index) => <TickerEntryUi_HighestOptionTurnover key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='change' && study==='percentgain' && list.map((entry, index) => <TickerEntryUi_HighestPercentGain key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='change' && study==='percentloss' && list.map((entry, index) => <TickerEntryUi_HighestPercentLoss key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='change' && study==='marketcapgain' && list.map((entry, index) => <TickerEntryUi_HighestMarketCapGain key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
					{!loading && view==='rankings' && subview==='change' && study==='marketcaploss' && list.map((entry, index) => <TickerEntryUi_HighestMarketCapLoss key={index} item={entry} index={index} token={token} focusedTicker={focusedTicker} setFocusedTicker={setFocusedTicker} />)}
				</div>
				{!loading ?
					<div className="hidden sm:flex flex-grow w-full items-start justify-center">
					
						<div className="flex justify-center items-start w-full max-w-lg mb-4 sm:mb-6 lg:mb-5 mx-0">
							<MARKET_STATS marketStats={marketStats} loading={loading} />
						</div>

					</div>
				: <></>}
			</div>
		</div>
  );
};

export default React.memo(MAIN, dontRerender);