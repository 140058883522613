import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import config from "../config.json";
import Headers from "../bin/Headers";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import signedCurrency from "../bin/signedCurrency";
import LOADING from "../components/LOADING";
import { BackspaceIcon, CheckBadgeIcon } from "@heroicons/react/24/solid";
import abbrInt from '../bin/abbrInt';

const MAIN = (props) => {
	let token = props.token,
		ticker = props.ticker,
		high = props.high,
		low = props.low,
		volume = props.volume,
		overHighRange = useRef(null),
		overLowRange = useRef(null),
		[currentRange, setCurrentRange]= useState(null),
		// [currentVolume, setCurrentVolume]= useState(null),
		[ranges, setRanges]= useState(null),
		[volumes, setVolumes]= useState(null),
		[LoadingValue, setLoadingValue] = useState(true);

	const start = async () => {
		setLoadingValue(true);
		if (high && low) {
			setCurrentRange(truncateToTwoDigits(high - low));
		}

		let result = await fetch(`${config.api}/tickerstats?ticker=${ticker}`, { headers: Headers(token) }).then((d) => (d.status === 200 ? d.json() : null)).then(d => d && d.result ? d.result : null);
		if (result && result.Ranges) {
			setRanges(result.Ranges);
			if (result.Ranges.average) {
				overLowRange.current = high ? truncateToTwoDigits(high + (result.Ranges.average * -1)) : null;
				overHighRange.current = low ? truncateToTwoDigits(low + result.Ranges.average) : null;
			}
		}
		if (result && result.Volumes) {
			setVolumes(result.Volumes);
			if (result.Volumes.average) {
				overLowRange.current = high ? truncateToTwoDigits(high + (result.Ranges.average * -1)) : null;
				overHighRange.current = low ? truncateToTwoDigits(low + result.Ranges.average) : null;
			}
		}

		setLoadingValue(false);
	};
	
	useEffect(() => { start(); }, [high, low]);
	
  return (
		<div className="w-full h-full">
			{LoadingValue === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300"><LOADING color="text-green-500" /></div>}
			{ranges ? <div className="w-full flex flex-row flex-wrap items-center justify-evenly gap-2 p-2 bg-gradient-to-r from-sky-950 to-blue-800">
				<span className='w-28 rounded flex flex-col items-center justify-center bg-slate-900 text-slate-200 px-2 py-0.5'>
					<span className='text-xxs sm:text-xs font-medium'>Current Range</span>
					<span className='text-xxs sm:text-xs'>{ currentRange ? `${currentRange}` : `-` }</span>
				</span>
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.average <= currentRange ? 'bg-purple-950' : 'bg-sky-800'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Average Range</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.average <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.average}</span>
				</span>
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.lowest <= currentRange ? 'bg-purple-950' : 'bg-sky-800'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Lowest Range</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.lowest <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.lowest}</span>
				</span>
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.highest <= currentRange ? 'bg-purple-950' : 'bg-sky-800'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Highest Range</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.highest <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.highest}</span>
				</span>
				<span className='w-full flex flex-col gap-0.5'>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable90 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>90th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable90 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable90}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable90 ? truncateToTwoDigits(low + ranges.probable90) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable90 ? truncateToTwoDigits(high + (ranges.probable90 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable80 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>80th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable80 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable80}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable80 ? truncateToTwoDigits(low + ranges.probable80) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable80 ? truncateToTwoDigits(high + (ranges.probable80 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable70 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>70th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable70 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable70}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable70 ? truncateToTwoDigits(low + ranges.probable70) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable70 ? truncateToTwoDigits(high + (ranges.probable70 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable60 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>60th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable60 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable60}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable60 ? truncateToTwoDigits(low + ranges.probable60) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable60 ? truncateToTwoDigits(high + (ranges.probable60 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable50 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>50th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable50 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable50}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable50 ? truncateToTwoDigits(low + ranges.probable50) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable50 ? truncateToTwoDigits(high + (ranges.probable50 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable40 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>40th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable40 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable40}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable40 ? truncateToTwoDigits(low + ranges.probable40) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable40 ? truncateToTwoDigits(high + (ranges.probable40 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable30 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>30th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable30 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable30}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable30 ? truncateToTwoDigits(low + ranges.probable30) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable30 ? truncateToTwoDigits(high + (ranges.probable30 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable20 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>20th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable20 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable20}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable20 ? truncateToTwoDigits(low + ranges.probable20) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable20 ? truncateToTwoDigits(high + (ranges.probable20 * -1)) : '-'}</span></span>
					</span>
					<span className={`w-full rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${currentRange && ranges.probable10 <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>10th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{currentRange && ranges.probable10 <= currentRange ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} ${ranges.probable10}</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-emerald-400 gap-1'><span>Projected High from Current Low:</span><span>${low && ranges.probable10 ? truncateToTwoDigits(low + ranges.probable10) : '-'}</span></span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-between text-rose-400 gap-1'><span>Projected Low from Current High:</span><span>${high && ranges.probable10 ? truncateToTwoDigits(high + (ranges.probable10 * -1)) : '-'}</span></span>
					</span>
				</span>
			</div> : <></>}
			{volumes ? <div className="mt-5 w-full flex flex-row flex-wrap items-center justify-evenly gap-2 p-2 bg-gradient-to-r from-sky-950 to-blue-800">
				{volume? <span className='w-28 rounded flex flex-col items-center justify-center bg-slate-900 text-slate-200 px-2 py-0.5'>
					<span className='text-xxs sm:text-xs font-medium'>Current Volume</span>
					<span className='text-xxs sm:text-xs'>{ abbrInt(volume, false) }</span>
				</span> : <></>}
				<span className={`w-28 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.average <= currentRange ? 'bg-purple-950' : 'bg-purple-700'}`}>
					<span className='text-xxs sm:text-xs font-medium'>Average Volume</span>
					<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.average <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.average, false)}</span>
				</span>
				<span className='w-full flex flex-row flex-wrap items-center justify-center gap-0.5'>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable90 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>90th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable90 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable90, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable80 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>80th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable80 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable80, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable70 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>70th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable70 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable70, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable60 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>60th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable60 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable60, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable50 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>50th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable50 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable50, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable40 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>40th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable40 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable40, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable30 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>30th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable30 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable30, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable20 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>20th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable20 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable20, false)}</span>
					</span>
					<span className={`w-22 rounded flex flex-col items-center justify-center text-purple-200 px-2 py-0.5 ${volume && volumes.probable10 <= volume ? 'bg-purple-950' : 'bg-purple-700'}`}>
						<span className='text-xxs sm:text-xs font-medium'>10th Percentile</span>
						<span className='text-xxs sm:text-xs w-full flex flex-row items-center justify-center gap-1'>{volume && volumes.probable10 <= volume ? <span className='text-green-500'><CheckBadgeIcon className='w-3' /></span> : <></>} {abbrInt(volumes.probable10, false)}</span>
					</span>
				</span>
			</div> : <></>}
		</div>
  );
}
export default MAIN;