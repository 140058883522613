import config from '../config.json';

const main = async (action) => {
	return new Promise((resolve, reject) => {
		window.grecaptcha.enterprise.ready(async () => {
			let captcha = await window.grecaptcha.enterprise.execute(config.googleCaptcha, { action });
			resolve(captcha);
		});
	});
};

export default main;