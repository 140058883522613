import React, { useState, useEffect } from 'react';
import config from '../config.json';
import Headers from "../bin/Headers";
import scrollToTop from "../bin/scrollToTop";
import pTickerToBrokerTicker from "../bin/pTickerToBrokerTicker";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import LOADING from "../components/LOADING";
import { Link, useNavigate } from "react-router-dom";
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import abbrInt from '../bin/abbrInt';

const Main = ({ item, index, token, focusedTicker, setFocusedTicker }) => {
	const navigate = useNavigate();

	const focusOnTicker = (_ticker) => {
		return navigate(`/_/${_ticker}`);
		
		//on mobile, redirect to the ticker page
		let mobileScreen = (window.innerWidth < 640) ? true : false;
		if (mobileScreen || focusedTicker===_ticker){
			//redirect to the ticker page
			return navigate(`/_/${_ticker}`);
		}
		setFocusedTicker(_ticker);
		scrollToTop();
	};

	let {Strike, Type, ExpirationDateString} = pTickerToBrokerTicker(item.Ticker, true);
	let subtleColor = 'neutral';
	if ((Type === 'PUT' && item.TradeSide === 'SELL') || (Type === 'CALL' && item.TradeSide === 'BUY')) { subtleColor = 'positive'; }
	if ((Type === 'PUT' && item.TradeSide === 'BUY') || (Type === 'CALL' && item.TradeSide === 'SELL')) { subtleColor = 'negative'; }

	return (
		<div key={index} className={`w-full h-12 px-2 py-2 ${focusedTicker===item.UnderlyingTicker ? 'bg-gray-950' : ''}`}>
			<button onClick={()=>focusOnTicker(item.UnderlyingTicker)} className={`flex flex-row items-start w-full relative`}>
				<span className='text-amber-500 text-[8px] absolute -top-2 -left-2 -rotate-12'>#{index+1}</span>
				<span className={`text-sm font-bold w-[60px] text-gray-100 flex flex-col justify-start items-start`}>
					<span>{item.UnderlyingTicker ? item.UnderlyingTicker : '-'}</span>
					{Strike && Type ? <span className='text-xxs ml-1 font-light text-gray-400'>${Strike}<span className={`text-${Type==='PUT' ? 'rose' : (Type==='CALL' ? 'emerald' : 'gray')}-400 ml-0.5`}>{ Type }</span></span> : <></>}
				</span>
				
				<div className="flex flex-grow flex-col gap-1 justify-center items-center w-[170px]">
					{item.Size && item.Price ? <span className={`text-xxs leading-tight font-light text-gray-400`}>{(item.Size).toLocaleString()}<span className='mx-1 text-sky-400'>@</span>${truncateToTwoDigits(item.Price)}</span> : <></>}
					{item.hasOwnProperty('DaysToExpiration') ? <span className='text-xs ml-1 font-light text-gray-400'>{item.DaysToExpiration}<span className='text-rose-400 ml-0.5'>dte</span></span> : <></>}
				</div>
					
				<div className='w-[100px] flex flex-col gap-1 items-end justify-end'>
					{item.Notional ? <span className={`w-[60px] h-[20px] inline-flex justify-center items-center rounded-sm px-1.5 py-0 text-xxs leading-tight font-medium ${subtleColor==='positive' ? 'text-green-800 bg-green-300' : ''}${subtleColor==='negative' ? 'text-red-800 bg-red-300' : ''}${subtleColor==='neutral' ? 'text-gray-800 bg-gray-300' : ''}`}>${abbrInt(item.Notional, false)}</span> : <></>}		
					{/* {item.TradeSide==='BUY' ? <span className={`text-xxs leading-tight font-light text-emerald-400 mr-1`}>buy side</span> : (item.TradeSide==='SELL' ? <span className={`text-xxs leading-tight font-light text-rose-400 mr-1`}>sell side</span> : <></>)} */}
					{item.TradeSide === 'BUY' || item.TradeSide === 'SELL' ? <span className={`text-xxs leading-tight font-light ${subtleColor === 'positive' ? 'text-emerald-400' : (subtleColor === 'negative' ? 'text-rose-400' : 'text-gray-400')} mr-1`}>{item.TradeSide === 'BUY' ? 'buy side' : (item.TradeSide === 'SELL' ? 'sell side' : '')}</span> : <></>}
				</div>
			</button>
		</div>
  );
};

export default Main;