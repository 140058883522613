const main = (floatValue, truncateToDigits=2)=>{
	// Convert the float value to a string
  let stringValue = floatValue.toString();

  // Find the index of the decimal point
  let decimalIndex = stringValue.indexOf('.');

  // If the decimal point is not found, just return the original value
  if (decimalIndex === -1) {
    // return floatValue;
    return `${floatValue}.00`;
  }

  // Extract the integer part and the first two decimal places
  // let truncatedValue = stringValue.substring(0, decimalIndex + 3);
  let truncatedValue = stringValue.substring(0, decimalIndex + 1 + truncateToDigits);

  // Convert the result back to a float
  return parseFloat(truncatedValue);
};
export default main;