import React, {useEffect, useState} from 'react';
import config from "../config.json";
import { useSelector } from 'react-redux';
import Headers from "../bin/Headers";
import dontRerender from "../bin/dontRerender";
import ICONS from '../bin/ICONS';
import decodeJwt from "../bin/decodeJwt";
import abbrInt from "../bin/abbrInt";
import epochConverterToEST from "../bin/epochConverterToEST";
import unauthorizedLevel from "../bin/unauthorizedLevel";

const Main = ()=>{
  const token= useSelector(state => state.token);
  let [list, setList] = useState([]),
		[updated, setUpdated] = useState(null),
		[updateText, setUpdateText] = useState(null),
		[LoadingValue, setLoadingValue] = useState(true),
		[sorting, setSorting] = useState('capital'),	
		decodedToken = decodeJwt(token);

  const readData = async () => {
    setLoadingValue(true);
    let data = await fetch(`${config.api}/robinhood24hrmarketstats`, {headers:Headers(token)}).then(d => d.json());
		if (!data || !data.data || !data.data.length) { setLoadingValue(false); return; }
		if (data && data.updated) {
			let _date = epochConverterToEST(data.updated);
			//format date yyyy-mm-dd hh:mm:ss.ms
			_date = `${_date.Year}-${_date.Month}-${_date.Date} ${_date.Hour}:${_date.Minute}:${_date.Second}.${_date.Millisecond}`;
			setUpdated(_date);
		}
		if (data && data.data && data.data.length) {
			if (sorting === 'volume') {
				data = data.data.sort((a, b) => Number((b.Volume).replace(/\D/g, '')) - Number((a.Volume).replace(/\D/g, '')));
			} else if (sorting === 'capital') {
				data = data.data.sort((a, b) => Number((b.Value).replace(/\D/g, '')) - Number((a.Value).replace(/\D/g, '')));
			} else if (sorting === 'changeup') {
				// data = data.data.filter(a => a.Change && isNaN(a.Change)===false && isNaN(extractDecimalNumber(a.Change))===false).sort((a, b) => extractDecimalNumber(b.Change) - extractDecimalNumber(a.Change));
				data = data.data.filter(a => a.Change && !isNaN(a.Change)).sort((a, b) => extractDecimalNumber(b.Change) - extractDecimalNumber(a.Change));
			}
			data = data.map((entry, idx) => {
				entry.rank = idx + 1;
				return entry;
			}
			);
		}
		data = data.filter(a => a.Ticker && a.Ticker!==null && a.Ticker!==undefined && a.Ticker!=='' && a.Ticker!=='null' && a.Ticker!=='undefined');
		// data.length = data.length > 50 ? 50 : data.length;
		setList(data);
		
		let gmeSpot = data.find(a => a.Ticker === 'GME');
		if (gmeSpot) {
			//build the updateText
			let _updateText = `Update\n\n`;
			//read the last price
			let gmeLast = data.find(a => a.Ticker === 'GME');
			//read the volume rank
			let sortedByVolume = data.sort((a, b) => Number((b.Volume).replace(/\D/g, '')) - Number((a.Volume).replace(/\D/g, '')));
			let gmeVolumeRank = sortedByVolume.findIndex(a => a.Ticker === 'GME') + 1;
			_updateText += `#${gmeVolumeRank} in volume (${abbrInt(Number((gmeLast.Volume).replace(/\D/g, '')))})\n`;
			//read the capital rank
			let sortedByCapital = data.sort((a, b) => Number((b.Value).replace(/\D/g, '')) - Number((a.Value).replace(/\D/g, '')));
			let gmeCapitalRank = sortedByCapital.findIndex(a => a.Ticker === 'GME') + 1;
			_updateText += `#${gmeCapitalRank} in capital ($${abbrInt(Number((gmeLast.Value).replace(/\D/g, '')))})\n`;
			//read the change rank
			// let sortedByChange = data.filter(a => a.Change && !isNaN(a.Change)).sort((a, b) => extractDecimalNumber(b.Change) - extractDecimalNumber(a.Change));
			// let gmeChangeRank = sortedByChange.findIndex(a => a.Ticker === 'GME') + 1;
			// _updateText += `#${gmeChangeRank} in change\n`;
			_updateText += `Last: ${gmeLast.Last} (${gmeLast.Change})`;
			setUpdateText(_updateText);
		}
    setLoadingValue(false);
  };

	const start = async () => { await readData(); return; };

	const resort = async (key) => {
		if (sorting === key) { return; }
		setSorting(key);
		let sortedList = list.sort((a, b) => {
			if (key === 'volume') {
				let bVolume = Number(b.Volume.replace(/\D/g, '')),
					aVolume = Number(a.Volume.replace(/\D/g, ''));
				return bVolume - aVolume;
			} else if (key === 'capital') {
				let aCapital = Number(a.Value.replace(/\D/g, '')),
					bCapital = Number(b.Value.replace(/\D/g, ''));
				return bCapital - aCapital;
			} else if (key === 'changeup') {
				let aChange = extractDecimalNumber(a.Change),
					bChange = extractDecimalNumber(b.Change);
				return bChange - aChange;
			} else if (key === 'changedown') {
				let aChange = extractDecimalNumber(a.Change),
					bChange = extractDecimalNumber(b.Change);
				return aChange - bChange;
			}
		}).map((entry, idx) => {
			entry.rank = idx + 1;
			return entry;
		});
		// sortedList.length = sortedList.length > 50 ? 50 : sortedList.length;
		setList([...sortedList]);
	}

	function extractDecimalNumber(str) {
    // Use regex to remove any characters that are not digits, decimal points, or negative signs
		// Keep negative sign only if it's at the beginning of the number
		if (!str || str === null || str === undefined || str === '' || str === 'null' || str === 'undefined') { return 0; }
    let cleanedStr = str.replace(/[^\d.-]+/g, '');
    let validNumber = cleanedStr.match(/-?\d*\.?\d+/);
    return validNumber ? parseFloat(validNumber[0]) : 0;
	}

	useEffect(() => {
		if (!token || !decodedToken || !decodedToken.level || unauthorizedLevel(decodedToken.level, 'diamond')) {
			window.location.href = '/notfound';
			return;
		}
		start();
	}, []);

  return (
    <main className="py-4 sm:py-6 lg:py-8 flex items-center justify-center">

			{LoadingValue === false && 
				<div className="w-full flex flex-col items-center justify-center">
					<table className="mt-10 divide-y divide-gray-200 w-full max-w-lg self-center text-xs overflow-x-auto">
						
						<thead>
							<tr className="bg-gray-800 text-gray-200 bg-opacity-50 text-sm text-left">
								<th scope="col" className="p-1 font-medium uppercase tracking-wider w-16 text-xs sm:text-sm">
									<span className='inline sm:hidden'>#</span><span className='hidden sm:inline'>Rank</span>
								</th>
								<th scope="col" className="p-1 font-medium uppercase tracking-wider text-xs sm:text-sm">Ticker</th>
								<th scope="col" onClick={() => { resort(`volume`); }} className={`hover:cursor-pointer p-1 font-medium uppercase tracking-wider text-xs sm:text-sm ${sorting==='volume' ? 'cursor-pointer hover:bg-indigo-700' : ''}`}>
									<span className='inline sm:hidden'>Vol.</span><span className='hover:cursor-pointer hidden sm:inline'>Volume</span> {sorting === 'volume' ? <ICONS.ChevronDownIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>}
								</th>
								<th scope="col" onClick={() => { resort('capital'); }} className={`hover:cursor-pointer p-1 font-medium uppercase tracking-wider text-xs sm:text-sm ${sorting==='capital' ? 'cursor-pointer hover:bg-indigo-700' : ''}`}>
									<span className='inline sm:hidden'>Cap.$</span><span className='hover:cursor-pointer hidden sm:inline'>Capital</span> {sorting === 'capital' ? <ICONS.ChevronDownIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>}
								</th>
								<th scope="col" className="p-1 font-medium uppercase tracking-wider lg:table-cell text-xs sm:text-sm text-right">Last</th>
								<th scope="col" onClick={() => { if (sorting === 'changeup') { resort('changedown'); } else { resort('changeup'); } }} className={`hover:cursor-pointer p-1 font-medium uppercase tracking-wider text-xs sm:text-sm text-right ${sorting==='changeup' || sorting==='changedown' ? 'cursor-pointer hover:bg-indigo-700' : ''}`}>
								{/* <th scope="col" className="p-1 font-medium uppercase tracking-wider lg:table-cell text-xs sm:text-sm text-right">Change</th> */}
									{/* <span className='inline sm:hidden'>+/- %</span><span className='hover:cursor-pointer hidden sm:inline text-right'>Change</span> {sorting === 'changeup' ? <ICONS.ChevronDownIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : (sorting === 'changedown' ? <ICONS.ChevronUpIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>)} */}
									<span className='inline sm:hidden'>+/- %</span><span className='hover:cursor-pointer hidden sm:inline text-right'>Change</span> {sorting === 'changeup' ? <ICONS.ChevronDownIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>}{sorting === 'changedown' ? <ICONS.ChevronUpIcon className='ml-1 text-amber-500 h-4 w-4 inline' /> : <></>}
								</th>
							</tr>
						</thead>
						
						<tbody>
							{ (!list || !list.length) &&
								<tr><th scope="col" colSpan="6" className="py-8 font-light text-center text-gray-300 text-sm">no data</th></tr>
							}
							{ list && list.length>0 &&
								(list).map((entry, idx) => {
									if (idx > 49) { return; }
									return (<tr key={idx} className={`bg-opacity-25 text-left ${entry.Change && (entry.Change[0] === '+') ? 'bg-emerald-800' : (entry.Change && (entry.Change[0] === '-' ? 'bg-rose-800' : (idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800')))} text-sm text-gray-300`}>
										<td className="p-1 font-light text-amber-500 w-16">#{entry.rank}</td>
										<td className="p-1 font-light">
											<span className={`cursor-pointer hover:bg-indigo-800 w-full block lg:hidden ${entry.Ticker === 'GME' ? 'bg-amber-300/30' : ''}`}>{entry.Ticker}</span>
											<span className='hidden lg:block'>{entry.Ticker}</span>
										</td>
										<td className={`p-1 font-light ${sorting === 'volume' ? 'text-sm' : 'text-xxs'} sm:text-sm`}>{entry.Volume && sorting === 'volume' ? entry.Volume : abbrInt((entry.Volume).replace(/\D/g, ''), false)}</td>
										{/* <td className="p-1 font-light">{entry.Value && sorting === 'capital' ? entry.Value : `$${abbrInt((entry.Value).replace(/\D/g,), false)}`}</td> */}
										<td className={`p-1 font-light ${sorting === 'capital' ? 'text-sm' : 'text-xxs'} sm:text-sm`}>{entry.Value && sorting === 'capital' ? entry.Value : `$${abbrInt((entry.Value).replace(/\D/g, ''), false)}`}</td>
										<td className="p-1 font-light text-xs sm:text-sm text-right">{entry.Last}</td>
										<td className="p-1 font-bold text-xs sm:text-sm text-right">{entry.Change}</td>
									</tr>);
								})
							}
						</tbody>
					</table>
					{/* create onClick for textarea that selects the entire text and copys it to clipboard */}
					<textarea className="mt-10 w-full text-left" defaultValue={updateText} onClick={(evt) => {
						evt.target.select();
						document.execCommand('copy');
						evt.target.setSelectionRange(0, 0);
					}}></textarea>
					{/* position Last Updated to be statically fixed at the bottom and full width */}
					<div className="fixed w-full bottom-0 bg-rose-950 px-2 pt-1 pb-5 text-center text-xs text-gray-300">Last Updated: <span className='text-amber-400'>{updated}</span></div>
				</div>
      }

    </main>
  );
};

export default React.memo(Main, dontRerender);