import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import todaysDate from "../bin/todaysDate";
import decodeJwt from "../bin/decodeJwt";
import dontRerender from "../bin/dontRerender";
import abbrInt from "../bin/abbrInt";
import Headers from "../bin/Headers";
import config from "../config.json";
import LOADING from "../components/LOADING";
import NOTABLE_TRADE_MODAL from "../components/NOTABLE_TRADE_MODAL";
import { BanknotesIcon, Square3Stack3DIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const MAIN= (props)=>{
	let token = useSelector(state => state.token),
		ticker = props.ticker,
		indexDate = props.indexDate,
		trades = props.trades,
		[delayedData, setDelayedData] = useState(false),
		[showTradeInfo, updateShowTradeInfo]= useState(false),
		[tradeDetail, updateTradeDetail]= useState(null);

	const tickerEntryUi = (item, index) => {
		if (!item || !item.UnderlyingTicker || !item.OptionType || !item.OptionStrike || !item.ExpirationDateString || !item.Size || !item.Price || !item.Notional || !item.EntryTimeString) { return; }
		let bgColor = '';
		if (item.TradeSide) {
			if ((item.TradeSide === 'BUY' && item.OptionType==='CALL') || (item.TradeSide === 'SELL' && item.OptionType==='PUT')) { bgColor = 'bg-emerald-950/50'; }
			else if ((item.TradeSide === 'SELL' && item.OptionType==='CALL') || (item.TradeSide === 'BUY' && item.OptionType==='PUT')) { bgColor = 'bg-rose-950/50'; }
			// else if (item.TradeSide === 'BUY' && !item.TradeSidePrecise) { bgColor = 'bg-emerald-950/50'; }
			// else if (item.TradeSide === 'SELL' && !item.TradeSidePrecise) { bgColor = 'bg-rose-950/50'; }
		}
		return (
			<button key={index} className={`flex flex-row w-full text-xxs sm:text-xs text-gray-200 ${bgColor}`} onClick={() => { updateTradeDetail(item); updateShowTradeInfo(true); } }>
				<span className="p-1 flex items-center justify-center font-light w-12">#{index + 1}</span>
				<span className={`hidden p-1 items-center justify-center w-20 ${!ticker ? 'sm:flex' : ''}`}>{item.UnderlyingTicker}</span>
				{/* <span className={`hidden sm:flex p-1 items-center justify-center w-24 ${item.Type==='CALL' ? 'bg-emerald-800' : ''}${item.Type==='PUT' ? 'bg-rose-800' : ''}`}>{item.Type}</span> */}
				<span className={`hidden sm:flex p-1 items-center justify-center w-24`}>{item.OptionType}</span>
				<span className="hidden sm:flex p-1 items-center justify-center w-20">${item.OptionStrike ? (item.OptionStrike).toLocaleString() : '-'}</span>
				<span className={`hidden p-1 items-center justify-center w-32 sm:flex`}>{item.ExpirationDateString}</span>
				<span className="p-1 flex items-center justify-start sm:justify-center flex-grow">
					<span className='hidden sm:block'>{item.Size ? (item.Size).toLocaleString() : '-'} @ ${item.Price ? (item.Price).toLocaleString() : '-'}{ item.TradeSidePrecise ? <span className='text-xxxs mx-1 px-1 bg-amber-500 text-amber-950 rounded'>precise</span> : `` }</span>
					<span className='flex flex-col w-full items-start justify-start sm:hidden'>

						<span className='flex flex-row w-full items-start justify-start gap-1'>
							<span className={`text-left font-bold ${!ticker ? 'inline' : 'hidden'} w-14`}>{item.UnderlyingTicker}</span>
							{/* <span className={`${item.Type === 'CALL' ? 'bg-emerald-800' : ''}${item.Type === 'PUT' ? 'bg-rose-800' : ''} w-10`}>{item.Type}</span> */}
							<span className={`w-10`}>{item.OptionType}</span>
							<span className='text-amber-600 text-center flex-grow'>${item.OptionStrike ? (Number((item.OptionStrike).toFixed(2)).toFixed(2).toLocaleString()) : '-'}</span>
							{/* <span className='text-green-600 text-right pl-1 text-xxs'>{item.Binary ? 'binary' : ''}</span> */}
						</span>
						<span className='flex flex-row w-full items-start justify-between gap-1'>
							<span className="inline-block text-left w-full">{item.Size ? (item.Size).toLocaleString() : '-'} @ ${item.Price ? ((Number((item.Price).toFixed(2))).toFixed(2).toLocaleString()) : '-'}{ item.TradeSidePrecise ? <span className='text-xxxs mx-1 px-1 bg-amber-500 text-amber-950 rounded'>precise</span> : `` }</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Premium:</span>
								<span className="ml-1 font-light w-100 text-center text-amber-300">${abbrInt(item.Notional)}</span>
							</span>
						</span>
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className="inline-block text-left w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Expires:</span>
								<span className="ml-1 font-light w-100 text-center text-rose-400">{item.ExpirationDateString}</span>
							</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Time:</span>
								<span className="ml-1 font-light w-100 text-center text-gray-400">{item.EntryTimeString}</span>
							</span>
						</span>
					</span>
				</span>
				<span className="hidden sm:flex p-1 items-center justify-center w-32">${abbrInt(item.Notional, false)}</span>
				{/* <span className="hidden sm:flex p-1 items-center justify-center w-20">{item.Binary ? <CheckIcon className='w-5 h-5 text-green-500' /> : ''}</span> */}
				<span className="hidden sm:flex p-1 items-center justify-end text-xxs w-24">{item.EntryTimeString}</span>
			</button>
		);
	};

	const checkIfDelayedData = () => {
		if (!token) { return; }
		//check the level if red
		let { level } = decodeJwt(token);
		if (level==='red' && todaysDate()!==indexDate) {
			setDelayedData(true);
		}	
	};

	useEffect(()=>{
		checkIfDelayedData();
	}, [trades]);

  return (
		<div className="w-full flex flex-col rounded-tr-md rounded-md border bg-gray-300 text-black">
						
			<div className="col-span-2 flex flex-grow flex-col rounded-br-md rounded-bl-md border bg-gray-700">
				{delayedData ?
					<span className='flex flex-row items-center justify-start px-1 py-0.5 bg-gray-300 w-full'>
						<Link to="/pricing" className='flex flex-row items-start justify-start'>
							{indexDate ? <span className='text-xs text-rose-600'>Trades from {indexDate}</span> : <></>}
							<span className='text-xs text-rose-200 bg-rose-600 rounded px-2 ml-2'>delayed data</span>
						</Link>
					</span>
					: <></>}
				
				{(!trades || !trades.length) ? <h6 className='my-5 text-white text-center'>No Notable Option Trades</h6> : <></>}
				{trades && trades.length ?
					<div className="h-full w-full">
						<div className='flex flex-row w-full text-xxs sm:text-xs text-gray-200'>
							<span className="p-1 flex items-center justify-center border uppercase font-medium tracking-wider w-12">Rank</span>
							<span className={`hidden p-1 items-center justify-center border font-medium uppercase tracking-wider w-20 ${!ticker ? 'sm:flex' : ''}`}>Ticker</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-24">Type</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-20">Strike</span>
							<span className={`hidden p-1 items-center justify-center border font-medium uppercase tracking-wider w-32 sm:flex`}>Expiration</span>
							<span className="p-1 flex items-center justify-center border font-medium uppercase tracking-wider flex-grow">Trade</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-32">Value</span>
							{/* <span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-20">Binary</span> */}
							<span className="hidden sm:flex p-1 items-center justify-end border font-medium uppercase tracking-wider w-24">Time</span>
						</div>
						
						{trades.map((item, index) => tickerEntryUi(item, index))}
					</div>
					: <></>
				}
			</div>

			<NOTABLE_TRADE_MODAL
        open={ showTradeInfo }
				setOpen={() => { updateShowTradeInfo(false); updateTradeDetail(null); }}
				audit={true}
        data={ tradeDetail } />
		</div>
  );
};

// export default MAIN;
export default React.memo(MAIN, dontRerender);