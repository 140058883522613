import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import dontRerender from "../bin/dontRerender";
import abbrInt from "../bin/abbrInt";
import LOADING from "../components/LOADING";
import { BanknotesIcon, Square3Stack3DIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const MAIN= (props)=>{
	let marketStats = props.marketStats,
	lastTimestamp = props.lastTimestamp,
	loading = props.loading;

	const outputHighestOptionSide = (stats) => {
		let highestSide = 'hedge';
		let binaryCallPercent = Math.abs(Math.trunc((Number(stats.OptionBinaryCallPremium) / Number(stats.OptionPremium)) * 100));
		let binaryPutPercent = Math.abs(Math.trunc((Number(stats.OptionBinaryPutPremium) / Number(stats.OptionPremium)) * 100));
		let nonBinaryPercent = 100 - binaryCallPercent - binaryPutPercent;
		let nonBinaryTotal = Number(stats.OptionPremium) - (Number(stats.OptionBinaryCallPremium) + Number(stats.OptionBinaryPutPremium));
		if (Number(nonBinaryTotal) > Number(stats.OptionBinaryCallPremium) && Number(nonBinaryTotal) > Number(stats.OptionBinaryPutPremium)) {
			highestSide = 'hedge';
		}else if (Number(stats.OptionBinaryCallPremium) > Number(nonBinaryTotal) && Number(stats.OptionBinaryCallPremium) > Number(stats.OptionBinaryPutPremium)) {
			highestSide = 'calls';
		}else if (Number(stats.OptionBinaryPutPremium) > Number(nonBinaryTotal) && Number(stats.OptionBinaryPutPremium) > Number(stats.OptionBinaryCallPremium)) {
			highestSide = 'puts';
		}

		return <span className='grid grid-cols-3 w-full text-xxs h-13'>
			<span className='col-span-3 sm:col-span-3 sm:order-last inline-flex justify-between w-full items-center'>
				<span className='flex flex-row justify-between w-full text-xxs h-13 border'>
					{binaryCallPercent && isNaN(binaryCallPercent) === false && Number(binaryCallPercent) > 0 ? <span style={{ width: `${binaryCallPercent}%` }} className={`bg-emerald-700 flex items-center justify-start text-white pl-2`}>{binaryCallPercent}%</span> : <></>}
					{nonBinaryPercent && isNaN(nonBinaryPercent) === false && Number(nonBinaryPercent) > 0 ? <span style={{ width: `${nonBinaryPercent}%` }} className={`bg-sky-700 flex items-center justify-center text-white `}>{nonBinaryPercent}%</span> : <></>}
					{binaryPutPercent && isNaN(binaryPutPercent) === false && Number(binaryPutPercent) > 0 ? <span style={{ width: `${binaryPutPercent}%` }} className={`bg-red-700 flex items-center justify-end text-white pr-2`}>{binaryPutPercent}%</span> : <></>}
				</span>
			</span>
			<div className="col-span-3 sm:col-span-3 h-full w-full text-white p-2 gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
				<span className='text-xxs w-[80px] sm:text-xs flex flex-col items-center justify-center text-green-300'><span>Binary Calls</span><span className='text-green-300'>{stats.OptionBinaryCallPremium ? `$${abbrInt(stats.OptionBinaryCallPremium, false)}` : '' }</span></span>
				<span className='text-xxs w-[80px] sm:text-xs flex flex-col items-center justify-center text-sky-300'><span>Hedging</span><span className='text-sky-300'>{nonBinaryTotal ? `$${abbrInt(nonBinaryTotal, false)}` : ``}</span></span>
				<span className='text-xxs w-[80px] sm:text-xs flex flex-col items-center justify-center text-red-300'><span>Binary Puts</span><span className='text-red-300'>{stats.OptionBinaryPutPremium ? `$${abbrInt(stats.OptionBinaryPutPremium, false)}` : ``}</span></span>
			</div>
		</span>;
	};
	
	const outputStockTradeSentimentSide = (stats) => {
		let bullishPercent = Math.abs(Math.trunc((Number(stats.StockNotionalBullish) / Number(stats.StockNotional)) * 100));
		let bearishPercent = Math.abs(Math.trunc((Number(stats.StockNotionalBearish) / Number(stats.StockNotional)) * 100));
		let neutralPercent = 100 - bullishPercent - bearishPercent;

		return <span className='grid grid-cols-3 w-full text-xxs h-13'>
			<span className='col-span-3 sm:col-span-3 sm:order-last inline-flex justify-between w-full items-center'>
				<span className='flex flex-row justify-between w-full text-xxs h-13 border'>
					{bullishPercent && isNaN(bullishPercent) === false && Number(bullishPercent) > 0 ? <span style={{ width: `${bullishPercent}%` }} className={`bg-emerald-700 flex items-center justify-start text-white pl-2`}>{bullishPercent > 10 ? `${bullishPercent}% - $${abbrInt(stats.StockNotionalBullish, false)}` : ``}</span> : <></>}
					{neutralPercent && isNaN(neutralPercent) === false && Number(neutralPercent) > 0 ? <span style={{ width: `${neutralPercent}%` }} className={`bg-gray-700 flex items-center justify-center text-white `}>{neutralPercent > 10 ? `${neutralPercent}%` : ``}</span> : <></>}
					{bearishPercent && isNaN(bearishPercent) === false && Number(bearishPercent) > 0 ? <span style={{ width: `${bearishPercent}%` }} className={`bg-red-700 flex items-center justify-end text-white pr-2`}>{bearishPercent > 10 ? `$${abbrInt(stats.StockNotionalBearish, false)} - ${bearishPercent}%` : ``}</span> : <></>}
				</span>
			</span>
		</span>;
	};
	
  return (
		<div className="w-full flex flex-col rounded-tr-md rounded-md border bg-gray-300 text-black">
			
			<div className="flex items-center justify-between">
				<h3 className="flex-grow flex items-center justify-start m-1 bg-transparent rounded-tl-md rounded-tr-md border-gray-300">Market Overview</h3>
				<span className='flex items-end justify-end m-1 w-10 text-amber-800 text-xxs'>{lastTimestamp ? `${lastTimestamp}` : ``}</span>
			</div>
			
			<div className="col-span-2 flex flex-grow flex-col rounded-br-md rounded-bl-md border bg-gray-700">
				{loading ? <div className='h-48 flex items-center justify-center'><LOADING color="text-green-500" /></div> : <></>}
				{!loading && !marketStats ? <h6 className='my-5 text-gray-400 text-center'>No Market Stats</h6> : <></>}
				{!loading && marketStats && marketStats.StockNotional && marketStats.StockNotionalBullish && marketStats.StockNotionalBearish ?
					<span className='flex flex-row items-start justify-end whitespace-nowrap text-gray-400 truncate w-full'>
						{outputStockTradeSentimentSide(marketStats)}
					</span>
					: <></>}
				{!loading && marketStats ?
					<div className="h-full w-full text-white p-2 gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
						<span className='text-xxs w-[80px] sm:text-xs sm:w-[100px] flex flex-col items-center justify-center'><span>Stock Trades</span><span className='text-amber-400'>{abbrInt(marketStats.StockTrades, false)}</span></span>
						<span className='text-xxs w-[80px] sm:text-xs sm:w-[100px] flex flex-col items-center justify-center'><span>Stock Volume</span><span className='text-amber-400'>{abbrInt(marketStats.StockVolume, false)}</span></span>
						<span className='text-xxs w-[80px] sm:text-xs sm:w-[100px] flex flex-col items-center justify-center'><span>Stock Notional</span><span className='text-amber-400'>${abbrInt(marketStats.StockNotional, false)}</span></span>
					</div>
					: <></>
				}
				{/* {!loading && marketStats && marketStatus!=="PRE" ? */}
				{!loading && marketStats && (marketStats.OptionTrades || marketStats.OptionVolume || marketStats.OptionPremium) ?
					<div className="h-full w-full text-white p-2 gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
						<span className='text-xxs w-[80px] sm:text-xs sm:w-[100px] flex flex-col items-center justify-center'><span>Option Trades</span><span className='text-amber-400'>{abbrInt(marketStats.OptionTrades, false)}</span></span>
						<span className='text-xxs w-[80px] sm:text-xs sm:w-[100px] flex flex-col items-center justify-center'><span>Option Volume</span><span className='text-amber-400'>{abbrInt(marketStats.OptionVolume, false)}</span></span>
						<span className='text-xxs w-[80px] sm:text-xs sm:w-[100px] flex flex-col items-center justify-center'><span>Option Premium</span><span className='text-amber-400'>{marketStats.OptionPremium ? `$${abbrInt(marketStats.OptionPremium, false)}` : ``}</span></span>
					</div>
					: <></>
				}
				{/* {!loading && marketStats && marketStatus!=="PRE" ? */}
				{!loading && marketStats && (marketStats.OptionBinaryCallPremium || marketStats.OptionBinaryPutPremium || marketStats.OptionPremium) ?
					<span className='flex flex-row items-start justify-end whitespace-nowrap text-gray-400 truncate w-full'>
						{outputHighestOptionSide(marketStats)}
					</span>
					: <></>}
			</div>

		</div>
  );
};

export default React.memo(MAIN, dontRerender);