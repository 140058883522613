import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useSelector } from 'react-redux';
import { Link} from "react-router-dom";
import config from '../config.json';
import TICKER_SEARCH from '../components/TICKER_SEARCH';
import FOOTER from '../components/FOOTER';
import CHARTED_TRADE_SUMMARY from '../components/CHARTED_TRADE_SUMMARY';
import LOADING from "../components/LOADING";
import Headers from '../bin/Headers';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, MinusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowDownIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
	
const Main = () => {
	const token = useSelector(state => state.token),
		data = useRef({}),
		visibility = useRef({}),
		[loading, setLoading] = useState(true),
		[isVisible_LessNoise, setIsVisible_LessNoise] = useState(false),
		[indexDate, setIndexDate] = useState(null),
		[stockTickers, setStockTickers] = useState(0),
		[optionContracts, setOptionContracts] = useState(0),
		[capital, setCapital] = useState(0),
		[stockTrades, setStockTrades] = useState(0),
		[optionTrades, setOptionTrades] = useState(0),
		[notableTradeCount, setNotableTradeCount] = useState(0),
		[notableTrades, setNotableTrades] = useState([]);
	
	const countUpOnce_TotalOptionTrades = (visible) => {
		visibility.current.TotalOptionTrades = visible;
		if (!visible && !optionTrades && (!data.current || !data.current.OptionTrades)) { setOptionTrades(0); return; }
		if (!visible || optionTrades > 0) { return; }
		if (data.current && data.current.OptionTrades && isNaN(data.current.OptionTrades) === false) {
			setOptionTrades(data.current.OptionTrades);
		}
	};
	const countUpOnce_NotableTradeCount = (visible) => {
		visibility.current.NotableTradeCount = visible;
		if (!visible && !notableTradeCount) { setNotableTradeCount(0); return; }
		if (!visible || notableTradeCount>0) { return; }
		if (data.current && data.current.NotableTradeCount && isNaN(data.current.NotableTradeCount) === false) {
			setNotableTradeCount(data.current.NotableTradeCount);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${config.api}/highlights`, { headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d && d.result ? d.result : null);
				if (!response) {
					setLoading(false);
					return;
				}
				data.current = response;
				if (response.IndexDate) { setIndexDate(response.IndexDate); }
				if (response.NotableTrades) { setNotableTrades(response.NotableTrades); }
				if (response.NotableTradeCount && visibility.current.NotableTradeCount) { setNotableTradeCount(response.NotableTradeCount); }
				if (response.OptionTrades && visibility.current.TotalOptionTrades) { setOptionTrades(response.OptionTrades); }
				setLoading(false);
			} catch (e) {
				console.error(e);
				setLoading(false);
			}
		};
		fetchData();
	}, []);

  return (
    <div className="bg-gray-900 h-screen">
			{/* <div className="relative isolate px-2 overflow-hidden h-screen"> */}
			<div className="relative isolate px-2 h-screen">
				
        {/* <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" /> */}
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
					<div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)', }} />
				</div>


        <div className="mx-auto max-w-5xl py-20 sm:py-48 lg:py-56">

					<div className={`mb-8 flex flex-grow text-white justify-center items-center flex-col`}>
						<span className='w-[200px] sm:w-[250px]'>
							<TICKER_SEARCH />
						</span>
					</div>
          <div className="text-center flex flex-col justify-center items-center">
            <h1 className="animate__animated animate__zoomIn flex items-center justify-center text-4xl font-bold tracking-tight h-[150px] gradient-text sm:text-6xl drop-shadow-2xl">
              Real Time<br/>Large Trade Tracking
            </h1>
						<p className="mt-6 text-lg sm:text-xl sm:leading-8 text-sky-300">Money moves the market.<br className='block sm:hidden'/> Move with it.</p>
						<p className="text-md text-justify md:max-w-[400px] px-2 sm:px-0 sm:text-center sm:text-lg sm:leading-8 text-gray-300">Track large stock and option trades with clarity, speed, and real-time notifications within seconds of a trade.</p>
						<div className="mt-10 flex items-center justify-center gap-x-6">
              <Link to="/rankings-largest-stock-trades" className="rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Check out the<br className='block sm:hidden' /> Latest Highest Rankings
              </Link>
            </div>
					</div>
					




					<hr className="border-t border-gray-700 my-24 sm:my-48" />
					<div className="mt-10 text-center flex justify-center items-center flex-col">
						
						<div className="h-20 px-10 sm:px-24 flex justify-center items-center text-sky-200 border-2 rounded-lg border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_15px_#08f,0_0_30px_#08f]">
							<VisibilitySensor onChange={(visible) => { if (!visible || isVisible_LessNoise) { return; } setIsVisible_LessNoise(true); }}>
								<h1 className={`animate__animated ${isVisible_LessNoise ? 'animate__lightSpeedInLeft opacity-100' : 'opacity-0'} animate-all flex items-center justify-center text-4xl font-bold tracking-tight h-20 sm:text-5xl drop-shadow-2xl relative`}>
									Less Noise
								</h1>
							</VisibilitySensor>
						</div>
						<p className="mt-10 text-md text-justify md:max-w-[400px] px-2 sm:px-0 sm:text-center sm:text-lg sm:leading-8 text-gray-300">The "Notable Trade," algo was built with a simple goal of spotting, highly probable, opening option trades and tracking their trade performance.</p>
						
						<div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-x-6">

							<div className='relative flex flex-col items-center justify-start gap-y-4 border bg-gray-200/10 rounded-xl px-6 py-2 border-gray-700 h-[120px] w-[200px] lg:w-[300px]'>
								<span className="text-sm lg:text-lg font-bold text-white">Total Option Trades</span>
								<span className="text-2xl lg:text-4xl font-bold text-white">
									{loading ? <LOADING text={false} color={'text-green-500'} /> : <VisibilitySensor onChange={countUpOnce_TotalOptionTrades}><CountUp end={optionTrades} /></VisibilitySensor>}
								</span>
								{indexDate ? <span className="absolute bottom-1 right-1 text-xxs font-light text-gray-500">on {indexDate}</span> : <></>}
							</div>
							
							<div className='relative flex flex-col items-center justify-start px-6 py-2'>
								<ArrowRightIcon className="hidden sm:block h-14 w-14 lg:h-20 lg:w-20 text-emerald-500" />
								<ArrowDownIcon className="block sm:hidden h-20 w-20 text-emerald-500" />
							</div>

							<div className='relative flex flex-col items-center justify-start gap-y-4 border bg-gray-200/10 rounded-xl px-6 py-2 border-gray-700 h-[120px] w-[200px] lg:w-[300px]'>
								<span className="text-sm lg:text-lg font-bold text-white">Notable Trades</span>
								<span className="text-2xl lg:text-4xl font-bold text-white">
									{loading ? <LOADING text={false} color={'text-green-500'} /> : <VisibilitySensor onChange={countUpOnce_NotableTradeCount} intervalCheck={true}><CountUp end={notableTradeCount} /></VisibilitySensor>}
								</span>
							</div>

						</div>

						<div className="mt-10 flex flex-col items-center justify-center">
							<button className="rounded-md bg-gradient-to-r from-amber-500 to-rose-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
								<Link to="/notabletrades" className="text-white">View Notable Trades<br/>It's Free</Link>
							</button>
						</div>
					</div>
					

					
					
					
					
					
					<hr className="border-t border-gray-700 my-24 sm:my-48" />
					<div className="mt-10 text-center flex justify-center items-center flex-col">
						<h3 className='text-white text-xl'>Highest Highs of the last week</h3>
					</div>
					<div className="mt-10 text-center flex justify-center items-center flex-col">
						{!loading && notableTrades.length > 0 ? (
							<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 items-center justify-center">
								{notableTrades.map((trade, index) => (
									<CHARTED_TRADE_SUMMARY key={index} trade={trade} />
								))}
							</div>
						) : (
							<LOADING text={false} color={'text-green-500'} />
						)}
					</div>

					{/* <hr className="border-t border-gray-700 my-24 sm:my-48" /> */}
					{/* <FOOTER /> */}
				</div>

				
        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
          <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{clipPath:'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',}} />
        </div>
			</div>
    </div>
  );
};
export default Main;