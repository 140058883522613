const epochConverterToEST=(timestamp)=>{
  let epoch = timestamp ? Number(timestamp) : null;
  let d = null;
  let dateObject={
    Epoch: null,
    FullMonth: null,
    Day: null,
    Month: null,
    Date: null,
    Year: null,
    Hour: '00',
    Minute: '00',
    Second: '00',
    Millisecond: '000',
    Microsecond: '000',
    Nanosecond: '000',
  };
  let millisecondsOverwrite = null;
  //nanoseconds
  if (epoch && epoch>=1000000000000000000){
    epoch= Math.trunc(epoch / 1000000);
    millisecondsOverwrite = (new Date(epoch)).getMilliseconds().toString();
  }else if (epoch && epoch>=1000000000000000){
    epoch= Math.trunc(epoch / 1000);
    millisecondsOverwrite = (new Date(epoch)).getMilliseconds().toString();
  }else{
    millisecondsOverwrite = (new Date(epoch)).getMilliseconds().toString();
  }
  d = epoch ? new Date(epoch) : new Date();
  //set to est
  d = new Date( d.toLocaleString('en-US', { timeZone: 'America/New_York' }) );
  dateObject.epoch = epoch ? epoch : d.getTime();
  dateObject.Month= (d.getMonth()+1).toString().padStart(2,'0');
  dateObject.Date= d.getDate().toString().padStart(2,'0');
  dateObject.Year= d.getFullYear();
  dateObject.Hour= d.getHours().toString().padStart(2,'0');
  dateObject.Minute= d.getMinutes().toString().padStart(2,'0');
  dateObject.Second= d.getSeconds().toString().padStart(2,'0');
  dateObject.Millisecond= millisecondsOverwrite ? millisecondsOverwrite : d.getMilliseconds().toString();
  dateObject.FullMonth= ['January','Febuary','March','April','May','June','July','August','September','October','November','December'][d.getMonth()];
  dateObject.Day= ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'][d.getDay()];
  return dateObject;
};
module.exports= epochConverterToEST;