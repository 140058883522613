const main = (level, levelsAllowed = ['red', 'black', 'diamond', '1337']) => {
	if (!level) { return true; }
	if (typeof levelsAllowed === 'string') {
		if (levelsAllowed === 'red') { levelsAllowed = ['red', 'black', 'diamond', '1337']; }
		if (levelsAllowed === 'black') { levelsAllowed = ['black', 'diamond', '1337']; }
		if (levelsAllowed === 'diamond') { levelsAllowed = ['diamond', '1337']; }
		if (levelsAllowed === '1337') { levelsAllowed = ['1337']; }
	}
	// console.log(level);
	// console.log(levelsAllowed);
	if (levelsAllowed.includes(level)) { return false; }
	return true;
};
export default main;