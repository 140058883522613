import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import config from '../config.json';
import LOADING from "../components/LOADING";
import Headers from '../bin/Headers';
import recaptchaReady from '../bin/recaptchaReady';
import changeAvatarImage from '../bin/changeAvatarImage';
import animateText from '../bin/animateText';
import wait from '../bin/wait';
import { UserIcon } from '@heroicons/react/24/outline';

const Main = () => {
	const [username, setUsername] = useState(''),
		[password, setPassword] = useState(''),
		[confirmPassword, setConfirmPassword] = useState(''),
		[avatar, setAvatar]= useState(''),
		[email, setEmail]= useState(''),
		[loading, setLoading] = useState(false),
		[usernameUnavailable, setUsernameUnavailable] = useState(false),
		[usernameAvailable, setUsernameAvailable] = useState(false),
		[headerText, setHeaderText] = useState('Sign Up'),
		[showForm, setShowForm] = useState(true),
		[hasAgreedToTerms, setHasAgreedToTerms] = useState(false),
		[usernameCheckResult, setUsernameCheckResult] = useState(''),
		[welcomeText, setWelcomeText] = useState(''),
		[errorText, setErrorText] = useState(''),
		[colorChange, setColorChange] = useState(null),
		[requestTimeout, setRequestTimeout] = useState(null),
		navigate = useNavigate();
	
	const checkUsername = async (username) => {
		if (!username || !username.length || username.length < 5 || username.length > 25 || !/^[a-zA-Z0-9]+$/.test(username)) { return; }
		let headers = Headers();
		let requestOp = await fetch(`${config.api}/usernamecheck?q=${username}`, { headers }).then(d => d.json()).catch(async (e) => { return null; });
		if (!requestOp.message || requestOp.message !== 'ok') {
			setUsernameUnavailable(true);
			setUsernameAvailable(false);
			setUsernameCheckResult(requestOp.message);
			return;
		}
		setUsernameUnavailable(false);
		setUsernameAvailable(true);
		setUsernameCheckResult('username available');
		return;
	};

	const filterUsername = (value) => {
		// Use a regular expression to remove non-alphanumeric characters
		const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
		setUsername(filteredValue);
	};

  const handleSubmit = async (event)=>{
		event.preventDefault();

		// Clear any existing timeout to avoid duplicate timeouts
		if (requestTimeout) clearTimeout(requestTimeout);
		// Set a new timeout
		const newTimeout = setTimeout(() => {
			// This code runs after 9 seconds
			setColorChange('bg-rose-950');
			setHeaderText('Request Cancelled');
			setErrorText('Servers took too long to respond.');
			setLoading(false);
			setRequestTimeout(null);
		}, 9000); // 9 seconds
		setRequestTimeout(newTimeout);
		if (!hasAgreedToTerms) { alert('You must agree to the account terms and username policy.'); return; }
		if (!username || !username.length) { alert(`Username is missing`); return; }
		if (!password || !password.length) { alert(`Password is missing`); return; }
		if (username.length<5) { alert(`Username is too short`); return; }
		if (username.length>25) { alert(`Username is too long`); return; }
		if (!/^[a-zA-Z0-9]+$/.test(username)) { alert(`Username is invalid. Only A-Z and 0-9 are allowed.`); return; }
		if (password.length<4) { alert(`Password is too short`); return; }
		if (password.length > 50) { alert(`Password is too long`); return; }
		if (!/^[a-zA-Z0-9!@#$%^&*<>?]+$/.test(password)) { alert(`Password is invalid. Only A-Z, 0-9, and the following symbols are allowed !,@,#,$,%,^,&,*,<,>,?`); return; }
		if (email.length<5) { alert(`Email is too short`); return; }
		if (email.length > 254) { alert(`Email is too long`); return; }
		setLoading(true);
		setColorChange('bg-amber-950');

		let headers = Headers();
		let captcha = !headers.Accept ? await recaptchaReady('SIGNUP') : null;
		let requestOp = await fetch(`${config.api}/signup`, { headers, method: 'post', body: JSON.stringify({ username, password, email, avatar, captcha }) })
			.then(d => d.json())
			.catch(async (e) => {
				clearTimeout(newTimeout);
				setRequestTimeout(null);
				setColorChange('bg-rose-950');
				setHeaderText('Sign Up Failed');
				alert(e);
				setLoading(false);
				return;
			});				
		clearTimeout(newTimeout);
		setRequestTimeout(null);

		if (!requestOp.message || requestOp.message !== 'ok' || !requestOp.result) {
			setColorChange('bg-rose-950');
			setHeaderText('Sign Up Failed');
			if (requestOp.error) { await animateText(setErrorText, requestOp.error, 50); }
			setLoading(false);
			return;
		}
		setColorChange('bg-sky-950');
		setLoading(false);
		setShowForm(false);
		
		// Animate typing "Last Step"
		setHeaderText('Last Step');
				
		// Type the username
		await animateText(setWelcomeText, `Confirm your email ${email}, then you'll be all set`, 50);
		setColorChange('bg-emerald-950');
		await wait(500);
  };

  return (
    <div className="flex items-start justify-center mt-4 text-white w-full flex-wrap sm:flex-nowrap">

			<div className={`flex flex-col ${colorChange ? colorChange : 'bg-gray-800'} animate-bg rounded-xl shadow-md sm:p-8 sm:m-4 w-full max-w-sm`}>
				<div className="flex min-h-full flex-1 items-center justify-center px-6 py-6">
					<div className="w-full max-w-sm space-y-10">
						<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">
							{headerText}
						</h2>
						{showForm && (<div className='w-full max-w-sm space-y-10'>
							<form className="space-y-6" onSubmit={handleSubmit}>
								<div className='flex flex-row items-center justify-center w-full'>
									<button className='h-16 w-16 rounded-b-2xl rounded-t-md relative' onClick={(evt) => { changeAvatarImage(evt, setAvatar); }}>
										{avatar ? <img className="inline-block border shadow h-16 w-16 rounded-b-2xl rounded-t-md" src={avatar} alt="" /> : <UserIcon className="h-16 w-16 rounded-b-2xl rounded-t-md bg-cyan-700" />}
										<span className="absolute bottom-0 py-1 left-0 w-full text-center text-white text-xs bg-black bg-opacity-50 rounded-b-2xl">change</span>
									</button>
								</div>
								<div className="relative -space-y-px rounded-md shadow-sm">
									<div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
									<div>
										<input
											name="username"
											type="text"
											required
											minLength="5"
											maxLength="25"
											autoComplete='off'
											autoCorrect='off'
											className={`${usernameUnavailable ? 'bg-rose-300' : (usernameAvailable ? 'bg-emerald-300' : 'ring-1 ring-inset ring-gray-100')} relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6`}
											placeholder="Username"
											value={username}
											onChange={(evt) => { setUsernameCheckResult(''); setUsernameAvailable(false); setUsernameUnavailable(false); filterUsername(evt.target.value); }}
											onBlur={(evt)=>{checkUsername(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } checkUsername(evt); }}
										/>
									<p className={`text-center text-xs ${usernameUnavailable ? 'bg-rose-300 text-rose-900' : (usernameAvailable ? 'bg-emerald-300 text-emerald-900' : 'hidden text-gray-400')}`}>{usernameCheckResult}</p>
									</div>

									<div>
										<input
											name="password"
											type="password"
											required
											minLength="4"
											maxLength="50"
											autoComplete='off'
											autoCorrect='off'
											className={`relative block w-full ${password && password.length ? '' : 'rounded-b-md' } border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6`}
											placeholder="Password"
											value={password}
											onChange={(evt)=>{setPassword(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
										/>
									</div>
									<div>
										<input
											name="confirmPassword"
											type="password"
											required
											minLength="4"
											maxLength="50"
											className={`relative block w-full ${password && password.length ? 'block' : 'hidden' } rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6`}
											placeholder="Confirm Password"
											value={confirmPassword}
											onChange={(evt)=>{setConfirmPassword(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
										/>
									</div>
								</div>

								<div className="relative -space-y-px rounded-md shadow-sm">
									<div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
									<div>
										<input
											name="email"
											type="email"
											required
											minLength="5"
											maxLength="254"
											autoComplete
											className="relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
											placeholder="Email"
											value={email}
											onChange={(evt)=>{setEmail(evt.target.value)}}
											onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
										/>
									</div>
								</div>

								{/* <div className="h-24 overflow-y-auto text-justify text-xxs bg-gray-900/30">
									<h6 className='mb-3 font-bold text-center'>Account Terms and Username Policy</h6>
									<ul className='space-y-3'>
										<li><b>Fixed Username:</b> Once set, your username cannot be changed. Choose wisely to ensure it reflects your desired online identity.</li>
										<li><b>Ownership and Intellectual Property:</b> If your chosen username includes company names, locations, personal names, or any identifiable trademarks that you do not own, be aware that your username may be subject to automatic modification during periodic audits to ensure compliance with intellectual property rights and proper reputation identity.</li>
										<li><b>Prohibited Content:</b> Usernames that are found to be vulgar, obscene, or otherwise inappropriate will be subject to change without notice. This site is committed to maintaining a respectful and safe community.</li>
										<li><b>No Name Squatting:</b> Registering usernames for the purpose of selling them or preventing others from using them (name squatting) is prohibited. Such actions may lead to account suspension or termination.</li>
										<li><b>Inactive Accounts:</b> Please note that accounts showing no activity for an extended period may be considered inactive and subject to termination. This measure helps ensure the availability of desirable usernames for new community members.</li>
									</ul>
								</div> */}
								<div className="mt-4">
									<label className="inline-flex items-center">
										<input
											type="checkbox"
											required
											className="text-red-600 border-gray-300 rounded focus:ring-red-500"
											onChange={(evt) => setHasAgreedToTerms(evt.target.checked)}
										/>
										<span className="ml-5 text-sm text-gray-200">I agree to the <a href="/termsandconditions" target="_blank" className='text-sky-500'>Terms, Conditions, and Disclaimer Policy</a> for RedStripedTie.com.</span>
									</label>
								</div>

								<div className={`${usernameAvailable && password && password.length && confirmPassword && confirmPassword.length && password===confirmPassword && email && email.length && hasAgreedToTerms ? '' : 'hidden'}`}>
									<button type="submit" disabled={loading} className={`flex w-full justify-center rounded-md ${loading ? 'bg-gradient-to-r from-red-800 to-rose-900' : 'bg-gradient-to-r from-red-500 to-rose-500'} px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
										{loading ? <span className='py-1'><LOADING color="text-white" text={false} nopadding={true} /></span> : 'Join'}
									</button>
								</div>
									
							</form>
									
						</div> )}

						{!showForm && <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">{welcomeText}</h2>}

					</div>
				</div>
      </div>

    </div>
  );
};
export default Main;