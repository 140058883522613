const main = (date1, date2) => {
	// Convert both dates to midnight time (to remove time-of-day factors)
  const date1Midnight = new Date(date1);
  date1Midnight.setUTCHours(0, 0, 0, 0);
  
  const date2Midnight = new Date(date2);
  date2Midnight.setUTCHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = Math.abs(date2Midnight - date1Midnight);
  
  // Convert milliseconds to days (1 day = 24*60*60*1000 = 86400000 milliseconds)
  const diffInDays = Math.floor(diffInMilliseconds / 86400000);

  return diffInDays;
};
export default main;