import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import abbrInt from '../bin/abbrInt';

const MAIN = ({ candles }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!candles) return;

    const chart = echarts.init(chartRef.current);

    const candleData = candles.map(candle => ({
      value: [candle.Open, candle.Close, candle.Low, candle.High, candle.Volume],
      date: candle.DateString,
    }));

		const maxVolume = Math.max(...candles.map(candle => candle.Volume));

    const volumeData = candles.map(candle => ({
      value: candle.Volume * 0.30,
      date: candle.DateString,
      itemStyle: {
        color: candle.Close >= candle.Open ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)',
      },
    }));

    const allPrices = [...candleData.map(item => item.value[1])];
    const yAxisMin = Math.min(...allPrices);
    const yAxisMax = Math.max(...allPrices);

    const option = {
      textStyle: {
        color: 'white',
      },
      grid: {
        left: '10%',
        right: '10%',
        top: '10%',
        height: '80%',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.80)',
        position: function (point, params, dom, rect, size) {
          const chartWidth = size.viewSize[0];
          const tooltipWidth = size.contentSize[0];
          const x = point[0];

          if (x < chartWidth / 2) {
            return [chartWidth - tooltipWidth, 0];
          } else {
            return [0, 0];
          }
        },
        formatter: function (params) {
          const date = params[0].axisValue;
          const priceData = params.find(param => param.seriesName === 'Price')?.data;
          const open = priceData && !isNaN(priceData[1]) ? priceData[1].toLocaleString() : '-';
          const high = priceData && !isNaN(priceData[4]) ? priceData[4].toLocaleString() : '-';
          const low = priceData && !isNaN(priceData[3]) ? priceData[3].toLocaleString() : '-';
          const close = priceData && !isNaN(priceData[2]) ? priceData[2].toLocaleString() : '-';
          const volume = priceData && !isNaN(priceData[5]) ? priceData[5].toLocaleString() : '-';
          const stockTurnoverValue = params.find(param => param.seriesName.includes('Stock Turnover'))?.data;
          const stockNotionalValue = params.find(param => param.seriesName.includes('Stock Notional'))?.data;

          return `
            <div style="padding: 10px;">
              <span style="color:white;">● Date: ${date}</span><br/>
              <span style="color:white;">● Open: ${open}</span><br/>
              <span style="color:white;">● High: ${high}</span><br/>
              <span style="color:white;">● Low: ${low}</span><br/>
              <span style="color:white;">● Close: ${close}</span><br/>
              <span style="color:white;">● Volume: ${volume}</span><br/>
              <span style="color:blue;">● Stock Notional: ${stockNotionalValue && !isNaN(stockNotionalValue) ? stockNotionalValue.toLocaleString() : stockNotionalValue}</span><br/>
              <span style="color:yellow;">● Stock Turnover: ${stockTurnoverValue && !isNaN(stockTurnoverValue) ? stockTurnoverValue.toLocaleString() : stockTurnoverValue}%</span><br/>
            </div>
          `;
        },
      },
      xAxis: {
        type: 'category',
        data: candleData.map(item => item.date),
        boundaryGap: true,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'black',
          },
        },
        splitLine: { show: false },
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: [
        {
          type: 'value',
          name: 'Price',
          position: 'right',
          min: yAxisMin,
          max: yAxisMax,
          axisLabel: {
            color: 'white',
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'black',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
          interval: 5,
        },
        {
          type: 'value',
          name: 'Volume',
					show: false,
					min: 0,
					max: maxVolume,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: { show: false },
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          show: true,
          type: 'slider',
          top: '85%',
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          type: 'candlestick',
          name: 'Price',
          data: candleData.map(item => item.value),
          itemStyle: {
            color: 'green',
            color0: 'red',
            borderColor: 'green',
            borderColor0: 'red',
          },
        },
        {
          type: 'bar',
          name: 'Volume',
          xAxisIndex: 0,
          yAxisIndex: 1,
          data: volumeData.map(item => item.value),
          barWidth: '60%',
          itemStyle: {
            color: function (params) {
              return candles[params.dataIndex].Close >= candles[params.dataIndex].Open ? 'rgba(0, 255, 0, 0.15)' : 'rgba(255, 0, 0, 0.15)';
            },
          },
        },
        {
          type: 'line',
          name: 'Stock Notional',
          yAxisIndex: 0,
          data: candles.map(item => item.StockNotional),
          lineStyle: {
            width: 2,
            color: 'blue',
          },
          showSymbol: false,
        },
        {
          type: 'line',
          name: 'Stock Turnover',
          yAxisIndex: 0,
          data: candles.map(item => item.StockTurnover),
          lineStyle: {
            width: 2,
            color: 'purple',
          },
          showSymbol: false,
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [candles]);

  return <div ref={chartRef} style={{ width: '100%', height: '500px' }} />;
};

export default MAIN;
