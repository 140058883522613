import { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
// import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Menu, Transition } from '@headlessui/react'
import { loadStripe } from '@stripe/stripe-js';
import { MinusIcon } from '@heroicons/react/20/solid';
import Headers from "../bin/Headers";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import classNames from "../bin/classNames";
import setMetaTags from "../bin/setMetaTags";
import config from '../config.json';
import RANKINGS from "../components/RANKINGS";
import { ChevronDownIcon, } from "@heroicons/react/24/solid";

const Main = () => {
	const token = useSelector(state => state.token),
		[delayedDataDateString, setDelayedDataDateString] = useState(null);
	
	const rankingslist = [
		{ name: 'Largest Stock Trades', href: '/rankings-highest-stock-trades' },
		{ name: 'Largest Stock Trades - Non Auction', href: '/rankings-highest-stock-trades-non-auction' },
		{ name: 'Largest Binary Option Trades', href: '/rankings-highest-binary-option-trades' },
		{ name: 'Highest Stock Capital', href: '/rankings-highest-capital-stock' },
		{ name: 'Highest Option Premium', href: '/rankings-highest-capital-option-premium' },
		{ name: 'Highest Option Binary Premium', href: '/rankings-highest-capital-option-binary-premium' },
		{ name: 'Highest Stock Volume', href: '/rankings-highest-stock-volume' },
		{ name: 'Highest Option Volume', href: '/rankings-highest-option-volume' },
		{ name: 'Highest Stock Oddlot Turnover', href: '/rankings-highest-stock-oddlot-turnover' },
		{ name: 'Highest Option Turnover', href: '/rankings-highest-option-terminal' },
		{ name: 'Highest Percent Gain', href: '/rankings-highest-percent-gain' },
		{ name: 'Highest Percent Loss', href: '/rankings-highest-percent-loss' },
		{ name: 'Highest Market Cap Gain', href: '/rankings-highest-market-cap-gain' },
		{ name: 'Highest Market Cap Loss', href: '/rankings-highest-market-cap-loss' },
	];
	
	
	useEffect(() => {
		setMetaTags({ title: 'Largest Stock Trades' });

		return () => {
			setMetaTags({ title: 'Red Striped Tie' });
		}
	}, []);

  return (
    <div className="bg-gray-900 py-8 sm:py-12">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
				{/* <h1 className="flex flex-col sm:flex-row items-center justify-between gap-2"> */}
				<h1 className="flex flex-row w-full text-2xl font-bold text-white text-left items-center justify-start gap-2 flex-grow sm:grow-0">
					{/* <Link to={`histest-stock-trades`} onClick={() => {  }} className='w-full text-2xl font-bold text-white text-left flex flex-row items-center justify-start gap-2'> */}
					<span className='flex-grow sm:grow-0'>Largest Stock Trades</span>

					{/* <Popover className="relative">
						<PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
							<span>Largest Stock Trades</span>
							<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
						</PopoverButton>

						<PopoverPanel transition className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
							<div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
								{rankingslist.map((item) => (
									<a key={item.name} href={item.href} className="block p-2 hover:text-indigo-600">
										{item.name}
									</a>
								))}
							</div>
						</PopoverPanel>
					</Popover> */}
						

					{/* <Menu as="div" className="relative w-full flex flex-row">
						<div className='w-full'>
							{/* <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> * /}
							<Menu.Button className="w-full text-2xl font-bold text-white text-left flex flex-row items-center justify-start gap-2">
								<span className='flex-grow sm:grow-0'>Largest Stock Trades</span>
								<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</Menu.Button>
						</div>
						<Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
							<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								<div className="py-1">
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-stock-trades" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Largest Stock Trades</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-binary-option-trades" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Largest Binary Option Trades</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-capital-stock" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Stock Capital</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-capital-option-premium" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Option Premium</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-capital-option-binary-premium" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Option Binary Premium</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-stock-volume" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Stock Volume</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-option-volume" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Option Volume</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => (<Link to="/rankings-highest-stock-oddlot-turnover" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Highest Stock Oddlot Turnover</Link>)}</Menu.Item>
									<Menu.Item>{({ active }) => (<Link to="/rankings-highest-option-terminal" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Highest Option Turnover</Link>)}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-percent-gain" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Percent Gain</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-percent-loss" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Percent Loss</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-market-cap-gain" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Market Cap Gain</Link> )}</Menu.Item>
									<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-market-cap-loss" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Market Cap Loss</Link> )}</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
					</Menu>  */}




					{delayedDataDateString ? <Link to="/pricing" className='w-full sm:w-[150px] text-xxs text-red-200 text-center rounded bg-rose-950 px-3 py-1'>Trade data<br className='hidden sm:block' /> from { delayedDataDateString }</Link> : <></>}
				</h1>
				<hr className='my-2' />
				<RANKINGS view={'rankings'} subview={'trade'} study={'stock'} setDelayedDataDateString={setDelayedDataDateString} />
			</div>
    </div>
  );
}
export default Main;