const displayColorSignedPercentJSX = (percentage, options) => {
  if (!percentage){ return; }
  if (percentage>0){
    return <span className='ml-1 text-bold text-green-500'> 
    {options && options.noBrackets ? '' : '[' }
    +{ (percentage).toLocaleString() }%
    {options && options.noBrackets ? '' : ']' }
    </span>;
  }else if (percentage<0){
    return <span className='ml-1 text-bold text-red-500'> 
    {options && options.noBrackets ? '' : '[' }
    { (percentage).toLocaleString() }%
    {options && options.noBrackets ? '' : ']' }
    </span>;
  }
  return <span className='ml-1 text-bold text-gray-500'> 
  {options && options.noBrackets ? '' : '[' }
  { (percentage).toLocaleString() }%
  {options && options.noBrackets ? '' : ']' }
  </span>;
};

export default displayColorSignedPercentJSX;