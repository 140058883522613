import React, { useEffect, useState } from 'react';
import config from "../config.json";
import Headers from "../bin/Headers";
import { useSelector, useStore } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { RadioGroup } from '@headlessui/react';
import dontRerender from "../bin/dontRerender";
import decodeJwt from "../bin/decodeJwt";
import classNames from '../bin/classNames';
import NAVIGATION from "../components/NAVIGATION";
import LOADING from "../components/LOADING";
import ICONS from '../bin/ICONS';
import _logout from '../bin/logout';
import changeAvatarImage from '../bin/changeAvatarImage';
import actions from '../state/actions';
import { UserIcon, EnvelopeIcon } from '@heroicons/react/20/solid';

const Account = () => {
	const token = useSelector(state => state.token),
		[avatar, setAvatar] = useState(useSelector(state => state.avatar)),
		[avatarUpdateAction, setAvatarUpdateAction] = useState(false),
		[avatarUpdating, setAvatarUpdating] = useState(false),
		[currentLevel, setCurrentLevel] = useState(null),
		[loading, setLoading] = useState(true),
		[user, setUser] = useState({}),
		[email, setEmail] = useState(null),
		[invoices, setInvoices] = useState([]),
		[autoRenewOn, setAutoRenewOn] = useState(false),
		[autoRenewLoading, setAutoRenewLoading] = useState(false),
		store = useStore(),
		navigate = useNavigate(),
		decodedToken = decodeJwt(token),
		logoutUser = _logout();
		// logoutUser = _logout;

	const tiers = {
		'red': {
			name: 'Red',
			image: '/bin/RedTie.png',
			monthlyPrice: null,
			features: [
				'Ad supported',
				'Limited/Delayed data',
			],
		},
		'black': {
			name: 'Black',
			image: '/bin/BlackTie.png',
			monthlyPrice: 40,
			discount: 50,
			features: [
				'No ads',
				'Live data',
				'Real-time alerts',
			],
		},
	};

	const toggleAutoRenew = async () => {
		let confirmMessage = autoRenewOn ? 'Are you sure you want to turn OFF auto-renew?' : 'Are you sure you want to turn ON auto-renew?';
		let confirmed = window.confirm(confirmMessage);
		if (!confirmed) { return; }
		setAutoRenewLoading(true);
		let response = await fetch(`${config.api}/autorenew`, { method: 'PUT', headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
		if (response && response.hasOwnProperty('autoRenewOn')) {
			setAutoRenewOn(response.autoRenewOn);
		}
		setAutoRenewLoading(false);
	};

	const convertEpochToDateString = (epoch) => {
		let date = new Date();
		date.setTime(epoch * 1000);
		return date.toLocaleDateString();
	};
	
	const checkAvatarUpdated = (newBase) => {
		let changed = newBase !== avatar ? true : false;
		setAvatar(newBase);
		setAvatarUpdateAction(changed);
	};

	const handleSubmit = async (event) => {
		setAvatarUpdating(true);
		let response = await fetch(`${config.api}/account`, { method: 'PUT', headers: Headers(token), body: JSON.stringify({ avatar }) }).then(d => d.status === 200 ? d.json() : null).then(d => d.message && d.message==='ok' ? true : false);
		if (!response) {
			setAvatarUpdating(false);
			setAvatarUpdateAction(false);
			return;
		}
		store.dispatch(actions.updateAvatar(avatar));
		setAvatarUpdating(false);
		setAvatarUpdateAction(false);
	};

	const start = async () => {
		setLoading(true);
		if (!token) { return _logout(); }
		let accountData = await fetch(`${config.api}/account`, { headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result ? d.result : null);
		if (!accountData) { return _logout(); }
		
		setUser(accountData.user);
		setEmail(accountData.user.email);
		setCurrentLevel(accountData.user.level);
		setInvoices(accountData.invoices);

		setAutoRenewOn(accountData.user.stripeSubscriptionRecurring);
		setLoading(false);
	};

  useEffect(()=>{ start(); },[]);



  return (
		<main className='mt-5'>
			
			{loading ? <div className="flex items-center justify-center h-96"><LOADING /></div> : <></>}
			{!loading ? <div>

				{/* Profile */}
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
						<div className="sm:px-4 sm:text-left px-0 text-center">
							<h3 className="text-lg font-medium leading-6 text-amber-400">Profile</h3>
						</div>
					</div>
					<div className="mt-5 md:col-span-2 md:mt-0">
						<div className="overflow-hidden shadow sm:rounded-md">
							
							<div className="bg-gray-800 px-4 py-5 sm:p-6">
								<div className="grid grid-cols-6 gap-2">
									<div className="col-span-6">
										
										<div className='flex flex-col items-center justify-center w-full gap-2'>
											<button className='h-16 w-16 rounded-b-2xl rounded-t-md relative' onClick={(evt) => { changeAvatarImage(evt, checkAvatarUpdated); }}>
												{avatar ? <img className="inline-block border shadow h-16 w-16 rounded-b-2xl rounded-t-md" src={avatar} alt="" /> : <UserIcon className="h-16 w-16 rounded-b-2xl rounded-t-md bg-cyan-700 text-white" />}
												<span className="absolute bottom-0 py-1 left-0 w-full text-center text-white text-xs bg-black bg-opacity-50 rounded-b-2xl">change</span>
											</button>
											<span className="text-sm w-full block text-center text-sky-600">{user && user.username ? user.username : ''}</span>

											<div>
												<div className="relative mt-2 rounded-md shadow-sm">
													<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
														<EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
													</div>
													<input
														type="email"
														name="email"
														minLength="5"
														maxLength="254"
														disabled
														className="bg-gray-900/50 block w-full rounded-md border-0 py-1.5 pl-10 text-gray-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														placeholder="Email"
														value={email}
														onChange={(evt)=>{setEmail(evt.target.value)}}
														onKeyDown={(evt) => { if (evt.key !== 'Enter') { return; } handleSubmit(evt); }}
													/>
												</div>
											</div>
										
										</div>
									</div>
								</div>
							
								{avatarUpdateAction ?
									<div>
										<hr className="my-5 w-full border border-gray-600" />
										<div className="flex flex-col items-center justify-center">
											<div className="flex items-center justify-end w-full gap-2">
												<button className={`${avatarUpdating ? 'bg-gray-600 text-gray-400' : 'bg-emerald-800 hover:bg-emerald-700 text-white'} py-1 px-4 rounded-lg text-md flex flex-row gap-1 items-center justify-center`} onClick={() => { handleSubmit(); }}>
													{avatarUpdating ? 'Updating Profile Pic' : ''}
													{!avatarUpdating ? 'Update' : ''}
													{avatarUpdating? <LOADING color="text-white" text={false} inline={true} nopadding={true} /> : ''}
												</button>
											</div>
										</div>
									</div>
									: <></>}
								
							</div>

						</div>
					</div>
				</div>

				{/* Divider */}
				<div className="hidden sm:block sm:mt-5" aria-hidden="true">
					<div className="py-5">
						<div className="border-t border-gray-600" />
					</div>
				</div>

				{/* Subscription */}
				<div className="mt-10 sm:mt-0">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
						<div className="sm:px-4 sm:text-left px-0 text-center">
								<h3 className="text-lg font-medium leading-6 text-amber-400">Subscription</h3>
							</div>
						</div>
						<div className="mt-5 md:col-span-2 md:mt-0">

							<div className="overflow-hidden shadow sm:rounded-md">
								
								{currentLevel && tiers[currentLevel] ?
									<div className="bg-gray-800 px-4 py-5 sm:p-6">
										<div className="grid grid-cols-4 sm:grid-cols-6 gap-2">
											<div className="col-span-1 flex justify-center sm:col-span-2">
												<img className="border bg-gradient-to-b from-white to-gray-400 border-gray-700 p-1 rounded-full h-14 w-14" src={tiers[currentLevel].image} alt={tiers[currentLevel].name} />
											</div>
											<div className="col-span-3 sm:col-span-2 flex items-center justify-start flex-col">
												<h2 className="h-full text-2xl font-semibold leading-8 text-left w-full text-white">{tiers[currentLevel].name}</h2>
												{user && user.dayOneSupporter ?
													<span className='text-amber-500 text-xxs w-full text-left'>💫 Day One Supporter</span>
													: (user && user.dayOneMember ?
														<span className='text-sky-500 text-xxs w-full text-left'>🎖️ Day One Member</span>
														: <></>)}
												
											</div>
											<div className="col-span-4 sm:col-span-2 flex items-center justify-end flex-col">
												{user.stripeSubscriptionRecurringCost ? <span className="w-full text-md font-light text-right text-white">${user.stripeSubscriptionRecurringCost} per month</span> : <></>}
												{/* {tiers[currentLevel].monthlyPrice ? <span className="w-full text-md font-light text-right text-white">${(tiers[currentLevel].monthlyPrice * (tiers[currentLevel].discount * 0.01))} per month</span> : <></>} */}
												{/* {!tiers[currentLevel].monthlyPrice ? <span className="w-full text-md font-light text-right text-white">FREE</span> : <></>} */}
												{!user.stripeSubscriptionRecurringCost ? <span className="w-full text-md font-light text-right text-white">FREE</span> : <></>}
												{currentLevel === 'black' && autoRenewOn && user && user.stripeSubscriptionEndTime ? <span className="w-full text-xs font-light text-right text-green-400">renews on { convertEpochToDateString(user.stripeSubscriptionEndTime) }</span> : <></>}	
												{currentLevel === 'black' && !autoRenewOn && user && user.stripeSubscriptionEndTime ? <span className="w-full text-xs font-light text-right text-red-400">expires on { convertEpochToDateString(user.stripeSubscriptionEndTime) }</span> : <></>}	
											</div>
										</div>
										
										<hr className="my-5 w-full border border-gray-600" />
										<div className="flex flex-col items-center justify-center">
											<div className="flex items-center justify-end w-full gap-2">
												{user.stripeSubscriptionRecurringCost && tiers[currentLevel].monthlyPrice && autoRenewOn ?
													<button className={`${autoRenewLoading ? 'bg-gray-600 text-gray-400' : 'bg-rose-800 hover:bg-rose-700 text-white'} py-1 px-4 rounded-lg text-md flex flex-row gap-1 items-center justify-center`} onClick={() => { toggleAutoRenew(); }}>
														{autoRenewLoading ? 'Turning off auto-renew' : ''}
														{!autoRenewLoading ? 'Turn off auto-renew' : ''}
														{autoRenewLoading ? <LOADING color="text-white" text={false} inline={true} nopadding={true} /> : ''}
													</button> : <></>}
												{user.stripeSubscriptionRecurringCost && tiers[currentLevel].monthlyPrice && !autoRenewOn ?
													<button className={`${autoRenewLoading ? 'bg-gray-600 text-gray-400' : 'bg-emerald-800 hover:bg-emerald-700 text-white'} py-1 px-4 rounded-lg text-md flex flex-row gap-1 items-center justify-center`} onClick={() => { toggleAutoRenew(); }}>
														{autoRenewLoading ? 'Turning on auto-renew' : ''}
														{!autoRenewLoading ? 'Turn on auto-renew' : ''}
														{autoRenewLoading ? <LOADING color="text-white" text={false} inline={true} nopadding={true} /> : ''}
													</button> : <></>}
												{!user.stripeSubscriptionRecurringCost && currentLevel === 'black' ? <Link to="/pricing" className="bg-emerald-600 hover:bg-emerald-500 text-white py-1 px-4 rounded-lg text-md">Subscribe</Link> : <></>}
												{currentLevel === 'red' ? <Link to="/pricing" className="bg-emerald-600 hover:bg-emerald-500 text-white py-1 px-4 rounded-lg text-md">Upgrade</Link> : <></>}
												{/* {currentLevel === 'black' ? <Link className="bg-indigo-800 hover:bg-indigo-700 text-white py-1 px-4 rounded-lg text-md" onClick={() => { }}>Cancel Level</Link> : <></>} */}
											</div>
										</div>
									</div>
								: <></>}

							</div>

						</div>
					</div>
				</div>			

				{/* Divider */}
				<div className="hidden sm:block sm:mt-5" aria-hidden="true">
					<div className="py-5">
						<div className="border-t border-gray-600" />
					</div>
				</div>

				{/* Logout */}
				<div className="mt-10 inset-x-0 p-4 bg-gradient-to-r from-rose-950/0 to-rose-950/20 text-gray-400 sm:py-4 sm:px-8 flex items-center justify-end gap-2">
					<button className="bg-rose-950 rounded px-4 py-1" onClick={() => { logoutUser(); }}>Logout</button>
				</div>

			</div> : <></>}
      
    </main>
  );
};

export default React.memo(Account, dontRerender);