const app= {
  version: '3.1',
  token: null,
  avatar: null,
  tielevel: null,
  unseenalerts: null,
  market: null,
  navigation: [
    { 
      name: 'Red Striped Tie',
      href: '/',
      icon: "",
      current: false,
      menu: true,
      title: false,
      authrequired: false,
      maintenance: false,
    },
    { 
      name: 'Home',
      href: '/home',
      icon: "",
      current: false,
      menu: true,
      title: false,
      authrequired: true,
      maintenance: false,
    },
		// { 
    //   name: 'Watchlist',
    //   href: '/watchlist',
    //   icon: "EyeIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
		// },
    {
      name: 'Login',
      href: '/login',
      icon: "UserIcon",
      current: false,
      menu: false,
      title: false,
      authrequired: false,
      maintenance: false,
    },
    {
      name: 'Sign Up',
      href: '/signup',
      icon: "UserIcon",
      current: false,
      menu: false,
      title: false,
      authrequired: false,
      maintenance: false,
    },
    {
      name: 'Account Recovery',
      href: '/accountrecovery',
      icon: "UserIcon",
      current: false,
      menu: false,
      title: false,
      authrequired: false,
      maintenance: false,
    },
    {
      name: 'Verification',
      href: '/verification',
      icon: "UserIcon",
      current: false,
      menu: false,
      title: false,
      authrequired: false,
      maintenance: false,
    },
    // {
    //   name: 'Unsubscribe',
    //   href: '/unsubscribe',
    //   icon: "BuildingLibraryIcon",
    //   current: false,
    //   menu: false,
    //   title: true,
    //   authrequired: false,
    //   maintenance: false,
    // },
		// {
    //   name: 'Earnings',
    //   href: '/earnings',
    //   icon: "BanknotesIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    // },
    // {
    //   name: 'Funds',
    //   href: '/funds',
    //   icon: "BuildingLibraryIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    //   // modal: {
    //   //   open: false,
    //   //   type: "Info",
    //   //   headline: "Funds",
    //   //   htmlInformation: true,
    //   //   information: '<ul role="list"><li class="my-3">Exchange Traded Funds</li><li class="my-3">Hedge Funds</li><li class="my-3">Index Funds</li><li class="my-3">Mutual Funds</li><li class="my-3">Private Funds</li><li class="my-3">Portfolio Funds</li><li class="my-3">Trust Funds</li><li class="my-3">Alternative Investment Funds</li></ul>',
    //   //   actionClose: true
    //   // },
    // },
    // {
    //   name: 'Trade Trace',
    //   href: '/tradetrace',
    //   icon: "MagnifyingGlassIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   maintenance: false,
    //   authrequired: true,
    //   modal: {
    //     open: false,
    //     type: "Info",
    //     headline: "Trade Trace",
    //     htmlInformation: false,
    //     information: "This tool allows you to trace your trade for a stock and confirm it's existence on the consolidated tape. It was primarily built to confirm the exchange that an order is routed to. Not all trades are tracable, example being a trade where your broker may bulk your order with other orders and send it to the exchange in an allotment. In order to utilize this tool, it helps to have a trade confirmation to narrow trade trace results.",
    //     actionClose: true
    //   },
    // },
    // {
    //   name: 'Shorting',
    //   href: '/ssrlist',
    //   icon: "HandThumbDownIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   maintenance: true,
    //   authrequired: true,
    //   modal: {
    //     open: false,
    //     type: "Info",
    //     headline: "SSR List",
    //     htmlInformation: true,
    //     information: '<p class="pb-5 text-justify">Prior to using this tool, it\'s important that you recognize the following:</p><ol role="list" class="decimal"><li class="my-3">The short selling rule (SSR) is enforced by brokers, not the exchanges, or SEC.</li><li class="my-3">This tool utilizes trade data (CTA/SIP feed) directly from the exchanges to compile results.</li><li class="my-3">SSR should be triggered when a stock declines below 10% of the previous day\'s close and should last until the close of the next trading day.</li><li class="my-3">When SSR is enforced, every trade that enforcement is applicable to, should have a flag that\'s visible in the CTA data.</li></ol>',
    //     actionClose: true
    //   },
    // },
    // {
    //   name: 'Threshold List',
    //   href: '/thresholdlist',
    //   icon: "ArrowTrendingDownIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    //   modal: {
    //     open: false,
    //     type: "Info",
    //     headline: "Threshold List",
    //     htmlInformation: true,
    //     information: '<p class="pb-5 text-justify">A threshold security:</p><ol role="list" class="decimal"><li class="my-3">There are aggregate fails to deliver at a registered clearing agency of 10k shares or more per security</li><li class="my-3">The level of fails is equal to at least 1/2 of 1% of the issuer’s total shares outstanding</li><li class="my-3">The security is included on a list published by a self-regulatory organization (SRO)</li></ol><p class="pb-5 text-justify">A security ceases to be a threshold security if it does not exceed the specified level of fails for five consecutive settlement days.</p>',
    //     actionClose: true
    //   },
    // },
    // {
    //   name: 'Failure To Delivers',
    //   href: '/failtodelivers',
    //   icon: "FlagIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: true,
    //   maintenance: true,
    //   // modal: {
    //   //   open: false,
    //   //   type: "Info",
    //   //   headline: "Threshold List",
    //   //   htmlInformation: true,
    //   //   information: '<p class="pb-5 text-justify">A threshold security:</p><ol role="list" class="decimal"><li class="my-3">There are aggregate fails to deliver at a registered clearing agency of 10k shares or more per security</li><li class="my-3">The level of fails is equal to at least 1/2 of 1% of the issuer’s total shares outstanding</li><li class="my-3">The security is included on a list published by a self-regulatory organization (SRO)</li></ol><p class="pb-5 text-justify">A security ceases to be a threshold security if it does not exceed the specified level of fails for five consecutive settlement days.</p>',
    //   //   actionClose: true
    //   // },
    // },
    // {
    //   name: 'Volume',
    //   href: '/volume',
    //   icon: "SpeakerWaveIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: false,
    //   maintenance: true,
    //   modal: {
    //     open: false,
    //     type: "Info",
    //     headline: "Volume Spikes",
    //     htmlInformation: true,
    //     information: '<p class="pb-5 text-justify">This tool tracks all publicly traded stocks, etfs, and options and displays volume spikes in trades executed or single orders added to the order book. All data is tracked in real time with time since alert chronicled displayed.</p>',
    //     actionClose: true
    //   },
    // },
    {
      name: 'Notable Trades',
      href: '/notabletrades',
      icon: "TicketIcon",
      current: false,
      menu: true,
      title: true,
      // menulevel: 'red',
      // authrequired: true,
      authrequired: false,
      maintenance: false,
      // modal: {
      //   open: false,
      //   type: "Info",
      //   headline: "Notable Trades",
      //   htmlInformation: true,
      //   information: '<p class="pb-5 text-justify">This system tracks all near term option contracts and displays trades which exceed the average metrics.</p>',
      //   actionClose: true
      // },
    },
    {
      name: 'Alerts',
      href: '/alerts',
      icon: "BellIcon",
      current: false,
      menu: true,
      mobilemenu: false,
      title: true,
      // menulevel: '1337',
      authrequired: true,
      maintenance: false,
    },
    {
      name: 'Account',
      href: '/account',
      icon: "UserIcon",
      current: false,
      menu: false,
      title: true,
      authrequired: true,
      maintenance: false,
    },
    {
      name: 'Heimdall',
      href: '/heimdall',
      icon: "LockClosedIcon",
      current: false,
      menu: false,
      title: true,
      authrequired: false,
      maintenance: false,
    },
    // {
    //   name: 'About',
    //   href: '/about',
    //   icon: "AcademicCapIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: false,
    //   maintenance: false,
    // },
    // {
    //   name: 'Chart',
    //   href: '/chart',
    //   icon: "PresentationChartLineIcon",
    //   current: false,
    //   menu: true,
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    // },
    // {
    //   name: 'Discrepancies',
    //   href: '/discrepancies',
    //   icon: "HandRaisedIcon",
    //   current: false,
    //   menu: true,
    //   menulevel: 'red',
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    //   modal: {
    //     open: false,
    //     type: "Info",
    //     headline: "Data Discrepancies",
    //     htmlInformation: true,
    //     information: `<p class="pb-5 text-justify">This tool tracks the highs and lows of stocks and compares those highs and lows to the actual trades that occur on the SIP feed.<br/>Only variations that are 5% over the high or lows are shown.</p>`,
    //     actionClose: true
    //   },
    // },
    // {
    //   name: 'Trade Log',
    //   href: '/tradelog',
    //   icon: "BookOpenIcon",
    //   current: false,
    //   menu: true,
    //   menulevel: 'black',
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    // },
    {
      name: 'Pricing',
      href: '/pricing',
      icon: "CurrencyDollarIcon",
      current: false,
      menu: false,
      title: false,
      authrequired: false,
      maintenance: false,
    },
		// {
		// 	// available only to professionals
    //   name: 'Brokerage',
    //   href: '/brokerage',
    //   icon: "BriefcaseIcon",
    //   current: false,
    //   menu: true,
    //   menulevel: 'diamond',
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    // },
    // {
    //   name: 'Backtest',
    //   href: '/backtest',
    //   icon: "AcademicCapIcon",
    //   current: false,
    //   menu: true,
    //   menulevel: 'diamond',
    //   title: true,
    //   authrequired: true,
    //   maintenance: true,
    // },
    // {
    //   name: 'Option Scales',
    //   href: '/optionscales',
    //   icon: "ScaleIcon",
    //   current: false,
    //   menu: true,
    //   menulevel: 'diamond',
    //   title: true,
    //   authrequired: true,
    //   maintenance: false,
    // },
		{
			// available only to internal users
      name: 'Media',
      href: '/media',
      icon: "MegaphoneIcon",
      current: false,
      menu: true,
      menulevel: '1337',
      title: true,
      authrequired: true,
      maintenance: false,
    },
		{
			// available only to internal users
      name: 'System',
      href: '/system',
      icon: "CommandLineIcon",
      current: false,
      menu: true,
      menulevel: '1337',
      title: true,
      authrequired: true,
      maintenance: false,
    },
    // { name:'Reports', href:'/reports', icon:"DocumentChartBarIcon", current:false },
    // { name:'Options', href:'/options', icon:"ScaleIcon", authrequired:true, current:false },
    // { name:'Reference', href:'/reference', icon:"AcademicCapIcon", current:false },
    // { name:'Information', href:'/information', icon:"InformationCircleIcon", current:false },
  ]
};

export default app;