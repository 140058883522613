import React, {Fragment, useEffect, useState, useRef} from 'react';
import config from "../config.json";
import Headers from "../bin/Headers";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import updateRobinhoodToken from "../bin/updateRobinhoodToken";
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from "@heroicons/react/24/solid";
import LOADING from "./LOADING";

const MODAL = (props) => {
	const token= useSelector(state => state.token);
	const data = props.data || null,
		open = props.open || false,
		setOpen = props.setOpen || null,
		[balance, setBalance] = useState(0),
		[priceMethod, setPriceMethod] = useState('limit'),
		[sizeMethod, setSizeMethod] = useState('fixed'),
		[tokenRequired, setTokenRequired] = useState(false),
		[shareData, setShareData] = useState(null),
		[LoadingValue, setLoadingValue] = useState(false);
	const cancelButtonRef = useRef(null);

	const start = async () => {
		if (!data) { return; }
		setLoadingValue(true);
		// let [_balance, _pendingOrders, _position] = await Promise.all([
		let [_balance] = await Promise.all([
			// fetch(`${config.api}/robinhoodbalance`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.cashBalance ? d.cashBalance : null),
			// fetch(`${config.api}/robinhoodbalance`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.buyingPower ? d.buyingPower : null),
			fetch(`${config.api}/robinhoodbalance`, { headers: Headers(token) }).then(d => d.json()).then(d => d ? d : null),
			// fetch(`${config.api}/robinhoodoptionorders?underlyingTicker=${data.UnderlyingTicker}&optionExpiration=${data.ExpirationDateString}&optionType=${data.OptionType}&optionStrike=${data.OptionStrike}`, { headers: Headers(token), }).then(d => d.json()).then(d => d && d.pendingOrders && d.pendingOrders.length && d.pendingOrders.length === 1 ? d.pendingOrders[0] : null),
			// fetch(`${config.api}/robinhoodpositions?underlyingTicker=${data.UnderlyingTicker}&optionExpiration=${data.ExpirationDateString}&optionType=${data.OptionType}&optionStrike=${data.OptionStrike}`, { headers: Headers(token), }).then(d => d.json()).then(d => d && d.optionPositions && d.optionPositions.length && d.optionPositions.length === 1 ? d.optionPositions[0] : null)
		]);
		if (_balance && _balance.hasOwnProperty('accountNumber') && _balance.accountNumber===null) {
			setTokenRequired(true);
			setLoadingValue(false);
			return;
		}
		setTokenRequired(false);
		setBalance(_balance.buyingPower);
		// setPendingOrder(_pendingOrders);
		// setPosition(_position);
		let Size = _balance.buyingPower && data && data.Price ? Math.floor(_balance.buyingPower / data.Price) : 0;
		setShareData({
			Ticker: data && data.Ticker ? data.Ticker : null,
			Price: data && data.Price ? data.Price : null,
			Size,
		});
		setLoadingValue(false);
	};

	const confirmOrder = async () => {
		let confirmed = window.confirm(`Are you sure you want to send this order?\nBUY ${shareData.Size && !isNaN(shareData.Size) ? (shareData.Size).toLocaleString() : '-'} ${shareData.Ticker} for $${shareData.Price && !isNaN(shareData.Price) ? shareData.Price : '-'}\nTotal Cost: $${truncateToTwoDigits(shareData.Price * shareData.Size)}`);
		if (!confirmed) { return; }
		let sendOrder = await fetch(`${config.api}/robinhoodorderentry`, { method: 'POST', headers: Headers(token), body: JSON.stringify({ trade: { ticker: shareData.Ticker, price: shareData.Price, quantity: shareData.Size } }) }).then(d => d.json()).then(d => d ? d : null);
		if (sendOrder && sendOrder.buyOrderId) {
			alert(`Order sent: ${sendOrder.buyOrderId}`);
			setOpen(false);
		} else {
			alert(`Order failed: ${sendOrder && sendOrder.message ? sendOrder.message : 'unknown'}`);
		}
	}

	useEffect(() => {start()}, [data]);

	const outputTradeForm = () => {
		return (
			<div className="mt-2 w-full flex flex-col items-start justify-center">
				<span className="flex flex-row w-full items-start justify-between gap-1 mb-5">
					<span className='font-light text-gray-400 w-100 text-left'>Ticker:</span>
					<span className="ml-1 font-light w-100 text-right text-gray-800">
						{shareData && shareData.Ticker ? shareData.Ticker : '-'}
					</span>
				</span>
				<span className={`flex flex-row w-full items-start justify-center`}>
					<select className='font-light text-gray-400 text-left' defaultValue={priceMethod} onChange={(evt) => {setPriceMethod(evt.target.value)}}>
						<option value="limit">Entry Price</option>
						<option value="matchAsk">Match Ask</option>
						<option value="market">Market</option>
					</select>
					<span className={`font-light text-right text-gray-800 flex-grow ${priceMethod === 'limit' ? 'block' : 'hidden'}`}>
						<input type="text" className='w-full' value={shareData && shareData.Price ? shareData.Price : ''} onChange={(evt) => { setShareData({ ...shareData, Price: evt.target.value }) }} />
					</span>
				</span>
				<span className={`flex flex-row w-full items-start justify-center`}>
					<select className='font-light text-gray-400 text-left' defaultValue={sizeMethod} onChange={(evt) => {setSizeMethod(evt.target.value)}}>
						<option value="fixed">Entry Size</option>
						<option value="capitalLimit">Capital Limit</option>
						<option value="percentLimit">Percent Limit</option>
					</select>
					<span className={`font-light text-right text-gray-800 flex-grow ${priceMethod === 'limit' ? 'block' : 'hidden'}`}>
						<input type="text" className='w-full' value={shareData && shareData.Size ? shareData.Size : ''} onChange={(evt) => { setShareData({ ...shareData, Size: evt.target.value }) }} />
					</span>
				</span>
				{shareData && shareData.Size && shareData.Price ?
					<span className="flex flex-row w-full items-start justify-between gap-1 mt-5 bg-sky-800 p-2 rounded-t">
						<span className='font-light text-gray-400 w-100 text-left text-sky-200'>Order:</span>
						<span className="font-light w-full text-right text-white flex flex-col items-end justify-start">
							<span>{shareData.Size} for ${shareData.Price}</span>
							<span className='text-amber-100 text-xs rounded bg-amber-700 px-2'>Cost: ${truncateToTwoDigits(shareData.Size * shareData.Price)}</span>
						</span>
					</span>
					:
					<></>}
				<span className={`flex flex-row w-full items-start justify-center bg-sky-900 text-xs text-gray-200 ${shareData && shareData.Size && shareData.Price ? 'rounded-b' : 'rounded'}`}>
					Balance: ${balance && !isNaN(balance) ? balance.toLocaleString() : 0 }
				</span>
				{shareData && shareData.Size && shareData.Price ?
					<span className={`mt-5 flex flex-row w-full items-center justify-center`}>
						<button className='rounded px-3 py-1 bg-emerald-700 text-emerald-100' onClick={()=>confirmOrder()}>Send Order</button>
					</span>
					:
					<></>}
			</div>
		);
	};

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 md:items-start">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" >
						{/* <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"> */}
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full min-w-[310px] sm:p-6">
							<div className="absolute right-0 top-0 pr-4 pt-4 block">
								<button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => setOpen(false)} >
									<XMarkIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">Stock Trade</Dialog.Title>
								<div className="mt-2">
									{ LoadingValue===true && 
										<div className='w-full'>
											<LOADING color="text-green-700" />
										</div>
									}
								</div>
								{LoadingValue === false && !tokenRequired ? outputTradeForm() : ''}
								{!LoadingValue && tokenRequired ? <span className="flex flex-row w-full items-center justify-center">
									<input placeholder='robinhood token' defaultValue='' onKeyDown={(evt) => { if (evt.key === 'Enter') { updateRobinhoodToken(evt.target.value, token); } }} className="border border-indigo-800 text-xxs px-2 rounded font-light gap-1 text-sky-500" />
								</span> : <></>}
							</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
export default MODAL;