import formatTrade from "../bin/formatTrade";
import epochConverterToEST from "../bin/epochConverterToEST";
import abbrInt from "./abbrInt";

// const displayTradeCompressedReturnJSX = (trade, emphasisPrice=false, debug=false) => {
const displayTradeCompressedReturnJSX = (trade, emphasisPrice=false, field=null) => {
  if (!trade){return;}
  // let d = epochConverterToEST(trade.sip_timestamp);
  // let d = epochConverterToEST(trade.t);
  //     d = `${d.Hour}:${d.Minute}:${d.Second}${Number(d.Millisecond)>0 ? '.'+d.Millisecond : ''}`;
  //     d = <span className='text-xxs text-gray-500'> [{ d }]</span>;
  let d = <span className='text-xxs text-gray-500'> [{ trade.EntryTimeString }]</span>;
  // trade = formatTrade(trade, true);
  // let size = trade && trade.s ? trade.s : null;
  let size = trade && trade.Size ? trade.Size : null;
  // let price = trade && trade.p && !isNaN(trade.p) ? (trade.p).toLocaleString() : null;
  // let price = trade && trade.p ? (trade.p).toLocaleString() : null;
  let price = trade && trade.Price ? (trade.Price).toLocaleString() : null;
	if (field==='Size'){
		return <span>{ abbrInt(size, false) }</span>
	}
  if (emphasisPrice){
		if (field==='Price'){
			return <span className='text-lg text-extrabold text-sky-500'>{ price }</span>
		}
    return <span>{ abbrInt(size, false) } @ <span className='text-lg text-extrabold text-sky-500'>{ price }</span></span>;
  }
  // return <span>{ abbrInt(size, false) } @ { price }{ d }</span>;
  return <span>{ abbrInt(size, false) } @ { price }</span>;
};

export default displayTradeCompressedReturnJSX;