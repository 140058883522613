import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import abbrInt from '../bin/abbrInt';

const MAIN = ({ liquidCandles, trades, candles, priceLevels, showType }) => {
	const chartRef = useRef(null);

	useEffect(() => {
		const chart = echarts.init(chartRef.current);
		const closeValues = candles.map(candle => ({
			value: candle.Close,
			date: `${(candle.TimeString).split(':').slice(0, 2).join(':')}`
		}));

		const candleData = candles.map(candle => ({
			value: [candle.Open, candle.Close, candle.Low, candle.High, candle.Volume],
			date: `${(candle.TimeString).split(':').slice(0, 2).join(':')}`
		}));
		const maxVolume = Math.max(...candles.map(candle => candle.Volume));
		const scaledVolumeFactor = maxVolume * 0.25;

		const volumeData = candles.map(candle => ({
			value: candle.Volume / scaledVolumeFactor,
			date: `${(candle.TimeString).split(':').slice(0, 2).join(':')}`,
			itemStyle: {
				color: candle.Close >= candle.Open ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)'
			}
		}));

		const sentimentColors = {
			BULLISH: 'rgb(0, 255, 0)',
			BEARISH: 'rgb(255, 0, 0)',
			NEUTRAL: 'rgb(255, 255, 0)'
		};

		const tradeMarkers = trades && trades.length ? trades.map(trade => {
			let sentiment = 'NEUTRAL';
			if (trade.Type === 'CALL' && trade.TradeSide === 'BUY') { sentiment = 'BULLISH'; }
			if (trade.Type === 'PUT' && trade.TradeSide === 'BUY') { sentiment = 'BEARISH'; }
			if (trade.Type === 'CALL' && trade.TradeSide === 'SELL') { sentiment = 'BEARISH'; }
			if (trade.Type === 'PUT' && trade.TradeSide === 'SELL') { sentiment = 'BULLISH'; }
			return {
				trade: `$${abbrInt(trade.Notional)}`,
				sentiment,
				value: trade.UnderlyingPriceAtTrade,
				date: `${(trade.EntryTimeString).split(':').slice(0, 2).join(':')}`,
				color: sentimentColors[sentiment]
			};
		}) : [];

		const bearishPremium = liquidCandles && liquidCandles.length ? liquidCandles.map(candle => ({
			value: showType === 'premium' ? candle.bearishPremium : candle.bearishShares,
			date: `${(candle.time).split(':').slice(0, 2).join(':')}`,
			color: 'red'
		})) : [];

		const bullishPremium = liquidCandles && liquidCandles.length ? liquidCandles.map(candle => ({
			value: showType === 'premium' ? candle.bullishPremium : candle.bullishShares,
			date: `${(candle.time).split(':').slice(0, 2).join(':')}`,
			color: 'green'
		})) : [];

		const priceLines = priceLevels.map(entry => {
			let p = {
				name: `${entry.price}`,
				yAxis: entry.price,
				label: {
					show: true,
					position: 'insideStartTop',
					formatter: `${entry.price}`
				},
				lineStyle: {
					type: 'dotted',
					color: entry.dates && entry.dates.length === 1 ? 'rgb(255, 191, 0)' : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						{ offset: 0, color: 'rgb(4, 119, 148)' },
						{ offset: 1, color: 'rgb(4, 119, 148)' }
					])
				}
			};
			return p;
		});

		const allPrices = [
			// ...closeValues.map(item => item.value),
			...candleData.map(item => item.value[1]),
			...priceLevels.map(entry => entry.price)
		];
		const yAxisMin = Math.min(...allPrices);
		const yAxisMax = Math.max(...allPrices);

		const option = {
			textStyle: {
				color: 'white'
			},
			grid: [
				{
					left: '10%',
					right: '10%',
					top: '10%',
					height: '60%' // Adjust the height of the main grid for the candlestick chart
				},
				{
					left: '10%',
					right: '10%',
					top: '75%',
					height: '15%' // Height of the volume bars
				}
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross'
				},
				backgroundColor: 'rgba(0, 0, 0, 0.80)', // Make tooltip background partially transparent
				position: function (point, params, dom, rect, size) {
					const chartWidth = size.viewSize[0];
					const tooltipWidth = size.contentSize[0];
					const x = point[0];

					if (x < chartWidth / 2) {
						// Position tooltip on the right
						return [chartWidth - tooltipWidth, 0];
					} else {
						// Position tooltip on the left
						return [0, 0];
					}
				},
				formatter: function (params) {
					const date = params[0].axisValue;
					const priceData = params.find(param => param.seriesName === 'Price')?.data;
					let open = priceData && priceData[1] && !isNaN(priceData[1]) ? `${(priceData[1]).toLocaleString()}` : '-';
					let high = priceData && priceData[4] && !isNaN(priceData[4]) ? `${(priceData[4]).toLocaleString()}` : '-';
					let low = priceData && priceData[3] && !isNaN(priceData[3]) ? `${(priceData[3]).toLocaleString()}` : '-';
					let close = priceData && priceData[2] && !isNaN(priceData[2]) ? `${(priceData[2]).toLocaleString()}` : '-';
					let volume = priceData && priceData[5] && !isNaN(priceData[5]) ? `${(priceData[5]).toLocaleString()}` : '-';
					const bearishValue = params.find(param => param.seriesName.includes('Bearish'))?.data;
					const bullishValue = params.find(param => param.seriesName.includes('Bullish'))?.data;
					const bearishLabel = showType === 'premium' ? 'Bearish Premium' : 'Bearish Shares';
					const bullishLabel = showType === 'premium' ? 'Bullish Premium' : 'Bullish Shares';
					let difference = bearishValue && bullishValue ? `${Number( (((Math.max(bearishValue, bullishValue) / Math.min(bearishValue, bullishValue)) - 1) * 100).toFixed(2) )}%` : `0%`;
					let differenceColor = (bearishValue > bullishValue ? 'red' : (bearishValue < bullishValue ? 'green' : 'white'));
		
					return `
						<div style="padding: 10px;">
							<span style="color:white;">● Time: ${date}</span><br/>
							<span style="color:white;">● Open: ${open}</span><br/>
							<span style="color:white;">● High ${high}</span><br/>
							<span style="color:white;">● Low: ${low}</span><br/>
							<span style="color:white;">● Close: ${close}</span><br/>
							<span style="color:white;">● Volume: ${volume}</span><br/>
							<span style="color:red;">● ${bearishLabel}: ${bearishValue && !isNaN(bearishValue) ? `${showType==='premium' ? '$' : ''}${bearishValue.toLocaleString()}` : bearishValue}</span><br/>
							<span style="color:green;">● ${bullishLabel}: ${bullishValue && !isNaN(bullishValue) ? `${showType === 'premium' ? '$' : ''}${bullishValue.toLocaleString()}` : bullishValue}</span><br/>
							<span style="color:${differenceColor};">● Difference: ${difference}</span>
						</div>
					`;
				}
			},
			xAxis: [
				{
					type: 'category',
					data: candleData.map(item => item.date),
					boundaryGap: true,
					axisLine: {
						onZero: false,
						lineStyle: {
							color: 'black'
						}
					},
					splitLine: { show: false },
					min: 'dataMin',
					max: 'dataMax'
				},
				// {
				// 	type: 'category',
				// 	gridIndex: 1,
				// 	data: volumeData.map(item => item.date),
				// 	boundaryGap: true,
				// 	axisLine: {
				// 		onZero: false,
				// 		lineStyle: {
				// 			color: 'black'
				// 		}
				// 	},
				// 	axisLabel: { show: false },
				// 	splitLine: { show: false },
				// 	min: 'dataMin',
				// 	max: 'dataMax'
				// }
			],
			yAxis: [
				{
					type: 'value',
					name: 'Price',
					position: 'right',
					min: yAxisMin,
					max: yAxisMax,
					axisLabel: {
						color: 'white'
					},
					axisLine: {
						onZero: false,
						lineStyle: {
							color: 'black'
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)' // Adjust the color as needed
						}
					},
					// interval: 0.25 // Set interval to $0.25
					// interval: 0.50 // Set interval to $0.50
					// interval: 1 // Set interval to $1
					// interval: 5 // Set interval to $5
				},
				{
					type: 'value',
					name: 'Volume',
					show:false,
					axisLabel: {
						color: 'white'
					},
					axisLine: {
						lineStyle: {
							color: 'black'
						}
					},
					splitLine: { show: false }
				},
				// {
				// 	type: 'value',
				// 	gridIndex: 1,
				// 	axisLabel: { color: 'white' },
				// 	axisLine: { lineStyle: { color: 'black' } },
				// 	splitLine: { show: false }
				// },
				{
					type: 'value',
					name: showType === 'premium' ? 'Premium' : 'Shares',
					min: 'dataMin',
					max: 'dataMax',
					show: false,
					axisLabel: {
						color: 'white'
					},
					axisLine: {
						onZero: false,
						lineStyle: {
							color: 'black'
						}
					},
					splitLine: { show: false } // Hide split lines for the second yAxis
				}
			],
			dataZoom: [
				{
					type: 'inside',
					start: 0,
					end: 100,
					xAxisIndex: [0, 1]
				},
				{
					show: true,
					type: 'slider',
					top: '90%',
					start: 0,
					end: 100,
					// xAxisIndex: [0, 1]
				}
			],
			series: [
				{
					type: 'candlestick',
					name: 'Price',
					data: candleData.map(item => item.value),
					itemStyle: {
						color: 'green',
						color0: 'red',
						borderColor: 'green',
						borderColor0: 'red'
					}
				},
				{
					type: 'bar',
					name: 'Volume',
					// yAxisIndex: 1,
					yAxisIndex: 0,
					data: volumeData.map(item => item.value), // Use scaled volume data
					barWidth: '60%', // Adjust the width of the volume bars
					itemStyle: {
						color: function (params) {
							return candles[params.dataIndex].Close >= candles[params.dataIndex].Open ? 'rgba(0, 255, 0, 0.15)' : 'rgba(255, 0, 0, 0.15)';
						}
					}
				},
				{
					type: 'line',
					name: 'Price',
					// data: closeValues.map(item => item.value),
					// lineStyle: {
					// 	width: 2,
					// 	color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					// 		{ offset: 0, color: 'rgb(255, 0, 135)' },
					// 		{ offset: 1, color: 'rgb(135, 0, 157)' }
					// 	])
					// },
					// showSymbol: false,
					// areaStyle: {
					// 	opacity: 0.2,
					// 	color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					// 		{ offset: 0, color: 'rgb(255, 158, 68)' },
					// 		{ offset: 1, color: 'rgb(255, 70, 131)' }
					// 	])
					// },
					markPoint: {
						symbol: 'pin',
						symbolSize: 50,
						label: {
							formatter: '{b}',
							position: 'inside',
							color: 'black',
							fontSize: 10
						},
						data: tradeMarkers.map(marker => ({
							name: marker.trade,
							value: marker.value,
							coord: [marker.date, marker.value],
							itemStyle: { color: marker.color }
						})),
						tooltip: {
							formatter: (param) => {
								const trade = trades.find(trade => `${(trade.EntryTimeString).split(':').slice(0, 2).join(':')}` === param.data.coord[0]);
								return `Trade: ${trade.Ticker}<br/>Price: ${trade.Price}<br/>Time: ${param.data.coord[0]}`;
							}
						}
					},
					markLine: {
						symbol: ['none', 'none'], // Remove the starting dot and ending arrow
						data: priceLines
					}
				},
				{
					type: 'line',
					name: showType === 'premium' ? 'Bearish Premium' : 'Bearish Shares',
					yAxisIndex: 1,
					data: bearishPremium.map(item => item.value),
					lineStyle: {
						width: 2,
						color: 'red'
					},
					showSymbol: false
				},
				{
					type: 'line',
					name: showType === 'premium' ? 'Bullish Premium' : 'Bullish Shares',
					yAxisIndex: 1,
					data: bullishPremium.map(item => item.value),
					lineStyle: {
						width: 2,
						color: 'green'
					},
					showSymbol: false
				}
			],
			backgroundColor: '#2c343c'
		};

		chart.setOption(option);

		return () => { chart.dispose(); };
	}, [trades, candles, priceLevels, showType]);

	return <div ref={chartRef} style={{ width: '100%', height: '500px' }} />;
};

export default MAIN;
