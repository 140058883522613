import { Link } from "react-router-dom";

const LOGIN_REQUIRED = () => {
  return (
    <div className="py-4 text-white">
      <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 flex flex-col gap-5 justify-center items-center">
				<h1>You must be logged in to access this page.</h1>
				<Link to="/login" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Login</Link>
      </div>
    </div>  
  );
}
export default LOGIN_REQUIRED
