import truncateToTwoDigits from './truncateToTwoDigits';
const main = (initialPrice, finalPrice, days) => {
	// Calculate the fixed percentage decline needed each day
	const r = Math.pow(finalPrice / initialPrice, 1 / days) - 1;
	
	// Convert it to percentage
	const percentageDecline = r * 100;

	return truncateToTwoDigits(percentageDecline);
};
export default main;