import React, { useEffect, useState } from 'react';
import config from '../config.json';
import LOADING from "../components/LOADING";
import { useSearchParams } from 'react-router-dom';
import dontRerender from "../bin/dontRerender";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import removeCentsFromStrike from "../bin/removeCentsFromStrike";
import abbrInt from "../bin/abbrInt";
import daysBetween from "../bin/daysBetween";
import isOiProbableUi from "../bin/isOiProbableUi";

const MAIN = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const urlId = searchParams.get("id");
	const urlToken = searchParams.get("token");
	const [trade, setTrade] = useState({});
	const [LoadingValue, setLoadingValue] = useState(true);
	const [Unauthorized, setUnauthorized] = useState(false);

	const start = async () => {
		if (!urlToken || !urlId) {
			setLoadingValue(false);
			setUnauthorized(true);
			return;
		}
		setLoadingValue(true);
		let headers = { 'Content-Type': 'application/json', 'Authorization': urlToken };
		let _trade = await fetch(`${config.api}/notabletrades?id=${urlId}`, { headers }).then(d => d.json()).then(d => d.message === 'ok' && d.result && d.result[0] ? d.result[0] : d);
		let upcomingEarnings = await fetch(`${config.api}/earnings?tickers=${_trade.UnderlyingTicker}&upcoming=true&date=${_trade.EntryDateString}`, { headers }).then(d => d.json()).then(d => d.message === 'ok' && d.result ? d.result : null).then(d => d && d[0] ? d[0] : null);
		if (upcomingEarnings) {
			let ExpirationDate = new Date(_trade.ExpirationDateString);
			ExpirationDate.setUTCHours(0, 0, 0, 0);
			let EarningsDate = new Date(upcomingEarnings.EarningsDateString);
			if (EarningsDate <= ExpirationDate) {
				_trade.EarningsDateString = `${EarningsDate.getUTCFullYear()}-${(EarningsDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${EarningsDate.getUTCDate().toString().padStart(2, '0')}`;
				_trade.EarningsTime = upcomingEarnings.EarningsTime;
				_trade.EarningsDaysTill= daysBetween(upcomingEarnings.EarningsDateString, _trade.EntryDateString);
			}
		}
		setTrade(_trade);
		setLoadingValue(false);
		setUnauthorized(false);
	};

	useEffect(() => { start(); }, []);

	return (
		<div className='w-[300px] h-[250px]'>
			{Unauthorized === true && <div className='w-full'><h1 className="text-center my-10 text-white">UNAUTHORIZED</h1></div>}
			{LoadingValue === true && <div className='w-full'><LOADING color="text-green-700" /></div>}
			{LoadingValue === false ?
				<table className="divide-y divide-gray-400 w-full self-start text-xs whitespace-nowrap">
					<tbody className='divide-y divide-gray-800'>
						<tr className='divide-x divide-gray-800 bg-opacity-25 text-left bg-gray-800 text-xl text-gray-300'>
							<td className="w-[300px] p-2 font-light align-top block text-left">
								<span className='font-bold'>{ trade.UnderlyingTicker }</span> - <span className={`${ trade.OptionType==='PUT' ? 'text-red-700' : '' }${ trade.OptionType==='CALL' ? 'text-green-700' : '' }`}>{ trade.OptionType }</span> - <span className='text-amber-600'>${ removeCentsFromStrike(Number((trade.OptionStrike).toFixed(2)).toFixed(2).toLocaleString()) }</span><br/>
								{ (trade.Size).toLocaleString() } @ ${ ( Number((trade.Price).toFixed(2)) ).toFixed(2).toLocaleString() } = <span className='font-light text-amber-300'>${ abbrInt(trade.Notional) }</span><br/>
								<span className='flex flex-row w-100 justify-start items-between'>
									<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Trade Date:</span><span className="ml-1 font-light w-100 text-center text-sky-700">{ trade.EntryDateString }</span>{isNaN(trade.DaysToExpiration) === false ? <span className='text-red-500 ml-1'>{trade.DaysToExpiration} DTE</span> : ''}
								</span>
								{trade.Expired ?
									<span className='block font-medium bg-rose-900 text-gray-300 w-100 text-center'>expired</span> :
									<span className='flex flex-row w-100 justify-start items-between'>
										<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Expires:</span><span className="ml-1 font-light w-100 text-center text-rose-600">{ trade.ExpirationDateString }</span>
									</span>
								}
								<span className='flex flex-row w-100 justify-between items-center'>
									{ trade.TradeSide==="BUY" ? <span className='text-green-500 mr-1'>BUY SIDE</span> : ''}
									{ trade.TradeSide==="SELL" ? <span className='text-red-500 mr-1'>SELL SIDE</span> : ''}
									<span className='text-gray-500'>{trade.EntryTimeString}</span>
								</span>
								{trade.OpenInterestIncreasedNetProjectionMinimumAfterTrade ? <><span className='block font-medium text-amber-500 w-100 text-center'>oi confirmed</span></> : isOiProbableUi(trade)}
								{!trade.OpenInterestIncreasedNetProjectionMinimumAfterTrade && trade.OpenInterestIncreasedExceedsTradeSizePotentiallyOpen ? <><br /><span className='block font-medium text-violet-700 w-100 text-center'>oi supports open position</span></> : <></>}
								{trade.UnderlyingPriceAtTrade ?
									<span className='flex flex-row w-100 justify-between items-center'>
										<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Underlying:</span>
										<span className={`ml-1 font-light w-100 text-center ${trade.Moneyness === 'OTM' ? `text-amber-200` : ``}${trade.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>${(Number((trade.UnderlyingPriceAtTrade).toFixed(2))).toFixed(2).toLocaleString()}</span>
										<span className={`mr-1 font-light w-100 text-right flex-grow ${trade.Moneyness === 'OTM' ? `text-amber-500` : ``}${trade.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>{trade.Moneyness}</span>
									</span>
								:<></>}
								{trade.BreakevenPrice && trade.BreakevenMove ? 
									<span className='flex flex-row w-100 justify-between items-center'>
										<span className='font-light text-gray-400 w-100 text-left text-[16px]'>Breakeven:</span>
										<span className="ml-1 font-light w-100 text-center text-indigo-200">${(Number((trade.BreakevenPrice).toFixed(2))).toFixed(2).toLocaleString()}</span>
										<span className="mr-1 font-light w-100 text-right flex-grow text-indigo-400">{trade.BreakevenMove}%</span>
									</span>
								:<></>}
								{trade.EarningsDateString ?
									<span className='flex flex-row w-100 bg-amber-950 w-100 justify-between items-center'>
										<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Earnings:</span>
										<span className="ml-1 font-light w-100 text-center text-amber-300">{ trade.EarningsDateString }</span>
										{trade.EarningsTime && trade.EarningsTime !== 'Unknown' ? <span className="ml-1 font-light text-center text-amber-200 text-[8px]">{(trade.EarningsTime).toLowerCase()}</span> : <></>}
										<span className='text-yellow-500 mr-1 flex-grow text-right'>{trade.EarningsDaysTill}d</span>
									</span>
								:<></>}
							</td>
						</tr>
					</tbody>
				</table>
			: <></>}
    </div>
  );
}

export default React.memo(MAIN, dontRerender);