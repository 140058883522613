import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from "../config.json";
import dontRerender from "../bin/dontRerender";
import Headers from '../bin/Headers';
import readUrlQuery from '../bin/readUrlQuery';
import ICONS from '../bin/ICONS';

const MAIN = (props) => {
  let { email } = readUrlQuery(),
      [emailAddress, setEmailAddress] = useState('');
  
  const checkForSubmit = async (evt) => {
    if (!evt || !evt.keyCode || evt.keyCode!==13){ return; }
    console.log(emailAddress);
  //   if (!ticker){ return; }
  //   let headers= Headers(token);
  //   let _tickerDetails= await redstripedtieApi.readTickerDetails(ticker, {headers});
  //   if (!_tickerDetails || !_tickerDetails.result || !_tickerDetails.result){ return; }
  //   updateTickerDetails(_tickerDetails.result);
  };
  
  const runOnMount = async (_email) => {
    // if (!token){ return; }
    if (!_email){ return; }
    setEmailAddress( _email );
    return;
  };

  useEffect(()=> { runOnMount(email); }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full my-10 flex gap-4 flex-wrap justify-center items-center text-black">
      <div className="w-5/6 md:w-3/6">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <ICONS.MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email"
            value={ emailAddress }
            onChange={ (evt)=> setEmailAddress(evt.target.value) }
            onKeyDown={ (evt) =>  checkForSubmit(evt) }
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="you@example.com"
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(MAIN, dontRerender);