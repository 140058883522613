import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import config from "../config.json";
import Headers from "../bin/Headers";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import signedCurrency from "../bin/signedCurrency";
import LOADING from "../components/LOADING";
import { BackspaceIcon, CheckBadgeIcon } from "@heroicons/react/24/solid";
import abbrInt from '../bin/abbrInt';
import removeCentsFromStrike from '../bin/removeCentsFromStrike';
import daysBetween from '../bin/daysBetween';
import isOiProbableUi from "../bin/isOiProbableUi";
import { StarIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarIcon_ } from '@heroicons/react/24/outline';

const MAIN = ({ trade, index, tradeWatchList, updateTradeDetail, setTradeWatchList }) => {
	const token = useSelector(state => state.token);
		
	const modalTradeDetail = (trade) => {
    updateTradeDetail(trade);
	};
	
	const modifyTracking = async (tradeId) => {
		let updatedTradeWatchList = await fetch(`${config.api}/tradewatchlist?tradeid=${tradeId}`, { method: "PUT", headers: Headers(token) }).then(d => d.status === 200 ? d.json() : null).then(d => d.result && d.result.list ? d.result.list : null);
		if (updatedTradeWatchList) { setTradeWatchList(updatedTradeWatchList); }
	};
	
  return (
		<tr key={index} className={`whitespace-nowrap divide-x divide-gray-800 bg-opacity-25 text-left ${index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'} text-xxs text-gray-300`}>
			<td className="p-1 font-light align-top w-5">
      	{ tradeWatchList && tradeWatchList.length && tradeWatchList.includes(trade._id) ?
					<button className='text-amber-600' onClick={()=>{modifyTracking(trade._id)}}><StarIcon className='w-5' /></button>
					: <button className='text-gray-500/20' onClick={()=>{modifyTracking(trade._id)}}><StarIcon_ className='w-5' /></button>
        }
      </td>
			<td className="p-1 font-light align-top w-36">
				<button className='block text-left w-100' onClick={() => modalTradeDetail(trade) }>
					<span className='font-bold'>{ trade.UnderlyingTicker }</span> - <span className={`${ trade.OptionType==='PUT' ? 'text-red-700' : '' }${ trade.OptionType==='CALL' ? 'text-green-700' : '' }`}>{ trade.OptionType }</span> - <span className='text-amber-600'>${ removeCentsFromStrike(Number((trade.OptionStrike).toFixed(2)).toFixed(2).toLocaleString()) }</span><br/>
						{ (trade.Size).toLocaleString() } @ ${ ( Number((trade.Price).toFixed(2)) ).toFixed(2).toLocaleString() } <span className='font-light text-amber-200 bg-amber-950 ml-1 rounded px-0.5 py-0.25'>${ abbrInt(trade.Notional) }</span><br/>
						<span className='flex flex-row w-100 justify-start items-between'>
						<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Trade Date:</span><span className="ml-1 font-light w-100 text-center text-sky-700">{ trade.EntryDateString }</span>{isNaN(trade.DaysToExpiration) === false ? <span className='text-red-500 ml-1'>{trade.DaysToExpiration} DTE</span> : ''}
					</span>
					{trade.Expired ?
						<span className='block font-medium bg-rose-900 text-gray-300 w-100 text-center'>expired</span> :
						<span className='flex flex-row w-100 justify-start items-between'>
							<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Expires:</span><span className="ml-1 font-light w-100 text-center text-rose-600">{ trade.ExpirationDateString }</span>
						</span>
					}
					<span className='flex flex-row w-100 justify-between items-center'>
						{ trade.TradeSide==="BUY" ? <span className='text-green-500 mr-1'>BUY SIDE</span> : ''}
						{ trade.TradeSide==="SELL" ? <span className='text-red-500 mr-1'>SELL SIDE</span> : ''}
						<span className='text-gray-500'>{trade.EntryTimeString}</span>
					</span>
					{trade.OpenInterestIncreasedNetProjectionMinimumAfterTrade ? <><span className='block font-medium text-amber-500 w-100 text-center'>oi confirmed</span></> : isOiProbableUi(trade)}
					{!trade.OpenInterestIncreasedNetProjectionMinimumAfterTrade && trade.OpenInterestIncreasedExceedsTradeSizePotentiallyOpen ? <><br /><span className='block font-medium text-violet-700 w-100 text-center'>oi supports open position</span></> : <></>}
					{trade.UnderlyingPriceAtTrade ?
						<span className='flex flex-row w-100 justify-between items-center'>
							<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Underlying:</span>
							<span className={`ml-1 font-light w-100 text-center ${trade.Moneyness === 'OTM' ? `text-amber-200` : ``}${trade.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>${(Number((trade.UnderlyingPriceAtTrade).toFixed(2))).toFixed(2).toLocaleString()}</span>
							<span className={`mr-1 font-light w-100 text-right flex-grow ${trade.Moneyness === 'OTM' ? `text-amber-500` : ``}${trade.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>{trade.Moneyness}</span>
						</span>
						: <></>
					}
					{trade.BreakevenPrice && trade.BreakevenMove ? 
						<span className='flex flex-row w-100 justify-between items-center'>
							<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Breakeven:</span>
							<span className="ml-1 font-light w-100 text-center text-indigo-200">${(Number((trade.BreakevenPrice).toFixed(2))).toFixed(2).toLocaleString()}</span>
							<span className="mr-1 font-light w-100 text-right flex-grow text-indigo-400">{trade.BreakevenMove}%</span>
						</span>
						: <></>
					}
					{trade.EarningsDateString ?
						<span className='flex flex-row w-100 bg-amber-950 w-100 justify-between items-center'>
							<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Earnings:</span>
							<span className="ml-1 font-light w-100 text-center text-amber-300">{ trade.EarningsDateString }</span>
							{trade.EarningsTime && trade.EarningsTime !== 'Unknown' ? <span className="ml-1 font-light text-center text-amber-200 text-[8px]">{(trade.EarningsTime).toLowerCase()}</span> : <></>}
							<span className='text-yellow-500 mr-1 flex-grow text-right'>{trade.EarningsDaysTill}d</span>
						</span>
						: <></>
					}
				</button>
			</td>
			<td className="p-1 font-light align-top w-24">
				<div className='flex flex-col gap-2'>
					{trade.LowestPercent === 0 && trade.EndingLowestPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
					{trade.LowestPercent != 0 ?
						<span>
							<span className={`font-medium ${trade.LowestPercent < 0 ? `text-red-700` : `text-gray-500`}`}>{(trade.LowestPercent).toLocaleString()}%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>same day</span><br />
							${(trade.Lowest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.Lowest * trade.Size * trade.SharesPerContract),false)}</span><br />
							<span className='text-sky-600'>{trade.TimeToLow}</span>
						</span>
						: <></>
					}
					{trade.EndingLowestPercent != 0 ?
						<span>
							<span className={`font-medium ${trade.EndingLowestPercent < 0 ? `text-red-700` : `text-gray-500`}`}>{(trade.EndingLowestPercent).toLocaleString()}%</span><br />
							${(trade.EndingLowest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EndingLowest * trade.Size * trade.SharesPerContract),false)}</span><br />
							<span className='text-sky-600'>{trade.TimeToEndingLowest}</span>
						</span>
						: <></>
					}
				</div>
			</td>
			<td className="p-1 font-light align-top w-24">
				<div className='flex flex-col gap-2'>
					{trade.HighestPercent === 0 && trade.EndingHighestPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
					{trade.HighestPercent != 0 ?
						<span className='w-100'>
							<span className={`font-medium ${trade.HighestPercent > 0 ? `text-green-700` : `text-gray-500`}`}>{(trade.HighestPercent).toLocaleString()}%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>same day</span><br />
							${(trade.Highest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.Highest * trade.Size * trade.SharesPerContract),false)}</span><br />
							<span className='text-sky-600'>{trade.TimeToHigh}</span>
						</span>
						: <></>
					}
					{trade.EndingHighestPercent != 0 ?
						<span className='w-100'>
							<span className={`font-medium ${trade.EndingHighestPercent > 0 ? `text-green-700` : `text-gray-500`}`}>{(trade.EndingHighestPercent).toLocaleString()}%</span><br />
							${(trade.EndingHighest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EndingHighest * trade.Size * trade.SharesPerContract),false)}</span><br />
							<span className='text-sky-600'>{trade.TimeToEndingHighest}</span>
						</span>
						: <></>
					}
				</div>
			</td>
			<td className="p-1 font-light align-top w-24 bg-gradient-to-b from-indigo-900 to-ingigo-800">
				<div className='flex flex-col gap-2'>
					{trade.EODPercent === 0 && trade.EndingLastPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
					{trade.EODPercent != 0 ?
						<span>
							<span className={`font-medium ${trade.EODPercent>0 ? `text-green-700` : ``} ${trade.EODPercent<0 ? `text-red-700` : ``} ${trade.EODPercent===0 ? `text-gray-500` : ``}`}>{ (trade.EODPercent).toLocaleString() }%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>same day</span><br />
							${(trade.EOD).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EOD * trade.Size * trade.SharesPerContract),false,false)}</span><br />
							<span className='text-sky-600'>{trade.TimeToEOD}</span>
						</span>
						: <></>
					}
					{trade.EndingLastPercent != 0 ?
						<span>
							<span className={`font-medium ${trade.EndingLastPercent>0 ? `text-green-700` : ``} ${trade.EndingLastPercent<0 ? `text-red-700` : ``} ${trade.EndingLastPercent===0 ? `text-gray-500` : ``}`}>{(trade.EndingLastPercent).toLocaleString() }%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>last</span><br />
							${(trade.EndingLast).toLocaleString()} - <span className='text-gray-500'>${abbrInt((trade.EndingLast * trade.Size * trade.SharesPerContract),false,false)}</span><br />
							<span className='text-sky-600'>{trade.TimeToEndingLast}</span>
						</span>
						: <></>
					}
				</div>
			</td>
		</tr>
  );
}
export default MAIN;