import React, {useEffect, useState, useRef} from 'react';
import { Link } from "react-router-dom";
import dontRerender from "../bin/dontRerender";
import config from '../config.json';
import { useStore, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import actions from '../state/actions';
import headers from '../bin/Headers';
import _displayChangeInPercentJSX from "../bin/_displayChangeInPercentJSX";
import decodeJwt from "../bin/decodeJwt";
import formatTimestamp from "../bin/formatTimestamp";
import LOADING from "../components/LOADING";
import TICKER_AUTOCOMPLETE from "../components/TICKER_AUTOCOMPLETE";
import { PlusIcon, TrashIcon, MinusIcon, BellAlertIcon, BellSlashIcon } from "@heroicons/react/24/solid";

const MAIN = (props) => {
	const store = useStore(),
		token = useSelector(state => state.token),
		navigate = useNavigate(),
		decodedToken = decodeJwt(token),
		[searchParams, setSearchParams] = useSearchParams(),
		[pushNotifications, setPushNotifications] = useState(false),
		[supportsPushNotifications, setSupportsPushNotifications] = useState(false),
		[permission, setPermission] = useState('waiting'),
		[matches, setMatches] = useState(0),
		[notices, setNotices] = useState([]),
		[loading, setLoading] = useState(true),
		timerToMarkAsSeen = useRef(null);

	const start = async () => {
		setNotices([]);
		setMatches(0);
		if (!token) { navigate('/login', { replace: true }); return; }
		isUserSubscribed();
		setLoading(true);
		setMatches(0);
		let _notices = await fetch(`${config.api}/notices`, { method: "GET", headers: headers(token) }).then(d => d.json()).then(d => d.message === 'ok' ? d : null);
		if (_notices && _notices.result && _notices.result.length) { setNotices(_notices.result); }
		if (_notices && _notices.matches){ setMatches(_notices.matches); }
		setLoading(false);
		timerToMarkAsSeen.current = setTimeout(() => { markAlertsAsSeen(_notices.result); }, 1000 * 2);
	};
  
	const askForNotificationPermission = async () => {
		try {
			const permission = await Notification.requestPermission();
			console.log("askForNotificationPermission [permission]: ", permission);
			setPermission(permission);
			if (permission !== 'granted') { return; }
			
			const registration = await navigator.serviceWorker.ready;
			console.log("askForNotificationPermission [registration]: ", registration);
			let subscription = await registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(config.vapid_key)
			});
			const authKey = subscription.getKey ? btoa(String.fromCharCode.apply(null, new Uint8Array( subscription.getKey("auth") ))) : "";
      const p256dhKey = subscription.getKey ? btoa(String.fromCharCode.apply(null, new Uint8Array( subscription.getKey("p256dh") ))) : "";
      //build the payload
      let payload = {
				endpoint: subscription.endpoint,
        expirationTime: subscription.expirationTime,
        authKey,
        p256dhKey,
			};
			console.log("askForNotificationPermission [payload]: ", payload);
			let data = await fetch(`${config.api}/notifications`, { method: "POST", headers: headers(token), body: JSON.stringify(payload) }).then(d => d.json()).then(d => d.message && d.message === 'ok' ? true : d);
			console.log("askForNotificationPermission [data]: ", data);
			if (!data) { alert(data); return; }
			setPushNotifications(true);
		} catch (err) {
			console.error(err);
			setPushNotifications(false);
		}
	};

	function urlBase64ToUint8Array(base64String) {
		const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);
		for (let i = 0; i < rawData.length; ++i) { outputArray[i] = rawData.charCodeAt(i);}
		return outputArray;
	}

	async function isUserSubscribed() {
		if (!('PushManager' in window)) { setSupportsPushNotifications(false); return; }
		setSupportsPushNotifications(true);

		try {
			if ("serviceWorker" in navigator) {
				const registration = await navigator.serviceWorker.register("/service-worker.js");
				const subscription = await registration.pushManager.getSubscription();
				
				if (!subscription) { setPushNotifications(false); return; }
				setPushNotifications(true);
      }
		} catch (error) {
			setPushNotifications(false);
		}
	}

	async function markAlertsAsSeen(_notices) {
		if (!_notices || !_notices.length) { return; }
		let ids = _notices.filter( ({Seen}) => !Seen ).map(n => n._id);
		if (!ids.length) { return; }
		let data = await fetch(`${config.api}/notice`, { method: "PUT", headers: headers(token), body: JSON.stringify({ ids }) }).then(d => d.json()).then(d => d.message === 'ok' ? true : false);
		if (!data) { return; }
		_notices = _notices.map(n => { n.Seen = true; return n; });
		setNotices(_notices);
	};
	
	async function deleteAllAlerts() {
		if (!notices || !notices.length) { return; }
		if (timerToMarkAsSeen.current) { clearTimeout(timerToMarkAsSeen.current); }
		let ids = notices.map(n => n._id);
		let data = await fetch(`${config.api}/notice`, { method: "DELETE", headers: headers(token), body: JSON.stringify({ ids }) }).then(d => d.json()).then(d => d.message === 'ok' ? true : false);
		if (!data) { return; }
		return start();
	};

  // eslint-disable-next-line
	useEffect(() => {
		start();
		return () => {
			if (timerToMarkAsSeen.current) { clearTimeout(timerToMarkAsSeen.current); }
		};
	}, [])

  return (
    <div className="py-4 text-white">
      <div className="min-h-96 flex flex-col justify-start items-center">

				<div className="grid grid-cols-12 w-full mb-5">
					{/* left */}
					<div className="col-span-4 md:col-span-3 md:flex justify-start">
						{notices && notices.length ?
							<button className='text-xxs sm:text-sm bg-rose-950 text-rose-300 rounded-lg flex flex-row gap-1 px-1 py-0.5 items-center justify-center' onClick={() => deleteAllAlerts()}>
								<TrashIcon className='text-amber-500 w-3 h-3 my-0.5' /><span>Delete All</span>
							</button>
							: <></>
						}
					</div>
					{/* main */}
					<div className="hidden md:flex col-span-6">
					</div>
					{/* right */}
					<div className="col-span-8 md:col-span-3 flex justify-end">
						{supportsPushNotifications && pushNotifications === false ?
							<button className='text-xxs sm:text-sm bg-sky-950 text-sky-300 rounded-lg flex flex-row gap-1 px-1 py-0.5 items-center justify-center' onClick={()=>askForNotificationPermission()}>
								<span>Enable Push Notifications</span><BellAlertIcon className='text-amber-500 w-3 h-3 my-0.5' />
							</button>
							: <></>
						}
						{supportsPushNotifications && pushNotifications === true ?
							// <button className='text-xxs bg-rose-950 text-rose-300 rounded-lg flex flex-row gap-1 px-1 py-0.5 items-center justify-center' onClick={askForNotificationPermission}>
							// 	<span>Disable Push Notifications</span><BellSlashIcon className='text-amber-500 w-3 h-3 my-0.5' />
							// </button>
							<span className='text-xxs text-sky-300'>Push Notifications Enabled</span>
							: <></>
						}
					</div>

					{notices && notices.length ?
						<div className="col-span-12 text-left text-gray-200 w-full text-center mt-3 text-sm text-gray-400">
							Showing <span className="font-medium">{notices && notices.length ? notices.length : 0}</span> of <span className="font-medium">{matches && isNaN(matches) === false ? matches.toLocaleString() : 0}</span> {matches && isNaN(matches) === false && Number(matches) === 1 ? 'alert' : 'alerts'}
						</div>
						: <></>}
				</div>

				<div className="overflow-hidden rounded-md w-full md:w-1/2">
					{!loading && notices && notices.length ?
						<ul role="list" className="shadow bg-white divide-y divide-gray-200 w-full">
							{notices.map((item, idx) => (
								<li key={item._id} className={`px-2 py-3 sm:px-6 sm:py-4 w-full ${!item.Seen && idx % 2 != 0 ? `bg-gradient-to-r from-amber-100 to-sky-100` : (!item.Seen && idx % 2 === 0 ? `bg-gradient-to-r from-amber-200 to-sky-200` : (idx % 2 === 0 ? 'bg-gray-100' : ''))}`}>
									<Link to={item.Url} className='w-full flex items-start text-md justify-center flex-col text-gray-800'>
										<span className='w-full flex flex-row items-start justify-between'>
											<span className='flex-grow font-bold text-xs sm:text-md'>{item.Title}</span>
											<span className='text-amber-600'>{formatTimestamp(item.createdAt)}</span>
										</span>
										<span className='w-full text-xs'>{item.Body}</span>
									</Link>
								</li>
							))}
						</ul>
					: <></>}
					{loading ? <div className='w-full'><LOADING color="text-green-700" text={false} /></div> : ''}
					{!loading && (!notices || !notices.length) ? <div className='w-full text-center text-gray-400'>no alerts</div> : ''}
				</div>

      </div>
    </div>  
  );
};

export default React.memo(MAIN, dontRerender);