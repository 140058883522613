const exchanges = {
  // id : mic
  "1": "XASE",
  "2": "XBOS",
  "3": "XCIS",
  // "4": "XNYS",
  "4": "XADF",
  "5": "XNAS",
  "6": "XISE",
  "7": "EDGA",
  "8": "EDGX",
  "9": "XCHI",
  "10": "XNYS",
  "11": "ARCX",
  "12": "XNAS",
  "13": "XNYS",
  "14": "LTSE",
  "15": "IEXG",
  "16": "CBSX",
  "17": "XPHL",
  "18": "BATS",
  "19": "BATS",
  "20": "EPRL",
  "21": "MEMX",
  "300": "XNYS", //NYSE American Options
  "301": "XBOX", //Boston Options Exchange
  "302": "XCBO", //Chicago Board Options Exchange
  "303": "EMLD", //MIAX Emerald, LLC
  "304": "XCBO", //CBOE EDGX Options
  "307": "GEMX", //Nasdaq Global Markets Exchange Group
  "308": "XISX", //International Securities Exchange LLC
  "309": "XISX", //Nasdaq MRX Options Exchange
  "312": "MIHI", //MIAX International Securities Exchange, LLC
  "313": "XNYS", //NYSE Arca, Inc. - Options Division
  "314": "OPRA", //Options Price Reporting Authority
  "315": "MIHI", //MIAX Pearl, LLC - Options Division
  "316": "XNAS", //Nasdaq Options Market
  "319": "XNAS", //Nasdaq BX - Options
  "322": "XCBO", //CBOE C2 Options Exchange
  "323": "XNAS", //Nasdaq Philadelphia Exchange, LLC - Options
  "325": "XCBO", //CBOE BZX Options Exchange
};
export default exchanges;