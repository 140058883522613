
import decodeJwt from '../bin/decodeJwt';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import hideKeyboardOnEnter from "../bin/hideKeyboardOnEnter";
import { Combobox } from '@headlessui/react';
import config from "../config.json";
import Headers from "../bin/Headers";
import ICONS from '../bin/ICONS';

const MAIN = (props) => {
  const token= decodeJwt(props.token),
		[ticker, setTicker]= useState(''),
		setSidebarOpen = props.setSidebarOpen,
		navigate = useNavigate(),
		[suggestions, setSuggestions] = useState([]);

  const autoselectAllText = (obj) => {
    if (!obj || !obj.target){ return; }
    if (!obj.target.select){ return; }
    obj.target.select();
    if (!obj.target.setSelectionRange){ return; }
    obj.target.setSelectionRange(0, obj.target.value.length);
  };

  const checkForEnter = (event, input) => {
    if (!event || !event.keyCode || event.keyCode!==13 ){ return event; }
    hideKeyboardOnEnter(event.target);
    let _ticker = event.target.value;
    return event;
  };

  const suggestTicker = async (input) => {
    if (!input || !input.trim()){ setTicker(input); setSuggestions([]); return; }
    setTicker(input);
    let payload={ headers: Headers() };
    let suggest = await fetch(`${ config.api }/ticker?q=${input}`, payload).then( d => d.json() );
    if (suggest.message==='ok' && suggest.result){
      setSuggestions(suggest.result);
    }
    return;
  };

	const selectSuggestion = (input) => {
		setTicker(input);
		if (setSidebarOpen) { setSidebarOpen(false); }
    navigateToTicker(input);
  };
  
	const navigateToTicker = (_ticker) => {
		setTicker('');
		if (setSidebarOpen) { setSidebarOpen(false); }
		navigate(`/_/${_ticker}`);
	};
	
  return (
    <div className='flex-1 mb-5 bg-gray-800 space-y-1 text-white'>
			<Combobox
        as="div"
        value={ticker}
        className="focus:ring-indigo-500 h-full focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md border-gray-500 bg-gray-800"
        onChange={selectSuggestion} >
				<div className="relative block m-0 h-full w-full">
					<Combobox.Input
						className="rounded-md border-gray-500 bg-gradient-to-r bg-gray-800 focus:from-indigo-800 focus:to-gray-800 w-full uppercase text-sm text-center"
						onFocus={ autoselectAllText }
						onChange={(event) => suggestTicker(event.target.value)}
						onKeyDown={ checkForEnter }
						placeholder="Ticker"
						displayValue={ticker}
					/>


					{suggestions.length > 0 && (
						<Combobox.Options className="absolute z-10 mt-0 max-h-60 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white py-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{suggestions.map((suggestion) => (
								<Combobox.Option
									key={suggestion._id}
									value={suggestion.Ticker}
									className={`relative cursor-default select-none m-0`}>

									{({ active }) => (
										<div className={`p-1 flex items-start flex-col ${ active ? 'bg-sky-500 text-white' : 'text-black'}`}>
											<span className={`truncate`} >{suggestion.Ticker}</span>
											<span className={`pr-3 italic text-xxs truncate max-w-full ${ active ? 'text-gray-200' : 'text-gray-500'} pl-4`}>
												<ICONS.CheckIcon className={`h-4 w-4 text-yellow-300 inline hidden`} aria-hidden="true" /> {suggestion.Name}
											</span>
										</div>
									)}

								</Combobox.Option>
							))}
						</Combobox.Options>
					)}

				</div>
			</Combobox>
    </div>
  )
}

export default MAIN;