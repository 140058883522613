import { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { loadStripe } from '@stripe/stripe-js';
import { MinusIcon } from '@heroicons/react/20/solid';
import Headers from "../bin/Headers";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import classNames from "../bin/classNames";
import setMetaTags from "../bin/setMetaTags";
import config from '../config.json';
import RANKINGS from "../components/RANKINGS";
import { ChevronDownIcon, } from "@heroicons/react/24/solid";

const Main = () => {
	const token = useSelector(state => state.token),
		[delayedDataDateString, setDelayedDataDateString] = useState(null);
	
	
	useEffect(() => {
		setMetaTags({ title: 'Largest Stock Trades - Non Auction' });

		return () => {
			setMetaTags({ title: 'Red Striped Tie' });
		}
	}, []);

  return (
    <div className="bg-gray-900 py-8 sm:py-12">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
				<h1 className="flex flex-row w-full text-2xl font-bold text-white text-left items-center justify-start gap-2 flex-grow sm:grow-0">
						<span className='flex-grow sm:grow-0'>Largest Stock Trades<span className='hidden sm:inline'> - </span><br className='block sm:hidden' /><span className='text-sky-300'>Non Auction</span></span>

						{/* <Menu as="div" className="relative w-full flex flex-row">
							<div className='w-full'>
								{/* <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> * /}
								<Menu.Button className="w-full text-2xl font-bold text-white text-left flex flex-row items-center justify-start gap-2">
									<span className='flex-grow sm:grow-0'>Largest Stock Trades</span>
									<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
								</Menu.Button>
							</div>
							<Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
								<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
									<div className="py-1">
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-stock-trades" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Largest Stock Trades</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-binary-option-trades" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Largest Binary Option Trades</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-capital-stock" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Stock Capital</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-capital-option-premium" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Option Premium</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-capital-option-binary-premium" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Option Binary Premium</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-stock-volume" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Stock Volume</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-option-volume" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Option Volume</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => (<Link to="/rankings-highest-stock-oddlot-turnover" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Highest Stock Oddlot Turnover</Link>)}</Menu.Item>
										<Menu.Item>{({ active }) => (<Link to="/rankings-highest-option-terminal" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Highest Option Turnover</Link>)}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-percent-gain" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Percent Gain</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-percent-loss" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Percent Loss</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-market-cap-gain" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Market Cap Gain</Link> )}</Menu.Item>
										<Menu.Item>{({ active }) => ( <Link to="/rankings-highest-market-cap-loss" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-sm')}>Highest Market Cap Loss</Link> )}</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>  */}




						{delayedDataDateString ? <Link to="/pricing" className='w-full sm:w-[150px] text-xxs text-red-200 text-center rounded bg-rose-950 px-3 py-1'>Trade data<br className='hidden sm:block' /> from { delayedDataDateString }</Link> : <></>}
				</h1>
				<hr className='my-2' />
				<RANKINGS view={'rankings'} subview={'trade'} study={'stocknonauction'} setDelayedDataDateString={setDelayedDataDateString} />
			</div>
    </div>
  );
}
export default Main;