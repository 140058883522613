import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import _logout from "../bin/logout";
import dontRerender from "../bin/dontRerender";
import todaysDate from "../bin/todaysDate";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import determineMarketOpenTimestamp from "../bin/determineMarketOpenTimestamp";
import determineMarketCloseTimestamp from "../bin/determineMarketCloseTimestamp";
import determineMarketStatusBasedOnDayAndTime from "../bin/determineMarketStatusBasedOnDayAndTime";
import Headers from '../bin/Headers';
import abbrInt from '../bin/abbrInt';
import ECHART from "../components/ECHART1";
import LOADING from "../components/LOADING";
import { useWebSocket } from '../components/WEBSOCKET_CONTEXT';

// import _data from "../bin/QQQ_2024-06-04.json";

const MAIN = (props) => {
	let token = useSelector(state => state.token),
		{ symbol } = useParams(),
		[searchParams, setSearchParams] = useSearchParams(),
		{ websocket } = useWebSocket(),
		[ticker, updateTicker] = useState(symbol || ''),
		[candles, updateCandles] = useState([]),
		[liquidCandles, updateLiquidCandles] = useState([]),
		[priceLevels, updatePriceLevels] = useState([]),
		[projectedHigh, updateProjectedHigh] = useState(null),
		[projectedLow, updateProjectedLow] = useState(null),
		[tickerRangePercentile, updateTickerRangePercentile] = useState('average'),
		[lastPrice, updateLastPrice] = useState(null),
		[focalDate, updateFocalDate] = useState(searchParams && searchParams.get('date') ? searchParams.get('date') : todaysDate()),
		[sentiment, updateSentiment] = useState(null),
		tradingDate = useRef(null),
		[loading, setLoading] = useState(true);
		
	symbol = symbol ? symbol.toUpperCase() : null;
	

	const loadLiquidityChart = async (payload) => {
		if (!ticker) { return; }
		let data = await fetch(`https://api.redstripedtie.com/optionsentiment?tickers=${ticker}`, { headers: Headers(token) }).then(x => x.json()).then(x => x.result ? x.result : null);
		if (!data || !data.length) { return; }
		updateLiquidCandles(data);
		// console.log('Data:', data.data[data.data.length - 1]);
		updateSentiment(data[data.length - 1]);
		// if (data.tradingDate) {
		// 	tradingDate.current = data.tradingDate;
		// }
		// let _focalDate = tradingDate.current ? tradingDate.current : todaysDate();
		// updateFocalDate(_focalDate);
		// let focalDate = todaysDate();
		// await loadCandleChart(_focalDate);
		await loadCandleChart(focalDate);
	};

	const loadPriceLevels = async (priceBounds, highPrice, lowPrice) => {
		let data = await fetch(`https://api.redstripedtie.com/tickerstats?ticker=${ticker}`, { headers: Headers(token) }).then(x => x.json()).then(x => x.result ? x.result : null);
		if (!data || !data.PriceLevels) { return; }
		// if Ranges
		if (data.Ranges) {
			if (tickerRangePercentile && (data.Ranges).hasOwnProperty(tickerRangePercentile)) {
				let rangeSelected = data.Ranges[tickerRangePercentile];
				if (highPrice) {
					let projectedHighBasedOnCurrentLowAndSelectedRange = lowPrice && !isNaN(lowPrice) ? truncateToTwoDigits(Number(lowPrice) + Number(rangeSelected)) : null;
					updateProjectedHigh(projectedHighBasedOnCurrentLowAndSelectedRange);
				}
				if (lowPrice) {
					let projectedLowBasedOnCurrentHighAndSelectedRange = highPrice && !isNaN(highPrice) ? truncateToTwoDigits(Number(highPrice) - Number(rangeSelected)) : null;
					updateProjectedLow(projectedLowBasedOnCurrentHighAndSelectedRange);
				}
			}
		}
		//filter the data (price field) based on the priceBounds (upper, lower)
		data = data.PriceLevels.filter(x => {
			if (x.price >= priceBounds.lower && x.price <= priceBounds.upper) { return true; }
			return false;
		});
		updatePriceLevels(data);
	};
	
	const loadCandleChart = async (focalDate) => {
		if (!ticker || !focalDate) { return; }
		let _candles = await fetch(`https://io.redstripedtie.com/ursa/candles_p3?ticker=${ticker}&format=minute&startDate=${focalDate}&endDate=${focalDate}`, { headers: Headers(token) }).then(x => x.json()).then(x => x.result ? x.result : null);
		if (!_candles) { return; }
		let startOfOpenMarket = determineMarketOpenTimestamp(focalDate);
		let endOfOpenMarket = determineMarketCloseTimestamp(focalDate);
		//add 3hrs to the startOfOpenMarket and endOfOpenMarket to adjust for EST
		startOfOpenMarket += 10800000;
		endOfOpenMarket += 10800000;
		//add 15mins to endOfOpenMarket to adjust for the last candle
		endOfOpenMarket += 900000;
		_candles = _candles.filter(x => {
			let candleTimestamp = new Date(`${x.DateString}T${x.TimeString}`).getTime();
			return candleTimestamp >= startOfOpenMarket && candleTimestamp <= endOfOpenMarket;
		});

		// Detect gaps and fill them
		const oneMinute = 60000;
		let filledCandles = [];
		for (let i = 0; i < _candles.length - 1; i++) {
			filledCandles.push(_candles[i]);

			let currentCandleTime = new Date(`${_candles[i].DateString}T${_candles[i].TimeString}`).getTime();
			let nextCandleTime = new Date(`${_candles[i + 1].DateString}T${_candles[i + 1].TimeString}`).getTime();

			while (nextCandleTime - currentCandleTime > oneMinute) {
				currentCandleTime += oneMinute;
				let newDate = new Date(currentCandleTime);
				filledCandles.push({
					Volume: 0,
					Open: _candles[i].Close,
					Close: _candles[i].Close,
					High: _candles[i].Close,
					Low: _candles[i].Close,
					DateString: newDate.toISOString().split('T')[0],
					TimeString: newDate.toTimeString().split(' ')[0],
					Trades: 0
				});
			}
		}
		filledCandles.push(_candles[_candles.length - 1]); // Add the last candle

		updateCandles(filledCandles);

		let lastPrice = filledCandles && filledCandles.length ? filledCandles[filledCandles.length - 1].Close : null;
		let highPrice = filledCandles && filledCandles.length ? [...filledCandles.map(({ High }) => High)] : null;
		let lowPrice = filledCandles && filledCandles.length ? [...filledCandles.map(({ Low }) => Low)] : null;
		let priceBounds = { upper: Math.max(...highPrice), lower: Math.min(...lowPrice) };

		if (priceBounds.upper && !isNaN(priceBounds.upper)) {
			priceBounds.upper = ((priceBounds.upper * 1.005).toFixed(2));
		}
		if (priceBounds.lower && !isNaN(priceBounds.lower)) {
			priceBounds.lower = ((priceBounds.lower * 0.995).toFixed(2));
		}

		updateLastPrice(lastPrice);
		await loadPriceLevels(priceBounds, Math.max(...highPrice), Math.min(...lowPrice));
  };

  const loadData = async (payload) => {
    if (!token){ return; }
		if (!ticker) { return; }
		setLoading(true);
		
		await loadLiquidityChart(payload);

		setLoading(false);
    return;
	};

	useEffect(() => {
		if (ticker !== symbol) { updateTicker(symbol); }
		loadData();
		const interval = setInterval(() => {
			let marketOpen = determineMarketStatusBasedOnDayAndTime();
			if (!marketOpen) { return; }
			window.location.reload();
		}, 60000); // 60000 milliseconds = 1 minute
		return () => clearInterval(interval); // Clear the interval on component unmount
	}, [ticker, symbol]);

  return (
		<div className="w-full my-5 flex gap-4 flex-wrap justify-between items-start text-white">
			{loading ? <div className='w-full h-24 flex items-center justify-center'><LOADING /></div> : <></>}
			{!loading ?
				<div className="h-full w-full flex flex-col items-center justify-center">
					<ECHART candles={candles} liquidCandles={liquidCandles} priceLevels={priceLevels} projectedHigh={projectedHigh} projectedLow={projectedLow} />
					<div className="w-full flex flex-row gap-4 flex-wrap justify-evenly items-start text-white">
						
						
						<div className='text-center flex flex-col gap-1 justify-evenly items-center text-white'>
							<span>
								<a href={`https://redstripedtie.com/_/${ticker}`} className='text-2xl'>{ ticker } - ${ lastPrice } [{focalDate}]</a>
							</span>
							<span className={`px-2 rounded ${sentiment.Sentiment === 'Bullish' ? `bg-emerald-300 text-emerald-600` : (sentiment.Sentiment === 'Bearish' ? `bg-rose-300 text-rose-600` : '-')}`}>
								{sentiment.Sentiment} - {sentiment.SentimentVolumeDifference && isNaN(sentiment.SentimentVolumeDifference) === false ? (sentiment.SentimentVolumeDifference).toLocaleString() : '-'} {sentiment.Sentiment === 'Bullish' ? sentiment.BullishPercentage : (sentiment.Sentiment === 'Bearish' ? sentiment.BearishPercentage : '-')}%
							</span>
						</div>

						<div className='text-center flex flex-col gap-1 justify-evenly items-center text-white'>
							<h1 className='text-center flex flex-row gap-1'>
								<span className='bold'>Bullish Volume:</span>
								<span className={`px-2 rounded text-emerald-600`}>
									{sentiment.Bullish && isNaN(sentiment.Bullish) === false ? (sentiment.Bullish).toLocaleString() : '-'} {sentiment.BullishPercentage && isNaN(sentiment.BullishPercentage) === false ? `${(sentiment.BullishPercentage).toLocaleString()}%` : ''}
								</span>
							</h1>
							<h1 className='text-center flex flex-row gap-1'>
							<span className='bold'>Bearish Volume:</span>
								<span className={`px-2 rounded text-rose-600`}>
									{sentiment.Bearish && isNaN(sentiment.Bearish) === false ? (sentiment.Bearish).toLocaleString() : '-'} {sentiment.BearishPercentage && isNaN(sentiment.BearishPercentage) === false ? `${(sentiment.BearishPercentage).toLocaleString()}%` : ''}
								</span>
							</h1>
						</div>
						
					</div>

					<div className="w-full flex flex-row gap-4 flex-wrap justify-evenly items-center text-white">
						<h6 className='text-center flex flex-row gap-1'>
							<span className='bold'>Projected High{ lastPrice && projectedHigh ? ` [$${truncateToTwoDigits(projectedHigh - lastPrice)}]` : ''}:</span>
							<span className={`px-2 rounded text-emerald-600`}>{projectedHigh && !isNaN(projectedHigh) ? `$${(projectedHigh).toLocaleString()}` : '-'}</span>
						</h6>
						<h6 className='text-center flex flex-row gap-1'>
							<span className='bold'>Projected Low{ lastPrice && projectedLow ? ` [$${truncateToTwoDigits(lastPrice - projectedLow)}]` : ''}:</span>
							<span className={`px-2 rounded text-rose-600`}>{projectedLow && !isNaN(projectedLow) ? `$${(projectedLow).toLocaleString()}` : '-'}</span>
						</h6>
					</div>
				</div>
			: <></>}
    </div>
  );
}

export default React.memo(MAIN, dontRerender);