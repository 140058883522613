import React, {useEffect, useState, useRef} from 'react';
import config from "../config.json";
import { useSelector, useStore } from 'react-redux';
import Headers from "../bin/Headers";
import abbrInt from "../bin/abbrInt";
import todaysDate from "../bin/todaysDate";
import countWeekdays from "../bin/countWeekdays";
import calculatePercentagePerDay from "../bin/calculatePercentagePerDay";
import LOADING from "../components/LOADING";
import NOTABLE_TRADE_MODAL from "../components/NOTABLE_TRADE_MODAL";
import dontRerender from "../bin/dontRerender";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import removeCentsFromStrike from "../bin/removeCentsFromStrike";
import pTickerToBrokerTicker from "../bin/pTickerToBrokerTicker";
import daysBetween from "../bin/daysBetween";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import ICONS from '../bin/ICONS';
import { StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import actions from '../state/actions';
import isOiProbableUi from "../bin/isOiProbableUi";

const NotableTrades = ()=>{
  const token= useSelector(state => state.token);
	const store = useStore();
	const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlId = searchParams.get("id");
  const urlTicker = searchParams.get("ticker");
  const urlTradeDate = searchParams.get("tradedate");
  const urlExpirationDate = searchParams.get("expirationdate");
  let urlBuySideOnly = searchParams.get("buysideonly") ? searchParams.get("buysideonly") : true;
  let urlBinaryOnly = searchParams.get("binaryonly") ? searchParams.get("binaryonly") : true;
  let urlHighlightsOnly = searchParams.get("highlightsonly");
  let urlNotableAlgoOnly = searchParams.get("notablealgoonly");
  let urlConfirmedOnly = searchParams.get("confirmedonly");
  let urlPrecisionSideMatchOnly = searchParams.get("precisionsidematchonly");
  let urlLimit = searchParams.get("limit");
  let urlSkip = searchParams.get("skip");
  let urlSort = searchParams.get("sort");
  let urlDteMin = searchParams.get("dtemin");
  let urlDteMax = searchParams.get("dtemax");
  let urlNotionalMin = searchParams.get("notionalmin");
  let urlUnderlyingType = searchParams.get("underlyingtype");
  let [showBackButton, setShowBackButton] = useState(false),
			[showNextButton, setShowNextButton] = useState(false),
			[isPreviousDateData, setIsPreviousDateData] = useState(false),
      [list, setList] = useState([]),
      [matches, setMatches] = useState(null),
      [ticker, setTicker] = useState(''),
      [tradedate, setTradedate] = useState(''),
      [expirationdate, setExpirationdate] = useState(''),
      [dtemin, setDtemin] = useState(0),
      // [dtemax, setDtemax] = useState(180),
      [dtemax, setDtemax] = useState(0),
      // [notionalmin, setNotionalmin] = useState(75000),
      [notionalmin, setNotionalmin] = useState(0),
      [showTypeADRC, setShowTypeADRC] = useState(true),
      [showTypeCS, setShowTypeCS] = useState(true),
      [showTypeETF, setShowTypeETF] = useState(true),
      // [showBuySideOnly, setShowBuySideOnly] = useState(true),
      [showBuySideOnly, setShowBuySideOnly] = useState(false),
      // [showBinaryOnly, setShowBinaryOnly] = useState(true),
      [showBinaryOnly, setShowBinaryOnly] = useState(false),
      [showHighlightsOnly, setShowHighlightsOnly] = useState(false),
      [showNotableAlgoOnly, setNotableAlgoOnly] = useState(false),
      [showConfirmedOnly, setShowConfirmedOnly] = useState(false),
      [showPrecisionSideMatchOnly, setShowPrecisionSideMatchOnly] = useState(false),
      [limit, setLimit] = useState(25),
      [skip, setSkip] = useState(0),
      [sort, setSort] = useState("EntryTimestamp:-1"),
      _skip = useRef(0),
      [LoadingValue, setLoadingValue] = useState(true),
      [showTradeInfo, updateShowTradeInfo]= useState(false),
      [tradeDetail, updateTradeDetail]= useState(null);
	// let notableTradeWatchlist = {
	// 	"O:GS230728P00370000_2023-07-24_13:07:13.657_100_10.2": true,
	// };

	const modalTradeDetail = (trade) => {
    updateTradeDetail(trade);
    updateShowTradeInfo(true);
  };

  const readNotableTrades = async (overwrite) => {
    setLoadingValue(true);
    let headers = Headers(token);
    let uri = `${config.api}/notabletrades`;
    let uriParameters = [];
    if ( (ticker && ticker.trim()!=='') || (overwrite && overwrite.ticker && overwrite.ticker.trim()!=='') ){
      if (overwrite && overwrite.ticker){
        uriParameters.push(`tickers=${ overwrite.ticker.trim() }`);
      }else{
        uriParameters.push(`tickers=${ ticker.trim() }`);
      }
    }
    if ( tradedate || (overwrite && overwrite.tradedate) ){
      if (overwrite && overwrite.tradedate){
        uriParameters.push(`tradedate=${ overwrite.tradedate }`);
        setTradedate(overwrite.tradedate);
      }else{
        uriParameters.push(`tradedate=${ tradedate }`);
      }
    }
    if ( expirationdate || (overwrite && overwrite.expirationdate) ){
      if (overwrite && overwrite.expirationdate){
        uriParameters.push(`expirationdate=${ overwrite.expirationdate }`);
        setExpirationdate(overwrite.expirationdate);
      }else{
        uriParameters.push(`expirationdate=${ expirationdate }`);
      }
    }
    if ( limit || (overwrite && overwrite.limit) ){
			if (overwrite && overwrite.limit){
        uriParameters.push(`limit=${ overwrite.limit }`);
        setLimit(overwrite.limit);
      }else{
				uriParameters.push(`limit=${ limit }`);
			}
    }else{
			uriParameters.push(`limit=${ limit }`);
		}
    if ( skip || (overwrite && overwrite.skip) ){
			if (overwrite && overwrite.skip){
        uriParameters.push(`skip=${ overwrite.skip }`);
        setSkip(overwrite.skip);
      }else{
				uriParameters.push(`skip=${ skip }`);
			}
    }else{
			uriParameters.push(`skip=${ skip }`);
		}
    if ( showBuySideOnly || (overwrite && overwrite.hasOwnProperty('buysideonly')) ){
			if (overwrite && overwrite.hasOwnProperty('buysideonly')){
        uriParameters.push(`buysideonly=${ overwrite.buysideonly }`);
        setShowBuySideOnly(overwrite.buysideonly);
      }else{
				uriParameters.push(`buysideonly=${ showBuySideOnly }`);
			}
    }
    if ( showBinaryOnly || (overwrite && overwrite.hasOwnProperty('binaryonly')) ){
			if (overwrite && overwrite.hasOwnProperty('binaryonly')){
        uriParameters.push(`binaryonly=${ overwrite.binaryonly }`);
        setShowBinaryOnly(overwrite.binaryonly);
      }else{
				uriParameters.push(`binaryonly=${ showBinaryOnly }`);
			}
    }
    if ( showHighlightsOnly || (overwrite && overwrite.hasOwnProperty('highlightsonly')) ){
			if (overwrite && overwrite.hasOwnProperty('highlightsonly')){
        uriParameters.push(`highlightsonly=${ overwrite.highlightsonly }`);
        setShowHighlightsOnly(overwrite.highlightsonly);
      }else{
				uriParameters.push(`highlightsonly=${ showHighlightsOnly }`);
			}
		}

    if ( showNotableAlgoOnly || (overwrite && overwrite.hasOwnProperty('notablealgoonly')) ){
			if (overwrite && overwrite.hasOwnProperty('notablealgoonly') && (overwrite.notablealgoonly === 'true' || overwrite.notablealgoonly === true)) {
        uriParameters.push(`algo=notable`);
        setNotableAlgoOnly(overwrite.notablealgoonly);
      }else if (showNotableAlgoOnly===true){
        uriParameters.push(`algo=notable`);
			}
    }
		if (showConfirmedOnly || (overwrite && overwrite.hasOwnProperty('confirmedonly'))) {
			if (overwrite && overwrite.hasOwnProperty('confirmedonly')){
        uriParameters.push(`oiconfirmedonly=${ overwrite.confirmedonly }`);
        setShowConfirmedOnly(overwrite.confirmedonly);
      }else{
				uriParameters.push(`oiconfirmedonly=${ showConfirmedOnly }`);
			}
    }
		if (showPrecisionSideMatchOnly || (overwrite && overwrite.hasOwnProperty('precisionsidematchonly'))) {
			if (overwrite && overwrite.hasOwnProperty('precisionsidematchonly')){
        uriParameters.push(`precisionsidematchonly=${ overwrite.precisionsidematchonly }`);
        setShowPrecisionSideMatchOnly(overwrite.precisionsidematchonly);
      }else{
				uriParameters.push(`precisionsidematchonly=${ showPrecisionSideMatchOnly }`);
			}
    }
    if ( sort || (overwrite && overwrite.sort) ){
			if (overwrite && overwrite.sort){
				uriParameters.push(`sort=${ overwrite.sort }`);
			}else{
				uriParameters.push(`sort=${ sort }`);
			}
    }
		if (urlDteMin) {
			uriParameters.push(`dtemin=${urlDteMin}`);
			setDtemin(urlDteMin);
		} else {
			uriParameters.push(`dtemin=${dtemin}`);
		}
		if (urlDteMax) {
			uriParameters.push(`dtemax=${urlDteMax}`);
			setDtemax(urlDteMax);
		} else {
			uriParameters.push(`dtemax=${dtemax}`);
		}
		
		if (urlNotionalMin) {
			uriParameters.push(`notionalmin=${urlNotionalMin}`);
			setNotionalmin(urlNotionalMin);
		} else {
			uriParameters.push(`notionalmin=${notionalmin}`);
		}
		if (urlUnderlyingType) {
			uriParameters.push(`underlyingtype=${urlUnderlyingType}`);
		} else if (showTypeADRC || showTypeCS || showTypeETF) {
			let _underlyingTypes = [];
			if (showTypeADRC) { _underlyingTypes.push('ADRC'); }
			if (showTypeCS) { _underlyingTypes.push('CS'); }
			if (showTypeETF) { _underlyingTypes.push('ETF'); }
			_underlyingTypes = _underlyingTypes.join(',');
			uriParameters.push(`underlyingtype=${_underlyingTypes}`);
		}
    
    
		if (uriParameters.length > 0) { uri += `?${uriParameters.join('&')}`; }

		if (overwrite && overwrite.id) { uri = `${config.api}/notabletrades?id=${overwrite.id}`; }

		let data = await fetch(uri, { headers }).then(d => d.json());
		if (data && data.message && data.message === 'invalid token') {
			store.dispatch(actions.removeToken(token));
			navigate('/login', {replace:true});
			return;
		}
    if (!data || !data.message || data.message!=='ok' || !data.result){ setLoadingValue(false); updatePageNavigation(0); return; }
		let { matches, result } = data;

		//check if all EntryDateString are the same
		let commonEntryDateString = result && result.length ? result.reduce((hashMap, entry) => { if (!hashMap.hasOwnProperty(entry.EntryDateString)) { hashMap[entry.EntryDateString] = entry.EntryDateString; } return hashMap; }, {}) : null;
		commonEntryDateString = commonEntryDateString && Object.keys(commonEntryDateString) ? Object.keys(commonEntryDateString).join(',') : null;
		if (commonEntryDateString && commonEntryDateString !== todaysDate() && !token) {
			setIsPreviousDateData(commonEntryDateString);
		}

		let uniqueTickers = result && result.length ? result.reduce((hashMap, entry) => { if (!hashMap.hasOwnProperty(entry.UnderlyingTicker)) { hashMap[entry.UnderlyingTicker] = entry.UnderlyingTicker; } return hashMap; }, {}) : null;
		uniqueTickers = uniqueTickers && Object.keys(uniqueTickers) ? Object.keys(uniqueTickers).join(',') : null;
		if (uniqueTickers) {
			let upcomingEarnings = await fetch(`${config.api}/earnings?tickers=${uniqueTickers}&upcoming=true&date=${tradedate}`, { headers }).then(d => d.json()).then(d => d.message==='ok' && d.result ? d.result : null);
			if (upcomingEarnings && upcomingEarnings.length) {
				upcomingEarnings = upcomingEarnings.reduce((hashMap, entry) => { hashMap[entry.Ticker] = entry; return hashMap; }, {});
				for (let i = 0; i < result.length; i++){
					if (upcomingEarnings.hasOwnProperty(result[i].UnderlyingTicker)) {
						let ExpirationDate = new Date(result[i].ExpirationDateString);
						ExpirationDate.setUTCHours(0, 0, 0, 0);
						let EarningsDate = new Date(upcomingEarnings[result[i].UnderlyingTicker].EarningsDateString);
						if (EarningsDate <= ExpirationDate) {
							result[i].EarningsDateString = `${EarningsDate.getUTCFullYear()}-${(EarningsDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${EarningsDate.getUTCDate().toString().padStart(2, '0')}`;
							result[i].EarningsTime = upcomingEarnings[result[i].UnderlyingTicker].EarningsTime;
							result[i].EarningsDaysTill= daysBetween(result[i].EarningsDateString, result[i].EntryDateString);

						}
					}
				}
			}
		}
		updatePageNavigation(matches);
    if (matches && isNaN(matches)===false){
      setMatches(Number(matches));
		}
		setList(result);
		setLoadingValue(false);
  };
	const updatePageNavigation = (results=null) => {
		if (!results || results<limit){
			setShowBackButton(false);
			setShowNextButton(false);
			return;
		}
		if (_skip.current>0){
			setShowBackButton(true);
		}
		if ((_skip.current+limit)<results){
			setShowNextButton(true);
		}
	};
  
	const navigateToHashed = (payload) => {
		let underlyingtype = ['ADRC', 'CS', 'ETF'];
		if (payload.hasOwnProperty('typeADRC')) {
			if (payload.typeADRC===true) { underlyingtype.push('ADRC'); }
			else { underlyingtype = underlyingtype.filter(type => type !== 'ADRC'); }
		} else if (showTypeADRC === false) {
			underlyingtype = underlyingtype.filter(type => type !== 'ADRC');
		}
		if (payload.hasOwnProperty('typeCS')) { 
			if (payload.typeCS===true) { underlyingtype.push('CS'); }
			else { underlyingtype = underlyingtype.filter(type => type !== 'CS'); }
		} else if (showTypeCS === false) {
			underlyingtype = underlyingtype.filter(type => type !== 'CS');
		}
		if (payload.hasOwnProperty('typeETF')) { 
			if (payload.typeETF===true) { underlyingtype.push('ETF'); }
			else { underlyingtype = underlyingtype.filter(type => type !== 'ETF'); }
		} else if (showTypeETF === false) {
			underlyingtype = underlyingtype.filter(type => type !== 'ETF');
		}
		underlyingtype = underlyingtype.join(',');

		let vars = [];
		let _ticker = null;
		let _tradedate = null;
		let _expirationdate = null;
		if (payload && payload.hasOwnProperty('ticker')) {
			_ticker = payload.ticker;
			vars.push(`ticker=${payload.ticker}`);
		} else {
			_ticker = ticker;
			vars.push(`ticker=${ticker}`);
		};
		if (payload && payload.hasOwnProperty('tradedate')) {
			_tradedate = payload.tradedate;
			vars.push(`tradedate=${payload.tradedate}`);
		} else {
			_tradedate = tradedate;
			vars.push(`tradedate=${tradedate}`);
		};
		if (payload && payload.hasOwnProperty('expirationdate')) {
			_expirationdate = payload.expirationdate;
			vars.push(`expirationdate=${payload.expirationdate}`);
		} else {
			_expirationdate = expirationdate;
			vars.push(`expirationdate=${expirationdate}`);
		};
		if (payload && payload.hasOwnProperty('buysideonly')) { vars.push(`buysideonly=${payload.buysideonly}`); } else { vars.push(`buysideonly=${showBuySideOnly}`); };
		if (payload && payload.hasOwnProperty('binaryonly')) { vars.push(`binaryonly=${payload.binaryonly}`); } else { vars.push(`binaryonly=${showBinaryOnly}`); };
		if (payload && payload.hasOwnProperty('confirmedonly')) { vars.push(`confirmedonly=${payload.confirmedonly}`); } else { vars.push(`confirmedonly=${showConfirmedOnly}`); };
		if (payload && payload.hasOwnProperty('notablealgoonly')) { vars.push(`notablealgoonly=${payload.notablealgoonly}`); } else { vars.push(`notablealgoonly=${showNotableAlgoOnly}`); };
		if (payload && payload.hasOwnProperty('highlightsonly')) { vars.push(`highlightsonly=${payload.highlightsonly}`); } else { vars.push(`highlistsonly=${showHighlightsOnly}`); };
		if (payload && payload.hasOwnProperty('precisionsidematchonly')) { vars.push(`precisionsidematchonly=${payload.precisionsidematchonly}`); } else { vars.push(`precisionsidematchonly=${showPrecisionSideMatchOnly}`); };
		if (payload && payload.hasOwnProperty('limit')) { vars.push(`limit=${payload.limit}`); } else { vars.push(`limit=${limit}`); };
		if (payload && payload.hasOwnProperty('skip')) { vars.push(`skip=${payload.skip}`); } else { vars.push(`skip=${skip}`); };
		if (payload && payload.hasOwnProperty('sort')) { vars.push(`sort=${payload.sort}`); } else { vars.push(`sort=${sort}`); };
		if (payload && payload.hasOwnProperty('dtemin')) { vars.push(`dtemin=${payload.dtemin}`); } else { vars.push(`dtemin=${dtemin}`); };
		if (payload && payload.hasOwnProperty('dtemax')) { vars.push(`dtemax=${payload.dtemax}`); } else { vars.push(`dtemax=${dtemax}`); };
		if (payload && payload.hasOwnProperty('notionalmin')) { vars.push(`notionalmin=${payload.notionalmin}`); } else { vars.push(`notionalmin=${notionalmin}`); };
		if (underlyingtype && underlyingtype.length) { vars.push(`underlyingtype=${underlyingtype}`); };

		if (!_ticker && !_tradedate && !_expirationdate) {
			vars = vars.filter(q => q !== 'tradedate=');
			vars.push(`tradedate=${todaysDate()}`);
		}

		vars = vars && vars.length ? `?${vars.join('&')}` : ``;
		let uri = `/notabletrades${vars}`;
		// let uri = `./notabletrades?ticker=${payload && payload.hasOwnProperty('ticker') ? payload.ticker : ticker}&tradedate=${payload && payload.hasOwnProperty('tradedate') ? payload.tradedate : tradedate}&expirationdate=${payload && payload.hasOwnProperty('expirationdate') ? payload.expirationdate : expirationdate}&buysideonly=${payload && payload.hasOwnProperty('buysideonly') ? payload.buysideonly : 'true'}&binaryonly=${payload && payload.hasOwnProperty('binaryonly') ? payload.binaryonly : 'true'}&confirmedonly=${payload && payload.hasOwnProperty('confirmedonly') ? payload.confirmedonly : showConfirmedOnly}&highlightsonly=${payload && payload.hasOwnProperty('highlightsonly') ? payload.highlightsonly : showHighlightsOnly}&precisionsidematchonly=${payload && payload.hasOwnProperty('precisionsidematchonly') ? payload.precisionsidematchonly : showPrecisionSideMatchOnly}&limit=${payload && payload.hasOwnProperty('limit') ? payload.limit : limit}&skip=${payload && payload.hasOwnProperty('skip') ? payload.skip : skip}&sort=${payload && payload.hasOwnProperty('sort') ? payload.sort : sort}&dtemin=${payload && payload.hasOwnProperty('dtemin') ? payload.dtemin : dtemin}&dtemax=${payload && payload.hasOwnProperty('dtemax') ? payload.dtemax : dtemax}&notionalmin=${payload && payload.hasOwnProperty('notionalmin') ? payload.notionalmin : notionalmin}&underlyingtype=${underlyingtype}`;
		// let uri = `./notabletrades?ticker=${payload && payload.hasOwnProperty('ticker') ? payload.ticker : ticker}&tradedate=${payload && payload.hasOwnProperty('tradedate') ? payload.tradedate : tradedate}&expirationdate=${payload && payload.hasOwnProperty('expirationdate') ? payload.expirationdate : expirationdate}&buysideonly=${payload && payload.hasOwnProperty('buysideonly') ? payload.buysideonly : 'true'}&confirmedonly=${payload && payload.hasOwnProperty('confirmedonly') ? payload.confirmedonly : showConfirmedOnly}&precisionsidematchonly=${payload && payload.hasOwnProperty('precisionsidematchonly') ? payload.precisionsidematchonly : showPrecisionSideMatchOnly}&limit=${payload && payload.hasOwnProperty('limit') ? payload.limit : limit}&skip=${payload && payload.hasOwnProperty('skip') ? payload.skip : skip}&sort=${payload && payload.hasOwnProperty('sort') ? payload.sort : sort}&dtemin=${payload && payload.hasOwnProperty('dtemin') ? payload.dtemin : dtemin}&dtemax=${payload && payload.hasOwnProperty('dtemax') ? payload.dtemax : dtemax}&notionalmin=${payload && payload.hasOwnProperty('notionalmin') ? payload.notionalmin : notionalmin}&underlyingtype=${underlyingtype}`;
		// console.log(uri);
		window.location.href = uri;
		// navigate(uri, { replace: true });
		return;
	};

	const idIntercept = async () => {
		if (!urlId) {
			return start();
		}
		return await readNotableTrades({id:urlId});
	};

	const start = async () => {
		let overwrite = {};
    //check for a defined ticker
    if (urlTicker){
      overwrite.ticker = urlTicker.toUpperCase();
      setTicker(urlTicker.toUpperCase());
    }else{
      overwrite.ticker = '';
    }
    let _tradedate = null
    let _expirationdate = null
    let currentDateString = new Date();
    currentDateString = `${currentDateString.getFullYear()}-${(currentDateString.getMonth()+1).toString().padStart(2,'0')}-${ (currentDateString.getDate()).toString().padStart(2,'0')}`;
		urlBuySideOnly = urlBuySideOnly === 'true' ? true : false;
		overwrite.buysideonly = urlBuySideOnly;
		setShowBuySideOnly(urlBuySideOnly);
		
		urlBinaryOnly = urlBinaryOnly === 'true' ? true : false;
		overwrite.binaryonly = urlBinaryOnly;
		setShowBinaryOnly(urlBinaryOnly);
		
		urlHighlightsOnly = urlHighlightsOnly === 'true' ? true : false;
		if (urlHighlightsOnly) {
			urlSort = "EndingHighestPercent:-1";
		}
		overwrite.highlightsonly = urlHighlightsOnly;
		setShowHighlightsOnly(urlHighlightsOnly);
		
		urlNotableAlgoOnly = urlNotableAlgoOnly === 'true' ? true : false;
		overwrite.notablealgoonly = urlNotableAlgoOnly;
		setNotableAlgoOnly(urlNotableAlgoOnly);
		
		urlConfirmedOnly = urlConfirmedOnly === 'true' ? true : false;
		overwrite.confirmedonly = urlConfirmedOnly;
		setShowConfirmedOnly(urlConfirmedOnly);
		
		urlPrecisionSideMatchOnly = urlPrecisionSideMatchOnly === 'true' ? true : false;
		overwrite.precisionsidematchonly = urlPrecisionSideMatchOnly;
		setShowPrecisionSideMatchOnly(urlPrecisionSideMatchOnly);

		if (urlTradeDate) {
      let testDate = Date.parse(urlTradeDate);
      if ( isNaN(testDate) ){
        _tradedate = currentDateString;
        overwrite.tradedate = _tradedate;
      }else{
        _tradedate = urlTradeDate;
        overwrite.tradedate = urlTradeDate;
      }
    }else if (urlTradeDate===''){
      overwrite.tradedate = '';
		}else{
      overwrite.tradedate = currentDateString;
		}

		if (urlUnderlyingType) {
			if (urlUnderlyingType.includes('ADRC')) { setShowTypeADRC(true); } else { setShowTypeADRC(false); }
			if (urlUnderlyingType.includes('CS')) { setShowTypeCS(true); } else { setShowTypeCS(false); }
			if (urlUnderlyingType.includes('ETF')) { setShowTypeETF(true); } else { setShowTypeETF(false); }
		}

		if (urlExpirationDate) {
      let testDate = Date.parse(urlExpirationDate);
      if ( isNaN(testDate) ){
        _expirationdate = currentDateString;
        overwrite.expirationdate = _expirationdate;
      }else{
        _expirationdate = urlExpirationDate;
        overwrite.expirationdate = urlExpirationDate;
      }
    }else if (urlExpirationDate===''){
      overwrite.expirationdate = '';
		}
		
		if (urlDteMin!==null && urlDteMin!==undefined){
			overwrite.dtemin = Number(urlDteMin);
		}
		if (urlDteMax!==null && urlDteMax!==undefined){
			overwrite.dtemax = Number(urlDteMax);
		}

		if (urlNotionalMin!==null && urlNotionalMin!==undefined){
			overwrite.notionalmin = Number(urlNotionalMin);
		}
		
		if (urlLimit) {
			overwrite.limit = Number(urlLimit);
		}
		
		if (urlSkip){
			overwrite.skip = Number(urlSkip);
			_skip.current = Number(urlSkip);
		}
		
		if (urlSort){
			overwrite.sort = urlSort;
			setSort(urlSort);
    }else{
			overwrite.sort = "EntryTimestamp:-1";
			setSort("EntryTimestamp:-1");
		}

    await readNotableTrades(overwrite);
	};

	useEffect(() => { idIntercept(); }, []);

  return (
    <main className="py-4 sm:py-6 lg:py-8">
			{token ?
				<div className="flex flex-col sm:justify-between sm:flex-row gap-4 w-full">
					
					<div className='w-full md:max-w-sm sm:w-1/2'>
						<div className="max-w-full flex rounded-md shadow-sm">
							<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
								Ticker
							</span>
							<input
								disabled={LoadingValue}
								type="text"
								value={ticker}
								onChange={evt => setTicker(evt.target.value)}
								onKeyDown={evt => { if (evt.keyCode !== 13) { return; } navigateToHashed({ ticker: evt.target.value }) }}
								autoComplete='off'
								autoCorrect='off'
								autoCapitalize='off'
								spellCheck='off'
								placeholder='Ticker'
								className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
							/>
							<button type="button" onClick={() => { window.location.reload(); }} className="ml-1 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">Reload</button>
						</div>

						<div className="max-w-full flex rounded-md shadow-sm mt-2">
							<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
								Trade Date
							</span>
							<input
								disabled={LoadingValue}
								type="date"
								value={tradedate}
								onChange={evt => { navigateToHashed({ tradedate: evt.target.value }) }}
								className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
							/>
							<button type="button" onClick={() => { navigateToHashed({ tradedate: "" }) }} className={`${tradedate ? 'block' : 'hidden'} ml-1 rounded-md bg-rose-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600`}>
								<span className='text-white'><XMarkIcon className='w-5' /></span>
							</button>
						</div>
						<div className="max-w-full flex rounded-md shadow-sm mt-2">
							<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
								Expiration Date
							</span>
							<input
								disabled={LoadingValue}
								type="date"
								value={expirationdate}
								onChange={evt => { navigateToHashed({ expirationdate: evt.target.value }) }}
								className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
							/>
							<button type="button" onClick={() => { navigateToHashed({ expirationdate: "" }) }} className={`${expirationdate ? 'block' : 'hidden'} ml-1 rounded-md bg-rose-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600`}>
								<span className='text-white'><XMarkIcon className='w-5' /></span>
							</button>
						</div>
						<div className="max-w-full flex rounded-md shadow-sm mt-2">
							<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
								DTE Min
							</span>
							<input
								disabled={LoadingValue}
								type="number"
								value={dtemin}
								placeholder='DTE Min'
								onChange={evt => setDtemin(evt.target.value)}
								onKeyDown={evt => { if (evt.keyCode !== 13) { return; } navigateToHashed({ dtemin: evt.target.value }) }}
								className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
							/>
							<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
								DTE Max
							</span>
							<input
								disabled={LoadingValue}
								type="number"
								value={dtemax}
								placeholder='DTE Min'
								onChange={evt => setDtemax(evt.target.value)}
								onKeyDown={evt => { if (evt.keyCode !== 13) { return; } navigateToHashed({ dtemax: evt.target.value }) }}
								className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
							/>
						</div>
						<div className="max-w-full flex rounded-md shadow-sm mt-2">
							<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
								Notional Min
							</span>
							<input
								disabled={LoadingValue}
								type="number"
								value={notionalmin}
								placeholder='Notional Min'
								onChange={evt => setNotionalmin(evt.target.value)}
								onKeyDown={evt => { if (evt.keyCode !== 13) { return; } navigateToHashed({ notionalmin: evt.target.value }) }}
								className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
							/>
						</div>

					</div>
					
					{LoadingValue === true &&
						<div className='w-full'>
							<LOADING />
						</div>
					}

					{LoadingValue === false &&
						<div className='w-full'>
							
							<dl className="space-y-1 text-sm">

								<Switch.Group as="div" className="flex justify-between">
									<Switch.Label as="span" className="mr-3">
										<span className="text-sm font-medium text-gray-200">Buy Side Only</span>
									</Switch.Label>
									<Switch
										checked={showBuySideOnly}
										onChange={v => { navigateToHashed({ buysideonly: v }); }}
										className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${showBuySideOnly ? 'bg-indigo-600' : 'bg-gray-200'}`} >
										<span
											aria-hidden="true"
											className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showBuySideOnly ? 'translate-x-5' : 'translate-x-0'}`} />
									</Switch>
								</Switch.Group>

								<Switch.Group as="div" className="flex justify-between">
									<Switch.Label as="span" className="mr-3">
										<span className="text-sm font-medium text-gray-200">Binary Only</span>
									</Switch.Label>
									<Switch
										checked={showBinaryOnly}
										onChange={v => { navigateToHashed({ binaryonly: v }); }}
										className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${showBinaryOnly ? 'bg-indigo-600' : 'bg-gray-200'}`} >
										<span
											aria-hidden="true"
											className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showBinaryOnly ? 'translate-x-5' : 'translate-x-0'}`} />
									</Switch>
								</Switch.Group>
								
								<Switch.Group as="div" className="flex justify-between">
									<Switch.Label as="span" className="mr-3">
										<span className="text-sm font-medium text-gray-200">OI Confirmed</span>
									</Switch.Label>
									<Switch
										checked={showConfirmedOnly}
										onChange={v => { navigateToHashed({ confirmedonly: v }); }}
										className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${showConfirmedOnly ? 'bg-indigo-600' : 'bg-gray-200'}`} >
										<span
											aria-hidden="true"
											className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showConfirmedOnly ? 'translate-x-5' : 'translate-x-0'}`} />
									</Switch>
								</Switch.Group>
								
								<Switch.Group as="div" className="flex justify-between">
									<Switch.Label as="span" className="mr-3">
										<span className="text-sm font-medium text-gray-200">Precision Side Match</span>
									</Switch.Label>
									<Switch
										checked={showPrecisionSideMatchOnly}
										onChange={v => { navigateToHashed({ precisionsidematchonly: v }); }}
										className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${showPrecisionSideMatchOnly ? 'bg-indigo-600' : 'bg-gray-200'}`} >
										<span
											aria-hidden="true"
											className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showPrecisionSideMatchOnly ? 'translate-x-5' : 'translate-x-0'}`} />
									</Switch>
								</Switch.Group>
								
								<Switch.Group as="div" className="flex justify-between">
									<Switch.Label as="span" className="mr-3">
										<span className="text-sm font-medium text-gray-200">Highlights Only</span>
									</Switch.Label>
									<Switch
										checked={showHighlightsOnly}
										onChange={v => { navigateToHashed({ highlightsonly: v }); }}
										className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${showHighlightsOnly ? 'bg-indigo-600' : 'bg-gray-200'}`} >
										<span
											aria-hidden="true"
											className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showHighlightsOnly ? 'translate-x-5' : 'translate-x-0'}`} />
									</Switch>
								</Switch.Group>
								
								{/* <Switch.Group as="div" className="flex justify-between">
									<Switch.Label as="span" className="mr-3">
										<span className="text-sm font-medium text-gray-200">Notable Algo Only</span>
									</Switch.Label>
									<Switch
										checked={showNotableAlgoOnly}
										onChange={ v => { navigateToHashed({notablealgoonly:v}); }}
										className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${showNotableAlgoOnly ? 'bg-indigo-600' : 'bg-gray-200'}`} >
										<span
											aria-hidden="true"
											className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showNotableAlgoOnly ? 'translate-x-5' : 'translate-x-0'}`} />
									</Switch>
								</Switch.Group> */}

								<div className='w-full flex flex-row gap-1 justify-between items-center'>
									<span className='text-sm font-medium text-gray-200'>Underlying Type</span>
									<span className="isolate inline-flex rounded-md shadow-sm">
										<button type="button" onClick={v => { navigateToHashed({ typeADRC: !showTypeADRC }); }} className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${showTypeADRC ? 'text-gray-300 bg-emerald-800' : 'text-gray-900 bg-white'}`}>ADRC</button>
										<button type="button" onClick={v => { navigateToHashed({ typeCS: !showTypeCS }); }} className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${showTypeCS ? 'text-gray-300 bg-emerald-800' : 'text-gray-900 bg-white'}`}>CS</button>
										<button type="button" onClick={v => { navigateToHashed({ typeETF: !showTypeETF }); }} className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${showTypeETF ? 'text-gray-300 bg-emerald-800' : 'text-gray-900 bg-white'}`}>ETF</button>
									</span>
								</div>
							</dl>

						</div>
					}
					

				</div>
				:
				<div className="flex flex-col sm:justify-between sm:flex-row gap-4 w-full">
					
				</div>
			}


			{ LoadingValue===false && (showBackButton || showNextButton) &&
				<nav className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-3">
					<div className="pb-3 sm:pb-0 self-start block">
						<p className="text-sm text-gray-400">
							Showing <span className="font-medium">{ skip+1 }</span> to <span className="font-medium">{ (skip+limit) > matches ? matches : (skip+limit) }</span> of{' '}
							<span className="font-medium">{ matches && isNaN(matches)===false ? matches.toLocaleString() : 0 }</span> { matches && isNaN(matches)===false && Number(matches)===1 ? 'Result' : 'Results' }
						</p>
						{isPreviousDateData ? <span className='bg-red-600 text-white text-xs font-light px-2 py-0.5 rounded-md'>Trade Date: {isPreviousDateData}</span> : <></>}
					</div>
					<div className="self-end flex flex-1 justify-between sm:justify-end">
						{ showBackButton===true && <button
							onClick={ v => { navigateToHashed({skip:(Number(skip)-Number(limit) < 0 ? 0 : Number(skip)-Number(limit)) }); }}
							type="button" className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">
							Back
						</button> }
						{ showNextButton===true && <button
							onClick={ v => { navigateToHashed({skip:(Number(skip)+Number(limit)) }); }}
							type="button" className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">
							Next
						</button> }
					</div>
				</nav>
			}

      { LoadingValue===false && 
      <div className='overflow-y-auto'>
        <table className="mt-10 divide-y divide-gray-400 w-full self-start text-xs overflow-x-auto whitespace-nowrap">
          
          <thead>
            <tr className="divide-x divide-gray-400 bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-left">
              {/* <th scope="col" className="p-1 font-medium tracking-wider w-5"> */}
                {/* <span className='text-amber-500'><ICONS.EyeIcon className='w-5' /></span> */}
              {/* </th> */}
              <th scope="col" className="font-medium tracking-wider w-36">
								<button
									onClick={ () => { navigateToHashed({sort:( sort==='EntryTimestamp:1' ? 'EntryTimestamp:-1' : ( sort==='EntryTimestamp:-1' ? 'EntryTimestamp:1' : 'EntryTimestamp:-1' ) )}) }}
									className='p-1 flex items-center hover:cursor-pointer'>
									Trade
									{sort==='EntryTimestamp:-1' && <span className='text-amber-500 pl-0.5'><ICONS.ChevronDownIcon className='w-3' /></span>}
									{sort==='EntryTimestamp:1' && <span className='text-amber-500 pl-0.5'><ICONS.ChevronUpIcon className='w-3' /></span>}
								</button>
							</th>
              {/* <th scope="col" className="p-1 font-medium tracking-wider w-16">Notional</th> */}
              <th scope="col" className="p-1 font-medium tracking-wider w-24">
								<button
									onClick={ () => { navigateToHashed({sort:'LowestPercent:1'}) }}
									className='p-1 flex items-center hover:cursor-pointer'>
									Low
									{sort==='LowestPercent:1' && <span className='text-amber-500 pl-0.5'><ICONS.ChevronDownIcon className='w-3' /></span>}
								</button>
							</th>
              <th scope="col" className="font-medium tracking-wider w-24">
								<button
									onClick={ () => { navigateToHashed({sort:'HighestPercent:-1'}) }}
									className='p-1 flex items-center hover:cursor-pointer'>
									High
									{sort==='HighestPercent:-1' && <span className='text-amber-500 pl-0.5'><ICONS.ChevronDownIcon className='w-3' /></span>}
								</button>
							</th>
              <th scope="col" className="p-1 font-medium tracking-wider w-24">Last</th>
              <th scope="col" className="p-1 font-medium tracking-wider">Metrics</th>
            </tr>
          </thead>
          
          <tbody className='divide-y divide-gray-800'>
            { (!list || !list.length) &&
              <tr><th scope="col" colSpan="7" className="py-8 font-light text-center text-gray-300 text-xs">no notable trades</th></tr>
            }
            { list && list.length>0 &&
              (list)
							.map( (entry,idx) =>
                // <tr key={idx} className={`divide-x divide-gray-800 bg-opacity-25 text-left ${idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'} text-xxs text-gray-300`}>
                <tr key={idx} className={`divide-x divide-gray-800 text-left ${((entry.OptionType==='PUT' && entry.TradeSide==='BUY') || (entry.OptionType==='CALL' && entry.TradeSide==='SELL')) ? `bg-rose-950/25` : (((entry.OptionType==='CALL' && entry.TradeSide==='BUY') || (entry.OptionType==='PUT' && entry.TradeSide==='SELL')) ? `bg-emerald-950/25` : `bg-gray-950/25`)} text-xxs text-gray-300`}>
                  {/* <td className="p-1 font-light align-top w-5"> */}
                    {/* {
                      entry.EndingHighestPercent>50 &&
                      <span className='text-amber-600'><StarIcon className='w-5' /></span>
                    } */}
                    {/* {
                      notableTradeWatchlist[entry.TradeId] &&
                      <span className='text-amber-600'><StarIcon className='w-5' /></span>
                    } */}
                  {/* </td> */}
									<td className="p-1 font-light align-top w-36">
										<button className='block text-left w-100' onClick={() => modalTradeDetail(entry) }>
											<span className='font-bold'>{ entry.UnderlyingTicker }</span> - <span className={`${ entry.OptionType==='PUT' ? 'text-red-700' : '' }${ entry.OptionType==='CALL' ? 'text-green-700' : '' }`}>{ entry.OptionType }</span> - <span className='text-amber-600'>${ removeCentsFromStrike(Number((entry.OptionStrike).toFixed(2)).toFixed(2).toLocaleString()) }</span><br/>
											{ (entry.Size).toLocaleString() } @ ${ ( Number((entry.Price).toFixed(2)) ).toFixed(2).toLocaleString() } = <span className='font-light text-amber-300'>${ abbrInt(entry.Notional) }</span><br/>
											{/* <span className='text-sky-700'>{ pTickerToBrokerTicker(entry.Ticker) }</span> */}
											{/* {isNaN(entry.DaysToExpiration) === false ? <span className='text-red-500 ml-1'>{entry.DaysToExpiration} DTE</span> : ''} */}
											{/* <br /> */}
											<span className='flex flex-row w-100 justify-start items-between'>
												<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Trade Date:</span><span className="ml-1 font-light w-100 text-center text-sky-700">{ entry.EntryDateString }</span>{isNaN(entry.DaysToExpiration) === false ? <span className='text-red-500 ml-1'>{entry.DaysToExpiration} DTE</span> : ''}
											</span>
											{/* <br/> */}
											{entry.Expired ?
												<span className='block font-medium bg-rose-900 text-gray-300 w-100 text-center'>expired</span> :
												<span className='flex flex-row w-100 justify-start items-between'>
													<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Expires:</span><span className="ml-1 font-light w-100 text-center text-rose-600">{ entry.ExpirationDateString }</span>
												</span>
											}
											<span className='flex flex-row w-100 justify-between items-center'>
												{ entry.TradeSide==="BUY" ? <span className='text-green-500 mr-1'>BUY SIDE</span> : ''}
												{ entry.TradeSide==="SELL" ? <span className='text-red-500 mr-1'>SELL SIDE</span> : ''}
												<span className='text-gray-500'>{entry.EntryTimeString}</span>
											</span>
											{entry.OpenInterestIncreasedNetProjectionMinimumAfterTrade ? <><span className='block font-medium text-amber-500 w-100 text-center'>oi confirmed</span></> : isOiProbableUi(entry)}
											{!entry.OpenInterestIncreasedNetProjectionMinimumAfterTrade && entry.OpenInterestIncreasedExceedsTradeSizePotentiallyOpen ? <><br /><span className='block font-medium text-violet-700 w-100 text-center'>oi supports open position</span></> : <></>}
											{entry.UnderlyingPriceAtTrade ?
												<span className='flex flex-row w-100 justify-between items-center'>
													<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Underlying:</span>
													<span className={`ml-1 font-light w-100 text-center ${entry.Moneyness === 'OTM' ? `text-amber-200` : ``}${entry.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>${(Number((entry.UnderlyingPriceAtTrade).toFixed(2))).toFixed(2).toLocaleString()}</span>
													<span className={`mr-1 font-light w-100 text-right flex-grow ${entry.Moneyness === 'OTM' ? `text-amber-500` : ``}${entry.Moneyness === 'ITM' ? `text-sky-500` : ``}`}>{entry.Moneyness}</span>
												</span>
												:
												<></>
											}
											{entry.BreakevenPrice && entry.BreakevenMove ? 
												<span className='flex flex-row w-100 justify-between items-center'>
													<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Breakeven:</span>
													<span className="ml-1 font-light w-100 text-center text-indigo-200">${(Number((entry.BreakevenPrice).toFixed(2))).toFixed(2).toLocaleString()}</span>
													<span className="mr-1 font-light w-100 text-right flex-grow text-indigo-400">{entry.BreakevenMove}%</span>
												</span>
												:
												<></>
												}
											{entry.EarningsDateString ?
												<span className='flex flex-row w-100 bg-amber-950 w-100 justify-between items-center'>
													<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Earnings:</span>
													<span className="ml-1 font-light w-100 text-center text-amber-300">{ entry.EarningsDateString }</span>
													{entry.EarningsTime && entry.EarningsTime !== 'Unknown' ? <span className="ml-1 font-light text-center text-amber-200 text-[8px]">{(entry.EarningsTime).toLowerCase()}</span> : <></>}
													<span className='text-yellow-500 mr-1 flex-grow text-right'>{entry.EarningsDaysTill}d</span>
												</span>
												:
												<></>
											}
										</button>
                  </td>
									<td className="p-1 font-light align-top w-24">
										<div className='flex flex-col gap-2'>
										{entry.LowestPercent === 0 && entry.EndingLowestPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
										{entry.LowestPercent != 0 ?
											<span>
												<span className={`font-medium ${entry.LowestPercent < 0 ? `text-red-700` : `text-gray-500`}`}>{(entry.LowestPercent).toLocaleString()}%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>same day</span><br />
												{/* ${(entry.Lowest).toLocaleString()} - <span className='text-gray-500'>{entry.LowestTime}</span><br /> */}
												${(entry.Lowest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((entry.Lowest * entry.Size * entry.SharesPerContract),false)}</span><br />
												<span className='text-sky-600'>{entry.TimeToLow}</span>
											</span>
										: <></>}
										{entry.EndingLowestPercent != 0 ?
											<span>
												<span className={`font-medium ${entry.EndingLowestPercent < 0 ? `text-red-700` : `text-gray-500`}`}>{(entry.EndingLowestPercent).toLocaleString()}%</span><br />
												{/* ${(entry.EndingLowest).toLocaleString()} - <span className='text-gray-500'>{entry.EndingLowestTime}</span><br /> */}
												${(entry.EndingLowest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((entry.EndingLowest * entry.Size * entry.SharesPerContract),false)}</span><br />
												<span className='text-sky-600'>{entry.TimeToEndingLowest}</span>
											</span>
										: <></>}
										</div>
                  </td>
									<td className="p-1 font-light align-top w-24">
										<div className='flex flex-col gap-2'>
											{entry.HighestPercent === 0 && entry.EndingHighestPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
											{entry.HighestPercent != 0 ?
												<span className='w-100'>
													<span className={`font-medium ${entry.HighestPercent > 0 ? `text-green-700` : `text-gray-500`}`}>{(entry.HighestPercent).toLocaleString()}%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>same day</span><br />
													{/* ${(entry.Highest).toLocaleString()} - <span className='text-gray-500'>{entry.HighestTime}</span><br /> */}
													${(entry.Highest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((entry.Highest * entry.Size * entry.SharesPerContract),false)}</span><br />
													<span className='text-sky-600'>{entry.TimeToHigh}</span>
												</span>
											: <></>}
											{entry.EndingHighestPercent != 0 ?
												<span className='w-100'>
													<span className={`font-medium ${entry.EndingHighestPercent > 0 ? `text-green-700` : `text-gray-500`}`}>{(entry.EndingHighestPercent).toLocaleString()}%</span><br />
													{/* ${(entry.EndingHighest).toLocaleString()} - <span className='text-gray-500'>{entry.EndingHighestTime}</span><br /> */}
													${(entry.EndingHighest).toLocaleString()} - <span className='text-gray-500'>${abbrInt((entry.EndingHighest * entry.Size * entry.SharesPerContract),false)}</span><br />
													<span className='text-sky-600'>{entry.TimeToEndingHighest}</span>
												</span>
											: <></>}
										</div>
									</td>
									<td className="p-1 font-light align-top w-24 bg-gradient-to-b from-indigo-900 to-ingigo-800">
										<div className='flex flex-col gap-2'>
											{entry.EODPercent === 0 && entry.EndingLastPercent === 0 ? <span className='font-light text-gray-500 block w-100 text-center p-4'>not<br/>available</span> : <></>}
											{entry.EODPercent != 0 ?
												<span>
													<span className={`font-medium ${entry.EODPercent>0 ? `text-green-700` : ``} ${entry.EODPercent<0 ? `text-red-700` : ``} ${entry.EODPercent===0 ? `text-gray-500` : ``}`}>{ (entry.EODPercent).toLocaleString() }%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>same day</span><br />
													{/* ${ (entry.EOD).toLocaleString() }<br/> */}
													${(entry.EOD).toLocaleString()} - <span className='text-gray-500'>${abbrInt((entry.EOD * entry.Size * entry.SharesPerContract),false,false)}</span><br />
													<span className='text-sky-600'>{entry.TimeToEOD}</span>
												</span>
											: <></>}
											{entry.EndingLastPercent != 0 ?
												<span>
													<span className={`font-medium ${entry.EndingLastPercent>0 ? `text-green-700` : ``} ${entry.EndingLastPercent<0 ? `text-red-700` : ``} ${entry.EndingLastPercent===0 ? `text-gray-500` : ``}`}>{(entry.EndingLastPercent).toLocaleString() }%</span><span className='ml-1 font-light text-amber-700 text-[6px]'>last</span><br />
													{/* ${(entry.EndingLast).toLocaleString()} - <span className='text-gray-500'>{entry.EndingLastTime}</span><br /> */}
													${(entry.EndingLast).toLocaleString()} - <span className='text-gray-500'>${abbrInt((entry.EndingLast * entry.Size * entry.SharesPerContract),false,false)}</span><br />
													<span className='text-sky-600'>{entry.TimeToEndingLast}</span>
												</span>
											: <></>}
										</div>
                  </td>
                  <td className="p-1 font-light align-top">
										<span className='flex flex-row'>
											<span className='font-light text-gray-500'>Open Interest:</span>
											<span className='flex flex-row w-full justify-evenly'>
												<span className='flex flex-col items-center font-medium justify-start'>
													<span className='font-light'>{(entry.OpenInterestBeforeTrade).toLocaleString()}</span>
													<span className='text-[6px] text-amber-800 font-light'>before</span>
												</span>
												<span className='flex flex-col items-center font-medium justify-start'>
													<span className='font-light'>{(entry.OpenInterestAfterTrade).toLocaleString()}</span>
													<span className='text-[6px] text-amber-800 font-light'>after</span>
												</span>
											</span>
										</span>
										
										<span className='flex flex-row'>
											<span className='font-light text-gray-500'>Volume:</span>
											<span className='flex flex-row w-full justify-evenly'>
												<span className='flex flex-col items-center font-medium justify-start'>
													<span className='font-light'>{(entry.VolumeAfterTradeSameDay).toLocaleString()}</span>
													<span className='text-[6px] text-amber-800 font-light'>after trade & same day</span>
												</span>
												<span className='flex flex-col items-center font-medium justify-start'>
													<span className='font-light'>{(entry.VolumeAfterTradeEnding).toLocaleString()}</span>
													<span className='text-[6px] text-amber-800 font-light'>after trade till expiry</span>
												</span>
											</span>
										</span>
										
										<span className='flex flex-row'>
											<span className='font-light text-gray-500'>Quote:</span>
											<span className='flex flex-row w-full justify-evenly'>
												<span className='flex flex-col items-center font-medium justify-start'>
													<span className='font-light'>Bid: { entry.BidBeforeTrade ? `$${entry.BidBeforeTrade}` : '-' }</span>
													<span className='font-light'>Ask: { entry.AskBeforeTrade ? `$${entry.AskBeforeTrade}` : '-' }</span>
												</span>
												<span className='flex flex-col items-start font-medium justify-start'>
													<span className='font-light flex flex-row gap-1'>Bid: { entry.BidAfterTrade ? `$${entry.BidAfterTrade}` : '-'}{entry.BidBeforeTrade<entry.BidAfterTrade && <span className='text-green-500 pl-0.5'><ICONS.ChevronUpIcon className='w-3' /></span>}{entry.BidBeforeTrade>entry.BidAfterTrade && <span className='text-red-500 pl-0.5'><ICONS.ChevronDownIcon className='w-3' /></span>}</span>
													<span className='font-light flex flex-row gap-1'>Ask: { entry.AskAfterTrade ? `$${entry.AskAfterTrade}` : '-' }{entry.AskBeforeTrade<entry.AskAfterTrade && <span className='text-green-500 pl-0.5'><ICONS.ChevronUpIcon className='w-3' /></span>}{entry.AskBeforeTrade>entry.AskAfterTrade && <span className='text-red-500 pl-0.5'><ICONS.ChevronDownIcon className='w-3' /></span>}</span>
												</span>
											</span>
										</span>
										
										<span className='flex flex-row'>
											<span className='font-light text-gray-500'>Days to Change:</span>
											<span className='flex flex-row w-full justify-evenly'>
												<span className='flex flex-col items-center font-medium justify-start'>
													<span className='font-light'>Remaining: { countWeekdays(todaysDate(), entry.ExpirationDateString) }</span>
												</span>
												<span className='flex flex-col items-start font-medium justify-start'>
													<span className='font-light'>Change: { calculatePercentagePerDay(entry.UnderlyingPriceAtTrade, entry.BreakevenPrice, countWeekdays(todaysDate(), entry.ExpirationDateString))}</span>
												</span>
											</span>
										</span>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
        </div>
      }

			<NOTABLE_TRADE_MODAL
        open={ showTradeInfo }
        audit={ true }
        setOpen={() => { updateShowTradeInfo(false); updateTradeDetail(null); }}
        data={ tradeDetail } />
    </main>
  );
};

export default React.memo(NotableTrades, dontRerender);