import React, { useEffect, useState, useRef } from 'react';
import Headers from "../bin/Headers";
import dontRerender from '../bin/dontRerender';
import abbrInt from '../bin/abbrInt';
import truncateToTwoDigits from '../bin/truncateToTwoDigits';
import isOiProbableUi from '../bin/isOiProbableUi';
import config from '../config.json';
import LOADING from "./LOADING";
import { useNavigate, useSearchParams } from 'react-router-dom';

const MAIN = (props) => {
	let data = props.data || null,
		token = props.token || null,
		auditResult = props.auditResult || null;

	const [searchParams, setSearchParams] = useSearchParams();
	let urlTweetType = searchParams.get("tweettype");
	let urlTweetId = searchParams.get("tweetid");

	const [LoadingValue, setLoadingValue] = useState(true),
		[copied, setCopied] = useState(false),
		[shareImage, setShareImage] = useState(null),
		[shareTweetLink, setShareTweetLink] = useState(null),
		[shareLoadingValue, setShareLoadingValue] = useState(false),
		[tweetId, setTweetId] = useState(''),
		sendingTweet = useRef(false);

	const start = async () => {
		if (urlTweetId) {
			setTweetId(urlTweetId);
		}
		setLoadingValue(false);
	};

	const handleFocus = (event) => {
		if (!copied) {
			event.target.select();
			document.execCommand('copy');
			alert('Text copied to clipboard!');
			setCopied(true);
		}
	};

	const extractTweetId = () => {
		let url = tweetId;
		// Regular expression to match the tweet ID in the URL
		const tweetIdMatch = url.match(/\/status\/(\d+)/);
		if (tweetIdMatch && tweetIdMatch.length > 1) {
			setTweetId(tweetIdMatch[1]);
			return;
		} else {
			return tweetId;
		}
	};

	const getShareImageDownload = async (type = 'initial') => {
		try {
			if (sendingTweet.current) { alert('preventing double invocation'); return; }
			sendingTweet.current = true;
			let tweetText = '';
			// if (type === 'initial') { tweetText = `Notable Trade on $${data.UnderlyingTicker}\n\n${data.Size ? (data.Size).toLocaleString() : ''} $${data.OptionStrike ? (data.OptionStrike).toLocaleString() : (data.Strike ? (data.Strike).toLocaleString() : '')} ${data.OptionType === 'CALL' || data.Type === 'CALL' ? 'CALLS' : (data.OptionType === 'PUT' || data.Type === 'PUT' ? 'PUTS' : '')} -$${data.Notional ? (data.Notional).toLocaleString() : ''}\nExpires: ${data.ExpirationDateString}`; }
			if (type === 'initial') {
				tweetText = `Notable Trade on $${data.UnderlyingTicker}\n\n${data.Size ? (data.Size).toLocaleString() : ''} $${data.OptionStrike ? (data.OptionStrike).toLocaleString() : (data.Strike ? (data.Strike).toLocaleString() : '')} ${data.OptionType === 'CALL' || data.Type === 'CALL' ? 'CALLS' : (data.OptionType === 'PUT' || data.Type === 'PUT' ? 'PUTS' : '')} -$${data.Notional ? abbrInt(data.Notional, false, true) : ''}\nExpires: ${data.ExpirationDateString}`;
			}
			if (type === 'confirm') {
				let MidQuoteValue = '';
				if (auditResult && auditResult.MidLast) {
					let quotePercentage = truncateToTwoDigits(((auditResult.MidLast / data.Price) - 1) * 100);;
					let quotePercentageString = `${quotePercentage}%`;
					MidQuoteValue = `\nLast Value: ${quotePercentageString} ($${abbrInt((auditResult.MidLast * data.Size * data.SharesPerContract), false)}) [Mid $${auditResult.MidLast}]`;
				}
				tweetText = `Update for $${data.UnderlyingTicker}: ${data.OpenInterestIncreasedNetProjectionMinimumAfterTrade ? 'OI Confirmed' : (isOiProbableUi(data, true) ? isOiProbableUi(data, true) : 'OI did not confirm this trade')}\n\nHigh: ${truncateToTwoDigits(data.EndingHighestPercent, 0)}% ($${abbrInt(data.Notional, false, false)} -> $${abbrInt((data.EndingHighest * data.Size * data.SharesPerContract), false)})\nLow: ${truncateToTwoDigits(data.EndingLowestPercent, 0)}% ($${abbrInt(data.Notional, false, false)} -> $${abbrInt((data.EndingLowest * data.Size * data.SharesPerContract), false)})${MidQuoteValue}`;
			}
			if (type === 'result') {
				tweetText = `Final Update for $${data.UnderlyingTicker}\n\nHigh: ${truncateToTwoDigits(data.EndingHighestPercent, 0)}% ($${abbrInt(data.Notional, false, false)} -> $${abbrInt((data.EndingHighest * data.Size * data.SharesPerContract), false)})\nLow: ${truncateToTwoDigits(data.EndingLowestPercent, 0)}% ($${abbrInt(data.Notional, false, false)} -> $${abbrInt((data.EndingLowest * data.Size * data.SharesPerContract), false)})`;
			}
			if (!tweetId && (type === 'confirm' || type === 'result')) {
				alert('Missing Tweet Id');
				sendingTweet.current = false;
				return;
			}
			let payload = {
				id: data._id,
				expiration: data.ExpirationDateString,
				type,
				tweetId,
				tweetText,
			};
			setShareLoadingValue(true);
			let response = await fetch(`${config.api}/sharenotabletrade`, { method: "POST", headers: Headers(token), body: JSON.stringify(payload) }).then(d => d.json()).then(d => d.message === 'ok' ? d : null);
			if (!response) { throw `unexpected response`; }
			if (!response.image) { setShareImage(null); } else { setShareImage(response.image); }
			if (!response.tweetLink) { setShareTweetLink(null); } else { setShareTweetLink(response.tweetLink); }
			setShareLoadingValue(false);
			sendingTweet.current = false;
		} catch (error) {
			console.error('Failed to share: ', error);
			alert('Failed to share');
			setShareLoadingValue(false);
			sendingTweet.current = false;
		}
	};

	useEffect(() => { start(); }, [data]);
	useEffect(() => { setCopied(false); }, [props.data]);

	return (
		<div>
			{LoadingValue === true && <div className='w-full'><LOADING color="text-green-700" /></div>}
			{LoadingValue === false ?
				<div className="mt-2 w-full flex flex-col items-start justify-center">
					{/* <a href={`/_/${data.UnderlyingTicker}`} className='w-full flex flex-col items-start justify-center'> */}
					<span className='w-full flex flex-col items-start justify-center'>
						<span className="flex flex-row w-full items-center justify-center font-light gap-1 text-sky-500">
							<span className='font-light w-100 text-center'>{data.UnderlyingTicker ? data.UnderlyingTicker : ''}</span>
							{data.Type || data.OptionType ? <span className={`text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center ${(data.OptionType === 'CALL' || data.Type === 'CALL') ? 'text-emerald-700 bg-emerald-300' : ''}${(data.OptionType === 'PUT' || data.Type === 'PUT') ? 'text-rose-700 bg-rose-300' : ''}`}>{data.OptionType ? data.OptionType : (data.Type ? data.Type : '')}</span> : ``}
							{data.ExpirationDateString ? <span className="text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center text-violet-700 bg-violet-300">{data.ExpirationDateString}</span> : ``}
							<span className='font-light w-100 text-center'>${data.OptionStrike ? (data.OptionStrike).toLocaleString() : (data.Strike ? (data.Strike).toLocaleString() : '')}</span>
						</span>
						<span className="flex flex-row w-full items-center justify-center font-light gap-1 text-sky-500">
							<span className='font-light w-100 text-center'>{data.Size ? (data.Size).toLocaleString() : ''}</span>
							<span className='font-light w-100 text-center'>@</span>
							<span className='font-light w-100 text-center'>${data.Price ? (data.Price).toLocaleString() : ''} <span className='text-sm font-light text-center text-amber-700 py-0 px-2 rounded bg-amber-300'>${data.Notional ? (data.Notional).toLocaleString() : ''}</span></span>
						</span>
						<span className="flex flex-row w-full items-center justify-center">
							<input value={data._id} onFocus={handleFocus} readOnly className="border border-indigo-800 bg-indigo-950 text-xxs px-2 rounded font-light gap-1 text-sky-500" />
						</span>
					</span>
					{/* </a> */}
					{shareLoadingValue === true && <div className='w-full'><LOADING color="text-green-700" /></div>}

					{shareLoadingValue === false ?
						<span className="mt-5 flex flex-row w-full items-center justify-center">
							{/* <textarea onFocus={handleFocus} readOnly className='w-3/4 rounded' value={tweetText} ></textarea> */}
							<input type="text" value={tweetId} onKeyDown={(evt) => { if (evt.key === 'Enter') { extractTweetId(); } }} onBlur={(evt) => { extractTweetId(); }} onChange={(evt) => setTweetId(evt.target.value)} className="border border-gray-300 rounded px-4 py-2 w-64 focus:border-blue-500 focus:outline-none" placeholder="TweetId" />
							{/* <button onClick={()=>{extractTweetId()}} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-orange-900 bg-orange-300">🔨</button> */}
						</span>
						: <></>}
					{shareLoadingValue === false && !urlTweetType ?
						<span className="mt-5 flex flex-row gap-2 w-full items-center justify-center">
							<button onClick={() => { getShareImageDownload('initial') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-cyan-900 bg-cyan-300">Initial Tweet</button>
							{tweetId ? <button onClick={() => { getShareImageDownload('confirm') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-amber-900 bg-amber-300">Confirm Tweet</button> : <></>}
							{tweetId ? <button onClick={() => { getShareImageDownload('result') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-indigo-900 bg-indigo-300">Result Tweet</button> : <></>}
						</span>
						: <></>}
					{shareLoadingValue === false && urlTweetType === 'confirm' && tweetId ?
						<span className="mt-5 flex flex-row gap-2 w-full items-center justify-center">
							<button onClick={() => { getShareImageDownload('confirm') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-amber-900 bg-amber-300">Confirm Tweet</button>
						</span>
						: <></>}
					{shareLoadingValue === false && urlTweetType === 'result' && tweetId ?
						<span className="mt-5 flex flex-row gap-2 w-full items-center justify-center">
							<button onClick={() => { getShareImageDownload('result') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-indigo-900 bg-indigo-300">Result Tweet</button>
						</span>
						: <></>}
					{shareImage ? <span className="mt-10 flex flex-row w-full items-center justify-center">
						<img src={shareImage} alt="Screenshot" />
					</span> : <></>}
					{shareTweetLink ? <span className="mt-10 flex flex-row w-full items-center justify-center">
						<a href={shareTweetLink} target='_blank' className="text-xs border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-emerald-900 bg-emerald-300">{shareTweetLink}</a>
					</span> : <></>}
					{/* <span className="mt-20 flex flex-row w-full items-start justify-between gap-1">
						<span className='font-light text-gray-400 w-96 text-left'>Shared to Twitter:</span>
						<span className="ml-1 font-light flex flex-row w-full items-center justify-end gap-1">
							<span className="text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center text-rose-700 bg-rose-300">n/a</span>
						</span>
					</span> */}
					{/* <span className="flex flex-row w-full items-start justify-between gap-1">
						<span className='font-light text-gray-400 w-100 text-left'>Expiration:</span>
						<span className="ml-1 font-light flex flex-row w-full items-center justify-end gap-1">
							{data.ExpirationDateString ? <span className="text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center text-gray-700 bg-gray-300">{data.ExpirationDateString}</span> : ``}
							{data.ExpirationDateString ? <span className="text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center text-red-700 bg-red-300">{calculateDaysToExpiration(data.ExpirationDateString)}</span> : ``}
						</span>
					</span> */}
					{/* <span className="mt-5 flex flex-row w-full items-start justify-between gap-1">
						<span className='font-light text-gray-400 w-100 text-left'>Target Entry:</span>
						{!data.Copy ? <span className="ml-1 font-light w-100 text-right text-amber-500">${data.Price ? truncateToTwoDigits(data.Price * 0.5) : ''}/each</span> : ''}
						{data.Copy ? <span className="ml-1 font-light w-100 text-right text-amber-500">${data.Price ? truncateToTwoDigits(data.Price) : ''}/each</span> : ''}
					</span>
					<span className="flex flex-row w-full items-start justify-between gap-1">
						<span className='font-light text-gray-400 w-100 text-left'>Target Exit:</span>
						{!data.Copy ? <span className="ml-1 font-light w-100 text-right text-amber-500">${data.Price ? truncateToTwoDigits((data.Price * 0.5) * 5) : ''}/each</span> : ''}
						{data.Copy ? <span className="ml-1 font-light w-100 text-right text-amber-500">${data.Price ? truncateToTwoDigits((data.Price) * 5) : ''}/each</span> : ''}
					</span>
					<span className="mt-5 flex flex-row w-full items-start justify-between gap-1">
						<span className='font-light text-gray-400 w-100 text-left'>Cancel Pending Entry:</span>
						<span className="ml-1 font-light w-100 text-right text-amber-500">${data.Price ? truncateToTwoDigits(data.Price * 3) : ''}</span>
					</span>
					<span className="flex flex-row w-full items-start justify-between gap-1">
						<span className='font-light text-gray-400 w-100 text-left'>Cancel/Timed Exit:</span>
						<span className="ml-1 font-light w-100 text-right"><span className="text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center text-gray-700 bg-gray-300">13pm est</span></span>
					</span> */}

				</div>
				: <></>
			}
		</div>
	);
};

export default React.memo(MAIN, dontRerender);