import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import abbrInt from "../bin/abbrInt";
import dontRerender from "../bin/dontRerender";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";

const ORDER_BOOK = (props) => {
	const navigate = useNavigate();
	let orderBook = props.orderBook,
		ticker = props.ticker,
		wsConnected = props.wsConnected,
		orderBookTime = props.orderBookTime,
		// marketState = props.marketState,
		[millionDollarOrdersOnly, setMillionDollarOrdersOnly] = useState(false),
		[rangeBoundOrdersOnly, setRangeBoundOrdersOnly] = useState(false);

  //don't display orderbook if this isn't the calculated view
	// if (view!=='orderBook'){ return null; }
	
	const returnVisualTime= (nanosecondTimestamp) => {
		let diff = Math.trunc(Number(nanosecondTimestamp) / 1000000);
		let last = new Date(diff);
		return `${last.getHours().toString().padStart(2, '0')}:${last.getMinutes().toString().padStart(2, '0')}:${last.getSeconds().toString().padStart(2, '0')}.${last.getMilliseconds().toString().padStart(3, '0')}`;
	};

  //render the orderbook
  return (
    // <div className="rounded-lg flex flex-col justify-start items-center bg-gray-500 border border-solid border-gray-500 w-full sm:max-w-sm">
		<div className="rounded-lg flex flex-col justify-start items-center bg-gray-500 border border-solid border-gray-500 w-full">
			<h6 className='bg-gradient-to-r from-sky-950 to-purple-950 border-b border-b-gray-800 rounded-t-lg text-amber-500 px-1 w-full text-right text-semibold flex justify-end items-center text-xxs relative'>*beta testing</h6>
			<h6 className={`bg-gradient-to-r ${orderBook.OrderBookWeight && orderBook.OrderBookWeight==='ASK' ? `from-gray-950 to-rose-950` : (orderBook.OrderBookWeight && orderBook.OrderBookWeight==='BID' ? `from-emerald-950 to-gray-950` : `to-gray-950 from-gray-950`)} border-b border-b-gray-800 rounded-t-lg text-gray-100 w-full text-center text-semibold flex justify-center items-center relative`}>
        {/* <span className={`mr-2 inline-flex rounded-full absolute left-2 text-xxs`}> */}
				<span className={`inline-flex items-center absolute -left-1`}>
					<button type="button" onClick={()=>setMillionDollarOrdersOnly(!millionDollarOrdersOnly)}>
						{/* { orderBookTime ? orderBookTime : ''} */}
						{millionDollarOrdersOnly && <span className="flex justify-center items-center text-xxs h-6 w-10 text-white m-1 bg-amber-700 bg-opacity-70 rounded-r-lg rounded-tl-lg">$100k+</span>}
						{!millionDollarOrdersOnly && <span className="flex justify-center items-center text-xxs h-6 w-10 text-gray-500 m-1 bg-gray-700 bg-opacity-70 rounded-r-lg rounded-tl-lg">$100k+</span>}
					</button>
					{/* <span className={`mx-2 rounded-full h-2 w-2 ${wsConnected ? 'bg-green-500 animate-ping' : 'bg-red-500'}`}></span> */}
				</span>
				{/* <span className='py-1 text-xs'>{ticker} Order Book</span> */}
				<span className='py-1 text-xxs text-purple-400'>{ orderBookTime ? returnVisualTime(orderBookTime) : ''}</span>
				<span className="inline-flex items-center absolute -right-1">
					{/* <span className='text-[10px]'>{ orderBookTime ? returnVisualTime(orderBookTime) : ''}</span> */}
					<button type="button" onClick={() => setRangeBoundOrdersOnly(!rangeBoundOrdersOnly)}>
					{rangeBoundOrdersOnly && <span className="flex justify-center items-center text-xxs h-6 w-10 text-white m-1 bg-sky-700 bg-opacity-70 rounded-l-lg rounded-tr-lg">range</span>}
					{!rangeBoundOrdersOnly && <span className="flex justify-center items-center text-xxs h-6 w-10 text-gray-500 m-1 bg-gray-700 bg-opacity-70 rounded-l-lg rounded-tr-lg">range</span>}
						{/* <span className="flex justify-center items-center text-xxs h-6 w-10 text-white m-1 bg-sky-800 bg-opacity-70 rounded-l-lg rounded-tr-lg">bound</span> */}
						{/* { (!marketState) && <span className="flex justify-center items-center text-xxs h-6 w-10 text-white m-1 bg-red-700 bg-opacity-70 rounded-l-lg rounded-tr-lg">closed</span> }
						{ marketState && marketState==='pre' && <span className={`flex justify-center items-center text-xxs h-6 w-10 text-white m-1 bg-yellow-700 bg-opacity-70 rounded-l-lg rounded-tr-lg ${rangeBoundOrdersOnly ? 'border border-white' : ''}`}>pre</span> }
						{ marketState && marketState==='open' && <span className={`flex justify-center items-center text-xxs h-6 w-10 text-white m-1 bg-green-700 bg-opacity-70 rounded-l-lg rounded-tr-lg ${rangeBoundOrdersOnly ? 'border border-white' : ''}`}>open</span> }
						{marketState && marketState === 'post' && <span className={`flex justify-center items-center text-xxs h-6 w-10 text-white m-1 bg-orange-700 bg-opacity-70 rounded-l-lg rounded-tr-lg ${rangeBoundOrdersOnly ? 'border border-white' : ''}`}>post</span>} */}
					</button>
        </span>
      </h6>
      <div className="w-full flex flex-row h-full">
        {/* { (!marketState) &&
          <h1 className="py-8 font-light w-full text-center text-gray-300 text-xs">market is closed</h1>
        } */}
        {/* { marketState && (!orderBook || !orderBook.BidSideOrders || !orderBook.AskSideOrders) && */}
        { (!orderBook || !orderBook.BidSideOrders || !orderBook.AskSideOrders) &&
          <h1 className="py-8 font-light w-full text-center text-gray-300 text-xs">no orders yet</h1>
        }
        {/* { marketState && orderBook && orderBook.BidSideOrders && orderBook.AskSideOrders && <> */}
        { orderBook && orderBook.BidSideOrders && orderBook.AskSideOrders && <>
        {/* <div className="flex flex-col text-right w-full pr-2 max-h-96"> */}
        <div className="flex flex-col text-right w-full pr-2 max-h-max">
					<table className="divide-y divide-gray-200 w-full self-start">
						<thead>
							<tr>
								<th scope="col" colSpan="4" className="py-1 bg-gray-800 bg-opacity-50">
									<span className='text-center text-xxs flex justify-center items-center relative'>
										<span className="inline-flex absolute left-2 font-light text-gray-200">{rangeBoundOrdersOnly && orderBook.BidSideSharesBounds ? abbrInt(orderBook.BidSideSharesBounds,false,false) : abbrInt(orderBook.BidSideShares,false,false) }</span>
										<span className='font-bold text-green-500'>Buy Orders</span>
										<span className="inline-flex absolute right-2 font-light text-gray-200">{rangeBoundOrdersOnly && orderBook.BidSideShareNotionalBounds ?  `$`+ abbrInt(orderBook.BidSideShareNotionalBounds,false) : (orderBook.BidSideShareNotional ? `$`+ abbrInt(orderBook.BidSideShareNotional,false) : '') }</span>
									</span>
								</th>
							</tr>
							<tr className="bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-left">
                <th scope="col" className="p-1 font-medium uppercase tracking-wider hidden sm:block text-right">Ex</th>
                <th scope="col" className="p-1 font-medium uppercase tracking-wider text-left sm:text-right">Value</th>
                <th scope="col" className="p-1 font-medium uppercase tracking-wider text-center sm:text-right">Qyt</th>
                <th scope="col" className="p-1 font-medium uppercase tracking-wider text-right">Price</th>
              </tr>
						</thead>
						<tbody className="text-gray-200 text-xs whitespace-nowrap">
							<tr><th scope="col" colSpan="6" className="hidden font-light text-center text-gray-300 text-xs">&nbsp;</th></tr>
						</tbody>
					</table>
					{/* <div className="flex flex-col text-right w-full max-h-96 overflow-y-scroll"> */}
					<div className="flex flex-col text-right w-full max-h-max overflow-y-scroll">
						<table className="divide-y divide-gray-200 w-full self-end">
							{/* <thead>
								<tr>
									<th scope="col" colSpan="4" className="py-1 bg-gray-800 bg-opacity-50 text-center font-bold text-xxs text-green-500">Buy Orders</th>
								</tr>
								<tr className="bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-left">
									<th scope="col" className="p-1 font-medium uppercase tracking-wider hidden sm:block">Ex</th>
									<th scope="col" className="p-1 font-medium uppercase tracking-wider">Value</th>
									<th scope="col" className="p-1 font-medium uppercase tracking-wider">Qyt</th>
									<th scope="col" className="p-1 font-medium uppercase tracking-wider">Price</th>
								</tr>
							</thead> */}
							<tbody className="text-gray-200 text-xs whitespace-nowrap">
								{ (orderBook.BidSideOrders) ?
									(orderBook.BidSideOrders)
									.map((entry, idx) => {
										if (idx !== 0) {
											if (millionDollarOrdersOnly && entry.value < 100000) { return null; }
											if (rangeBoundOrdersOnly && !entry.bounds) { return null; }
										}
										// return (<tr key={idx} onClick={() => { navigate(`/alerts?ticker=${ticker}&type=stock&value=${truncateToTwoDigits(entry.price - 0.01)}&what=last&logic=lessThanEqual`); }} className={`hover:pointer bg-opacity-25 text-left ${millionDollarOrdersOnly && idx===0 ? 'bg-emerald-700' : (idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800')} text-xxs ${entry.value >= 1000000 ? `text-amber-300` : `text-gray-300`}`}>
										return (<tr key={idx} className={`hover:pointer bg-opacity-25 text-left ${idx===0 ? 'bg-emerald-700' : (idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800')} text-xxs ${entry.value >= 100000 ? `text-amber-300` : `text-gray-300`}`}>
											<td className="hover:pointer p-1 font-light hidden sm:block text-right">{entry.mpid}</td>
											<td className="hover:pointer p-1 font-light text-left sm:text-right">${abbrInt(entry.value)}</td>
											<td className="hover:pointer p-1 font-light text-center sm:text-right">{abbrInt(entry.shares, false)}</td>
											<td className="hover:pointer p-1 font-medium text-right">${(entry.price).toLocaleString()}</td>
										</tr>);
									})
									:
									<></>
								}
							</tbody>
						</table>
					</div>
        </div>
        
        {/* <div className="flex flex-col text-left w-full pl-2 max-h-96"> */}
        <div className="flex flex-col text-left w-full pl-2 max-h-max">
					<table className="divide-y divide-gray-200 w-full self-start">
						<thead>
							<tr>
								<th scope="col" colSpan="4" className="py-1 bg-gray-800 bg-opacity-50">
									<span className='text-center text-xxs flex justify-center items-center relative'>
										<span className="inline-flex absolute left-2 font-light text-gray-200">{ rangeBoundOrdersOnly && orderBook.AskSideShareNotionalBounds ?  `$`+ abbrInt(orderBook.AskSideShareNotionalBounds,false) : (orderBook.AskSideShareNotional ? `$`+ abbrInt(orderBook.AskSideShareNotional,false) : '') }</span>
										<span className='font-bold text-red-500'>Sell Orders</span>
										<span className="inline-flex absolute right-2 font-light text-gray-200">{ rangeBoundOrdersOnly && orderBook.AskSideSharesBounds ? abbrInt(orderBook.AskSideSharesBounds,false,false) : abbrInt(orderBook.AskSideShares,false,false) }</span>
									</span>
								</th>
							</tr>
							<tr className="bg-gray-800 text-gray-200 bg-opacity-50 text-xxs text-right">
								<th scope="col" className="p-1 font-medium uppercase tracking-wider text-left">Price</th>
								<th scope="col" className="p-1 font-medium uppercase tracking-wider text-center sm:text-left">Qyt</th>
								<th scope="col" className="p-1 font-medium uppercase tracking-wider text-right sm:text-left">Value</th>
								<th scope="col" className="p-1 font-medium uppercase tracking-wider hidden sm:block text-left">Ex</th>
							</tr>
						</thead>
						<tbody className="text-gray-200 text-xs whitespace-nowrap">
							<tr><th scope="col" colSpan="6" className="hidden font-light text-center text-gray-300 text-xs">&nbsp;</th></tr>
						</tbody>
					</table>
          {/* <div className="flex flex-col text-left w-full max-h-96 overflow-y-scroll"> */}
          <div className="flex flex-col text-left w-full max-h-max overflow-y-scroll">
						<table className="divide-y divide-gray-200 w-full self-start">
							<tbody className="text-gray-200 text-xs whitespace-nowrap">
								{ !orderBook || !orderBook.AskSideOrders || !orderBook.AskSideOrders.length &&
									<tr><th scope="col" colSpan="6" className="py-8 font-light text-center text-gray-300 text-xs">no sell orders</th></tr>
								}
								{ (orderBook.AskSideOrders) ?
									(orderBook.AskSideOrders)
									.map( (entry,idx) => {
										if (idx !== 0) {
											if (millionDollarOrdersOnly && entry.value < 100000) { return null; }
											if (rangeBoundOrdersOnly && !entry.bounds) { return null; }
										}
										// return (<tr key={idx} onClick={() => { navigate(`/alerts?ticker=${ticker}&type=stock&value=${truncateToTwoDigits(entry.price + 0.01)}&what=last&logic=greaterThanEqual`); }} className={`hover:pointer bg-opacity-25 text-left ${millionDollarOrdersOnly && idx===0 ? 'bg-rose-700' : (idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800')} text-xxs ${ entry.value>=1000000 ? `text-amber-300`: `text-gray-300` }`}>
										return (<tr key={idx} className={`hover:pointer bg-opacity-25 text-left ${idx===0 ? 'bg-rose-700' : (idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800')} text-xxs ${ entry.value>=100000 ? `text-amber-300`: `text-gray-300` }`}>
											<td className="hover:pointer p-1 font-medium text-left">${ (entry.price).toLocaleString() }</td>
											<td className="hover:pointer p-1 font-light text-center sm:text-left">{ abbrInt(entry.shares, false) }</td>
											<td className="hover:pointer p-1 font-light text-right sm:text-left">${ abbrInt(entry.value) }</td>
											<td className="hover:pointer p-1 font-light hidden sm:block text-left">{ entry.mpid }</td>
										</tr>);
									})
									:
									<></>
								}
							</tbody>
						</table>
					</div>

        </div>
        </>}

			</div>
			
    </div>
  );
};

export default React.memo(ORDER_BOOK, dontRerender);