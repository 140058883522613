import isDst from './isDst';

const main = (focalDate) => {
	let d = new Date(`${focalDate}T14:30:00.000Z`); //14:30utc === 09:30est
	let adjustForDst = isDst(d.getTime());
	if (adjustForDst) { d.setUTCHours(d.getUTCHours() - 1); }
	let result = d.getDay() >= 1 && d.getDay() < 6 ? true : false;
	return result;
};

export default main;