import React, {useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import todaysDate from "../bin/todaysDate";
import config from '../config.json';
import decodeJwt from "../bin/decodeJwt";
import dontRerender from "../bin/dontRerender";
import abbrInt from "../bin/abbrInt";
import Headers from "../bin/Headers";
import compileTradeDetailForModal from "../bin/compileTradeDetailForModal";
import LOADING from "../components/LOADING";
import SPARKLINECHART from "../components/SPARKLINECHART";
import MODAL from "../components/MODAL";
import { BanknotesIcon, BookOpenIcon, ClockIcon, BeakerIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const MAIN= (props)=>{
	let token = useSelector(state => state.token),
		ticker = props.ticker,
		passedtrades = props.trades,
		loading = props.loading,
		_trades = useRef([]),
		_sort = useRef('time'),
		[delayedData, setDelayedData] = useState(false),
		[sort, setSort] = useState('time'),
		[trades, setTrades] = useState([]),
		[candles, updateCandles]= useState([]),
		[showTradeInfo, updateShowTradeInfo]= useState(false),
		[tradeDetail, updateTradeDetail]= useState(null);

	const tradeEntryUi = (item, index) => {
		let side = null;
		let bgColor = 'bg-gray-950/50';
		
		// if (item.TradeSide === 'SELL' && !item.ClosingTrade && !item.OpeningTrade) { side = 'Bid'; bgColor = 'bg-rose-950/50'; }
		// else if (item.TradeSide === 'BUY' && !item.ClosingTrade && !item.OpeningTrade) { side = 'Ask'; bgColor = 'bg-emerald-950/50'; }
		if (item.TradeSide === 'SELL') { side = 'Bid'; bgColor = 'bg-rose-950/50'; }
		else if (item.TradeSide === 'BUY') { side = 'Ask'; bgColor = 'bg-emerald-950/50'; }
		else if (item.TradeSide) { side = 'Mid'; }

		let exchange = item.Exchange ? item.Exchange : null;
		if (exchange === 'XADF') { exchange = 'DARK'; }
		else if (exchange === 'XNYS') { exchange = 'NYSE'; }
		else if (exchange === 'XNAS') { exchange = 'NASDAQ'; }
		// else if (exchange === 'ARCX') { exchange = 'NYSE ARCA'; }
		else if (exchange === 'ARCX') { exchange = 'ARCA'; }
		else if (exchange === 'BATS') { exchange = 'BATS'; }
		// else if (exchange === 'XCHI') { exchange = 'NYSE CHICAGO'; }
		else if (exchange === 'XCHI') { exchange = 'CHICAGO'; }
		// else if (exchange === 'XPHL') { exchange = 'NASDAQ PHILLY'; }
		else if (exchange === 'XPHL') { exchange = 'PHILLY' }
		// else if (exchange === 'XASE') { exchange = 'NYSE AMEX'; }
		else if (exchange === 'XASE') { exchange = 'AMEX'; }
		else if (exchange === 'XOTC') { exchange = 'OTC'; }
		else if (exchange === 'XCBO') { exchange = 'CBOE'; }
		else if (exchange === 'IEXG') { exchange = 'IEX'; }
		else if (exchange === 'MEMX') { exchange = 'MEMX'; }

		// console.log(`item [${side}]: `, item);
		return (
			<button key={index} className={`flex flex-row w-full text-xxs sm:text-xs text-gray-200 ${bgColor}`} onClick={()=>modalTradeDetail(item)}>
				<span className={`${!ticker ? 'hidden' : 'flex'} p-1 items-center justify-center font-light w-12 ${item.Rank>=1 && item.Rank<=3 ? 'text-amber-500' : ''}`}>#{item.Rank}</span>
				<span className={`hidden p-1 items-center justify-center w-20 ${!ticker ? 'sm:flex' : ''}`}>{item.Ticker}</span>
				<span className={`p-1 flex items-center justify-start sm:justify-center flex-grow `}>
					<span className='hidden sm:block'>{(item.Size).toLocaleString()} @ ${(item.Price).toLocaleString()}</span>
					<span className='flex flex-col w-full items-start justify-start sm:hidden'>

						<span className='flex flex-row w-full items-start justify-start gap-1'>
							<span className={`text-left font-bold ${!ticker ? 'inline' : 'hidden'} w-14`}>{item.Ticker}</span>
						</span>
						<span className={`flex flex-row w-full items-start justify-between gap-1`}>
							<span className="inline-block text-left w-full">
								{(item.Size).toLocaleString()} @ ${(Number((item.Price).toFixed(2))).toFixed(2).toLocaleString()}
							</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Value:</span>
								<span className="ml-1 font-light w-100 text-center text-amber-300">${abbrInt(item.Notional)}</span>
							</span>
						</span>
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className="inline-block text-left w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Side:</span>
								{item.ClosingTrade ? <span className='mx-1 text-xxs px-1 bg-rose-700 rounded text-rose-200'>close</span> : <></>}
								{item.OpeningTrade ? <span className='mx-1 text-xxs px-1 bg-emerald-700 rounded text-emerald-200'>open</span> : <></>}
								{!item.ClosingTrade && !item.OpeningTrade ? <span className="ml-1 font-light w-100 text-center text-blue-400">{item.Side ? item.Side : (side ? side : '')}</span> : <></>}
							</span>
							<span className={`inline-block text-left w-full ${item.Exchange==='XADF' ? 'bg-gray-900/50' : ''}`}>
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Exchange:</span>
								<span className="ml-1 font-light w-100 text-center text-rose-400">{exchange}</span>
							</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Time:</span>
								<span className={`ml-1 font-light w-100 text-center ${item.Audited ? 'text-cyan-200' : 'text-gray-400'}`}>{item.EntryTimeString}</span>
							</span>
						</span>
					</span>
				</span>
				<span className="hidden sm:flex p-1 items-center justify-center w-32">${abbrInt(item.Notional, false)}</span>
				<span className="hidden sm:flex p-1 items-center justify-center flex-col w-20">
					{!item.ClosingTrade && !item.OpeningTrade ? <span>{item.Side ? item.Side : (side ? side : '')}</span> : <></>}
					{item.ClosingTrade ? <span className='mx-1 text-xxs px-1 bg-rose-700 rounded text-rose-200'>close</span> : <></>}
					{item.OpeningTrade ? <span className='mx-1 text-xxs px-1 bg-emerald-700 rounded text-emerald-200'>open</span> : <></>}
				</span>
				<span className={`hidden sm:flex p-1 items-center justify-center w-24 ${item.Exchange==='XADF' ? 'bg-gray-900/50' : 'text-amber-300'}`}>{exchange}</span>
				<span className={`hidden sm:flex p-1 items-center justify-end text-xxs w-24 ${item.Audited ? 'text-cyan-200' : ''}`}>{item.EntryTimeString}</span>
			</button>
		);
	};

	const sortTrades = () => {
		if (!_trades.current || !_trades.current.length) { return; }
		let _tradesArray = [..._trades.current];
		let __sort = _sort.current;
		_tradesArray.sort((a, b) => b.Notional - a.Notional);
		_tradesArray = _tradesArray.filter((entry) => typeof entry==='object').map((entry, index) => { entry.Rank = (index + 1); return entry; });
		if (__sort === 'time') {
			_tradesArray.sort((a, b) => {
				const timeA = Number(a.EntryTimestamp);
        const timeB = Number(b.EntryTimestamp);

        // Check if both timestamps are valid numbers
        if (!isNaN(timeA) && !isNaN(timeB)) {
					return timeB - timeA;
        } else {
					// Handle invalid timestamps here (e.g., return 0 or some default value)
					return 0;
        }
			});
		}
		if (__sort === 'value') {
			_tradesArray.sort((a, b) => b.Notional - a.Notional);
		}
		_trades.current = _tradesArray;
		setTrades(_trades.current);
	};
	
	const modalTradeDetail = (trade) => {
		if (!trade) { return; }
		// console.log(trade);
		let exchange = trade.Exchange ? trade.Exchange : null;
		if (exchange === 'XADF') { exchange = 'DARK POOL'; }
		else if (exchange === 'XNYS') { exchange = 'NYSE'; }
		else if (exchange === 'XNAS') { exchange = 'NASDAQ'; }
		else if (exchange === 'ARCX') { exchange = 'NYSE ARCA'; }
		else if (exchange === 'BATS') { exchange = 'BATS'; }
		else if (exchange === 'XCHI') { exchange = 'NYSE CHICAGO'; }
		else if (exchange === 'XPHL') { exchange = 'NASDAQ PHILLY'; }
		else if (exchange === 'XASE') { exchange = 'NYSE AMEX'; }
		else if (exchange === 'XOTC') { exchange = 'OTC'; }
		else if (exchange === 'XCBO') { exchange = 'CBOE'; }
		else if (exchange === 'IEXG') { exchange = 'IEX'; }
		else if (exchange === 'MEMX') { exchange = 'MEMX'; }
		let _tradeDetail = compileTradeDetailForModal({
			ticker: trade.ticker ? trade.ticker : null,
			name: trade.name ? trade.name : null,
			time: trade.EntryTimestamp ? trade.EntryTimestamp : null,
			qyt: trade.Size ? trade.Size : null,
			price: trade.Price ? trade.Price : null,
			value: trade.Notional ? trade.Notional : null,
			ex: exchange ? exchange : null,
			side: trade.OrderSide ? trade.OrderSide : null,
			flags: trade.Conditions ? trade.Conditions : null,
		});
    updateTradeDetail(_tradeDetail);
    updateShowTradeInfo(true);
  };

	const updateSorting = (sorting) => {
		setSort(sorting);
		_sort.current = sorting;
		sortTrades();
	};

	const checkIfDelayedData = () => {
		let tradeDate = passedtrades && passedtrades.length ? passedtrades[0].EntryDateString : null;
		// console.log(tradeDate);
		if (tradeDate && tradeDate !== todaysDate()) {
			setDelayedData(tradeDate);
		}
		// if (!token) { setDelayedData(true); return; }
		// // if (!token) { return; }
		// //check the level if red
		// let { level } = decodeJwt(token);
		// if (level==='red' && todaysDate()!==indexDate) {
		// 	setDelayedData(true);
		// }	
	};

	const readCandlesP3 = async (latestTradeDate) => {
		// if (sort!=='graph' || !ticker) { return; }
		const response = await fetch(`https://io.redstripedtie.com/ursa/candles_p3?ticker=${ticker}&format=minute&startDate=${latestTradeDate ? latestTradeDate : todaysDate()}&endDate=${latestTradeDate ? latestTradeDate : todaysDate()}`, { headers: Headers(token) }).then(d => d.json());
		// const response = await fetch(`${config.api}/candles_p3?ticker=${ticker}&format=minute&startDate=${todaysDate()}&endDate=${todaysDate()}`, { headers: Headers(token) }).then(d => d.json());
		if (!response || response.message !== 'ok' || !response.result) { return; }
		updateCandles(response.result);
	};

	useEffect(() => {
		checkIfDelayedData();
		_trades.current = passedtrades;
		let latestTradeDate = trades && trades.length ? trades[0].EntryDateString : null;
		if (!candles.length && latestTradeDate) {
			readCandlesP3(latestTradeDate);
		}
		sortTrades();
	}, [passedtrades, sort]);

  return (
		<div className={`w-full h-full flex flex-col rounded-tr-md rounded-md border bg-gray-200 text-black`}>
			
			<div className="border-gray-300 rounded-t-lg w-full text-center text-semibold flex flex-col justify-center items-start px-1 py-0.5">
				<span className='flex flex-row items-center justify-end w-full'>
					{delayedData ?
						<Link to="/pricing" className='flex flex-row items-start justify-start'>
							<span className='text-xs text-rose-600'>Trades from {delayedData}</span>
						</Link>
						: <></>}
					{!loading ?
						<span className="flex-col inline-flex items-end justify-end flex-grow sm:items-center gap-1">
							<span className='inline-flex w-full justify-end items-center gap-1'>
								<button
									type="button"
									onClick={() => { updateSorting('graph'); }}
									className={`${!token ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'graph' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<BeakerIcon className='w-5 h-4' />
								</button>
								<button
									type="button"
									onClick={() => { updateSorting(sort === 'value' ? 'time' : 'value'); }}
									className={`rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'value' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<BanknotesIcon className='w-5 h-4' />
								</button>
								<button
									type="button"
									onClick={() => { updateSorting(sort === 'time' ? 'value' : 'time'); }}
									className={`rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'time' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<ClockIcon className='w-5 h-4' />
								</button>
							</span>
							{/* <span className='text-xs text-sky-800'>{ orderBookMatching ? 'Order Book Matching' : 'NBBO Matching'}</span> */}
						</span>
						:
						<></>
					}
				</span>
			</div>
			
			<div className="col-span-2 flex flex-grow flex-col rounded-br-md rounded-bl-md border bg-gray-700">
				{loading ? <div className='h-48 flex items-center justify-center'><LOADING color="text-green-500" /></div> : <></>}
				{!loading && (!trades || !trades.length) ? <h6 className='my-5 text-gray-400 text-center'>No Stock Trades</h6> : <></>}
				{!loading && sort!=='graph' && trades && trades.length ?
					<div className="h-full w-full">
						<div className='flex flex-row w-full text-xxs sm:text-xs text-gray-200'>
							<span className={`p-1 items-center justify-center border uppercase font-medium tracking-wider w-12 ${!ticker ? 'hidden' : 'flex'}`}>Rank</span>
							<span className={`hidden p-1 items-center justify-center border font-medium uppercase tracking-wider w-20 ${!ticker ? 'sm:flex' : ''}`}>Ticker</span>
							<span className="p-1 flex items-center justify-center border font-medium uppercase tracking-wider flex-grow">Trade</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-32">Value{sort === 'value' ? <ChevronDownIcon className='w-5 h-4' /> : <></>}</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-20">Side</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-24">Exchange</span>
							<span className="hidden sm:flex p-1 items-center justify-end border font-medium uppercase tracking-wider w-24">Time{sort === 'time' ? <ChevronDownIcon className='w-5 h-4' /> : <></>}</span>
						</div>
						
						{trades.map((item, index) => tradeEntryUi(item, index))}
					</div>
					: <></>
				}
				{!loading && sort === 'graph' && trades && trades.length && candles && candles.length ?
					<div className="h-full w-full flex items-center justify-center">
						<SPARKLINECHART data={candles} trades={trades} />
					</div>
					: <></>
				}
			</div>

			<MODAL
        open={ showTradeInfo }
        setOpen={() => { updateShowTradeInfo(false); updateTradeDetail(null); }}
        headline="Trade Detail [stock]"
        html_information={ true }
        information={ tradeDetail } />

		</div>
  );
};

// export default MAIN;
export default React.memo(MAIN, dontRerender);