import { useStore } from 'react-redux';
import { useNavigate } from "react-router-dom";
import actions from '../state/actions';
import { useWebSocket } from '../components/WEBSOCKET_CONTEXT';

const useMain = () => {
	const store = useStore(),
		{ closeWebSocket } = useWebSocket(),
		navigate = useNavigate();

	// Return a function from your hook that can be called by components.
	return (token) => {
		closeWebSocket();
		store.dispatch(actions.removeToken(token));
		store.dispatch(actions.removeAvatar());
		store.dispatch(actions.removeTieLevel());
		navigate('/login', { replace: true });
	};
}

export default useMain;