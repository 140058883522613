const main = () => {
  // For iOS devices
  if (window.navigator.standalone === true) {
    return true;
  }

  // For Android devices
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  }

  // Not loaded through a PWA
  return false;
};
export default main;