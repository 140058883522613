import AcademicCapIcon from '@heroicons/react/24/outline/AcademicCapIcon';
import AdjustmentsHorizontalIcon from '@heroicons/react/24/outline/AdjustmentsHorizontalIcon';
import AdjustmentsVerticalIcon from '@heroicons/react/24/outline/AdjustmentsVerticalIcon';
import ArchiveBoxArrowDownIcon from '@heroicons/react/24/outline/ArchiveBoxArrowDownIcon';
import ArchiveBoxIcon from '@heroicons/react/24/outline/ArchiveBoxIcon';
import ArchiveBoxXMarkIcon from '@heroicons/react/24/outline/ArchiveBoxXMarkIcon';
import ArrowDownCircleIcon from '@heroicons/react/24/outline/ArrowDownCircleIcon';
import ArrowDownIcon from '@heroicons/react/24/outline/ArrowDownIcon';
import ArrowDownLeftIcon from '@heroicons/react/24/outline/ArrowDownLeftIcon';
import ArrowDownOnSquareIcon from '@heroicons/react/24/outline/ArrowDownOnSquareIcon';
import ArrowDownOnSquareStackIcon from '@heroicons/react/24/outline/ArrowDownOnSquareStackIcon';
import ArrowDownRightIcon from '@heroicons/react/24/outline/ArrowDownRightIcon';
import ArrowDownTrayIcon from '@heroicons/react/24/outline/ArrowDownTrayIcon';
import ArrowLeftCircleIcon from '@heroicons/react/24/outline/ArrowLeftCircleIcon';
import ArrowLeftIcon from '@heroicons/react/24/outline/ArrowLeftIcon';
import ArrowLeftOnRectangleIcon from '@heroicons/react/24/outline/ArrowLeftOnRectangleIcon';
import ArrowLongDownIcon from '@heroicons/react/24/outline/ArrowLongDownIcon';
import ArrowLongLeftIcon from '@heroicons/react/24/outline/ArrowLongLeftIcon';
import ArrowLongRightIcon from '@heroicons/react/24/outline/ArrowLongRightIcon';
import ArrowLongUpIcon from '@heroicons/react/24/outline/ArrowLongUpIcon';
import ArrowPathIcon from '@heroicons/react/24/outline/ArrowPathIcon';
import ArrowPathRoundedSquareIcon from '@heroicons/react/24/outline/ArrowPathRoundedSquareIcon';
import ArrowRightCircleIcon from '@heroicons/react/24/outline/ArrowRightCircleIcon';
import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import ArrowRightOnRectangleIcon from '@heroicons/react/24/outline/ArrowRightOnRectangleIcon';
import ArrowSmallDownIcon from '@heroicons/react/24/outline/ArrowSmallDownIcon';
import ArrowSmallLeftIcon from '@heroicons/react/24/outline/ArrowSmallLeftIcon';
import ArrowSmallRightIcon from '@heroicons/react/24/outline/ArrowSmallRightIcon';
import ArrowSmallUpIcon from '@heroicons/react/24/outline/ArrowSmallUpIcon';
import ArrowTopRightOnSquareIcon from '@heroicons/react/24/outline/ArrowTopRightOnSquareIcon';
import ArrowTrendingDownIcon from '@heroicons/react/24/outline/ArrowTrendingDownIcon';
import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon';
import ArrowUpCircleIcon from '@heroicons/react/24/outline/ArrowUpCircleIcon';
import ArrowUpIcon from '@heroicons/react/24/outline/ArrowUpIcon';
import ArrowUpLeftIcon from '@heroicons/react/24/outline/ArrowUpLeftIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/outline/ArrowUpOnSquareIcon';
import ArrowUpOnSquareStackIcon from '@heroicons/react/24/outline/ArrowUpOnSquareStackIcon';
import ArrowUpRightIcon from '@heroicons/react/24/outline/ArrowUpRightIcon';
import ArrowUpTrayIcon from '@heroicons/react/24/outline/ArrowUpTrayIcon';
import ArrowUturnDownIcon from '@heroicons/react/24/outline/ArrowUturnDownIcon';
import ArrowUturnLeftIcon from '@heroicons/react/24/outline/ArrowUturnLeftIcon';
import ArrowUturnRightIcon from '@heroicons/react/24/outline/ArrowUturnRightIcon';
import ArrowUturnUpIcon from '@heroicons/react/24/outline/ArrowUturnUpIcon';
import ArrowsPointingInIcon from '@heroicons/react/24/outline/ArrowsPointingInIcon';
import ArrowsPointingOutIcon from '@heroicons/react/24/outline/ArrowsPointingOutIcon';
import ArrowsRightLeftIcon from '@heroicons/react/24/outline/ArrowsRightLeftIcon';
import ArrowsUpDownIcon from '@heroicons/react/24/outline/ArrowsUpDownIcon';
import AtSymbolIcon from '@heroicons/react/24/outline/AtSymbolIcon';
import BackspaceIcon from '@heroicons/react/24/outline/BackspaceIcon';
import BackwardIcon from '@heroicons/react/24/outline/BackwardIcon';
import BanknotesIcon from '@heroicons/react/24/outline/BanknotesIcon';
import Bars2Icon from '@heroicons/react/24/outline/Bars2Icon';
import Bars3BottomLeftIcon from '@heroicons/react/24/outline/Bars3BottomLeftIcon';
import Bars3BottomRightIcon from '@heroicons/react/24/outline/Bars3BottomRightIcon';
import Bars3CenterLeftIcon from '@heroicons/react/24/outline/Bars3CenterLeftIcon';
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import Bars4Icon from '@heroicons/react/24/outline/Bars4Icon';
import BarsArrowDownIcon from '@heroicons/react/24/outline/BarsArrowDownIcon';
import BarsArrowUpIcon from '@heroicons/react/24/outline/BarsArrowUpIcon';
import Battery0Icon from '@heroicons/react/24/outline/Battery0Icon';
import Battery100Icon from '@heroicons/react/24/outline/Battery100Icon';
import Battery50Icon from '@heroicons/react/24/outline/Battery50Icon';
import BeakerIcon from '@heroicons/react/24/outline/BeakerIcon';
import BellAlertIcon from '@heroicons/react/24/outline/BellAlertIcon';
import BellIcon from '@heroicons/react/24/outline/BellIcon';
import BellSlashIcon from '@heroicons/react/24/outline/BellSlashIcon';
import BellSnoozeIcon from '@heroicons/react/24/outline/BellSnoozeIcon';
import BoltIcon from '@heroicons/react/24/outline/BoltIcon';
import BoltSlashIcon from '@heroicons/react/24/outline/BoltSlashIcon';
import BookOpenIcon from '@heroicons/react/24/outline/BookOpenIcon';
import BookmarkIcon from '@heroicons/react/24/outline/BookmarkIcon';
import BookmarkSlashIcon from '@heroicons/react/24/outline/BookmarkSlashIcon';
import BookmarkSquareIcon from '@heroicons/react/24/outline/BookmarkSquareIcon';
import BriefcaseIcon from '@heroicons/react/24/outline/BriefcaseIcon';
import BugAntIcon from '@heroicons/react/24/outline/BugAntIcon';
import BuildingLibraryIcon from '@heroicons/react/24/outline/BuildingLibraryIcon';
import BuildingOffice2Icon from '@heroicons/react/24/outline/BuildingOffice2Icon';
import BuildingOfficeIcon from '@heroicons/react/24/outline/BuildingOfficeIcon';
import BuildingStorefrontIcon from '@heroicons/react/24/outline/BuildingStorefrontIcon';
import CakeIcon from '@heroicons/react/24/outline/CakeIcon';
import CalculatorIcon from '@heroicons/react/24/outline/CalculatorIcon';
import CalendarDaysIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import CalendarIcon from '@heroicons/react/24/outline/CalendarIcon';
import CameraIcon from '@heroicons/react/24/outline/CameraIcon';
import ChartBarIcon from '@heroicons/react/24/outline/ChartBarIcon';
import ChartBarSquareIcon from '@heroicons/react/24/outline/ChartBarSquareIcon';
import ChartPieIcon from '@heroicons/react/24/outline/ChartPieIcon';
import ChatBubbleBottomCenterIcon from '@heroicons/react/24/outline/ChatBubbleBottomCenterIcon';
import ChatBubbleBottomCenterTextIcon from '@heroicons/react/24/outline/ChatBubbleBottomCenterTextIcon';
import ChatBubbleLeftEllipsisIcon from '@heroicons/react/24/outline/ChatBubbleLeftEllipsisIcon';
import ChatBubbleLeftIcon from '@heroicons/react/24/outline/ChatBubbleLeftIcon';
import ChatBubbleLeftRightIcon from '@heroicons/react/24/outline/ChatBubbleLeftRightIcon';
import ChatBubbleOvalLeftEllipsisIcon from '@heroicons/react/24/outline/ChatBubbleOvalLeftEllipsisIcon';
import ChatBubbleOvalLeftIcon from '@heroicons/react/24/outline/ChatBubbleOvalLeftIcon';
import CheckBadgeIcon from '@heroicons/react/24/outline/CheckBadgeIcon';
import CheckCircleIcon from '@heroicons/react/24/outline/CheckCircleIcon';
import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import ChevronDoubleDownIcon from '@heroicons/react/24/outline/ChevronDoubleDownIcon';
import ChevronDoubleLeftIcon from '@heroicons/react/24/outline/ChevronDoubleLeftIcon';
import ChevronDoubleRightIcon from '@heroicons/react/24/outline/ChevronDoubleRightIcon';
import ChevronDoubleUpIcon from '@heroicons/react/24/outline/ChevronDoubleUpIcon';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import ChevronLeftIcon from '@heroicons/react/24/outline/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon';
import ChevronUpDownIcon from '@heroicons/react/24/outline/ChevronUpDownIcon';
import ChevronUpIcon from '@heroicons/react/24/outline/ChevronUpIcon';
import CircleStackIcon from '@heroicons/react/24/outline/CircleStackIcon';
import ClipboardDocumentCheckIcon from '@heroicons/react/24/outline/ClipboardDocumentCheckIcon';
import ClipboardDocumentIcon from '@heroicons/react/24/outline/ClipboardDocumentIcon';
import ClipboardDocumentListIcon from '@heroicons/react/24/outline/ClipboardDocumentListIcon';
import ClipboardIcon from '@heroicons/react/24/outline/ClipboardIcon';
import ClockIcon from '@heroicons/react/24/outline/ClockIcon';
import CloudArrowDownIcon from '@heroicons/react/24/outline/CloudArrowDownIcon';
import CloudArrowUpIcon from '@heroicons/react/24/outline/CloudArrowUpIcon';
import CloudIcon from '@heroicons/react/24/outline/CloudIcon';
import CodeBracketIcon from '@heroicons/react/24/outline/CodeBracketIcon';
import CodeBracketSquareIcon from '@heroicons/react/24/outline/CodeBracketSquareIcon';
import Cog6ToothIcon from '@heroicons/react/24/outline/Cog6ToothIcon';
import Cog8ToothIcon from '@heroicons/react/24/outline/Cog8ToothIcon';
import CogIcon from '@heroicons/react/24/outline/CogIcon';
import CommandLineIcon from '@heroicons/react/24/outline/CommandLineIcon';
import ComputerDesktopIcon from '@heroicons/react/24/outline/ComputerDesktopIcon';
import CpuChipIcon from '@heroicons/react/24/outline/CpuChipIcon';
import CreditCardIcon from '@heroicons/react/24/outline/CreditCardIcon';
import CubeIcon from '@heroicons/react/24/outline/CubeIcon';
import CubeTransparentIcon from '@heroicons/react/24/outline/CubeTransparentIcon';
import CurrencyBangladeshiIcon from '@heroicons/react/24/outline/CurrencyBangladeshiIcon';
import CurrencyDollarIcon from '@heroicons/react/24/outline/CurrencyDollarIcon';
import CurrencyEuroIcon from '@heroicons/react/24/outline/CurrencyEuroIcon';
import CurrencyPoundIcon from '@heroicons/react/24/outline/CurrencyPoundIcon';
import CurrencyRupeeIcon from '@heroicons/react/24/outline/CurrencyRupeeIcon';
import CurrencyYenIcon from '@heroicons/react/24/outline/CurrencyYenIcon';
import CursorArrowRaysIcon from '@heroicons/react/24/outline/CursorArrowRaysIcon';
import CursorArrowRippleIcon from '@heroicons/react/24/outline/CursorArrowRippleIcon';
import DevicePhoneMobileIcon from '@heroicons/react/24/outline/DevicePhoneMobileIcon';
import DeviceTabletIcon from '@heroicons/react/24/outline/DeviceTabletIcon';
import DocumentArrowDownIcon from '@heroicons/react/24/outline/DocumentArrowDownIcon';
import DocumentArrowUpIcon from '@heroicons/react/24/outline/DocumentArrowUpIcon';
import DocumentChartBarIcon from '@heroicons/react/24/outline/DocumentChartBarIcon';
import DocumentCheckIcon from '@heroicons/react/24/outline/DocumentCheckIcon';
import DocumentDuplicateIcon from '@heroicons/react/24/outline/DocumentDuplicateIcon';
import DocumentIcon from '@heroicons/react/24/outline/DocumentIcon';
import DocumentMagnifyingGlassIcon from '@heroicons/react/24/outline/DocumentMagnifyingGlassIcon';
import DocumentMinusIcon from '@heroicons/react/24/outline/DocumentMinusIcon';
import DocumentPlusIcon from '@heroicons/react/24/outline/DocumentPlusIcon';
import DocumentTextIcon from '@heroicons/react/24/outline/DocumentTextIcon';
import EllipsisHorizontalCircleIcon from '@heroicons/react/24/outline/EllipsisHorizontalCircleIcon';
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon';
import EllipsisVerticalIcon from '@heroicons/react/24/outline/EllipsisVerticalIcon';
import EnvelopeIcon from '@heroicons/react/24/outline/EnvelopeIcon';
import EnvelopeOpenIcon from '@heroicons/react/24/outline/EnvelopeOpenIcon';
import ExclamationCircleIcon from '@heroicons/react/24/outline/ExclamationCircleIcon';
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon';
import EyeDropperIcon from '@heroicons/react/24/outline/EyeDropperIcon';
import EyeIcon from '@heroicons/react/24/outline/EyeIcon';
import EyeSlashIcon from '@heroicons/react/24/outline/EyeSlashIcon';
import FaceFrownIcon from '@heroicons/react/24/outline/FaceFrownIcon';
import FaceSmileIcon from '@heroicons/react/24/outline/FaceSmileIcon';
import FilmIcon from '@heroicons/react/24/outline/FilmIcon';
import FingerPrintIcon from '@heroicons/react/24/outline/FingerPrintIcon';
import FireIcon from '@heroicons/react/24/outline/FireIcon';
import FlagIcon from '@heroicons/react/24/outline/FlagIcon';
import FolderArrowDownIcon from '@heroicons/react/24/outline/FolderArrowDownIcon';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import FolderMinusIcon from '@heroicons/react/24/outline/FolderMinusIcon';
import FolderOpenIcon from '@heroicons/react/24/outline/FolderOpenIcon';
import FolderPlusIcon from '@heroicons/react/24/outline/FolderPlusIcon';
import ForwardIcon from '@heroicons/react/24/outline/ForwardIcon';
import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import GifIcon from '@heroicons/react/24/outline/GifIcon';
import GiftIcon from '@heroicons/react/24/outline/GiftIcon';
import GiftTopIcon from '@heroicons/react/24/outline/GiftTopIcon';
import GlobeAltIcon from '@heroicons/react/24/outline/GlobeAltIcon';
import GlobeAmericasIcon from '@heroicons/react/24/outline/GlobeAmericasIcon';
import GlobeAsiaAustraliaIcon from '@heroicons/react/24/outline/GlobeAsiaAustraliaIcon';
import GlobeEuropeAfricaIcon from '@heroicons/react/24/outline/GlobeEuropeAfricaIcon';
import HandRaisedIcon from '@heroicons/react/24/outline/HandRaisedIcon';
import HandThumbDownIcon from '@heroicons/react/24/outline/HandThumbDownIcon';
import HandThumbUpIcon from '@heroicons/react/24/outline/HandThumbUpIcon';
import HashtagIcon from '@heroicons/react/24/outline/HashtagIcon';
import HeartIcon from '@heroicons/react/24/outline/HeartIcon';
import HomeIcon from '@heroicons/react/24/outline/HomeIcon';
import HomeModernIcon from '@heroicons/react/24/outline/HomeModernIcon';
import IdentificationIcon from '@heroicons/react/24/outline/IdentificationIcon';
import InboxArrowDownIcon from '@heroicons/react/24/outline/InboxArrowDownIcon';
import InboxIcon from '@heroicons/react/24/outline/InboxIcon';
import InboxStackIcon from '@heroicons/react/24/outline/InboxStackIcon';
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import KeyIcon from '@heroicons/react/24/outline/KeyIcon';
import LanguageIcon from '@heroicons/react/24/outline/LanguageIcon';
import LifebuoyIcon from '@heroicons/react/24/outline/LifebuoyIcon';
import LightBulbIcon from '@heroicons/react/24/outline/LightBulbIcon';
import LinkIcon from '@heroicons/react/24/outline/LinkIcon';
import ListBulletIcon from '@heroicons/react/24/outline/ListBulletIcon';
import LockClosedIcon from '@heroicons/react/24/outline/LockClosedIcon';
import LockOpenIcon from '@heroicons/react/24/outline/LockOpenIcon';
import MagnifyingGlassCircleIcon from '@heroicons/react/24/outline/MagnifyingGlassCircleIcon';
import MagnifyingGlassIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';
import MagnifyingGlassMinusIcon from '@heroicons/react/24/outline/MagnifyingGlassMinusIcon';
import MagnifyingGlassPlusIcon from '@heroicons/react/24/outline/MagnifyingGlassPlusIcon';
import MapIcon from '@heroicons/react/24/outline/MapIcon';
import MapPinIcon from '@heroicons/react/24/outline/MapPinIcon';
import MegaphoneIcon from '@heroicons/react/24/outline/MegaphoneIcon';
import MicrophoneIcon from '@heroicons/react/24/outline/MicrophoneIcon';
import MinusCircleIcon from '@heroicons/react/24/outline/MinusCircleIcon';
import MinusIcon from '@heroicons/react/24/outline/MinusIcon';
import MinusSmallIcon from '@heroicons/react/24/outline/MinusSmallIcon';
import MoonIcon from '@heroicons/react/24/outline/MoonIcon';
import MusicalNoteIcon from '@heroicons/react/24/outline/MusicalNoteIcon';
import NewspaperIcon from '@heroicons/react/24/outline/NewspaperIcon';
import NoSymbolIcon from '@heroicons/react/24/outline/NoSymbolIcon';
import PaintBrushIcon from '@heroicons/react/24/outline/PaintBrushIcon';
import PaperAirplaneIcon from '@heroicons/react/24/outline/PaperAirplaneIcon';
import PaperClipIcon from '@heroicons/react/24/outline/PaperClipIcon';
import PauseCircleIcon from '@heroicons/react/24/outline/PauseCircleIcon';
import PauseIcon from '@heroicons/react/24/outline/PauseIcon';
import PencilIcon from '@heroicons/react/24/outline/PencilIcon';
import PencilSquareIcon from '@heroicons/react/24/outline/PencilSquareIcon';
import PhoneArrowDownLeftIcon from '@heroicons/react/24/outline/PhoneArrowDownLeftIcon';
import PhoneArrowUpRightIcon from '@heroicons/react/24/outline/PhoneArrowUpRightIcon';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';
import PhoneXMarkIcon from '@heroicons/react/24/outline/PhoneXMarkIcon';
import PhotoIcon from '@heroicons/react/24/outline/PhotoIcon';
import PlayCircleIcon from '@heroicons/react/24/outline/PlayCircleIcon';
import PlayIcon from '@heroicons/react/24/outline/PlayIcon';
import PlayPauseIcon from '@heroicons/react/24/outline/PlayPauseIcon';
import PlusCircleIcon from '@heroicons/react/24/outline/PlusCircleIcon';
import PlusIcon from '@heroicons/react/24/outline/PlusIcon';
import PlusSmallIcon from '@heroicons/react/24/outline/PlusSmallIcon';
import PowerIcon from '@heroicons/react/24/outline/PowerIcon';
import PresentationChartBarIcon from '@heroicons/react/24/outline/PresentationChartBarIcon';
import PresentationChartLineIcon from '@heroicons/react/24/outline/PresentationChartLineIcon';
import PrinterIcon from '@heroicons/react/24/outline/PrinterIcon';
import PuzzlePieceIcon from '@heroicons/react/24/outline/PuzzlePieceIcon';
import QrCodeIcon from '@heroicons/react/24/outline/QrCodeIcon';
import QuestionMarkCircleIcon from '@heroicons/react/24/outline/QuestionMarkCircleIcon';
import QueueListIcon from '@heroicons/react/24/outline/QueueListIcon';
import RadioIcon from '@heroicons/react/24/outline/RadioIcon';
import ReceiptPercentIcon from '@heroicons/react/24/outline/ReceiptPercentIcon';
import ReceiptRefundIcon from '@heroicons/react/24/outline/ReceiptRefundIcon';
import RectangleGroupIcon from '@heroicons/react/24/outline/RectangleGroupIcon';
import RectangleStackIcon from '@heroicons/react/24/outline/RectangleStackIcon';
import RocketLaunchIcon from '@heroicons/react/24/outline/RocketLaunchIcon';
import RssIcon from '@heroicons/react/24/outline/RssIcon';
import ScaleIcon from '@heroicons/react/24/outline/ScaleIcon';
import ScissorsIcon from '@heroicons/react/24/outline/ScissorsIcon';
import ServerIcon from '@heroicons/react/24/outline/ServerIcon';
import ServerStackIcon from '@heroicons/react/24/outline/ServerStackIcon';
import ShareIcon from '@heroicons/react/24/outline/ShareIcon';
import ShieldCheckIcon from '@heroicons/react/24/outline/ShieldCheckIcon';
import ShieldExclamationIcon from '@heroicons/react/24/outline/ShieldExclamationIcon';
import ShoppingBagIcon from '@heroicons/react/24/outline/ShoppingBagIcon';
import ShoppingCartIcon from '@heroicons/react/24/outline/ShoppingCartIcon';
import SignalIcon from '@heroicons/react/24/outline/SignalIcon';
import SignalSlashIcon from '@heroicons/react/24/outline/SignalSlashIcon';
import SparklesIcon from '@heroicons/react/24/outline/SparklesIcon';
import SpeakerWaveIcon from '@heroicons/react/24/outline/SpeakerWaveIcon';
import SpeakerXMarkIcon from '@heroicons/react/24/outline/SpeakerXMarkIcon';
import Square2StackIcon from '@heroicons/react/24/outline/Square2StackIcon';
import Square3Stack3DIcon from '@heroicons/react/24/outline/Square3Stack3DIcon';
import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import SquaresPlusIcon from '@heroicons/react/24/outline/SquaresPlusIcon';
import StarIcon from '@heroicons/react/24/outline/StarIcon';
import StopCircleIcon from '@heroicons/react/24/outline/StopCircleIcon';
import StopIcon from '@heroicons/react/24/outline/StopIcon';
import SunIcon from '@heroicons/react/24/outline/SunIcon';
import SwatchIcon from '@heroicons/react/24/outline/SwatchIcon';
import TableCellsIcon from '@heroicons/react/24/outline/TableCellsIcon';
import TagIcon from '@heroicons/react/24/outline/TagIcon';
import TicketIcon from '@heroicons/react/24/outline/TicketIcon';
import TrashIcon from '@heroicons/react/24/outline/TrashIcon';
import TrophyIcon from '@heroicons/react/24/outline/TrophyIcon';
import TruckIcon from '@heroicons/react/24/outline/TruckIcon';
import TvIcon from '@heroicons/react/24/outline/TvIcon';
import UserCircleIcon from '@heroicons/react/24/outline/UserCircleIcon';
import UserGroupIcon from '@heroicons/react/24/outline/UserGroupIcon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';
import UserMinusIcon from '@heroicons/react/24/outline/UserMinusIcon';
import UserPlusIcon from '@heroicons/react/24/outline/UserPlusIcon';
import UsersIcon from '@heroicons/react/24/outline/UsersIcon';
import VariableIcon from '@heroicons/react/24/outline/VariableIcon';
import VideoCameraIcon from '@heroicons/react/24/outline/VideoCameraIcon';
import VideoCameraSlashIcon from '@heroicons/react/24/outline/VideoCameraSlashIcon';
import ViewColumnsIcon from '@heroicons/react/24/outline/ViewColumnsIcon';
import ViewfinderCircleIcon from '@heroicons/react/24/outline/ViewfinderCircleIcon';
import WalletIcon from '@heroicons/react/24/outline/WalletIcon';
import WifiIcon from '@heroicons/react/24/outline/WifiIcon';
import WindowIcon from '@heroicons/react/24/outline/WindowIcon';
import WrenchIcon from '@heroicons/react/24/outline/WrenchIcon';
import WrenchScrewdriverIcon from '@heroicons/react/24/outline/WrenchScrewdriverIcon';
import XCircleIcon from '@heroicons/react/24/outline/XCircleIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';

const exportedModules = {
	AcademicCapIcon,
	AdjustmentsHorizontalIcon,
	AdjustmentsVerticalIcon,
	ArchiveBoxArrowDownIcon,
	ArchiveBoxIcon,
	ArchiveBoxXMarkIcon,
	ArrowDownCircleIcon,
	ArrowDownIcon,
	ArrowDownLeftIcon,
	ArrowDownOnSquareIcon,
	ArrowDownOnSquareStackIcon,
	ArrowDownRightIcon,
	ArrowDownTrayIcon,
	ArrowLeftCircleIcon,
	ArrowLeftIcon,
	ArrowLeftOnRectangleIcon,
	ArrowLongDownIcon,
	ArrowLongLeftIcon,
	ArrowLongRightIcon,
	ArrowLongUpIcon,
	ArrowPathIcon,
	ArrowPathRoundedSquareIcon,
	ArrowRightCircleIcon,
	ArrowRightIcon,
	ArrowRightOnRectangleIcon,
	ArrowSmallDownIcon,
	ArrowSmallLeftIcon,
	ArrowSmallRightIcon,
	ArrowSmallUpIcon,
	ArrowTopRightOnSquareIcon,
	ArrowTrendingDownIcon,
	ArrowTrendingUpIcon,
	ArrowUpCircleIcon,
	ArrowUpIcon,
	ArrowUpLeftIcon,
	ArrowUpOnSquareIcon,
	ArrowUpOnSquareStackIcon,
	ArrowUpRightIcon,
	ArrowUpTrayIcon,
	ArrowUturnDownIcon,
	ArrowUturnLeftIcon,
	ArrowUturnRightIcon,
	ArrowUturnUpIcon,
	ArrowsPointingInIcon,
	ArrowsPointingOutIcon,
	ArrowsRightLeftIcon,
	ArrowsUpDownIcon,
	AtSymbolIcon,
	BackspaceIcon,
	BackwardIcon,
	BanknotesIcon,
	Bars2Icon,
	Bars3BottomLeftIcon,
	Bars3BottomRightIcon,
	Bars3CenterLeftIcon,
	Bars3Icon,
	Bars4Icon,
	BarsArrowDownIcon,
	BarsArrowUpIcon,
	Battery0Icon,
	Battery100Icon,
	Battery50Icon,
	BeakerIcon,
	BellAlertIcon,
	BellIcon,
	BellSlashIcon,
	BellSnoozeIcon,
	BoltIcon,
	BoltSlashIcon,
	BookOpenIcon,
	BookmarkIcon,
	BookmarkSlashIcon,
	BookmarkSquareIcon,
	BriefcaseIcon,
	BugAntIcon,
	BuildingLibraryIcon,
	BuildingOffice2Icon,
	BuildingOfficeIcon,
	BuildingStorefrontIcon,
	CakeIcon,
	CalculatorIcon,
	CalendarDaysIcon,
	CalendarIcon,
	CameraIcon,
	ChartBarIcon,
	ChartBarSquareIcon,
	ChartPieIcon,
	ChatBubbleBottomCenterIcon,
	ChatBubbleBottomCenterTextIcon,
	ChatBubbleLeftEllipsisIcon,
	ChatBubbleLeftIcon,
	ChatBubbleLeftRightIcon,
	ChatBubbleOvalLeftEllipsisIcon,
	ChatBubbleOvalLeftIcon,
	CheckBadgeIcon,
	CheckCircleIcon,
	CheckIcon,
	ChevronDoubleDownIcon,
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
	ChevronDoubleUpIcon,
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpDownIcon,
	ChevronUpIcon,
	CircleStackIcon,
	ClipboardDocumentCheckIcon,
	ClipboardDocumentIcon,
	ClipboardDocumentListIcon,
	ClipboardIcon,
	ClockIcon,
	CloudArrowDownIcon,
	CloudArrowUpIcon,
	CloudIcon,
	CodeBracketIcon,
	CodeBracketSquareIcon,
	Cog6ToothIcon,
	Cog8ToothIcon,
	CogIcon,
	CommandLineIcon,
	ComputerDesktopIcon,
	CpuChipIcon,
	CreditCardIcon,
	CubeIcon,
	CubeTransparentIcon,
	CurrencyBangladeshiIcon,
	CurrencyDollarIcon,
	CurrencyEuroIcon,
	CurrencyPoundIcon,
	CurrencyRupeeIcon,
	CurrencyYenIcon,
	CursorArrowRaysIcon,
	CursorArrowRippleIcon,
	DevicePhoneMobileIcon,
	DeviceTabletIcon,
	DocumentArrowDownIcon,
	DocumentArrowUpIcon,
	DocumentChartBarIcon,
	DocumentCheckIcon,
	DocumentDuplicateIcon,
	DocumentIcon,
	DocumentMagnifyingGlassIcon,
	DocumentMinusIcon,
	DocumentPlusIcon,
	DocumentTextIcon,
	EllipsisHorizontalCircleIcon,
	EllipsisHorizontalIcon,
	EllipsisVerticalIcon,
	EnvelopeIcon,
	EnvelopeOpenIcon,
	ExclamationCircleIcon,
	ExclamationTriangleIcon,
	EyeDropperIcon,
	EyeIcon,
	EyeSlashIcon,
	FaceFrownIcon,
	FaceSmileIcon,
	FilmIcon,
	FingerPrintIcon,
	FireIcon,
	FlagIcon,
	FolderArrowDownIcon,
	FolderIcon,
	FolderMinusIcon,
	FolderOpenIcon,
	FolderPlusIcon,
	ForwardIcon,
	FunnelIcon,
	GifIcon,
	GiftIcon,
	GiftTopIcon,
	GlobeAltIcon,
	GlobeAmericasIcon,
	GlobeAsiaAustraliaIcon,
	GlobeEuropeAfricaIcon,
	HandRaisedIcon,
	HandThumbDownIcon,
	HandThumbUpIcon,
	HashtagIcon,
	HeartIcon,
	HomeIcon,
	HomeModernIcon,
	IdentificationIcon,
	InboxArrowDownIcon,
	InboxIcon,
	InboxStackIcon,
	InformationCircleIcon,
	KeyIcon,
	LanguageIcon,
	LifebuoyIcon,
	LightBulbIcon,
	LinkIcon,
	ListBulletIcon,
	LockClosedIcon,
	LockOpenIcon,
	MagnifyingGlassCircleIcon,
	MagnifyingGlassIcon,
	MagnifyingGlassMinusIcon,
	MagnifyingGlassPlusIcon,
	MapIcon,
	MapPinIcon,
	MegaphoneIcon,
	MicrophoneIcon,
	MinusCircleIcon,
	MinusIcon,
	MinusSmallIcon,
	MoonIcon,
	MusicalNoteIcon,
	NewspaperIcon,
	NoSymbolIcon,
	PaintBrushIcon,
	PaperAirplaneIcon,
	PaperClipIcon,
	PauseCircleIcon,
	PauseIcon,
	PencilIcon,
	PencilSquareIcon,
	PhoneArrowDownLeftIcon,
	PhoneArrowUpRightIcon,
	PhoneIcon,
	PhoneXMarkIcon,
	PhotoIcon,
	PlayCircleIcon,
	PlayIcon,
	PlayPauseIcon,
	PlusCircleIcon,
	PlusIcon,
	PlusSmallIcon,
	PowerIcon,
	PresentationChartBarIcon,
	PresentationChartLineIcon,
	PrinterIcon,
	PuzzlePieceIcon,
	QrCodeIcon,
	QuestionMarkCircleIcon,
	QueueListIcon,
	RadioIcon,
	ReceiptPercentIcon,
	ReceiptRefundIcon,
	RectangleGroupIcon,
	RectangleStackIcon,
	RocketLaunchIcon,
	RssIcon,
	ScaleIcon,
	ScissorsIcon,
	ServerIcon,
	ServerStackIcon,
	ShareIcon,
	ShieldCheckIcon,
	ShieldExclamationIcon,
	ShoppingBagIcon,
	ShoppingCartIcon,
	SignalIcon,
	SignalSlashIcon,
	SparklesIcon,
	SpeakerWaveIcon,
	SpeakerXMarkIcon,
	Square2StackIcon,
	Square3Stack3DIcon,
	Squares2X2Icon,
	SquaresPlusIcon,
	StarIcon,
	StopCircleIcon,
	StopIcon,
	SunIcon,
	SwatchIcon,
	TableCellsIcon,
	TagIcon,
	TicketIcon,
	TrashIcon,
	TrophyIcon,
	TruckIcon,
	TvIcon,
	UserCircleIcon,
	UserGroupIcon,
	UserIcon,
	UserMinusIcon,
	UserPlusIcon,
	UsersIcon,
	VariableIcon,
	VideoCameraIcon,
	VideoCameraSlashIcon,
	ViewColumnsIcon,
	ViewfinderCircleIcon,
	WalletIcon,
	WifiIcon,
	WindowIcon,
	WrenchIcon,
	WrenchScrewdriverIcon,
	XCircleIcon,
	XMarkIcon
};

export default exportedModules;