import { useState, useEffect, useRef, useCallback } from 'react';

const useInView = (options) => {
  const [inView, setInView] = useState([]);
  const refs = useRef([]);

  const observe = useCallback((node) => {
    if (node !== null) {
      refs.current.push(node);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          const index = refs.current.indexOf(entry.target);
          if (entry.isIntersecting) {
            setInView((prev) => {
              const updated = [...prev];
              updated[index] = true;
              return updated;
            });
          } else {
            setInView((prev) => {
              const updated = [...prev];
              updated[index] = false;
              return updated;
            });
          }
        });
      },
      { threshold: 0.1, ...options }
    );

    refs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => {
      refs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [options, observe]);

  return [observe, inView];
};

export default useInView;