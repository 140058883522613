import { combineReducers } from "redux";
import app from "../model/app";


export default combineReducers({

  navigation: (state=[...app.navigation], action)=>{
    if (action.name!=='navigation'){ return state; }
    switch(action.type){
      case 'UPDATE_NAVIGATION':{
        // let newNavigation=[...state];
        let newNavigation=[...action.payload];
        return newNavigation;
      }
      default: return state;
    }
  },
  
  token: (state=app.token, action)=>{
    if (action.name!=='token'){ return state; }
    switch(action.type){
      case 'UPDATE_TOKEN':{
        let newToken= action.payload;
        return newToken;
			}
			case 'REMOVE_TOKEN': {
				return null;
			}
      default: return state;
    }
  },
  
	unseenalerts: (state = app.unseenalerts, action) => {
    if (action.name!=='unseenalerts'){ return state; }
    switch(action.type){
			case 'UPDATE_UNSEEN_ALERTS': {
				let newUnseenAlerts = action.payload;
        return newUnseenAlerts;
			}
			case 'REMOVE_UNSEEN_ALERTS': {
				return null;
			}
      default: return state;
    }
	},
	
	tielevel: (state = app.tielevel, action) => {
    if (action.name!=='tielevel'){ return state; }
    switch(action.type){
			case 'UPDATE_TIE_LEVEL': {
				let newTieLevel = action.payload;
        return newTieLevel;
			}
			case 'REMOVE_TIE_LEVEL': {
				return null;
			}
      default: return state;
    }
	},
	
	avatar: (state = app.avatar, action) => {
    if (action.name!=='avatar'){ return state; }
    switch(action.type){
      case 'UPDATE_AVATAR':{
        let newAvatar= action.payload;
        return newAvatar;
			}
			case 'REMOVE_AVATAR': {
				return null;
			}
      default: return state;
    }
  },

//  appState: (state={...app}, action)=>{
//   if (action.name!=='appState'){ return state; }
//   switch(action.type){
//    case 'UPDATE':{
//     let newState= {...state, ...action.payload};
//     return newState
//    }
//    default: return state;
//   }
//  },

//  snapshotData: (state=[], action)=>{
//   if (action.name!=='snapshotData'){ return state; }
//   switch(action.type){
//    case 'UPDATE':{
//     let newState= [...action.payload];
//     return newState
//    }
//    default: return state;
//   }
//  },
 
});