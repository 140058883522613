import isLoadedFromPWA from '../bin/isLoadedFromPWA';

const main = (UnderlyingTicker, OptionExpiration, OptionType, OptionStrike) => {
	let d = new Date(OptionExpiration + "T00:00:00Z");
	// Format the date to YYMMDD
	let formattedDate = d.getUTCFullYear().toString().substr(-2) +
		('0' + (d.getUTCMonth() + 1)).slice(-2) +
		('0' + d.getUTCDate()).slice(-2);
	// Separate whole number and decimal parts of the OptionStrike
	let parts = OptionStrike.toString().split('.');
	let wholeNumber = parts[0];
	let decimalPart = parts.length > 1 ? parts[1] : '0';

	// Format the whole number part to be 5 digits with leading zeros
	let formattedWholeNumber = ('00000' + wholeNumber).slice(-5);

	// Format the decimal part to be 3 digits with trailing zeros
	let formattedDecimalPart = (decimalPart + '000').substring(0, 3);
	
	return `O:${UnderlyingTicker}${formattedDate}${OptionType[0].toUpperCase()}${formattedWholeNumber}${formattedDecimalPart}`;
};

export default main;