const getDSTStartEnd = (year) => {
	const getNthSundayOfMonth = (month, nth) => {
			const date = new Date(Date.UTC(year, month, 1));
			let sundayCount = 0;

			while (sundayCount < nth) {
					if (date.getUTCDay() === 0) { 
							sundayCount++;
					}

					if (sundayCount < nth) {
							date.setUTCDate(date.getUTCDate() + 1);
					}
			}

			// Set the time to 2am (in EST, hence 7am in UTC)
			date.setUTCHours(7, 0, 0, 0);
			return date.getTime();
	};

	const dstStart = getNthSundayOfMonth(2, 2); // 2nd Sunday of March
	const dstEnd = getNthSundayOfMonth(10, 1); // 1st Sunday of November

	return { dstStart, dstEnd };
};

const main = (epoch, returnOffset = false) => {
	if (!epoch) {
			return null;
	}

	// Adjust the epoch if it's in microseconds or nanoseconds
	if (epoch >= 1e18) {
			epoch = Math.trunc(epoch / 1e6);
	} else if (epoch >= 1e15) {
			epoch = Math.trunc(epoch / 1e3);
	}

	const date = new Date(epoch);
	const year = date.getUTCFullYear();
	const { dstStart, dstEnd } = getDSTStartEnd(year);

	const isDst = epoch >= dstStart && epoch < dstEnd;

	const estOffset = isDst ? -4 : -5;
	return returnOffset ? estOffset : isDst;
};

export default main;