import React, {Fragment, useEffect, useState, useRef} from 'react';
import config from "../config.json";
import Headers from "../bin/Headers";
import TICKER_CHART_CHARTJS_V2 from "../components/TICKER_CHART_CHARTJS_V2.js";
import SUBMIT_TRADE from "../components/SUBMIT_TRADE.js";
import OUTPUT_NOTABLE_TRADE_SHARE from "../components/OUTPUT_NOTABLE_TRADE_SHARE.js";
import calculateTimeDifference from "../bin/calculateTimeDifference";
import decodeJwt from "../bin/decodeJwt";
import { useSelector } from 'react-redux';
import { Switch } from '@headlessui/react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from "@heroicons/react/24/solid";
import LOADING from "./LOADING";

const MODAL = (props) => {
	const token = useSelector(state => state.token),
		open = props.open || false,
		setOpen = props.setOpen || null,

		[showBuySideOnly, setShowBuySideOnly] = useState(true),
		[showPrecisionSideMatchOnly, setShowPrecisionSideMatchOnly] = useState(false),
		[minimumBreakEvenMove, setminimumBreadEvenMove] = useState(0),
		[minimumSize, setMinimumSize] = useState(0),
		[minimumNotional, setMinimumNotional] = useState(0),
		[minimumDaysToExpiration, setMinimumDaysToExpiration] = useState(0),
		[maximumDaysToExpiration, setMaximumDaysToExpiration] = useState(0),
		
		[showUpdateActions, setShowUpdateActions] = useState(false),
		
		[LoadingValue, setLoadingValue] = useState(true),
		[alertSetting, setAlertSetting] = useState(null),
		cancelButtonRef = useRef(null);
	
	const start = async () => {
		if (!token) { return; }
		setLoadingValue(true);
		let result = await fetch(`${config.api}/alerts`, { headers: Headers(token) }).then(d => d.json()).then(d => d.result ? d.result : null);
		result = result && result.length ? result.filter(({ TrackWhat }) => TrackWhat === 'NotableOptionTrade') : null;
		result = result && result.length === 1 ? result[0] : null;
		setAlertSetting(result);
		setLoadingValue(false);
	};
	useEffect(() => { start(); }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 md:items-start">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" >
						{/* <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"> */}
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full min-w-[310px] sm:p-6">
							<div className="absolute right-0 top-0 pr-4 pt-4 block">
								<button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => setOpen(false)} >
									<XMarkIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
									Alert Settings: <span className="text-sm text-blue-500 bg-sky-200 px-2 py-0.5 rounded">Notable Trades</span>
								</Dialog.Title>
								<div className="mt-2">
									{ LoadingValue===true ? <div className='w-full'><LOADING color="text-green-700" /></div> : ''}
									{!LoadingValue ?
										<div className='w-full'>

											{/* <Switch.Group as="div" className="flex justify-between">
												<Switch.Label as="span" className="mr-3">
													<span className="text-sm font-medium text-gray-200">Buy Side Only</span>
												</Switch.Label>
												<Switch
													checked={showBuySideOnly}
													onChange={ evt => { setShowBuySideOnly(evt.source.value); }}
													className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${showBuySideOnly ? 'bg-indigo-600' : 'bg-gray-200'}`} >
													<span
														aria-hidden="true"
														className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showBuySideOnly ? 'translate-x-5' : 'translate-x-0'}`} />
												</Switch>
											</Switch.Group> */}

											{/* <div className="max-w-full flex rounded-md shadow-sm mt-2">
												<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
													Breakeven +/-
												</span>
												<input
													type="number"
													value={alertSetting.BreakevenMove}
													onChange={ evt => setAlertSetting({...alertSetting, BreakevenMove:evt.target.value}) }
													className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
												/>
											</div>
											<div className="max-w-full flex rounded-md shadow-sm mt-2">
												<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
													Notional
												</span>
												<input
													type="number"
													value={alertSetting.Notional}
													onChange={ evt => setAlertSetting({...alertSetting, Notional:evt.target.value}) }
													className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
												/>
											</div>
											<div className="max-w-full flex rounded-md shadow-sm mt-2">
												<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
													Size
												</span>
												<input
													type="number"
													value={alertSetting.Size}
													onChange={ evt => setAlertSetting({...alertSetting, Size:evt.target.value}) }
													className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
												/>
											</div> */}



										</div>
										: ''}
								</div>
								
							</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
export default MODAL;