import truncateToTwoDigits from "../bin/truncateToTwoDigits";
const main = (entry, textonly=false) => {
	let projectedTotal = (entry.Size + entry.OpenInterestBeforeTrade);
	let percent = truncateToTwoDigits(((entry.OpenInterestAfterTrade / projectedTotal) * 100), 0);
	if (textonly && percent < 75) { return null; }
	if (percent < 75) {return <></>; }
	// console.log(`projectedTotal: ${projectedTotal} | ${entry.OpenInterestAfterTrade} | ${percent}`);

	if (textonly) { return `OI Probable (${percent}%)`; }
	return <><span className='block font-medium text-yellow-800 w-100 text-center'>oi probable [{ percent }%]</span></>;
};
export default main;