import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import config from "../config.json";
import _logout from "../bin/logout";
import dontRerender from "../bin/dontRerender";
import todaysDate from "../bin/todaysDate";
import determineTradingDayOfWeek from "../bin/determineTradingDayOfWeek";
import determineMarketOpenTimestamp from "../bin/determineMarketOpenTimestamp";
import determineMarketCloseTimestamp from "../bin/determineMarketCloseTimestamp";
import determineMarketStatusBasedOnDayAndTime from "../bin/determineMarketStatusBasedOnDayAndTime";
import Headers from '../bin/Headers';
import abbrInt from '../bin/abbrInt';
import currentTime from '../bin/currentTime';
import setMetaTags from '../bin/setMetaTags';
import TICKER_INPUT_WITH_INFO_BUTTON from "../components/TICKER_INPUT_WITH_INFO_BUTTON";
import TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL from "../components/TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL";
import ORDER_BOOK from "../components/ORDER_BOOK";
import TRADE_LOG from "../components/TRADE_LOG";
import NOTABLE_TRADES from "../components/NOTABLE_TRADES";
import OPTION_OPEN_INTEREST from "../components/OPTION_OPEN_INTEREST";
import OPTIONS_LARGEST_TRADES from "../components/OPTIONS_LARGEST_TRADES";
import STOCKS_LARGEST_TRADES from "../components/STOCKS_LARGEST_TRADES";
import ECHART from "../components/ECHART";
import LOADING from "../components/LOADING";
import { useWebSocket } from '../components/WEBSOCKET_CONTEXT';

// import _data from "../bin/QQQ_2024-06-04.json";

const MAIN = (props) => {
	let token = useSelector(state => state.token),
		{ symbol } = useParams(),
		{ websocket } = useWebSocket(),
		[ticker, updateTicker] = useState(symbol || ''),
		[searchParams, setSearchParams] = useSearchParams(),
		[topTrades, updateTopTrades] = useState(null),
		[topTradeType, updateTopTradeType] = useState('Liquidity'),
		[candles, updateCandles] = useState([]),
		[liquidCandles, updateLiquidCandles] = useState([]),
		[priceLevels, updatePriceLevels] = useState([]),
		[lastPrice, updateLastPrice] = useState(null),
		[filtered, updateFiltered] = useState(false),
		// [expirationDate, updateExpirationDate] = useState(null),
		// [expirationDate, updateExpirationDate] = useState(''),
		// [expirationDate, updateExpirationDate] = useState(searchParams.get('date') ? searchParams.get('date') : todaysDate()),
		[expirationDate, updateExpirationDate] = useState(searchParams.get('date') ? searchParams.get('date') : ''),
		// [expirationDate, updateExpirationDate] = useState('2024-06-14'),
		// [expirationDate, updateExpirationDate] = useState('2024-06-10'),
		[expirationDates, updateExpirationDates] = useState([]),
		[focalDate, updateFocalDate] = useState(null),
		[showType, updateShowType] = useState('shares'),
		[sentiment, updateSentiment] = useState(null),
		tradingDate = useRef(null),
		[loading, setLoading] = useState(true);
		
	symbol = symbol ? symbol.toUpperCase() : null;
	

	const loadLiquidityChart = async (payload) => {
		if (!ticker) { return; }
		let _expirationDate = payload && payload.date ? payload.date : expirationDate;
		let data = await fetch(`https://api.redstripedtie.com/liquidityoptionchart?ticker=${ticker}${_expirationDate ? `&date=${_expirationDate}` : ``}`, { headers: Headers(token) }).then(x => x.json()).then(x => x.result ? x.result : null);
		// let data = await fetch(`https://api.redstripedtie.com/liquidityoptionchart?ticker=${ticker}${_expirationDate ? `&date=${_expirationDate}` : `&date=ALL`}`, { headers: Headers(token) }).then(x => x.json()).then(x => x.result ? x.result : null);
		if (!data || !data.data) { return; }
		updateLiquidCandles(data.data);
		// console.log('Data:', data.data[data.data.length - 1]);
		updateSentiment(data.data[data.data.length - 1]);
		if (data.expirationDates && data.expirationDates.length) {
			updateExpirationDates(data.expirationDates);
		}
		if (data.tradingDate) {
			tradingDate.current = data.tradingDate;
		}
		let _focalDate = tradingDate.current ? tradingDate.current : todaysDate();
		updateFocalDate(_focalDate);
		// let focalDate = todaysDate();
		await loadCandleChart(_focalDate);
	};

	const loadPriceLevels = async (priceBounds) => {
		let data = await fetch(`https://api.redstripedtie.com/tickerstats?ticker=${ticker}`, { headers: Headers(token) }).then(x => x.json()).then(x => x.result ? x.result : null);
		if (!data || !data.PriceLevels) { return; }
		//filter the data (price field) based on the priceBounds (upper, lower)
		data = data.PriceLevels.filter(x => {
			if (x.price >= priceBounds.lower && x.price <= priceBounds.upper) { return true; }
			return false;
		});
		updatePriceLevels(data);
	};
	
	const loadCandleChart = async (focalDate) => {
		if (!ticker || !focalDate) { return; }
		let _candles = await fetch(`https://io.redstripedtie.com/ursa/candles_p3?ticker=${ticker}&format=minute&startDate=${focalDate}&endDate=${focalDate}`, { headers: Headers(token) }).then(x => x.json()).then(x => x.result ? x.result : null);
		if (!_candles) { return; }
		let startOfOpenMarket = determineMarketOpenTimestamp(focalDate);
		let endOfOpenMarket = determineMarketCloseTimestamp(focalDate);
		//add 3hrs to the startOfOpenMarket and endOfOpenMarket to adjust for EST
		startOfOpenMarket += 10800000;
		endOfOpenMarket += 10800000;
		//add 15mins to endOfOpenMarket to adjust for the last candle
		endOfOpenMarket += 900000;
		_candles = _candles.filter(x => {
			let candleTimestamp = new Date(`${x.DateString}T${x.TimeString}`).getTime();
			return candleTimestamp >= startOfOpenMarket && candleTimestamp <= endOfOpenMarket;
		});

		// Detect gaps and fill them
		const oneMinute = 60000;
		let filledCandles = [];
		for (let i = 0; i < _candles.length - 1; i++) {
			filledCandles.push(_candles[i]);

			let currentCandleTime = new Date(`${_candles[i].DateString}T${_candles[i].TimeString}`).getTime();
			let nextCandleTime = new Date(`${_candles[i + 1].DateString}T${_candles[i + 1].TimeString}`).getTime();

			while (nextCandleTime - currentCandleTime > oneMinute) {
				currentCandleTime += oneMinute;
				let newDate = new Date(currentCandleTime);
				filledCandles.push({
					Volume: 0,
					Open: _candles[i].Close,
					Close: _candles[i].Close,
					High: _candles[i].Close,
					Low: _candles[i].Close,
					DateString: newDate.toISOString().split('T')[0],
					TimeString: newDate.toTimeString().split(' ')[0],
					Trades: 0
				});
			}
		}
		filledCandles.push(_candles[_candles.length - 1]); // Add the last candle

		updateCandles(filledCandles);

		let lastPrice = filledCandles && filledCandles.length ? filledCandles[filledCandles.length - 1].Close : null;
		let highPrice = filledCandles && filledCandles.length ? [...filledCandles.map(({ High }) => High)] : null;
		let lowPrice = filledCandles && filledCandles.length ? [...filledCandles.map(({ Low }) => Low)] : null;
		let priceBounds = { upper: Math.max(...highPrice), lower: Math.min(...lowPrice) };

		if (priceBounds.upper && !isNaN(priceBounds.upper)) {
			// let highestPrice = highPrice && highPrice.length ? highPrice.sort((a, b) => b - a)[0] : ((priceBounds.upper * 1.02).toFixed(2));
			// priceBounds.upper = highestPrice;
			priceBounds.upper = ((priceBounds.upper * 1.005).toFixed(2));
			// priceBounds.upper = ((priceBounds.upper * 1.02).toFixed(2));
		}
		if (priceBounds.lower && !isNaN(priceBounds.lower)) {
			// let lowestPrice = lowPrice && lowPrice.length ? lowPrice.sort((a, b) => a - b)[0] : ((priceBounds.lower * 0.98).toFixed(2));
			// priceBounds.lower = lowestPrice;
			priceBounds.lower = ((priceBounds.lower * 0.995).toFixed(2));
			// priceBounds.lower = ((priceBounds.lower * 0.98).toFixed(2));
		}

		updateLastPrice(lastPrice);
		await loadPriceLevels(priceBounds);
		
		// updateCandles(_candles);
		// let lastPrice = _candles && _candles.length ? _candles[_candles.length - 1].Close : null;
		// let highPrice = _candles && _candles.length ? [..._candles.map(({ High }) => High)] : null;
		// let lowPrice = _candles && _candles.length ? [..._candles.map(({ Low }) => Low)] : null;
		// let priceBounds = { upper: Math.max(...highPrice), lower: Math.min(...lowPrice) };
		// if (priceBounds.upper && isNaN(priceBounds.upper) === false) {
		// 	priceBounds.upper = ((priceBounds.upper * 1.02).toFixed(2));
		// }
		// if (priceBounds.lower && isNaN(priceBounds.lower) === false) {
		// 	priceBounds.lower = ((priceBounds.lower * 0.98).toFixed(2));
		// }
		// updateLastPrice(lastPrice);
		// // console.log('High Price:', highPrice);
		// // console.log('Low Price:', lowPrice);
		// // console.log('Price Bounds:', priceBounds);
		// await loadPriceLevels(priceBounds);
  };


  const loadData = async (payload) => {
    if (!token){ return; }
		if (!ticker) { return; }
		setLoading(true);
		
		await loadLiquidityChart(payload);

		setLoading(false);
    return;
	};

	useEffect(() => {
		if (ticker !== symbol) { updateTicker(symbol); }
		loadData();
		const interval = setInterval(() => {
			let marketOpen = determineMarketStatusBasedOnDayAndTime();
			if (!marketOpen) { return; }
			window.location.reload();
		}, 60000); // 60000 milliseconds = 1 minute
		return () => clearInterval(interval); // Clear the interval on component unmount
	}, [ticker, symbol]);

  return (
		<div className="w-full my-5 flex gap-4 flex-wrap justify-between items-start text-white">
			{loading ? <div className='w-full h-24 flex items-center justify-center'><LOADING /></div> : <></>}
			{!loading ?
				// <div className='flex gap-5 min-h-24 justify-center xl:justify-between w-full flex flex-wrap flex-col sm:flex-row'>
				// </div>
				<div className="h-full w-full flex flex-col items-center justify-center">
					<ECHART candles={candles} liquidCandles={liquidCandles} priceLevels={priceLevels} showType={showType} />
					<div className="w-full flex flex-row gap-4 flex-wrap justify-evenly items-start text-white">
						{/* <h1>Top Liquidity Option Trades</h1>
						<ul className="flex gap-4">
							{topTrades && topTrades.length > 0 ? topTrades.map((trade, i) => { return <li key={i} className="flex gap-4"><span>{trade.Size} {trade.Type} {trade.TradeSide} {trade.Strike} {trade.ExpiryDateString}</span><span>{trade.Price}</span></li>; }) : <></>}
						</ul> */}
						<h6>{ ticker } - ${ lastPrice } [{focalDate}]</h6>
						<h1 className='text-center flex flex-row gap-1'>
							<span className='bold'>Premium:</span>
							<span className={`px-2 rounded ${sentiment.weightedPremiumSentiment === 'Bullish' ? `bg-emerald-300 text-emerald-600` : (sentiment.weightedPremiumSentiment === 'Bearish' ? `bg-rose-300 text-rose-600` : '-')}`}>
								{sentiment.weightedPremiumSentiment} - ${sentiment.premiumDifference && isNaN(sentiment.premiumDifference) === false ? (sentiment.premiumDifference).toLocaleString() : '-'} {sentiment.weightedPremiumSentiment === 'Bullish' ? sentiment.bullishPremiumPercentage : (sentiment.weightedPremiumSentiment === 'Bearish' ? sentiment.bearishPremiumPercentage : '-')}%
							</span>
						</h1>
						<h1 className='text-center flex flex-row gap-1'>
							<span className='bold'>Shares:</span>
							<span className={`px-2 rounded ${sentiment.weightedSharesSentiment === 'Bullish' ? `bg-emerald-300 text-emerald-600` : (sentiment.weightedSharesSentiment === 'Bearish' ? `bg-rose-300 text-rose-600` : '-')}`}>
								{sentiment.weightedSharesSentiment} - {sentiment.sharesDifference && isNaN(sentiment.sharesDifference) === false ? (sentiment.sharesDifference).toLocaleString() : '-'} {sentiment.weightedSharesSentiment === 'Bullish' ? sentiment.bullishSharesPercentage : (sentiment.weightedSharesSentiment === 'Bearish' ? sentiment.bearishSharesPercentage : '-')}%
							</span>
						</h1>
					</div>
					<h6>[{sentiment.time}] Total Liquidity Shares Pulled: {(sentiment.sharesRepresented).toLocaleString()} - Total Premium: ${ sentiment.bearishPremium && sentiment.bullishPremium ? abbrInt(sentiment.bearishPremium + sentiment.bullishPremium) : '-' }</h6>
					<div className='w-full flex flex-row gap-4 flex-wrap justify-evenly items-start text-white'>
						<select className='w-32 p-2 rounded-md text-black' value={showType} onChange={(e) => { updateShowType(e.target.value); }}>
							<option value='premium'>Premium</option>
							<option value='shares'>Shares</option>
						</select>
						<select className='w-32 p-2 rounded-md text-black' value={topTradeType} onChange={(e) => { updateTopTradeType(e.target.value); loadData({ type: e.target.value }); }}>
							<option value='Liquidity'>Liquidity</option>
							<option value='Binary'>Binary</option>
							<option value='Sized'>Sized</option>
							<option value='Valued'>Valued</option>
							<option value='Latest'>Latest</option>
						</select>
						<select className='w-32 p-2 rounded-md text-black' value={expirationDate} onChange={(e) => { updateExpirationDate(e.target.value); loadData({ date: e.target.value }); }}>
							<option value=''>All Expirations</option>
							{expirationDates && expirationDates.length ? expirationDates.map((date, i) => { return <option key={i} value={date}>{date}</option>; }) : <></>}
						</select>
						{/* <input type='text' className='w-32 p-2 rounded-md text-black' placeholder='Expiration Date' value={expirationDate} onChange={(e) => updateExpirationDate(e.target.value)} /> */}
						<button className={`${filtered ? `bg-blue-500` : `bg-gray-500`} text-white p-2 rounded-md`} onClick={() => { updateFiltered(!filtered); loadData({filtered:!filtered}); }}>
							Filtered
						</button>
						<button className={`bg-blue-500 text-white p-2 rounded-md`} onClick={() => { window.location.reload(); }}>
							Reload
						</button>
					</div>
				</div>
			: <></>}
    </div>
  );
}

export default React.memo(MAIN, dontRerender);